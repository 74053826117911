// fix import eslint error https://github.com/microsoft/TypeScript-React-Starter/issues/8
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './app/index';
import reportWebVitals from './reportWebVitals';

import { store } from './store';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './styles/ftc.scss';

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

console.log(`%cApplication is serving as ${process.env.REACT_APP_WEBSITE} site`, 'background: black; color: yellow');

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} theme="colored" />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
