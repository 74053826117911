import { TableColumn } from 'react-data-table-component';
import { createPortal } from 'react-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import { Icon } from '@shared/components/Icon';
import Permissions from '@shared/components/Permissions';

import { FTC_TEMPLATE_BRANDING } from '@shared/utils/constants';

export const BrandingColumns = (
    handleEdit: Function,
    handleDelete: Function,
    handleSetDefault: Function,
    isAdmin: boolean
) =>
    [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Color Palettes',
            selector: (row, rowIndex) => (
                <div className="d-flex gap-3">
                    {row.templates['general'].properties.slice(0, 5).map((item, colorIndex) => (
                        <div key={colorIndex}>
                            <div
                                id={`uncontrolledTooltipMessage-${rowIndex}-${colorIndex}`}
                                style={{
                                    width: '2em',
                                    height: '2em',
                                    background: item.value,
                                    borderRadius: '50%',
                                    border: item.value === '#ffffff' ? '1px solid #e3e3e3' : 'none',
                                }}
                            ></div>
                            <UncontrolledTooltip
                                placement="top"
                                target={`uncontrolledTooltipMessage-${rowIndex}-${colorIndex}`}
                            >
                                {item.name}
                            </UncontrolledTooltip>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            name: 'Default',
            selector: (row) =>
                row.isDefault ? (
                    <Icon classNames={['material-symbols-outlined', 'text-success']} content="check_circle" />
                ) : (
                    ''
                ),
            width: '100px',
        },
        {
            cell: (row) => (
                <UncontrolledDropdown>
                    <DropdownToggle color="link" className="btn-icon-only" caret={false}>
                        <Icon classNames={['material-symbols-outlined', 'bold']} content="more_vert" />
                    </DropdownToggle>
                    <>
                        {createPortal(
                            <DropdownMenu className="action-dropdown-menu" end={true}>
                                <DropdownItem
                                    className="btn btn-link text-start"
                                    onClick={() => handleEdit({ id: row.id })}
                                >
                                    {row.id === FTC_TEMPLATE_BRANDING || !isAdmin ? 'Preview' : 'Edit'}
                                </DropdownItem>

                                <Permissions permission="super_admin" customerAdminRequried>
                                    <DropdownItem
                                        className="btn btn-link text-start"
                                        onClick={() => handleSetDefault({ id: row.id })}
                                        disabled={row.isDefault}
                                    >
                                        Set as Default
                                    </DropdownItem>
                                </Permissions>
                                <DropdownItem divider className="transparent" />
                                <Permissions permission="super_admin" customerAdminRequried>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        className="btn btn-link btn-link-danger text-start"
                                        onClick={() => handleDelete({ id: row.id })}
                                        disabled={row.id === FTC_TEMPLATE_BRANDING}
                                    >
                                        Delete
                                    </DropdownItem>
                                </Permissions>
                            </DropdownMenu>,
                            document.body
                        )}
                    </>
                </UncontrolledDropdown>
            ),
            width: '50px',
        },
    ] as TableColumn<BrandingData>[];
