import { createSelector } from '@reduxjs/toolkit';
import { TemplateParams } from '@services/api/templates';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface TemlateState extends GenericState<TemplateDetail | null> {}

const initialState: TemlateState = {
    loading: false,
    data: null,
    error: null,
};

const templateSlice = createGenericSlice({
    name: 'template',
    initialState: initialState as TemlateState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: TemplateParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: TemplateDetail }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = templateSlice.actions;

export const selectTemplate = createSelector(
    (state: RootState) => state.template.data,
    (data) => data ?? null
);

export default templateSlice.reducer;
