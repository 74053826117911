import { useEffect, useState, useCallback } from 'react';
import { SearchInput } from '@shared/components/FtcSearchBar';
import { FtcTable } from '@shared/components/FtcTable';
import { HelpLink } from '@shared/components/HelpLink';
import { Icon } from '@shared/components/Icon';
import { FixedLoading } from '@shared/components/Loading';
import Permissions from '@shared/components/Permissions';
import { useFilter } from '@shared/hooks/useFilter';
import { useModal } from '@shared/hooks/useModal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { cancelCreate as cancelCreateSigningCert } from '@store/modules/settings/signingCert/createSigningCertSlice';
import { cancelDelete as cancelDeleteSigningCerts } from '@store/modules/settings/signingCert/deleteSigningCertsSlice';
import {
    selectSigningCerts,
    loadRequest as loadSigningCertsRequest,
    cancelLoad as cancelLoadSigningCerts,
} from '@store/modules/settings/signingCert/signingCertsSlice';
import { cancelUpdate as cancelUpdateSigningCert } from '@store/modules/settings/signingCert/updateSigningCertSlice';
import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';
import DetailsModal from './DetailsModal';
import EditModal from './EditModal';
import { signingCertColumns } from './tableConfig';

const SigningCert = () => {
    const dispatch = useAppDispatch();
    const signingCertsState = useAppSelector((state) => state.signingCerts);
    const createSigningCertState = useAppSelector((state) => state.createSigningCert);
    const updateSigningCertState = useAppSelector((state) => state.updateSigningCert);
    const deleteSigningCertsState = useAppSelector((state) => state.deleteSigningCerts);
    const signingCerts = useAppSelector(selectSigningCerts);
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const { filterValue, setFilterValue, filtered: filteredSigningCerts } = useFilter(signingCerts);
    const { isOpen: isDeleteModalOpen, toggle: toggleDeleteModal, close: closeDeleteModal } = useModal();
    const { isOpen: isCreateModalOpen, toggle: toggleCreateModal, close: closeCreateModal } = useModal();
    const { isOpen: isEditModalOpen, toggle: toggleEditModal, close: closeEditModal } = useModal();
    const { isOpen: isDetialsModalOpen, toggle: toggleDetialsModal, close: closeDetialsModal } = useModal();

    const isDeleteBtnDisabled = selectedRows.length === 0;

    const handleRowSelected = useCallback((state: any) => {
        const rows = state.selectedRows as SigningCert[];
        const items = rows.map(({ id }) => id);
        setSelectedRows(items);
    }, []);

    const handleDeleteByIdModal = ({ id }: { id: string }) => {
        toggleDeleteModal();
        setSelectedRow(id);
    };

    const handleEditModal = ({ id }: { id: string }) => {
        toggleEditModal();
        setSelectedRow(id);
    };

    const handleDetailsModal = ({ id }: { id: string }) => {
        toggleDetialsModal();
        setSelectedRow(id);
    };

    useEffect(() => {
        dispatch(loadSigningCertsRequest());
        return () => {
            dispatch(cancelLoadSigningCerts());
        };
    }, []);

    useEffect(() => {
        if (
            deleteSigningCertsState.loading === false &&
            deleteSigningCertsState.data !== null &&
            deleteSigningCertsState.error === null
        ) {
            console.log('submit success, return to list page');
            closeDeleteModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelDeleteSigningCerts());
        }
    }, [deleteSigningCertsState]);

    useEffect(() => {
        if (
            createSigningCertState.loading === false &&
            createSigningCertState.data !== null &&
            createSigningCertState.error === null
        ) {
            console.log('submit success, return to list page');
            closeCreateModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelCreateSigningCert());
        }
    }, [createSigningCertState]);

    useEffect(() => {
        if (
            updateSigningCertState.loading === false &&
            updateSigningCertState.data !== null &&
            updateSigningCertState.error === null
        ) {
            console.log('submit success, return to list page');
            closeEditModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelUpdateSigningCert());
        }
    }, [updateSigningCertState]);

    return (
        <div className="d-flex gap-5 flex-column">
            <div className="content-wall">
                <h1 className="page-h1">Signing Certificate</h1>
                {signingCertsState.loading && <FixedLoading />}
                <div>
                    {/* table header */}
                    <div className="d-flex justify-content-between flex-wrap gap-5 mb-5">
                        <div className="d-flex flex-wrap gap-5">
                            <Permissions permission="super_admin" customerAdminRequried>
                                <button onClick={toggleCreateModal} className="btn btn-outline-primary">
                                    <Icon
                                        classNames={['material-symbols-outlined', 'fill']}
                                        content="add_circle_outline"
                                    />
                                    Add Certificate
                                </button>
                            </Permissions>
                            <Permissions permission="super_admin" customerAdminRequried>
                                <button
                                    onClick={toggleDeleteModal}
                                    disabled={isDeleteBtnDisabled}
                                    className="btn btn-outline-secondary"
                                >
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="delete" />
                                    Delete
                                </button>
                            </Permissions>
                        </div>
                        <div className="text-end">
                            <div className="d-inline-flex gap-3 align-items-center">
                                <SearchInput
                                    type="text"
                                    placeholder="Search"
                                    onFilter={setFilterValue}
                                    filterValue={filterValue}
                                />
                                <HelpLink mapKey="settings/cert"></HelpLink>
                            </div>
                        </div>
                    </div>

                    <FtcTable
                        data={filteredSigningCerts}
                        columns={signingCertColumns(handleEditModal, handleDetailsModal, handleDeleteByIdModal)}
                        clearSelectedRows={toggleCleared}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRows
                        pagination
                    />

                    <CreateModal
                        isOpen={isCreateModalOpen}
                        toggle={toggleCreateModal}
                        onClosed={() => {
                            closeCreateModal();
                            setSelectedRow('');
                        }}
                        onCanceled={closeCreateModal}
                        data={signingCerts}
                        meta={{
                            submitLoading: createSigningCertState.loading,
                        }}
                    />
                    <EditModal
                        isOpen={isEditModalOpen}
                        toggle={toggleEditModal}
                        data={signingCerts}
                        onClosed={() => {
                            closeEditModal();
                            setSelectedRow('');
                        }}
                        onCanceled={toggleEditModal}
                        meta={{
                            selectedId: selectedRow,
                            submitLoading: updateSigningCertState.loading,
                        }}
                    />
                    <DeleteModal
                        isOpen={isDeleteModalOpen}
                        toggle={toggleDeleteModal}
                        data={signingCerts}
                        onClosed={() => {
                            closeDeleteModal();
                            setSelectedRow('');
                        }}
                        onCanceled={toggleDeleteModal}
                        meta={{
                            submitLoading: deleteSigningCertsState.loading,
                            // for delete, the priorty of an item delete is higher than batch delete
                            selectedIds: selectedRow.length > 0 ? [selectedRow] : selectedRows,
                        }}
                    />
                    <DetailsModal
                        isOpen={isDetialsModalOpen}
                        toggle={toggleDetialsModal}
                        onClosed={() => {
                            closeDetialsModal();
                            setSelectedRow('');
                        }}
                        onBtnClosed={closeDetialsModal}
                        data={signingCerts}
                        meta={{ selectedId: selectedRow }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SigningCert;
