import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createCreateGenericSlice } from '../createCreateSlice';

interface InitialState extends GenericState<Realm | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const createRealmSlice = createCreateGenericSlice({
    name: 'realm',
    initialState: initialState as InitialState,
    reducers: {
        createRequest: (
            state,
            action: PayloadAction<{
                data: CreateRealmData;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        createSuccess: (state, action: PayloadAction<{ data: Realm }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { createRequest, createSuccess, createFailure, cancelCreate } = createRealmSlice.actions;

export default createRealmSlice.reducer;
