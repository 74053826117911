const RelativeLoading = () => {
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div className="spinner-border ftc-loader" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export { RelativeLoading };
