import { createSelector } from '@reduxjs/toolkit';
import { formatAdmin } from './helper';
import { AdminGroupsPermissionsResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<AdminGroupsPermissionsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

// this is for edit modal to load admins and realms data
const adminGroupsPermissionsSlice = createGenericSlice({
    name: 'adminGroupsPermissions',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                groupParams: { group_id?: string };
                permissionParams: { account_group_id?: string };
            }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: AdminGroupsPermissionsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = adminGroupsPermissionsSlice.actions;

export const selectAdminsByGroup = createSelector(
    (state: RootState) => state.adminGroupsPermissions.data?.admins.map(formatAdmin),
    (data) => data ?? []
);

export const selectRealmPermissionsByGroup = createSelector(
    (state: RootState) => state.adminGroupsPermissions.data?.realms,
    (data) => data ?? []
);

export default adminGroupsPermissionsSlice.reducer;
