import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getMetadata } from '@services/api/metadata';
import {
    loadRequest as loadMetadata,
    loadFailure as loadMetadataFailure,
    loadSuccess as loadMetadataSuccess,
    cancelLoad as cancelLoadMetadata,
} from './metadataSlice';

export function* fetchMetadata() {
    try {
        const { response, cancel }: { response: Metadata; cancel: any } = yield race({
            response: call(getMetadata),
            cancel: take(cancelLoadMetadata),
        });

        if (cancel) {
            return;
        }

        yield put(loadMetadataSuccess({ data: response }));
    } catch (e) {
        yield put(loadMetadataFailure(e.message));
    }
}

export function* fetchMetadataSaga() {
    yield takeLatest(loadMetadata, fetchMetadata);
}
