export function formatAuthClientHardToken(element: HardToken): HardTokenData {
    return {
        id: element.sn,
        sn: element.sn,
        updatedAt: element.updated_at,
        createdAt: element.created_at,
        model: element.model,
        realmId: element.realm_id,
        userId: element.user_id,
        username: element.username,
    };
}

export function formatAuthClientSoftToken(element: SoftToken): SoftTokenData {
    return {
        id: element.sn,
        sn: element.sn,
        algorithm: element.algorithm,
        platform: element.platform,
        realmName: element.realm_name,
        registrationId: element.registration_id,
        username: element.username,
    };
}
