import axiosClient from '../axiosClient';
import { API } from '../index';

export type DefaultTemplatesParams = {
    default?: boolean;
    method: SettingsTemplatesMethods;
    type: SettingsTemplatesTypes;
};

export type TemplateParams = {
    id: string;
};

export type PostTemplateParams = {
    name: string;
    method?: SettingsTemplatesMethods;
    type?: SettingsTemplatesTypes;
    subject?: string;
    body: string;
};

export type PreviewTemplateParams = {
    method: SettingsTemplatesMethods;
    type: SettingsTemplatesTypes;
    subject?: string;
    body: string;
};

export type TemplatesResponse = Template[];

export type TemplateResponse = TemplateDetail;

export type CreateTemplateResponse = {
    body: string;
    created_at: string;
    customer_id: string;
    id: string;
    method: string;
    name: string;
    subject: string;
    type: string;
};

type EmailTemplateResponse = {
    body: string;
    method: 'Email';
    name: string;
    subject: string;
    type: 'activation' | 'otp' | 'transfer';
};

type SMSTemplateResponse = {
    body: string;
    method: 'SMS';
    name: string;
    type: 'activation' | 'otp' | 'transfer';
};

export type DefaultTemplateResponse = EmailTemplateResponse | SMSTemplateResponse;

export type PreviewTemplateResponse = {
    body: string;
    subject?: string;
};

export type UpdateTemplateResponse = {
    body: string;
    created_at: string;
    customer_id: string;
    id: string;
    method: string;
    name: string;
    realm_id: null;
    subject: string;
    type: string;
    updated_at: string;
};

export function* getTemplates(params?: DefaultTemplatesParams): Generator {
    return yield axiosClient.get<TemplatesResponse>(API.TEMPLATE_GLOBAL, { params }).then((res) => res.data);
}

export function* getTemplate({ id }: { id: string }): Generator {
    return yield axiosClient.get<TemplateResponse>(`${API.TEMPLATE_GLOBAL}/${id}`).then((res) => res.data);
}

export function* postTemplate({ data }: { data: UpsertTemplateData }): Generator {
    return yield axiosClient.post<CreateTemplateResponse>(API.TEMPLATE_GLOBAL, data).then((res) => res.data);
}

export function* putTemplate({ id, data }: { id: string; data: UpsertTemplateData }): Generator {
    return yield axiosClient.put<UpdateTemplateResponse>(`${API.TEMPLATE_GLOBAL}/${id}`, data).then((res) => res.data);
}

export function* deleteTemplate({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.TEMPLATE_GLOBAL}/${id}`).then((res) => res.data);
}

export function* previewTemplate(params: PreviewTemplateParams): Generator {
    return yield axiosClient.post(`${API.TEMPLATE_PREVIEW}`, params).then((res) => res.data);
}

export function* postPreviewTemplate({ data }: { data: UpdateRealmTemplatePreviewData }): Generator {
    return yield axiosClient.post(API.TEMPLATE_PREVIEW, data).then((res) => res.data);
}
