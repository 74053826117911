import { createSelector } from '@reduxjs/toolkit';
import { formatDeviceTaskData } from './helper';
import { GenericState, createGenericSlice } from '../../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface DeviceTasksState extends GenericState<DeviceTask[] | null> {}

const initialState: DeviceTasksState = {
    loading: false,
    data: null,
    error: null,
};

const deviceTasksSlice = createGenericSlice({
    name: 'deviceTasks',
    initialState: initialState as DeviceTasksState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DeviceTask[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = deviceTasksSlice.actions;

export const selectAllDeviceTasks = createSelector(
    (state: RootState) => state.deviceTasks.data?.map(formatDeviceTaskData),
    (data) => data ?? []
);

export default deviceTasksSlice.reducer;
