import { createSelector } from '@reduxjs/toolkit';
import { AdminsParams } from '@services/api/admins';
import { formatAdmin, formatAdminNotInGroup } from './helper';
import { AdminsByGroupResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<AdminsByGroupResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

// there are two groups in this data
// admin in group & admin not in group

const adminsByGroupSlice = createGenericSlice({
    name: 'adminsByGroup',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                inGroupParams: AdminsParams;
                notInGroupParams: AdminsParams;
            }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: AdminsByGroupResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = adminsByGroupSlice.actions;

export const selectAdminsInGroup = createSelector(
    (state: RootState) => state.adminsByGroup.data?.adminsInGroup.map(formatAdmin),
    (data) => data ?? []
);

export const selectAdminsNotInGroup = createSelector(
    (state: RootState) => state.adminsByGroup.data?.adminsNotInGroup.map(formatAdminNotInGroup),
    (data) => data ?? []
);

export default adminsByGroupSlice.reducer;
