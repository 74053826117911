// Helper functions to generate action types for one Redux state
export const cancel = (action: string) => `CANCEL_${action}`;
export const failure = (action: string) => `${action}_FAILURE`;
export const request = (action: string) => `${action}_REQUEST`;
export const success = (action: string) => `${action}_SUCCESS`;

// export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const POST_LOGIN = 'POST_LOGIN';
export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_AUTH = 'FETCH_AUTH';
export const FETCH_NAVBAR = 'FETCH_NAVBAR';
