import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<DomainMapping[] | null> {}
export type DomainMappingParams = { user_source_id?: string; available?: boolean };

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userSourceDomainMappingsSlice = createGenericSlice({
    name: 'userSourceDomainMappings',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: DomainMappingParams } | undefined>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DomainMapping[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSourceDomainMappingsSlice.actions;

export const userSourceDomainMappings = createSelector(
    (state: RootState) => state.domainMappings.data,
    (data) => {
        return data ?? [];
    }
);

export default userSourceDomainMappingsSlice.reducer;
