import axiosClient from '../axiosClient';
import { API } from '../index';

export type UsersParams = { user_id?: string };
export type AuthSourceParams = { app_id: string };

// export type AdminParams = { group_id?: string };

export function* getUserGroupById({ id }: { id: string }): Generator {
    return yield axiosClient.get<UserGroup>(`${API.USER_GROUP}/${id}`).then((res) => res.data);
}

export function* getUserGroups(): Generator {
    return yield axiosClient.get<UserGroup[]>(API.USER_GROUP).then((res) => res.data);
}

export function* postUserGroup({ data }: { data: CreateUserGroupData }): Generator {
    return yield axiosClient.post<UserGroup>(API.USER_GROUP, data).then((res) => res.data);
}

export function* putUserGroup({ id, data }: { id: string; data: UpsertUserGroupData }): Generator {
    return yield axiosClient.put<UserGroup>(`${API.USER_GROUP}/${id}`, data).then((res) => res.data);
}

export function* deleteUserGroup({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.USER_GROUP}/${id}`).then((res) => res.data);
}

// get users with group info
export function* getUsersWithGroup({ id, params }: { id: string; params?: UsersParams }): Generator {
    return yield axiosClient.get<UserWithGroup[]>(`${API.USER_GROUP}/${id}/member`, { params }).then((res) => res.data);
}

// add users to group
export function* postUsersToGroup({ id, data }: { id: string; data: AddUserToGroup }): Generator {
    return yield axiosClient.post<UserGroupMapping[]>(`${API.USER_GROUP}/${id}/member`, data).then((res) => res.data);
}

// delete user from group
export function* deleteUserFromGroup({ groupId, userId }: { groupId: string; userId: string }): Generator {
    return yield axiosClient.delete(`${API.USER_GROUP}/${groupId}/member/${userId}`).then((res) => res.data);
}

// update users in group
export function* putUsersInGroup({ id, data }: { id: string; data: UpdateUsersInGroup }): Generator {
    return yield axiosClient.put(`${API.USER_GROUP}/${id}/member`, data).then((res) => res.data);
}

// get usergroup application permission list
export function* getUserGroupPermissions({ id }: { id: string }): Generator {
    return yield axiosClient.get<UserGroupPermission>(`${API.USER_GROUP}/${id}/app`).then((res) => res.data);
}

// add usergroup application permission
export function* postUserGroupPermission({ id, data }: { id: string; data: UpsertUserGroupAppPermission }): Generator {
    return yield axiosClient
        .post<UserGroupAppPermissionMapping>(`${API.USER_GROUP}/${id}/app`, data)
        .then((res) => res.data);
}

// update usergroup application permission
export function* putUserGroupPermission({
    id,
    application_id,
    data,
}: {
    id: string;
    application_id: string;
    data: UpsertUserGroupAppPermission;
}): Generator {
    return yield axiosClient
        .put<UserGroupAppPermissionMapping>(`${API.USER_GROUP}/${id}/app/${application_id}`, data)
        .then((res) => res.data);
}

// update usergroup application permission
export function* deleteUserGroupPermission({
    groupId,
    applicationId,
}: {
    groupId: string;
    applicationId: string;
}): Generator {
    return yield axiosClient.delete(`${API.USER_GROUP}/${groupId}/app/${applicationId}`).then((res) => res.data);
}

export function* getApplications(): Generator {
    return yield axiosClient.get(API.APPLICATION).then((res) => res.data);
}
