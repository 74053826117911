import axiosClient from '../axiosClient';
import { API } from '../index';

export type ProfileResponse = Profile[];

export function* getProfiles(): Generator {
    return yield axiosClient.get<ProfileResponse>(API.ADAPTIVEAUTH_PROFILE).then((res) => res.data);
}

export function* getProfile({ id }: { id: string }): Generator {
    return yield axiosClient.get<ProfileResponse>(`${API.ADAPTIVEAUTH_PROFILE}/${id}`).then((res) => res.data);
}

// create Profile
export function* postProfile({ data }: { data: UpsertProfileData }): Generator {
    return yield axiosClient.post<Profile>(API.ADAPTIVEAUTH_PROFILE, data).then((res) => res.data);
}

// update Profile
export function* putProfile({ id, data }: { id?: string; data: UpsertProfileData }): Generator {
    return yield axiosClient.put<Profile>(`${API.ADAPTIVEAUTH_PROFILE}/${id}`, data).then((res) => res.data);
}

export function* deleteProfile({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.ADAPTIVEAUTH_PROFILE}/${id}`).then((res) => res.data);
}
