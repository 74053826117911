export function createGroupData(element: Group): GroupData {
    return {
        id: element.id,
        name: element.name,
        description: element.description,
        groupMemberCount: element.member_count,
        members: element.members,
        isDefault: element.is_default,
        select: false,
    };
}

export function createReceiverData(element: Receiver): ReceiverData {
    return {
        id: element.id,
        name: element.name,
        description: element.description,
        email: element.email,
        isDefault: element.is_default,
        refs: element.groups ? element.groups.split(',').length : 0,
        groups: element.groups,
        select: false,
    };
}
