import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';

interface InitialState extends GenericState<Realm[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userSourceRealmsSlice = createGenericSlice({
    name: 'userSourceRealms',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Realm[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSourceRealmsSlice.actions;

export const selectUserSourceRealms = createSelector(
    (state: RootState) => state.userSourceRealms.data,
    (data) => data ?? []
);

export const selectuserSourceRealmOptions = createSelector(selectUserSourceRealms, (data) =>
    data.map(({ id, name }) => ({ value: id, label: name }))
);

export default userSourceRealmsSlice.reducer;
