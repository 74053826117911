import {
    ActionReducerMapBuilder,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
    createSlice,
} from '@reduxjs/toolkit';

export interface GenericState<T> {
    data: T;
    loading: boolean;
    error: Error | string | null;
}

export const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
    name = '',
    initialState,
    reducers,
    extraReducers,
}: {
    name: string;
    initialState: GenericState<T>;
    reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
    extraReducers?: (builder: ActionReducerMapBuilder<GenericState<T>>) => void;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            loadFailure: (state, action: PayloadAction<Error | string>) => {
                state.loading = false;
                state.error = action.payload;
            },
            cancelLoad: () => initialState,
            ...reducers,
        },
        extraReducers,
    });
};
