import { createSelector } from '@reduxjs/toolkit';
import { formatProfileData } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface ProfileState extends GenericState<ProfileDetailData | null> {}

const initialState: ProfileState = {
    loading: false,
    data: null,
    error: null,
};

const profileSlice = createGenericSlice({
    name: 'profile',
    initialState: initialState as ProfileState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: ProfileDetailData }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = profileSlice.actions;

export const selectProfile = createSelector(
    (state: RootState) => state.profile.data,
    (data) => (data ? formatProfileData(data) : null)
);

export default profileSlice.reducer;
