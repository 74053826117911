import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { FLabelInput, FLabelSelect } from '@shared/components/Form/Label';
import { FFormModal } from '@shared/components/FtcModal';
import { FtcSwal } from '@shared/components/FtcSwal';
import { FixedLoading } from '@shared/components/Loading';
import { FORTIPRODUCT } from '@shared/utils/constants';
import { safeTagsReplace } from '@shared/utils/safeTagsReplace';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    selectProfileOptions,
    selectRealmOptions,
} from '@store/modules/authClients/fortiProducts/fortiProductClientsSlice';
import { upsertRequest } from '@store/modules/authClients/fortiProducts/updateFortiProductClientSlice';

type Props = {
    data: { client?: AuthClientData; clients?: AuthClientData[] };
    meta: {
        selectedId: string;
        submitLoading: boolean;
    };
    onCanceled: () => void;
};

function EditForm({ data, meta, onCanceled }: Props) {
    const dispatch = useAppDispatch();
    const profileOptions = useAppSelector(selectProfileOptions);
    const realmOptions = useAppSelector(selectRealmOptions);
    const [showAlert, setShowAlert] = useState(true);

    const { client, clients } = data;
    const { submitLoading } = meta;

    if (!client || !clients) {
        return <>No data available</>;
    }

    const initialValues = {
        name: client.alias,
        realm_id: client.realmId,
        profile_id: client.profileId,
    };

    const schema = Yup.object().shape({
        name: Yup.string()
            .matches(
                /^[\S]{1,80}$/,
                'Whitespace characters are not allowed. The length is between 1 and 80 (inclusive) characters.'
            )
            // Note: comment out because current website not check, could remove comment if need
            // .test('check name (alias) without duplicated when realm is the same', '', function (value, context) {
            //     return checkAliasDuplicated(value, context, clients);
            // })
            .required(),
        realm_id: Yup.string().test('is-none', 'Realm can not be None', (value) => value !== 'none'),
        profile_id: Yup.string()
            .nullable()
            .transform((val) => (val === '' || val === 'none' ? null : val)),
    });

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
                const castedValues = schema.cast({ ...values }) as FortiProductClientData;

                dispatch(upsertRequest({ id: client.id, data: castedValues }));
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    {/* loading */}
                    {submitLoading && <FixedLoading />}

                    <FLabelInput label="Alias" name="name" type="text" />
                    <FLabelSelect
                        label="Realm"
                        name="realm_id"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            const realmId = e.target.value;
                            const realmName = realmOptions.find(({ id }: { id: string }) => id === realmId)?.name ?? '';

                            if (showAlert) {
                                FtcSwal.fire({
                                    title: `Migrate ${FORTIPRODUCT}`,
                                    icon: 'warning',
                                    html: (
                                        <div className="text-start">
                                            <p>
                                                {`${FORTIPRODUCT} ${
                                                    client.alias ? safeTagsReplace(client.alias) : client.id
                                                } will be assigned to realm ${safeTagsReplace(
                                                    realmName
                                                )}. All the users on the ${FORTIPRODUCT} will be migrated to the destination realm.`}
                                            </p>
                                            <p>When a user is migrated from source realm to destination realm:</p>
                                            <ul>
                                                <li>
                                                    If a user with the same username is already in the destination
                                                    realm, the two users will be merged. The token of the user in the
                                                    destination realm will be kept, and the token of the user in the
                                                    source realm will be disposed;
                                                </li>
                                                <li>
                                                    If there is no user with the same username in the destination realm,
                                                    the user will be removed from the source realm and added to the
                                                    destination realm.
                                                </li>
                                            </ul>
                                        </div>
                                    ),
                                    confirmButtonText: 'Yes',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setFieldValue('realm_id', realmId);
                                        setShowAlert(false);
                                    }
                                });
                            } else {
                                setFieldValue('realm_id', realmId);
                            }
                        }}
                    >
                        {client.realmId === 'none' && (
                            <option key="none" value={'none'}>
                                {'-- None --'}
                            </option>
                        )}
                        {realmOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </FLabelSelect>
                    <FLabelSelect
                        label="Adaptive Auth Profile"
                        name="profile_id"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue('profile_id', e.target.value);
                        }}
                        value={values['profile_id']}
                    >
                        <option value="">--None--</option>
                        {profileOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </FLabelSelect>
                    <FFormModal.ModalFooter>
                        <Button color="primary" outline type="reset" onClick={onCanceled}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" disabled={submitLoading}>
                            Apply
                        </Button>
                    </FFormModal.ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default EditForm;
