import { AxiosResponse } from 'axios';
import axiosClient from '../axiosClient';
import { API } from '../index';

export type NavbarResponse = {
    result: FortiCareMenu;
    status: {
        message: string;
        return_code: 1 | number; // 1 is success
        server_time: string;
    };
};

export function* getNavbar(): Generator {
    return yield axiosClient.get<AxiosResponse<NavbarResponse | AdminMenu>>(API.NAVBAR).then((res) => res.data);
}
