import { createSelector } from '@reduxjs/toolkit';
import { UsageParams } from '@services/api/usage';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UsageRealmsState extends GenericState<Realm[] | null> {}

const initialState: UsageRealmsState = {
    loading: false,
    data: null,
    error: null,
};

const usageRealmsSlice = createGenericSlice({
    name: 'usageRealms',
    initialState: initialState as UsageRealmsState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsageParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Realm[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usageRealmsSlice.actions;

export const selectAllRealms = createSelector(
    (state: RootState) => state.usageRealms.data,
    (data) => {
        if (!data) {
            return [];
        }

        const extendedRealm = [...data];
        extendedRealm.unshift({ id: '', name: 'ALL', client_count: 0 } as Realm);
        return extendedRealm;
    }
);

export default usageRealmsSlice.reducer;
