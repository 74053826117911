import { GenericState, createCreateGenericSlice } from '../createCreateSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: GenericState<WebauthnRegisterationVerification | null> = {
    loading: false,
    data: null,
    error: null,
};

const webauthnRegistrationVerificationSlice = createCreateGenericSlice({
    name: 'webauthnRegistrationVerification',
    initialState: initialState,
    reducers: {
        createRequest: (state, action: PayloadAction<WebauthnRegisterationVerificationData>) => {
            state.loading = true;
        },
        createSuccess: (state, action: PayloadAction<{ data: WebauthnRegisterationVerification }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { createRequest, createSuccess, createFailure, cancelCreate } =
    webauthnRegistrationVerificationSlice.actions;

export default webauthnRegistrationVerificationSlice.reducer;
