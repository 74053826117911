import { PropsWithChildren } from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    ModalHeaderProps,
    ModalBodyProps,
    ModalFooterProps,
} from 'reactstrap';
import { Icon } from '../Icon';

type TFModalCustom = { size?: '' | 'sm' | 'lg' | 'xl' | 'xxl' };
type TFModal = TFModalCustom & ModalProps;

// This is general Modal
function FModal({ children, size = '', ...props }: React.PropsWithChildren<TFModal>) {
    return (
        <Modal {...props} centered={true} size={size} className="ftc-modal">
            {children}
        </Modal>
    );
}

function FHeader({ children, toggle, ...props }: PropsWithChildren<ModalHeaderProps>) {
    return (
        <ModalHeader
            {...props}
            close={
                <button className="close" type="button" onClick={toggle}>
                    <Icon classNames={['material-symbols-outlined']} content="close" />
                </button>
            }
        >
            {children}
        </ModalHeader>
    );
}

function FBody({ children, ...props }: PropsWithChildren<ModalBodyProps>) {
    return (
        <ModalBody {...props} className="ftc-modal-body">
            {children}
        </ModalBody>
    );
}

function FFooter({ children, ...props }: PropsWithChildren<ModalFooterProps>) {
    return (
        <ModalFooter {...props} className="is-border-bottom-radius">
            {children}
        </ModalFooter>
    );
}

FModal.ModalHeader = FHeader;
FModal.ModalBody = FBody;
FModal.ModalFooter = FFooter;

export { FModal };
