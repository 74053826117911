import { ChangeEvent } from 'react';
import './FtcSearchBar.scss';
import { Icon } from '../Icon';

type TSearchInput = {
    type?: 'search' | 'text' | 'number';
    placeholder?: string;
    filterValue: string;
    onFilter: (e: ChangeEvent) => void;
};

function SearchInput({ type = 'text', placeholder, filterValue, onFilter }: TSearchInput) {
    return (
        <div className="ftc-search-bar">
            <input type={type} placeholder={placeholder} value={filterValue} onChange={onFilter} />
            <Icon classNames={['material-symbols-outlined', 'ftc-search-bar-icon']} content="search" />
        </div>
    );
}

export { SearchInput };
