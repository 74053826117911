import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<Branding | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const upsertBrandingSlice = createUpsertGenericSlice({
    name: 'branding',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id?: string;
                data: UpsertBrandingData | UpdateBrandingDefaultData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: Branding }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertBrandingSlice.actions;

export default upsertBrandingSlice.reducer;
