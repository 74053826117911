import axiosClient from '../axiosClient';
import { API } from '../index';

export type TrialParams = {
    validate: string;
};

export function* getTrial({ params }: { params?: TrialParams }): Generator {
    return yield axiosClient.get(API.TRIAL, { params }).then((res) => res.data);
}
