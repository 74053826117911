import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { QuotaParams } from '@services/api/quota';
import { isObjectEmpty } from '@shared/utils/isEmpty';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { createRealmQuota } from './helper';

interface InitialState extends GenericState<RealmQuota | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const realmQuotaSlice = createGenericSlice({
    name: 'realmQuota',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                params: QuotaParams;
            }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmQuota }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmQuotaSlice.actions;

export const selectRealmQuota = createSelector(
    (state: RootState) => state.realms.data?.quota,
    (state: RootState) => state.realmQuota.data,
    (quota, realmQuota) => {
        if (quota && !isObjectEmpty(quota) && realmQuota && !isObjectEmpty(realmQuota)) {
            return createRealmQuota(quota, realmQuota);
        }
        return null;
    }
);

export default realmQuotaSlice.reducer;
