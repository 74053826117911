export const brandingFormatter = (branding: Branding): BrandingData => {
    const { id, name, is_default, templates, apps } = branding;
    const templateMap = ((templates as BrandingTemplate[]) ?? []).reduce(
        (acc, cur) => {
            acc[cur.app_type] = cur;
            return acc;
        },
        {} as Record<string, BrandingTemplate>
    );
    return { id, name, isDefault: is_default, templates: templateMap, apps: apps ?? '' };
};
