import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getAuth, AuthUserResponse, postLogin, getLogout } from '@services/api/auth';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import { loadAuth, loadAuthFailure, loadAuthSuccess, selectAuthUserLogoutHkey } from './authSlice';
import { loadLogin } from './loginSlice';
import { loadLogout } from './logoutSlice';
import { setJsonAccount } from './ssoAccountSlice';
import { selectLogoutUrl } from '../layout/navbarSlice';

export function* fetchAuthUser() {
    try {
        const res: AuthUserResponse = yield call(getAuth);
        if (res.status === 200) {
            //@ts-ignore
            yield put(setJsonAccount({ data: res.data }));
            // @ts-ignore
            return yield put(loadAuthSuccess({ user: res.data }));
        }

        throw Error("can't get user info");
    } catch (e) {
        yield put(loadAuthFailure(e.message));
    }
}

export function* fetchLogin(action: ReturnType<typeof loadLogin>) {
    try {
        // @ts-ignore
        const res = yield call(postLogin, action.payload);

        // admin login process handler
        // @ts-ignore
        const auth = yield select((state) => state.auth.user);
        if (auth?.admin === true && res.data?.admin_id) {
            // after admin user login successfully, re-fetch get_user API result
            yield fetchAuthUser();
        }
    } catch (e) {
        console.log('Error occurred in fetchLogin', e.message);
        showErrorAlert(e);
    }
}

export function* fetchLogout(): Generator {
    try {
        const authUserLogoutHey = yield select(selectAuthUserLogoutHkey);
        const logoutUrl = yield select(selectLogoutUrl);
        yield call(
            getLogout,
            authUserLogoutHey as ReturnType<typeof selectAuthUserLogoutHkey>,
            logoutUrl as ReturnType<typeof selectLogoutUrl>
        );
    } catch (e) {
        showErrorAlert(e);
    }
}

export function* fetchAuthUserSaga() {
    yield takeLatest(loadAuth, fetchAuthUser);
}

export function* fetchLoginSaga() {
    yield takeLatest(loadLogin, fetchLogin);
}

export function* fetchLogoutSaga() {
    yield takeLatest(loadLogout, fetchLogout);
}
