import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '@shared/components/FtcSearchBar';
import { FtcTable } from '@shared/components/FtcTable';
import { HelpLink } from '@shared/components/HelpLink';
import { Icon } from '@shared/components/Icon';
import { FixedLoading } from '@shared/components/Loading';
import Permissions from '@shared/components/Permissions';
import { useFilter } from '@shared/hooks/useFilter';
import { useModal } from '@shared/hooks/useModal';
import { FTC_TEMPLATE_BRANDING } from '@shared/utils/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/modules/auth/authSlice';
import {
    loadRequest as loadBrandings,
    cancelLoad as cancelLoadBrandings,
    selectBrandings,
} from '@store/modules/settings/branding/brandingsSlice';
import { cancelDelete as cancelDeletebrandings } from '@store/modules/settings/branding/deleteBrandingsSlice';
import {
    upsertRequest as updateDefaultBranding,
    cancelUpsert as cancelUpdateDefaultBranding,
} from '@store/modules/settings/branding/upsertBrandingSlice';
import DeleteModal from './DeleteModal';
import { BrandingColumns } from './tableConfig';

const Branding = () => {
    const dispatch = useAppDispatch();
    const brandingsState = useAppSelector((state) => state.brandings);
    const updateDefaultBrandingState = useAppSelector((state) => state.upsertBranding);
    const deleteBrandingsState = useAppSelector((state) => state.deleteBrandings);
    const brandings = useAppSelector(selectBrandings);
    const authUser = useAppSelector(selectAuthUser);
    const isAdmin = authUser.is_admin;
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const { filterValue, setFilterValue, filtered: filteredBrandings } = useFilter(brandings);
    const { isOpen: isDeleteModalOpen, toggle: toggleDeleteModal, close: closeDeleteModal } = useModal();
    // const { isOpen: isUpsertModalOpen, toggle: toggleUpsertModal, close: closeUpsertModal } = useModal();
    const navigate = useNavigate();

    const rowDisabledCriteria = (row: BrandingData) => row?.id === FTC_TEMPLATE_BRANDING;
    const isDeleteBtnDisabled = selectedRows.length === 0;

    const handleRowSelected = useCallback((state: any) => {
        const rows = state.selectedRows as BrandingData[];
        const items = rows.map(({ id }) => id);
        setSelectedRows(items);
    }, []);

    const handleDeleteByIdModal = ({ id }: { id: string }) => {
        toggleDeleteModal();
        setSelectedRow(id);
    };

    const handleUpsertPage = ({ id }: { id?: string }) => {
        id ? navigate(id) : navigate('create');
    };

    const handleSetDefault = ({ id }: { id: string }) => {
        dispatch(updateDefaultBranding({ id, data: { is_default: true } }));
    };

    useEffect(() => {
        dispatch(loadBrandings());
        return () => {
            dispatch(cancelLoadBrandings());
        };
    }, []);

    useEffect(() => {
        if (
            deleteBrandingsState.loading === false &&
            deleteBrandingsState.data !== null &&
            deleteBrandingsState.error === null
        ) {
            console.log('submit success, return to list page');
            closeDeleteModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelDeletebrandings());
        }
    }, [deleteBrandingsState]);

    useEffect(() => {
        if (
            updateDefaultBrandingState.loading === false &&
            updateDefaultBrandingState.data !== null &&
            updateDefaultBrandingState.error === null
        ) {
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelUpdateDefaultBranding());
            dispatch(loadBrandings());
        }
    }, [updateDefaultBrandingState]);

    return (
        <div className="d-flex gap-5 flex-column">
            <div className="content-wall">
                <h1 className="page-h1">Brandings</h1>
                {brandingsState.loading && <FixedLoading />}
                <div>
                    {/* table header */}
                    <div className="d-flex justify-content-between flex-wrap gap-5 mb-5">
                        <div className="d-flex flex-wrap gap-5">
                            <Permissions permission="super_admin" customerAdminRequried>
                                <button onClick={() => handleUpsertPage({})} className="btn btn-outline-primary">
                                    <Icon
                                        classNames={['material-symbols-outlined', 'fill']}
                                        content="add_circle_outline"
                                    />
                                    Add Branding
                                </button>
                            </Permissions>

                            <Permissions permission="super_admin" customerAdminRequried>
                                <button
                                    onClick={toggleDeleteModal}
                                    disabled={isDeleteBtnDisabled}
                                    className="btn btn-outline-secondary"
                                >
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="delete" />
                                    Delete
                                </button>
                            </Permissions>
                        </div>
                        <div className="text-end">
                            <div className="d-inline-flex gap-3 align-items-center">
                                <SearchInput
                                    type="text"
                                    placeholder="Search"
                                    onFilter={setFilterValue}
                                    filterValue={filterValue}
                                />
                                <HelpLink mapKey="settings/branding"></HelpLink>
                            </div>
                        </div>
                    </div>
                    {brandings && (
                        <FtcTable
                            data={filteredBrandings}
                            columns={BrandingColumns(
                                handleUpsertPage,
                                handleDeleteByIdModal,
                                handleSetDefault,
                                isAdmin
                            )}
                            clearSelectedRows={toggleCleared}
                            selectableRowDisabled={rowDisabledCriteria}
                            onSelectedRowsChange={handleRowSelected}
                            selectableRows
                            pagination
                        />
                    )}
                    <DeleteModal
                        isOpen={isDeleteModalOpen}
                        toggle={toggleDeleteModal}
                        data={brandings}
                        onClosed={() => {
                            closeDeleteModal();
                            setSelectedRow('');
                        }}
                        onCanceled={toggleDeleteModal}
                        meta={{
                            submitLoading: deleteBrandingsState.loading,
                            // for delete, the priorty of an item delete is higher than batch delete
                            selectedIds: selectedRow.length > 0 ? [selectedRow] : selectedRows,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Branding;
