import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@shared/utils/constants';
import { localTime } from '@shared/utils/formateTime';
import { isObjectEmpty } from '@shared/utils/isEmpty';

function compareTime(t1: Date, drift = 0) {
    const now = new Date();
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return t1.getTime() + drift > utc.getTime();
}

export const formatUser = (
    element: User,
    settings: Record<string, Realm>,
    userTempTokenMap: Record<string, Token>
): UserData => {
    let expire_status = 0;
    //activated
    if (element.status === 1 || element.status === 3) {
        expire_status = 1;
    } else {
        //pending
        if (compareTime(new Date(element.expire_at))) {
            expire_status = 2;
        } else {
            //expired
            expire_status = 0;
        }
    }
    let usernames = null;
    if (element.usernames != null && !element.usernames.includes(',') && element.usernames !== element.username) {
        usernames = element.usernames + ',' + element.username;
    }
    let lockout = false;
    if (element.lockout_at != null) {
        if (compareTime(new Date(element.lockout_at), settings[element.realm_id].period * 1000)) {
            lockout = true;
        }
    }
    const sn = ['Email', 'SMS'].includes(element.auth_method) ? '' : element.sn;
    const auth_method = !element.auth_method ? settings[element.realm_id].auth_method : element.auth_method;
    let temp_token_end_date = userTempTokenMap[element.id] ? userTempTokenMap[element.id]['expired_at'] : null;
    // convert to local time
    if (temp_token_end_date) {
        temp_token_end_date = dayjs.utc(temp_token_end_date).local().format(DATETIME_FORMAT);
    }
    let temp_token = element.temp_token;
    // if it's expired
    if (temp_token && temp_token_end_date && dayjs(temp_token_end_date).isBefore(dayjs())) {
        temp_token_end_date = null;
        temp_token = false;
    }

    const formattedRealm = element.realm_id == null ? '' : element.realm_id;
    let authHistory = isObjectEmpty(element.auth_history) ? null : element.auth_history;
    if (authHistory?.timestamp) {
        authHistory = { ...authHistory, timestamp: new Date(element.auth_history.timestamp).toLocaleString() };
    }

    return {
        name: element.username == null ? '' : element.username,
        id: element.id == null ? '' : element.id,
        realm: formattedRealm,
        authMethod: auth_method,
        notificationMethod: !element.notification_method
            ? settings[element.realm_id].notification_method
            : element.notification_method,
        sn: sn,
        authSn: auth_method + sn,
        email: element.email == null ? '' : element.email,
        mobileNumber: element.mobile_number == null ? '' : element.mobile_number,
        select: false,
        active: element.active,
        bypass: element.bypass_at != null,
        lockout: lockout,
        expire: expire_status,
        expireAt: localTime(element.expire_at),
        refs: element.refs,
        usernames: usernames == null ? element.usernames ?? element.username : usernames,
        emails: element.emails == null ? element.email : element.emails + ',' + element.email,
        createdAt: localTime(element.created_at),
        tempToken: temp_token,
        tempTokenAuthMethod: userTempTokenMap[element.id] ? userTempTokenMap[element.id]['auth_method'] : 'Email',
        tempTokenEndDate: temp_token_end_date ? new Date(temp_token_end_date) : null,
        lastLogin: localTime(element.auth_at),
        isBypass: element.bypass_at != null && settings[formattedRealm]?.bypass && !lockout,
        settingsBypass: settings[element.realm_id].bypass,
        authHistory: authHistory,
    };
};

export const disabledNotifyMethod = (method: string, selection: any) => {
    const { timeBase, SMSCount, zeroBalance, trial } = selection;
    const res = method === 'SMS' && ((timeBase && !SMSCount) || zeroBalance || trial);
    return res;
};

export const disabledAuthMethod = (method: string, selection: any, currentAuthMethod: string) => {
    const { hardTokens, timeBase, SMSCount, zeroBalance, trial } = selection;

    return (
        (method === 'FTK' && currentAuthMethod !== 'FTK' && hardTokens.length === 0) ||
        (method === 'SMS' && ((timeBase && !SMSCount) || zeroBalance || trial))
    );
};
