import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { deletePolicy, getPolicies, getPolicy, PolicyResponse, postPolicy, putPolicy } from '@services/api/policies';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deletePolicies,
    deleteSuccess as deletePoliciesSuccess,
    deleteFailure as deletePoliciesFailure,
    cancelDelete as cancelDeletePolicies,
} from './deletePoliciesSlice';
import {
    loadRequest as loadPolicies,
    loadFailure as loadPoliciesFailure,
    loadSuccess as loadPoliciesSuccess,
    cancelLoad as cancelLoadPolicies,
} from './policiesSlice';
import {
    loadRequest as loadPolicy,
    loadFailure as loadPolicyFailure,
    loadSuccess as loadPolicySuccess,
    cancelLoad as cancelLoadPolicy,
} from './policySlice';
import {
    upsertRequest as upsertPolicy,
    upsertFailure as upsertPolicyFailure,
    upsertSuccess as upsertPolicySuccess,
    cancelUpsert as cancelUpsertPolicy,
} from './upsertPolicySlice';

export function* fetchPolicies() {
    try {
        const { response, cancel }: { response: PolicyResponse; cancel: any } = yield race({
            response: call(getPolicies),
            cancel: take(cancelLoadPolicies),
        });

        if (cancel) {
            return;
        }

        yield put(loadPoliciesSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadPoliciesFailure(e.message));
    }
}

export function* fetchPolicy(action: ReturnType<typeof loadPolicy>) {
    const { id } = action.payload;
    try {
        const { response, cancel }: { response: Policy; cancel: any } = yield race({
            response: call(getPolicy, { id }),
            cancel: take(cancelLoadPolicy),
        });

        if (cancel) {
            return;
        }

        yield put(loadPolicySuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadPolicyFailure(e.message));
    }
}

export function* fetchPoliciesSaga() {
    yield takeLatest(loadPolicies, fetchPolicies);
}

export function* fetchPolicySaga() {
    yield takeLatest(loadPolicy, fetchPolicy);
}

export function* upsertPolicyRequest(action: ReturnType<typeof upsertPolicy>) {
    const { id, data } = action.payload;

    // id only exists for edit mode
    const upsertCall = id ? call(putPolicy, { id, data }) : call(postPolicy, { data });

    try {
        //@ts-ignore
        const { response, cancel }: { response: PolicyBrief; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertPolicy),
        });

        if (cancel) {
            return;
        }

        yield put(upsertPolicySuccess({ data: response }));
        yield put(loadPolicies());
    } catch (e) {
        showErrorAlert(e);
        yield put(upsertPolicyFailure(e?.response?.data));
    }
}

export function* upsertPolicySaga() {
    yield takeLatest(upsertPolicy, upsertPolicyRequest);
}

export function* deletePoliciesRequest(action: ReturnType<typeof deletePolicies>) {
    const { ids } = action.payload;

    const deletePolicyCalls = ids.map((id) => call(deletePolicy, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deletePolicyCalls),
            cancel: take(cancelDeletePolicies),
        });

        if (cancel) {
            return;
        }

        yield put(deletePoliciesSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadPolicies());
    } catch (e) {
        showErrorAlert(e);
        yield put(deletePoliciesFailure(e?.response?.data?.error_message));
    }
}

export function* deletePoliciesSaga() {
    yield takeLatest(deletePolicies, deletePoliciesRequest);
}
