import { GenericState, createCreateGenericSlice } from '@store/modules/createCreateSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<SigningCert | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const upsertSigningCertSlice = createCreateGenericSlice({
    name: 'signingCerts',
    initialState: initialState as InitialState,
    reducers: {
        createRequest: (
            state,
            action: PayloadAction<{
                data: CreateSigningCertData;
            }>
        ) => {
            state.loading = true;
        },
        createSuccess: (state, action: PayloadAction<{ data: SigningCert }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { createRequest, createSuccess, createFailure, cancelCreate } = upsertSigningCertSlice.actions;

export default upsertSigningCertSlice.reducer;
