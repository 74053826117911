import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface InitialState {
    value: {
        auth: {
            startDate: string | null;
            endDate: string | null;
        };
        mgmt: {
            startDate: string | null;
            endDate: string | null;
        };
        sms: {
            startDate: string | null;
            endDate: string | null;
        };
    };
}

const initialState: InitialState = {
    value: {
        auth: {
            startDate: null,
            endDate: null,
        },
        mgmt: {
            startDate: null,
            endDate: null,
        },
        sms: {
            startDate: null,
            endDate: null,
        },
    },
};

const logDateSlice = createSlice({
    name: 'logDate',
    initialState,
    reducers: {
        setDate: (
            state,
            action: PayloadAction<{
                [key in keyof InitialState['value']]?: {
                    startDate: string | null;
                    endDate: string | null;
                };
            }>
        ) => {
            state.value = { ...state.value, ...action.payload };
        },
        cleanDate: (state) => {
            state.value = initialState.value;
        },
    },
});

export const { setDate, cleanDate } = logDateSlice.actions;

export const selectLogDate = (state: RootState) => state.logDates.value;

export const selectAuthLogDate = (state: RootState) => state.logDates.value.auth;

export const selectMgmtLogDate = (state: RootState) => state.logDates.value.mgmt;

export const selectSMSLogDate = (state: RootState) => state.logDates.value.sms;

export default logDateSlice.reducer;
