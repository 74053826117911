import { createSelector } from '@reduxjs/toolkit';
import { DefaultTemplatesParams } from '@services/api/templates';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TemlatesState extends GenericState<TemplateDetail | null> {}

const initialState: TemlatesState = {
    loading: false,
    data: null,
    error: null,
};

const realmTemplateSlice = createGenericSlice({
    name: 'realmTemplate',
    initialState: initialState as TemlatesState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id?: string; data?: DefaultTemplatesParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: TemplateDetail }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmTemplateSlice.actions;

export const selectTemplate = createSelector(
    (state: RootState) => state.realmTemplate.data,
    (data) => data
);

export default realmTemplateSlice.reducer;
