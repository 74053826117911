import { createSelector } from '@reduxjs/toolkit';
import { INTRO_VIDEO_URL } from '@shared/utils/constants';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface MetadataState extends GenericState<Metadata | null> {}

const initialState: MetadataState = {
    loading: false,
    data: null,
    error: null,
};

const metadataSlice = createGenericSlice({
    name: 'metadata',
    initialState: initialState as MetadataState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Metadata }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = metadataSlice.actions;

export const selectAllMetadata = createSelector(
    (state: RootState) => state.metadata.data,
    (data) => data ?? []
);

export const selectIntroVideoUrl = createSelector(
    (state: RootState) => state.metadata.data?.intro_video_url,
    (data) => data ?? INTRO_VIDEO_URL
);

export default metadataSlice.reducer;
