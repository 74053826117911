import { ANY_SOURCE, SOURCE_LOCATION, SOURCE_SUBNET } from '@shared/utils/constants';
import { localTime } from '@shared/utils/formateTime';

const policyAction: IObjectKeys = {
    0: 'Multi-factor Authentication',
    1: 'Block',
    2: 'Bypass',
};

export const formatPolicy = (element: PolicyBrief): PolicyBriefData => ({
    id: element.id,
    name: element.name,
    action: policyAction[element.action] ?? 'NA',
    profileCount: element.profile_count,
    lastUpdate: localTime(element.updated_at || element.created_at),
});

export const formatProfileBrief = (element: ProfileBrief): ProfileBriefData => ({
    id: element.id,
    name: element.name,
    action: policyAction[element.action] ?? 'NA',
});

export const formatPolicyData = (element: Policy): PolicyData => {
    const subnet_filter: SubnetFilterData = {
        subnets: element.subnet_filter ? element.subnet_filter.subnets : [],
        authInterval: element.subnet_filter?.auth_interval ?? null,
    };

    const location_filter: LocationFilterData = {
        countries: (element.location_filter ? element.location_filter.countries : []) || [],
        impossibleTravel: (element.location_filter ? element.location_filter.impossible_travel : false) || false,
    };

    const time_filter: TimeFilterData = {
        weekdays: element.time_filter ? element.time_filter.weekdays : [0, 1, 2, 3, 4, 5, 6],
        startTime: element.time_filter ? element.time_filter.start_time : '00:00',
        endTime: element.time_filter ? element.time_filter.end_time : '23:59',
        timezone: element.time_filter ? element.time_filter.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return {
        id: element.id,
        name: element.name || '',
        action: element.action || 0,
        sourceFilter: element.subnet_filter ? SOURCE_SUBNET : element.location_filter ? SOURCE_LOCATION : ANY_SOURCE,
        schedule: element.time_filter ? true : false,
        subnetFilter: subnet_filter,
        locationFilter: location_filter,
        timeFilter: time_filter,
    };
};
