import Swal from 'sweetalert2/dist/sweetalert2.js';
import withReactContent from 'sweetalert2-react-content';
import 'sweetalert2/src/sweetalert2.scss';

// custom sweetalert2 style for FTC
import './FtcSwal.scss';

const FtcSwal = withReactContent(
    Swal.mixin({
        buttonsStyling: false,
        reverseButtons: true,
        showCloseButton: true,

        // confirm button
        showConfirmButton: true,
        confirmButtonText: 'Confirm',

        // cancel button
        showCancelButton: true,
        cancelButtonText: 'Cancel',

        // apply bs5 styles
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary',
            popup: 'template-preview',
            actions: 'd-flex justify-content-end gap-5',
        },
        closeButtonHtml: "<i class='material-symbols-outlined'>close</i>",
    })
);

export { FtcSwal };
