import { createSelector } from '@reduxjs/toolkit';
import { formatApplicationPermission } from './helper';
import { UserGroupPermissionResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<UserGroupPermissionResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userGroupPermissionSlice = createGenericSlice({
    name: 'userGroupPermission',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ groupId: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserGroupPermissionResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userGroupPermissionSlice.actions;

export const selectAllApplicationPermission = createSelector(
    (state: RootState) => {
        const { groupPermission, applications } = state.userGroupPermission.data || {};
        return applications?.map((app) => formatApplicationPermission(app, groupPermission));
    },
    (data) => data ?? []
);

export default userGroupPermissionSlice.reducer;
