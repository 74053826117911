import { DEVICE_STATUS } from '@shared/utils/constants';

export function formatDeviceTaskData(element: DeviceTask): DeviceTaskData {
    function _getProgress(item: DeviceStatus) {
        if (item.status === DEVICE_STATUS.DEV_CONSISTENT) {
            return 100;
        }

        if (item.progress == null) {
            return 0;
        }

        if (item.progress === 0) {
            // give started transfer a init progress to display

            return 1;
        }

        return Math.ceil(100 * item.progress);
    }
    return {
        deviceList: element.device_list,
        devicesStatus:
            Object.keys(element.devices_status).length === 0
                ? []
                : element.device_list
                      .map((sn) => element.devices_status[sn])
                      .map((item) => {
                          return {
                              progress: item ? _getProgress(item) : 0,
                              status: item?.status == null ? '' : item.status,
                              cluster_id: item?.cluster_id ?? '',
                          };
                      }),
        keepToken: !!element.keep_token,
        status: element.status,
        taskId: element.task_id,
        notifyCustomerId: element.notify_customer_id?.toString(),
    };
}
