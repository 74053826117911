import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface UpsertProfileState extends GenericState<Profile | null> {}

const initialState: UpsertProfileState = {
    loading: false,
    data: null,
    error: null,
};

const upsertProfileSlice = createUpsertGenericSlice({
    name: 'upsertProfile',
    initialState: initialState as UpsertProfileState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id?: string | null;
                data: UpsertProfileData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: Profile }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertProfileSlice.actions;

export default upsertProfileSlice.reducer;
