import { createSelector } from '@reduxjs/toolkit';
import { DEVICE_STATUS } from '@shared/utils/constants';
import { GenericState, createGenericSlice } from '../../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface DevicesInClusterStatusState extends GenericState<DeviceValidateStatus[][] | null> {}

const initialState: DevicesInClusterStatusState = {
    loading: false,
    data: null,
    error: null,
};

const devicesInClusterStatusSlice = createGenericSlice({
    name: 'devicesInClusterStatus',
    initialState: initialState as DevicesInClusterStatusState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ data: DeviceRemoteData[] }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DeviceValidateStatus[][] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = devicesInClusterStatusSlice.actions;

export const selectAllDeviceStatuss = createSelector(
    (state: RootState) => state.devicesInClusterStatus.data,
    (data) => data ?? []
);

export const checkConsistency = createSelector(
    (state: RootState) => state.devicesInClusterStatus.data,
    (data) =>
        !data
            ? true
            : data.every((devicesStatus) => {
                  if (!devicesStatus || devicesStatus.length === 0) return true;
                  const existCustomerId = devicesStatus[0].exist_customer_id;
                  const fcCustomerId = devicesStatus[0].customer_id;
                  return devicesStatus.every(
                      (device) =>
                          device.status === DEVICE_STATUS.DEV_INCONSISTENT &&
                          device.customer_id === fcCustomerId &&
                          device.exist_customer_id === existCustomerId
                  );
              })
);

export const findInConsistencyClusters = createSelector(
    (state: RootState) => state.devicesInClusterStatus.data,
    (data) =>
        data
            ?.filter((devicesStatus) => {
                if (!devicesStatus || devicesStatus.length === 0) return true;
                const existCustomerId = devicesStatus[0].exist_customer_id;
                const fcCustomerId = devicesStatus[0].customer_id;
                return devicesStatus.every(
                    (device) =>
                        device.status === DEVICE_STATUS.DEV_INCONSISTENT &&
                        device.customer_id === fcCustomerId &&
                        device.exist_customer_id === existCustomerId
                );
            })
            .map((item) => item[0].cluster_id) ?? []
);

export default devicesInClusterStatusSlice.reducer;
