import { useCopyToClipboard } from '@uidotdev/usehooks';
import { toast } from 'react-toastify';
import { FLabel } from '@shared/components/Form/Label';
import { FtcModal } from '@shared/components/FtcModal';
import { Icon } from '@shared/components/Icon';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onBtnClosed: () => void;
    data: SigningCert[];
    meta: { selectedId: string };
};

const MetaData = ({ label, value }: { label: string; value: string }) => {
    const [, copyToClipboard] = useCopyToClipboard();
    return (
        <FLabel cols={[2, 9]} label={label}>
            <div className="d-flex align-items-center">
                <div className="text-truncate">{value}</div>
                <button
                    className="btn btn-link"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        try {
                            copyToClipboard(value);
                            toast.success('Text Copied!');
                        } catch (e) {
                            toast.error(e.toString());
                        }
                    }}
                >
                    <Icon classNames={['material-symbols-outlined']} content="content_copy" />
                </button>
            </div>
        </FLabel>
    );
};

function DetailsModal({ isOpen, toggle, onClosed, onBtnClosed, data, meta }: Props) {
    const { selectedId } = meta;
    const selectedDetails = data.find(({ id: idpId }) => idpId === selectedId);

    return (
        <FtcModal
            header={`Detailed Information ${selectedDetails?.name ? `for Certificate: ${selectedDetails.name}` : ''}`}
            size="xl"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            onBtnClosed={onBtnClosed}
        >
            <div className="details">
                {selectedDetails ? (
                    <>
                        <div className="row g-5">
                            <div className="col-xxl">
                                <div className="text-center font-weight-bold">Certificate</div>
                                <div className="mt-5">
                                    <FLabel cols={[2, 9]} label="Subject">
                                        <div className="text-truncate">{selectedDetails.subject}</div>
                                    </FLabel>
                                    <FLabel cols={[2, 9]} label="Issuer">
                                        <div className="text-truncate">{selectedDetails.issuer}</div>
                                    </FLabel>
                                    <FLabel cols={[2, 9]} label="Issued">
                                        <div className="text-truncate">{selectedDetails.created_at}</div>
                                    </FLabel>
                                    <FLabel cols={[2, 9]} label="Expired">
                                        <div className="text-truncate">{selectedDetails.expire_at}</div>
                                    </FLabel>
                                    <MetaData label="Certificate" value={selectedDetails.signing_cert} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    'No data available'
                )}
            </div>
        </FtcModal>
    );
}

export default DetailsModal;
