import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '../upsertGenericSlice';

export type UserFormSubmitResponse = { user: User | null; tokenTemp?: Token };

interface UserFormState extends GenericState<UserFormSubmitResponse | null> {}

const initialState: UserFormState = {
    loading: false,
    data: null,
    error: null,
};

const userFormSubmitSlice = createUpsertGenericSlice({
    name: 'userFormSubmit',
    initialState: initialState as UserFormState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id: string;
                userData: UserEditSubmitData;
                tempTokenData: UserTempTokenEditSubmitData;
                isTempTokenDeleted: boolean;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: UserFormSubmitResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = userFormSubmitSlice.actions;

export default userFormSubmitSlice.reducer;
