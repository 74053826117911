import axiosClient from '../axiosClient';
import { API } from '../index';

export type ClientsParams = { realm_id?: string; type?: string };
export type ClientParams = { id?: string };

export function* getClients({ params }: { params: ClientsParams }): Generator {
    return yield axiosClient.get<Client[]>(API.CLIENT, { params }).then((res) => res.data);
}

export function* getClient({ params }: { params: ClientParams }): Generator {
    return yield axiosClient.get<Client>(API.CLIENT, { params }).then((res) => res.data);
}

export function* postClient({ data }: { data: UpsertMgmtAppsClientData | UpsertWebAppsClientData }): Generator {
    return yield axiosClient.post<Client>(API.CLIENT, data).then((res) => res.data);
}

export function* putClient({
    id,
    data,
}: {
    id: string;
    data: FortiProductClientData | UpsertMgmtAppsClientData | UpdateSecretAppsClientData | UpsertWebAppsClientData;
}): Generator {
    return yield axiosClient.put<Client>(`${API.CLIENT}/${id}`, data).then((res) => res.data);
}

export function* deleteClient({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.CLIENT}/${id}`).then((res) => res.data);
}
