import { createSelector } from '@reduxjs/toolkit';
import { RealmsBriefParams } from '@services/api/realms';
import { buildRealmMap } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface RealmsBriefState extends GenericState<RealmBrief[] | null> {}

const initialState: RealmsBriefState = {
    loading: false,
    data: null,
    error: null,
};

const realmsBriefSlice = createGenericSlice({
    name: 'realmsBrief',
    initialState: initialState as RealmsBriefState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: RealmsBriefParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmBrief[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmsBriefSlice.actions;

export const selectAllRealmsBreif = createSelector(
    (state: RootState) => state.realms.data,
    (data) => data ?? []
);

export const selectRealmsBreifMap = createSelector(
    (state: RootState) => state.realmsBrief.data,
    (data) => (data ? buildRealmMap(data) : {})
);

export default realmsBriefSlice.reducer;
