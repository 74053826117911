import { createSelector } from '@reduxjs/toolkit';
import { UsersParams } from '@services/api/userGroups';
import { formatUserInGroup } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<UserWithGroup[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const usersByGroupSlice = createGenericSlice({
    name: 'usersByGroup',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string; params?: UsersParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserWithGroup[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usersByGroupSlice.actions;

export const selectUsersByGroup = createSelector(
    (state: RootState) => state.usersByGroup.data?.map(formatUserInGroup),
    (data) => data ?? []
);
export default usersByGroupSlice.reducer;
