import { toast } from 'react-toastify';
import { all, call, delay, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getRealms } from '@services/api/realms';
import { getBalance, getResourceCount } from '@services/api/resources';
import { getTask } from '@services/api/task';
import { deleteTokenTemp, getTokens, postTokenTemp } from '@services/api/tokens';
import {
    getUsers,
    getUser,
    putUser,
    putUserRef,
    addBatchUser,
    getUserExtension,
    deleteBatchUser,
    deleteUser,
} from '@services/api/users';
import {
    getRegistrationOptions,
    getWebauthnCredentials,
    postRegistrationVerification,
    deleteWebauthnCredential,
} from '@services/api/webauthn';
import { QUEUE_JOB_TIMEOUT, QUEUE_JOB_TTL, TASK_RETRY_TIME } from '@shared/utils/constants';
import { isObjectEmpty } from '@shared/utils/isEmpty';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteUsers,
    deleteSuccess as deleteUsersSuccess,
    deleteFailure as deleteUsersFailure,
    cancelDelete as cancelDeleteUsers,
} from '@store/modules/users/deleteUsersSlice';
import {
    upsertRequest as removeUsersAliasToken,
    upsertSuccess as removeUsersAliasSuccess,
    upsertFailure as removeUsersAliasFailure,
    cancelUpsert as cancelRemoveUsersAlias,
} from '@store/modules/users/removeUsersAliasSlice';
import {
    upsertRequest as updateUsersAlias,
    upsertSuccess as updateUsersAliasSuccess,
    upsertFailure as updateUsersAliasFailure,
    cancelUpsert as cancelUpdateUsersAlias,
} from '@store/modules/users/updateUsersAliasSlice';
import {
    upsertRequest as upsertUsersToken,
    upsertSuccess as upsertUsersTokenSuccess,
    upsertFailure as upsertUsersTokenFailure,
    cancelUpsert as cancelUpsertUsersToken,
} from '@store/modules/users/updateUsersTokenSlice';
import {
    loadRequest as loadUserExtension,
    loadSuccess as loadUserExtensionSuccess,
    loadFailure as loadUserExtensionFailure,
    cancelLoad as cancelLoadUserExtension,
} from '@store/modules/users/userExtensionSlice';
import {
    upsertRequest as editUser,
    upsertSuccess as editUserSuccess,
    upsertFailure as editUserFailure,
    cancelUpsert as cancelEditUser,
    UserFormSubmitResponse,
} from '@store/modules/users/userFormSubmitSlice';
import {
    loadRequest as loadUser,
    loadSuccess as loadUserSuccess,
    loadFailure as loadUserFailure,
    cancelLoad as cancelLoadUser,
} from '@store/modules/users/userSlice';
import {
    loadRequest as loadUsers,
    loadSuccess as loadUsersSuccess,
    loadFailure as loadUsersFailure,
    cancelLoad as cancelLoadUsers,
} from '@store/modules/users/usersSlice';
import {
    loadRequest as loadWebauthnRegisterationOption,
    loadSuccess as loadWebauthnRegisterationOptionSuccess,
    loadFailure as loadWebauthnRegisterationOptionFailure,
    cancelLoad as cancelLoadWebauthnRegisterationOption,
} from '@store/modules/users/webauthnRegistrationOptionSlice';
import {
    createRequest as createWebauthnRegistrationVerification,
    createSuccess as CreateWebauthnRegistrationVerificationSuccess,
    createFailure as CreateWebauthnRegistrationVerificationFailure,
    cancelCreate as cancelCreateWebauthnRegistrationVerification,
} from '@store/modules/users/webauthnRegistrationVerificationSlice';
import {
    upsertRequest as loadbatchAddUser,
    upsertSuccess as batchAddUserSuccess,
    upsertFailure as batchAddUserFailure,
    cancelUpsert as cancelBatchAddUser,
} from './batchAddUserSlice';
import {
    upsertRequest as loadbatchDeleteUser,
    upsertSuccess as batchDeleteUserSuccess,
    upsertFailure as batchDeleteUserFailure,
    cancelUpsert as cancelBatchDeleteUser,
} from './batchDeleteUserSlice';
import {
    deleteRequest as deleteUserWebauthnCredentials,
    deleteSuccess as deleteUserWebauthnCredentialsSuccess,
    deleteFailure as deleteUserWebauthnCredentialsFailure,
    cancelDelete as cancelDeleteUserWebauthnCredentials,
} from './deleteUserWebauthnCredentialsSlice';
import {
    loadRequest as loadUserWebauthnCredentials,
    loadFailure as loadUserWebauthnCredentialsFailure,
    loadSuccess as loadUserWebauthnCredentialsSuccess,
    cancelLoad as cancelLoadUserWebauthnCredentials,
} from './userWebauthnCredentialsSlice';

import type { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';

export type UsersResponse = {
    users: User[];
    realms: Realm[];
    tempTokens: Token[];
    hardTokens: Token[];
    SMSCount: SMSResource;
    balance: Balance;
};

export type UserWebauthnResponse = WebauthnCredential[];

export type WebauthnRegisterationOptionsResponse = { key: string; options: PublicKeyCredentialCreationOptionsJSON };

export function* fetchUsers(action: ReturnType<typeof loadUsers>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: UsersResponse; cancel: any } = yield race({
            response: all({
                users: call(getUsers, {}),
                tempTokens: call(getTokens, { params: params.tempToken }),
                hardTokens: call(getTokens, { params: params.hardToken }),
                SMSCount: call(getResourceCount, { params: params.SMSCount }),
                balance: call(getBalance),
                realms: call(getRealms),
            }),
            cancel: take(cancelLoadUsers),
        });

        if (cancel) {
            return;
        }

        yield put(loadUsersSuccess({ data: response }));
    } catch (e) {
        yield put(loadUsersFailure(e?.response?.data));
        showErrorAlert(e);
    }
}

export function* fetchUsersSaga() {
    yield takeLatest(loadUsers, fetchUsers);
}

export function* fetchUser(action: ReturnType<typeof loadUser>) {
    const { id } = action.payload;
    try {
        const { response, cancel }: { response: User; cancel: any } = yield race({
            response: call(getUser, { id }),
            cancel: take(cancelLoadUser),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserSuccess({ data: response }));
    } catch (e) {
        yield put(loadUserFailure(e?.response?.data));
        showErrorAlert(e);
    }
}

export function* fetchUserSaga() {
    yield takeLatest(loadUser, fetchUser);
}

export function* fetchUserExtension(action: ReturnType<typeof loadUserExtension>) {
    const { id } = action.payload;
    try {
        const { response, cancel }: { response: UserExtension; cancel: any } = yield race({
            response: call(getUserExtension, { id }),
            cancel: take(cancelLoadUserExtension),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserExtensionSuccess({ data: response }));
    } catch (e) {
        if (e.response?.status === 404) {
            yield put(loadUserExtensionSuccess({ data: null }));
            return;
        }

        yield put(loadUserExtensionFailure(e.response?.data));
        showErrorAlert(e);
    }
}

export function* fetchUserExtensionSaga() {
    yield takeLatest(loadUserExtension, fetchUserExtension);
}

const params = { tempToken: { temp_token: true }, hardToken: { available: true }, SMSCount: { resource: 'sms' } };

export function* editUserRequest(action: ReturnType<typeof editUser>) {
    const { id, userData, tempTokenData, isTempTokenDeleted } = action.payload;
    try {
        let finalResponse: UserFormSubmitResponse = { user: null };
        if (isObjectEmpty(tempTokenData)) {
            // without adding temp token
            const { userResponse, userCancel }: { userResponse: User; userCancel: any } = yield race({
                userResponse: call(putUser, { id: id, data: userData }),
                userCancel: take(cancelEditUser),
            });
            finalResponse['user'] = userResponse;
            if (userCancel) {
                return;
            }
            if (isTempTokenDeleted) {
                // delete temp token
                const { tempTokenresponse, tempTokencancel }: { tempTokenresponse: Token; tempTokencancel: any } =
                    yield race({
                        tempTokenresponse: call(deleteTokenTemp, { id }),
                        tempTokencancel: take(cancelEditUser),
                    });
                if (tempTokencancel) {
                    return;
                }
                finalResponse['tokenTemp'] = tempTokenresponse;
            }
        } else {
            // add temp token
            const { userResponse, userCancel }: { userResponse: User; userCancel: any } = yield race({
                userResponse: call(putUser, { id: id, data: userData }),
                userCancel: take(cancelEditUser),
            });
            if (userCancel) {
                return;
            }
            finalResponse['user'] = userResponse;
            const { tempTokenresponse, tempTokencancel }: { tempTokenresponse: Token; tempTokencancel: any } =
                yield race({
                    tempTokenresponse: call(postTokenTemp, { data: tempTokenData }),
                    tempTokencancel: take(cancelEditUser),
                });
            if (tempTokencancel) {
                return;
            }
            finalResponse['tokenTemp'] = tempTokenresponse;
        }

        yield put(editUserSuccess({ data: finalResponse }));
        // fetch users & user api after successfully
        yield put(loadUsers({ params: params }));
        yield put(loadUser({ id }));
        toast.success('User Updated');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(editUserFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* editUserSaga() {
    yield takeLatest(editUser, editUserRequest);
}

export function* deletehUsersRequest(action: ReturnType<typeof deleteUsers>) {
    const { ids } = action.payload;

    const deleteUserCalls = ids.map((id) => call(deleteUser, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteUserCalls),
            cancel: take(cancelDeleteUsers),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteUsersSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadUsers({ params: params }));
        const counts = ids.length;
        toast.success(`${counts}  ${pluralize('User', counts)} Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteUsersFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteUsersSaga() {
    yield takeLatest(deleteUsers, deletehUsersRequest);
}

export function* upsertUsersTokenRequest(action: ReturnType<typeof upsertUsersToken>) {
    const { ids, data } = action.payload;

    const upsertUsersCall = ids.map((id) => call(putUser, { id: id, data: data }));

    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: all(upsertUsersCall),
            cancel: take(cancelUpsertUsersToken),
        });

        if (cancel) {
            return;
        }

        yield put(upsertUsersTokenSuccess({ data: response }));

        // load initial data
        yield put(loadUsers({ params: params }));
        const counts = ids.length;
        toast.success(`${counts} success`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(upsertUsersTokenFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* upsertUsersTokenSaga() {
    yield takeLatest(upsertUsersToken, upsertUsersTokenRequest);
}

export function* updateUsersAliasRequest(action: ReturnType<typeof updateUsersAlias>) {
    const { ids, data } = action.payload;

    const upsertUsersCall = ids.map((id) => call(putUser, { id: id, data: data }));

    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: all(upsertUsersCall),
            cancel: take(cancelUpdateUsersAlias),
        });

        if (cancel) {
            return;
        }

        yield put(updateUsersAliasSuccess({ data: response }));

        // load initial data
        yield put(loadUsers({ params: params }));
        const counts = ids.length;
        toast.success(`${counts} success`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateUsersAliasFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* upsertUsersAliasSaga() {
    yield takeLatest(updateUsersAlias, updateUsersAliasRequest);
}

export function* removeUsersAliasRequest(action: ReturnType<typeof removeUsersAliasToken>) {
    const { ids, data } = action.payload;

    const updateUsersCalls = ids.map((id) => call(putUserRef, { id: id, data: data }));

    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: all(updateUsersCalls),
            cancel: take(cancelRemoveUsersAlias),
        });

        if (cancel) {
            return;
        }

        yield put(removeUsersAliasSuccess({ data: response }));

        // load initial data
        yield put(loadUsers({ params: params }));
        const counts = ids.length;
        toast.success(`${counts} success`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(removeUsersAliasFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* removeUsersAliasSaga() {
    yield takeLatest(removeUsersAliasToken, removeUsersAliasRequest);
}

export function* batchAddUser(action: ReturnType<typeof loadbatchAddUser>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(addBatchUser, { data }),
            cancel: take(cancelBatchAddUser),
        });

        if (cancel) {
            return;
        }
        yield put(batchAddUserSuccess({ data: 'success' }));
        toast.success('The creation request for users has been successfully sent!');
        const toastId = toast.warning('Please hold on as we are currently adding users.', {
            position: 'top-center',
            autoClose: false,
        });
        const taskId = response.task_id;
        let retryTimes = 0;
        const userSize = data.users.length;
        // test add 1000 users take 60 seconds
        // add 500 users take 28 seconds
        // add 100 users take 6 seconds
        // init as 1 second
        let retryTime = TASK_RETRY_TIME;
        if (userSize > 100) {
            retryTime = 3 * TASK_RETRY_TIME;
        }
        if (userSize > 500) {
            retryTime = 5 * TASK_RETRY_TIME;
        }
        if (userSize > 1000) {
            retryTime = 10 * TASK_RETRY_TIME;
        }
        const taskMaxRetryTimes = (QUEUE_JOB_TTL + QUEUE_JOB_TIMEOUT) / retryTime;
        while (retryTimes <= taskMaxRetryTimes) {
            const taskResponse: Task = yield call(getTask, { id: taskId });
            if (taskResponse.status !== 'pending') {
                const { success, failure } = taskResponse.msg.create;
                if (failure === 0) {
                    toast.success(`Successfully added ${success} ${pluralize('user', success)}`);
                } else {
                    toast.warn(
                        `Successfully added ${success} ${pluralize('user', success)}; ${failure}  ${pluralize(
                            'user',
                            failure
                        )} failed to add. Please check under Logs > Management page for error details.`
                    );
                }
                break;
            }
            yield put(loadUsers({ params: params }));
            yield delay(retryTime);
            retryTimes++;
        }
        toast.dismiss(toastId);
        yield put(loadUsers({ params: params }));
    } catch (e) {
        showErrorAlert(e);
        yield put(batchAddUserFailure('failure'));
    }
}

export function* batchAddUserSaga() {
    yield takeEvery(loadbatchAddUser, batchAddUser);
}

export function* batchDeleteUser(action: ReturnType<typeof loadbatchDeleteUser>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(deleteBatchUser, { data }),
            cancel: take(cancelBatchDeleteUser),
        });

        if (cancel) {
            return;
        }
        yield put(batchDeleteUserSuccess({ data: 'success' }));
        toast.success('The deletion request for users has been successfully sent!');
        const toastId = toast.warning('Please hold on as we are currently deleting users.', {
            position: 'top-center',
            autoClose: false,
        });
        const taskId = response.task_id;
        let retryTimes = 0;
        let retryTime = TASK_RETRY_TIME;
        const taskMaxRetryTimes = (QUEUE_JOB_TTL + QUEUE_JOB_TIMEOUT) / retryTime;
        while (retryTimes <= taskMaxRetryTimes) {
            const taskResponse: Task = yield call(getTask, { id: taskId });
            if (taskResponse.status !== 'pending') {
                const { success, failure } = taskResponse.msg.delete;
                if (failure === 0) {
                    toast.success(`Successfully deleted ${success} ${pluralize('user', success)}`);
                } else {
                    toast.warn(
                        `Successfully deleted ${success} ${pluralize('user', success)}; ${failure}  ${pluralize(
                            'user',
                            failure
                        )} failed to delete.`
                    );
                }
                break;
            }
            yield put(loadUsers({ params: params }));
            yield delay(retryTime);
            retryTimes++;
        }
        toast.dismiss(toastId);
        yield put(loadUsers({ params: params }));
    } catch (e) {
        showErrorAlert(e);
        yield put(batchDeleteUserFailure('failure'));
    }
}

export function* batchDeleteUserSaga() {
    yield takeEvery(loadbatchDeleteUser, batchDeleteUser);
}

export function* fetchUserWebauthnCredentials(action: ReturnType<typeof loadUserWebauthnCredentials>) {
    const { user_id } = action.payload;
    try {
        const { response, cancel }: { response: UserWebauthnResponse; cancel: any } = yield race({
            response: call(getWebauthnCredentials, user_id),
            cancel: take(cancelLoadUserWebauthnCredentials),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserWebauthnCredentialsSuccess({ data: response }));
    } catch (e) {
        yield put(loadUserWebauthnCredentialsFailure(e?.response?.data));
        showErrorAlert(e);
    }
}

export function* fetchUserWebauthnCredentialsSaga() {
    yield takeLatest(loadUserWebauthnCredentials, fetchUserWebauthnCredentials);
}

export function* deleteUserWebauthnsRequest(action: ReturnType<typeof deleteUserWebauthnCredentials>) {
    const { ids, user_id } = action.payload;
    const deleteCalls = ids.map((id) => call(deleteWebauthnCredential, { id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteUserWebauthnCredentials),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteUserWebauthnCredentialsSuccess({ data: 'success' }));
        toast.success('PassKey Deleted');

        //  load initial data
        yield put(loadUserWebauthnCredentials({ user_id }));
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteUserWebauthnCredentialsFailure(e?.response?.data));
    }
}

export function* deleteUserWebauthnCredentialsSaga() {
    yield takeLatest(deleteUserWebauthnCredentials, deleteUserWebauthnsRequest);
}

export function* fetchWebauthnRegisterationOptions(action: ReturnType<typeof loadWebauthnRegisterationOption>) {
    try {
        const { response, cancel }: { response: WebauthnRegisterationOptionsResponse; cancel: any } = yield race({
            response: call(getRegistrationOptions, { user_id: action.payload.user_id }),
            cancel: take(cancelLoadWebauthnRegisterationOption),
        });

        if (cancel) {
            return;
        }

        yield put(loadWebauthnRegisterationOptionSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadWebauthnRegisterationOptionFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchWebauthnRegisterationOptionsSaga() {
    yield takeLatest(loadWebauthnRegisterationOption, fetchWebauthnRegisterationOptions);
}

export function* createWebauthnRegisterationVerification(
    action: ReturnType<typeof createWebauthnRegistrationVerification>
) {
    try {
        const { response, cancel }: { response: WebauthnRegisterationVerification; cancel: any } = yield race({
            response: call(postRegistrationVerification, { data: action.payload }),
            cancel: take(cancelCreateWebauthnRegistrationVerification),
        });

        if (cancel) {
            return;
        }

        yield put(CreateWebauthnRegistrationVerificationSuccess({ data: response }));
        toast.success('The PassKey was registered successfully.');

        //  load initial data
        yield put(loadUserWebauthnCredentials({ user_id: action.payload.user_id }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(CreateWebauthnRegistrationVerificationFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* createWebauthnRegisterationVerificationSaga() {
    yield takeLatest(createWebauthnRegistrationVerification, createWebauthnRegisterationVerification);
}
