export const parseHTMLStr = (data: string): string => {
    let res = '';
    for (let i = 0; i < data.length; i++) {
        let strChar = data.charAt(i);
        if (strChar === '&') {
            strChar = '&amp';
        } else if (strChar === '<') {
            strChar = '&lt';
        } else if (strChar === '>') {
            strChar = '&gt';
        }
        res += strChar;
    }
    return res;
};
