import { TableColumn } from 'react-data-table-component';

export const clientUsersColumns: TableColumn<User>[] = [
    {
        name: 'Name',
        selector: (row) => row.username,
        sortable: true,
    },
    {
        name: 'Authentication Method',
        selector: (row) => row.auth_method,
    },
    {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
    },
    {
        name: 'Mobile Phone',
        selector: (row) => row.mobile_number || '',
        sortable: true,
    },
];
