// import { usageDailyUserCredit } from './usage.mock';
import axiosClient from '../axiosClient';
import { API } from '../index';

export type UsageResponse = Usage[];

export type UsageRealmResponse = Realm[];

export type UsageParams = {
    realm_list?: boolean;
    realm_id?: string;
    start?: string;
    end?: string;
};

export function* getUsage({ params }: { params: UsageParams }): Generator {
    return yield axiosClient.get<UsageRealmResponse | UsageResponse>(API.USAGE, { params }).then((res) => res.data);
}
