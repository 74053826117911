import axiosClient from '../axiosClient';
import { API } from '../index';

export type RateParams = {
    resource: string;
    all?: boolean;
};
export function* getRate(params: RateParams): Generator {
    return yield axiosClient.get<SMS[]>(API.RATE, { params }).then((res) => res.data);
}
