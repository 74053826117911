import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { deleteForwarder, getForwarders, postForwarder, putForwarder } from '@services/api/settingLog';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteForwarders,
    deleteSuccess as deleteForwardersSuccess,
    deleteFailure as deleteForwardersFailure,
    cancelDelete as cancelDeleteForwarders,
} from './deleteForwardersSlice';
import {
    loadRequest as loadForwarders,
    loadFailure as loadForwardersFailure,
    loadSuccess as loadForwardersSuccess,
    cancelLoad as cancelLoadForwarders,
} from './forwardersSlice';
import {
    upsertRequest as upsertForwarder,
    upsertFailure as upsertForwarderFailure,
    upsertSuccess as upsertForwarderSuccess,
    cancelUpsert as cancelUpsertForwarder,
} from './upsertForwarderSlice';

export function* fetchForwarders() {
    try {
        const { response, cancel }: { response: Forwarder[]; cancel: any } = yield race({
            response: call(getForwarders),
            cancel: take(cancelLoadForwarders),
        });

        if (cancel) {
            return;
        }

        yield put(loadForwardersSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(loadForwardersFailure(errMsg));
    }
}

export function* fetchForwardersSaga() {
    yield takeLatest(loadForwarders, fetchForwarders);
}

export function* upsertForwarderRequest(action: ReturnType<typeof upsertForwarder>) {
    const { id, data } = action.payload;

    // id only exists for edit mode
    const upsertCall = id ? call(putForwarder, { id, data }) : call(postForwarder, { data });

    try {
        const { response, cancel }: { response: Forwarder; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertForwarder),
        });

        if (cancel) {
            return;
        }

        yield put(upsertForwarderSuccess({ data: response }));
        // load initial data
        yield put(loadForwarders());
        toast.success('Forwarder Created');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(upsertForwarderFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* upsertForwarderSaga() {
    yield takeLatest(upsertForwarder, upsertForwarderRequest);
}

export function* deleteForwardersRequest(action: ReturnType<typeof deleteForwarders>) {
    const { ids } = action.payload;

    const deleteForwarderCalls = ids.map((id) => call(deleteForwarder, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteForwarderCalls),
            cancel: take(cancelDeleteForwarders),
        });

        if (cancel) {
            return;
        }

        yield put(deleteForwardersSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadForwarders());

        const count = ids.length;
        toast.success(`${count} ${pluralize('Forwarder', count)} Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteForwardersFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteForwardersSaga() {
    yield takeLatest(deleteForwarders, deleteForwardersRequest);
}
