import { ErrorMessage } from 'formik';
import { FtcErrorMessage } from '../FtcErrorMessage.tsx';

export const FErrorMessage = ({ name }: { name: string }) => {
    return (
        <>
            <ErrorMessage name={name} render={(msg) => <FtcErrorMessage msg={msg} />} />
        </>
    );
};
