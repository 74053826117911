import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as loadBrandingsSuccess } from './brandingsSlice';
import { brandingFormatter } from './helper';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

type BrandingResource = {
    brandings: Branding[] | null;
    branding: Branding | null;
    templateBranding: Branding | null;
};

type NonNullableBrandingResource = {
    brandings: BrandingData[];
    branding: BrandingData | undefined;
    templateBranding: BrandingData | undefined;
};

interface InitialState extends GenericState<BrandingResource> {}

const initialState: InitialState = {
    loading: false,
    data: { brandings: null, branding: null, templateBranding: null },
    error: null,
};

const brandingResourcesSlice = createGenericSlice({
    name: 'brandingResource',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id?: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: BrandingResource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadBrandingsSuccess, (state, action: PayloadAction<{ data: Branding[] }>) => {
            const { data: brandings } = action.payload;
            state.data.brandings = brandings;
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = brandingResourcesSlice.actions;

export const selectBrandingResources = createSelector(
    (state: RootState) => state.brandingResources.data,
    (data) =>
        // (data.brandings && data.defaultBranding ? data : null)
        {
            let nonNullData: NonNullableBrandingResource = {
                brandings: [],
                branding: undefined,
                templateBranding: undefined,
            };
            nonNullData['brandings'] = (data['brandings'] ?? []).map(brandingFormatter);
            nonNullData['branding'] = data['branding'] ? brandingFormatter(data['branding']) : undefined;
            nonNullData['templateBranding'] = data['templateBranding']
                ? brandingFormatter(data['templateBranding'])
                : undefined;
            return nonNullData;
        }
);

export default brandingResourcesSlice.reducer;
