import axiosClient from '../axiosClient';
import { API } from '../index';

export type DevicesRemoteResponse = DeviceRemote[];

export function* getDevicesRemote(): Generator {
    return yield axiosClient
        .get<DevicesRemoteResponse>(API.DEVICE, { params: { remote_sync: true } })
        .then((res) => res.data);
}

export function* validateDevicesByClusterId({ id }: { id: string }): Generator {
    return yield axiosClient
        .get<DeviceValidateStatus[]>(API.DEVICE, { params: { validate: true, cluster_id: id } })
        .then((res) => res.data);
}

export function* validateDeviceBySn({ sn }: { sn: string }): Generator {
    return yield axiosClient
        .get<DeviceValidateStatus>(API.DEVICE, { params: { validate: true, sn: sn } })
        .then((res) => res.data);
}

export function* deleteDevice({ sn }: { sn: string }): Generator {
    return yield axiosClient.delete(`${API.DEVICE}/${sn}`).then((res) => res.data);
}
