import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as validateTrialSuccess } from './validateTrialSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<Trial | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const trialSlice = createGenericSlice({
    name: 'trial',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Trial }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(validateTrialSuccess, (state, action: PayloadAction<{ data: Trial }>) => {
            const { data } = action.payload;
            if (state.data?.premium) {
                state.data.premium = data.premium;
            }
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = trialSlice.actions;
export default trialSlice.reducer;
