import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getGlobalSettings(): Generator {
    return yield axiosClient.get<GlobalSettings[]>(API.SETTING_GLOBAL).then((res) => res.data);
}

export function* putGlobalSettings({ data }: { data: UpdateGlobalSettings }): Generator {
    return yield axiosClient.put<GlobalSettings>(API.SETTING_GLOBAL, data).then((res) => res.data);
}
