import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface InitialState extends GenericState<Client | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const upsertWebAppClientSlice = createUpsertGenericSlice({
    name: 'webAppClient',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id: string;
                data: UpsertWebAppsClientData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: Client }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertWebAppClientSlice.actions;

export const selectUpsertWebAppsClient = createSelector(
    (state: RootState) => state.upsertWebAppClient.data,
    (data) => data
);

export default upsertWebAppClientSlice.reducer;
