import { call, put, takeLatest, race, take } from 'redux-saga/effects';
import { getBalance } from '@services/api/resources';
import { getTrial } from '@services/api/trial';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadSuccess as loadBalanceSuccess,
    cancelLoad as cancelLoadBalance,
} from '@store/modules/resources/balanceSlice';
import {
    loadRequest as loadTrial,
    loadSuccess as loadTrialSuccess,
    loadFailure as loadTrialFailure,
    cancelLoad as cancelLoadTrial,
} from './trialSlice';

import {
    loadRequest as validateTrial,
    loadSuccess as validateTrialSuccess,
    loadFailure as validateTrialFailure,
    cancelLoad as cancelValidateTrial,
} from './validateTrialSlice';

export function* fetchTrial(action: ReturnType<typeof loadTrial>) {
    try {
        const { response, cancel }: { response: Trial; cancel: any } = yield race({
            response: call(getTrial, {}),
            cancel: take(cancelLoadTrial),
        });

        if (cancel) {
            return;
        }

        yield put(loadTrialSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadTrialFailure(e.message));
    }
}

export function* validateTrialCall(action: ReturnType<typeof validateTrial>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Trial; cancel: any } = yield race({
            response: call(getTrial, { params }),
            cancel: take(cancelValidateTrial),
        });

        if (cancel) {
            return;
        }
        try {
            if (response.trial_status !== 1) {
                const { response }: { response: Balance; cancel: any } = yield race({
                    response: call(getBalance),
                    cancel: take(cancelLoadBalance),
                });
                yield put(loadBalanceSuccess({ data: response }));
            }
        } catch (e) {
            yield put(cancelLoadBalance(e.message));
        }

        yield put(validateTrialSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(validateTrialFailure(e.message));
    }
}

export function* fetchTrialSaga() {
    yield takeLatest(loadTrial, fetchTrial);
}

export function* validateTrialSaga() {
    yield takeLatest(validateTrial, validateTrialCall);
}
