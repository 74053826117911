import { Row, Col } from 'reactstrap';
import { Icon } from '@shared/components/Icon';
import { classnames } from '@shared/utils/classnames';
import { DEFAULT_COL } from '@shared/utils/constants';

/**
 * Please compared with FLabelWrapper.tsx.
 * This is usually directly used for page level.
 */

const FLabel = ({
    label,
    cols = DEFAULT_COL,
    labelStyle = {},
    styles = {},
    cssClasses = [],
    align = 'center',
    alignText = 'end',
    children,
    id,
    name,
    tooltip,
}: React.PropsWithChildren<TFLabel>) => {
    return (
        <Row className={classnames(['mb-5', `align-items-${align}`, ...cssClasses])} style={styles}>
            <Col xs={cols[0]} className="d-inline-flex align-items-center">
                <label
                    className={classnames(['w-100', `text-${alignText}`, 'break-all-word'])}
                    style={labelStyle}
                    htmlFor={id || name}
                >
                    {label}
                </label>
                {tooltip && (
                    <span style={{ cursor: 'pointer' }} className="d-inline-flex" title={tooltip}>
                        <Icon
                            classNames={[
                                'material-symbols-outlined btn-outline-secondary text-primary font-size-xs ms-1',
                            ]}
                            content="info"
                        />
                    </span>
                )}
            </Col>
            <Col className="break-all-word" xs={cols[1]}>
                {children}
            </Col>
        </Row>
    );
};

export { FLabel };
