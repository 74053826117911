import { GenericState, createGenericSlice } from '../genericSlice';
import type { WebauthnRegisterationOptionsResponse } from './watcher';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: GenericState<WebauthnRegisterationOptionsResponse | null> = {
    loading: false,
    data: null,
    error: null,
};

const webauthnRegistrationOptionSlice = createGenericSlice({
    name: 'webauthnRegistrationOption',
    initialState: initialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ user_id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: WebauthnRegisterationOptionsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = webauthnRegistrationOptionSlice.actions;

export default webauthnRegistrationOptionSlice.reducer;
