export function formatDeviceRemoteData(element: DeviceRemote): DeviceRemoteData {
    return {
        id: element.sn,
        sn: element.sn,
        clusterId: element.cluster_id,
        status: element.status,
        progress: element.progress,
        message: element.message,
    };
}
