import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '@store/index';

interface InitialState {
    value: {
        daily: {
            startDate: string;
            endDate: string;
        };
        monthly: {
            startDate: string;
            endDate: string;
        };
    };
}

const initialState: InitialState = {
    value: {
        daily: {
            startDate: String(dayjs().subtract(9, 'day').toDate()),
            endDate: String(dayjs().toDate()),
        },
        monthly: {
            startDate: String(dayjs(dayjs().subtract(5, 'month')).startOf('month').toDate()),
            endDate: String(dayjs().toDate()),
        },
    },
};

const usageDatetimeSlice = createSlice({
    name: 'usageDateTime',
    initialState,
    reducers: {
        setDatetime: (state, action: PayloadAction<InitialState>) => {
            state.value = action.payload.value;
        },
        cleanDatetime: (state) => {
            state.value = initialState.value;
        },
    },
});

export const { setDatetime, cleanDatetime } = usageDatetimeSlice.actions;

export const selectUsageDatetime = (state: RootState) => state.usageDatetime.value;

export default usageDatetimeSlice.reducer;
