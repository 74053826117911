import axiosClient from '../axiosClient';
import { API } from '../index';

// export function* getBranding({ params }: { params: { id: string } }): Generator {
//     return yield axiosClient.get<Branding[]>(`${API.BRANDING}`, { params }).then((res) => res.data);
// }

export function* getBranding({ id }: { id: string }): Generator {
    return yield axiosClient.get<Branding>(`${API.BRANDING}/${id}`).then((res) => res.data);
}

export function* getBrandings(): Generator {
    return yield axiosClient.get<Branding[]>(`${API.BRANDING}`).then((res) => res.data);
}

export function* postBranding({ data }: { data: UpsertBrandingData }): Generator {
    return yield axiosClient.post(API.BRANDING, data).then((res) => res.data);
}

export function* putBranding({
    id,
    data,
}: {
    id: string;
    data: UpsertBrandingData | UpdateBrandingDefaultData;
}): Generator {
    return yield axiosClient.put(`${API.BRANDING}/${id}`, data).then((res) => res.data);
}

export function* deleteBranding({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.BRANDING}/${id}`).then((res) => res.data);
}
