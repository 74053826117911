import { useEffect, useState } from 'react';
import { FixedLoading, RelativeLoading } from '@shared/components/Loading';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { selectOrgList, selectAccessFrom } from '@store/modules/accounts/accountsSlice';
import { loadLogin } from '@store/modules/auth/loginSlice';
import { flattenTree, buildTree, phraseOrgAccount } from './helper';
import TreeView from './TreeView';
import type { TreeNode, TreeItem, NodeContents } from './types';
import '../AccountSelection.scss';

const nodeContent: NodeContents = [
    { key: 'name', className: 'node-content' },
    { key: 'description', className: 'node-content' },
];

const matcher = (filterText: string, node: TreeItem<TreeNode>) => {
    return (
        node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        node.description.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
};

const OrganizationView = (props: { filter: string }) => {
    const dispatch = useAppDispatch();
    const accountsState = useAppSelector((state: RootState) => state.accounts);
    const [showLoadingMask, setShowLoadingMask] = useState(false);
    const organizationList = useAppSelector(selectOrgList);
    const [organizationTree, setOrganizationTree] = useState<TreeItem<TreeNode> | null>(null);

    // search input
    const { filter } = props;
    const accessFrom = useAppSelector(selectAccessFrom);
    const onclick = (node: TreeItem<TreeNode>) => {
        if (!node.children) {
            if (accessFrom) {
                setShowLoadingMask(true);
                dispatch(loadLogin(phraseOrgAccount(node.id, accessFrom)));
            }
        }
    };

    // initial filterAccountList value
    useEffect(() => {
        if (organizationList.length) {
            const flatTree = flattenTree(organizationList);
            const tree = buildTree(flatTree, 'id', 'parentId');
            setOrganizationTree(tree);
        }
    }, [organizationList]);

    return accountsState.loading ? (
        <RelativeLoading />
    ) : (
        organizationTree && (
            <>
                {showLoadingMask && <FixedLoading />}
                <TreeView
                    filter={filter}
                    data={organizationTree}
                    onNodeToggle={onclick}
                    matcher={matcher}
                    nodeContent={nodeContent}
                ></TreeView>
            </>
        )
    );
};

export { OrganizationView };
