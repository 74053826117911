import { useEffect, useState } from 'react';

const useLogout = (startTime: number, isLogin: boolean) => {
    const [timer, setTimer] = useState(startTime);
    useEffect(() => {
        if (!isLogin) {
            return;
        }
        const logoutIdleTimeInterval = setInterval(() => {
            setTimer((timer) => (timer - 1 >= 0 ? timer - 1 : 0));
        }, 1000);
        const resetTimeout = () => {
            setTimer(startTime);
        };
        const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
        events.forEach((event) => window.addEventListener(event, resetTimeout));
        return () => {
            clearInterval(logoutIdleTimeInterval);
            events.forEach((event) => window.removeEventListener(event, resetTimeout));
        };
    }, [isLogin]);
    return timer;
};

export default useLogout;
