import { toast } from 'react-toastify';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { getRealms } from '@services/api/realms';
import {
    deleteDomainMapping,
    getDomainMappings,
    postDomainMapping,
    putDomainMapping,
    getUserSources,
} from '@services/api/userSource';
import { RootState } from '@store/index';
import {
    deleteRequest as deleteDomainMappings,
    deleteSuccess as deleteDomainMappingsSuccess,
    deleteFailure as deleteDomainMappingsFailure,
    cancelDelete as cancelDeleteDomainMappings,
} from './deleteDomainMappingsSlice';
import {
    loadRequest as loadDomainMappingResources,
    loadSuccess as loadDomainMappingResourcesSuccess,
    loadFailure as loadDomainMappingResourcesFailure,
    cancelLoad as cancelLoadDomainMappingResources,
} from './domainMappingResourcesSlice';
import {
    loadRequest as loadDomainMappings,
    loadSuccess as loadDomainMappingsSuccess,
    loadFailure as loadDomainMappingsFailure,
    cancelLoad as cancelLoadDomainMappings,
} from './domainMappingsSlice';
import {
    upsertRequest as upsertDomainMappingRequest,
    upsertSuccess as upsertDomainMappinguccess,
    upsertFailure as upsertDomainMappingFailure,
    cancelUpsert as cancelUpsertDomainMapping,
} from './upsertDomainMappingSlice';

export function* fetchDomainMappings(action?: ReturnType<typeof loadDomainMappings>) {
    try {
        const { response, cancel }: { response: DomainMapping[]; cancel: any } = yield race({
            response: call(getDomainMappings, action?.payload ?? {}),
            cancel: take(cancelLoadDomainMappings),
        });

        if (cancel) {
            return;
        }

        yield put(loadDomainMappingsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadDomainMappingsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchDomainMappingResources(action?: ReturnType<typeof loadDomainMappingResources>) {
    try {
        const { response, cancel }: { response: DomainMapping[]; cancel: any } = yield race({
            response: call(getDomainMappings, {}),
            cancel: take(cancelLoadDomainMappingResources),
        });
        if (cancel) {
            return;
        }

        let realms: Realm[] = [];
        realms = yield select((state: RootState) => state.userSourceResources.data.realms);
        if (!realms) {
            const { response }: { response: Realm[] } = yield race({ response: call(getRealms) });
            realms = response;
        }

        let userSources: UserSource[] = [];
        userSources = yield select((state: RootState) => state.appResources.data.userSources);
        if (!userSources) {
            const { response }: { response: UserSource[] } = yield race({ response: call(getUserSources) });
            userSources = response;
        }
        yield put(loadDomainMappingResourcesSuccess({ data: { domainMappings: response, realms, userSources } }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadDomainMappingResourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* upsertDomainMapping(action: ReturnType<typeof upsertDomainMappingRequest>) {
    const { id, data } = action.payload;

    // Only edit mode has id
    const upsertCall = id ? call(putDomainMapping, { id, data }) : call(postDomainMapping, { data });

    try {
        const { response, cancel }: { response: DomainMapping; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertDomainMapping),
        });

        if (cancel) {
            return;
        }

        yield put(upsertDomainMappinguccess({ data: response }));
        // load initial data
        yield put(loadDomainMappings());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(upsertDomainMappingFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteDomainMappingsRequest(action: ReturnType<typeof deleteDomainMappings>) {
    const { ids } = action.payload;

    const deleteDomainMappingCalls = ids.map((id) => call(deleteDomainMapping, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteDomainMappingCalls),
            cancel: take(cancelDeleteDomainMappings),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteDomainMappingsSuccess({ data: 'success' }));
        // load initial data
        yield put(loadDomainMappings());
        toast.success(`${ids.length} Domain(s) Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteDomainMappingsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchDomainMappingsSaga() {
    yield takeLatest(loadDomainMappings, fetchDomainMappings);
}

export function* fetchDomainMappingResourcesSaga() {
    yield takeLatest(loadDomainMappingResources, fetchDomainMappingResources);
}

export function* upsertDomainMappingSaga() {
    yield takeLatest(upsertDomainMappingRequest, upsertDomainMapping);
}

export function* deleteDomainMappingSaga() {
    yield takeLatest(deleteDomainMappings, deleteDomainMappingsRequest);
}
