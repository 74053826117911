import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { DefaultTemplatesParams } from '@services/api/templates';
import { RootState } from '@store/index';
import { RealmSettingsResponse } from './watcher';
import { GenericState, createGenericSlice } from '../../genericSlice';

interface InitialState extends GenericState<RealmSettingsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const realmSettingsSlice = createGenericSlice({
    name: 'realmSettings',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{ realmId: string | null; templateParamsList: DefaultTemplatesParams[] }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmSettingsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const selectRealmOptions = createSelector(
    (state: RootState) => state.realmSettings.data?.realms.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectSMSotpOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.SMSotp?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const selectEmailotpOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.Emailotp?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const selectSMSactivationOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.SMSactivation?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const selectEmailactivationOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.Emailactivation?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const selectSMStransferOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.SMStransfer?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const selectEmailtransferOptions = createSelector(
    (state: RootState) =>
        state.realmSettings.data?.Emailtransfer?.map((option) => {
            if (!option.id) {
                return {
                    ...option,
                    id: 0,
                };
            }

            return option;
        }),
    (data) => data ?? []
);

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmSettingsSlice.actions;

export default realmSettingsSlice.reducer;
