import axiosClient from '../axiosClient';
import { API } from '../index';

export type GroupParams = { alarm_id?: string };

export function* getGroup({ params }: { params?: GroupParams }): Generator {
    return yield axiosClient.get<Group[]>(API.NOTIFICATION_GROUP, { params }).then((res) => res.data);
}

export function* postGroup({ data }: { data: CreateGroupData }): Generator {
    return yield axiosClient.post<Group>(API.NOTIFICATION_GROUP, data).then((res) => res.data);
}

export function* putGroup({ id, data }: { id: string; data: UpdateGroupData['group'] }): Generator {
    return yield axiosClient.put<Group>(`${API.NOTIFICATION_GROUP}/${id}`, data).then((res) => res.data);
}

export function* deleteGroup({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.NOTIFICATION_GROUP}/${id}`).then((res) => res.data);
}

export function* postGroupReceiver({ id, data }: { id: string; data: { receiver_id: string } }): Generator {
    return yield axiosClient.post(`${API.NOTIFICATION_GROUP}/${id}/receiver`, data).then((res) => res.data);
}

export function* deleteGroupReceiver({ groupId, receiverId }: { groupId: string; receiverId: string }): Generator {
    return yield axiosClient
        .delete(`${API.NOTIFICATION_GROUP}/${groupId}/receiver/${receiverId}`)
        .then((res) => res.data);
}
