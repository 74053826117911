import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getWebauthnCredentials, deleteWebauthnCredential } from '@services/api/webauthn';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteWebauthnCredentials,
    deleteSuccess as deleteWebauthnCredentialsSuccess,
    deleteFailure as deleteWebauthnCredentialsFailure,
    cancelDelete as cancelDeleteWebauthnCredentials,
} from './deleteWebauthnCredentialsSlice';
import {
    loadRequest as loadWebauthnCredentials,
    loadFailure as loadWebauthnCredentialsFailure,
    loadSuccess as loadWebauthnCredentialsSuccess,
    cancelLoad as cancelWebauthnCredentials,
} from './webauthnCredentialsSlice';

export function* fetchWebauthnCredentials(action: ReturnType<typeof loadWebauthnCredentials>) {
    try {
        const { response, cancel }: { response: WebauthnCredential[]; cancel: any } = yield race({
            response: call(getWebauthnCredentials),
            cancel: take(cancelWebauthnCredentials),
        });

        if (cancel) {
            return;
        }

        yield put(loadWebauthnCredentialsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadWebauthnCredentialsFailure(e.message));
    }
}

export function* deleteWebauthnsRequest(action: ReturnType<typeof deleteWebauthnCredentials>) {
    const { ids } = action.payload;
    const deleteCalls = ids.map((id) => call(deleteWebauthnCredential, { id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteWebauthnCredentials),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteWebauthnCredentialsSuccess({ data: 'success' }));
        toast.success('Passkey(s) Deleted');

        //  load initial data
        yield put(loadWebauthnCredentials());
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteWebauthnCredentialsFailure(e?.response?.data));
    }
}

export function* fetchWebauthnCredentialsSaga() {
    yield takeLatest(loadWebauthnCredentials, fetchWebauthnCredentials);
}

export function* deleteWebauthnCredentialsSaga() {
    yield takeLatest(deleteWebauthnCredentials, deleteWebauthnsRequest);
}
