import { type PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface InitialState extends GenericState<Application | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const upsertApplicationSlice = createUpsertGenericSlice({
    name: 'upsertApplication',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id?: string;
                data: UpsertApplicationCallbackData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: Application }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertApplicationSlice.actions;

export default upsertApplicationSlice.reducer;
