import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getWebauthnCredentials(user_id?: string): Generator {
    const webauthnCredentialUrl = user_id ? `${API.WEBAUTHN_CREDENTIAL}?user_id=${user_id}` : API.WEBAUTHN_CREDENTIAL;
    return yield axiosClient.get<WebauthnCredential[]>(webauthnCredentialUrl).then((res) => res.data);
}

export function* deleteWebauthnCredential({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.WEBAUTHN_CREDENTIAL}/${id}`).then((res) => res.data);
}

export function* getRegistrationOptions({ user_id }: { user_id: string }): Generator {
    return yield axiosClient.get(`${API.WEBAUTHN_REGISTERATION_OPTIONS}?user_id=${user_id}`).then((res) => res.data);
}

export function* postRegistrationVerification({ data }: { data: WebauthnRegisterationVerificationData }): Generator {
    return yield axiosClient
        .post<WebauthnRegisterationVerification>(API.WEBAUTHN_REGISTERATION_VERIFICATION, data)
        .then((res) => res.data);
}
