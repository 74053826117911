import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest, select } from 'redux-saga/effects';
import { getRealms } from '@services/api/realms';
import {
    getUserSources,
    getUserSource,
    postUserSource,
    putUserSource,
    deleteUserSource,
    getDomainMappings,
    postDomainMapping,
    getUserSourcePreview,
    putUserSourceDomainMapping,
} from '@services/api/userSource';
import { RootState } from '@store/index';

import {
    createRequest as createUserSourceDomainMappingRequest,
    createSuccess as createUserSourceDomainMappingSuccess,
    createFailure as createUserSourceDomainMappingFailure,
    cancelCreate as cancelCreateUserSourceDomainMapping,
} from './createUserSourceDomainMappingSlice';
import {
    deleteRequest as deleteUserSourcesRequest,
    deleteSuccess as deleteUserSourcesSuccess,
    deleteFailure as deleteUserSourcesFailure,
    cancelDelete as cancelDeleteUserSources,
} from './deleteUserSourcesSlice';
import {
    upsertRequest as upsertUserSourceRequest,
    upsertSuccess as upsertUserSourceSuccess,
    upsertFailure as upsertUserSourceFailure,
    cancelUpsert as cancelUpsertUserSource,
} from './upsertUserSourceSlice';
import {
    loadRequest as loadUserSourceDomainMappings,
    loadSuccess as loadUserSourceDomainMappingsSuccess,
    loadFailure as loadUserSourceDomainMappingsFailure,
    cancelLoad as cancelLoadUserSourceDomainMappings,
} from './userSourceDomainMappingsSlice';
import {
    loadRequest as loadUserSourceRealms,
    loadSuccess as loadUserSourceRealmsSuccess,
    loadFailure as loadUserSourceRealmsFailure,
} from './userSourceRealmsSlice';
import {
    loadRequest as loadUserSourceResources,
    loadSuccess as loadUserSourceResourcesSuccess,
    loadFailure as loadUserSourceResourcesFailure,
    cancelLoad as cancelLoadUserSourceResources,
    NonNullableUserSourceResource,
} from './userSourceResourcesSlice';
import {
    loadRequest as loadUserSource,
    loadSuccess as loadUserSourceSuccess,
    loadFailure as loadUserSourceFailure,
    cancelLoad as cancelLoadUserSource,
} from './userSourceSlice';
import {
    loadRequest as loadUserSources,
    loadSuccess as loadUserSourcesSuccess,
    loadFailure as loadUserSourcesFailure,
    cancelLoad as cancelLoadUserSources,
} from './userSourcesSlice';

export function* fetchUserSources(action: ReturnType<typeof loadUserSources>) {
    try {
        const { response, cancel }: { response: UserSource[]; cancel: any } = yield race({
            // response: authnetications,
            response: call(getUserSources),
            cancel: take(cancelLoadUserSources),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserSourcesSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserSource(action: ReturnType<typeof loadUserSource>) {
    try {
        const { response, cancel }: { response: UserSourceDetail; cancel: any } = yield race({
            // response: authnetications.find((auth) => auth.id === action.payload.params.id),
            response: call(getUserSource, action.payload),
            cancel: take(cancelLoadUserSource),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserSourceSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourceFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* upsertUserSource(action: ReturnType<typeof upsertUserSourceRequest>) {
    const { id, data: dataWithCallback } = action.payload;
    const { domain_ids, ...data } = dataWithCallback;

    // Only edit mode has id
    const upsertCall = id ? call(putUserSource, { id, data }) : call(postUserSource, { data });

    try {
        const { response, cancel }: { response: UserSourceDetail; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertUserSource),
        });

        if (cancel) {
            return;
        }
        if (domain_ids) {
            try {
                const userSourceId = response.id;
                yield race({
                    response: call(putUserSourceDomainMapping, { userSourceId, data: { domain_ids } }),
                });
                toast.success('Mapping of Domain Name to User Source successful');
            } catch (e) {
                const errMsg =
                    typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
                toast.error(errMsg);
            }
        }
        yield put(upsertUserSourceSuccess({ data: response }));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(upsertUserSourceFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteUserSources(action: ReturnType<typeof deleteUserSourcesRequest>) {
    const { ids } = action.payload;

    const deleteUserSourceCalls = ids.map((id) => call(deleteUserSource, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteUserSourceCalls),
            cancel: take(cancelDeleteUserSources),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteUserSourcesSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadUserSources());
        toast.success(`${ids.length} UserSource(s) Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteUserSourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserSourceRealms(action: ReturnType<typeof loadUserSourceRealms>) {
    try {
        const { response, cancel }: { response: Realm[]; cancel: any } = yield race({
            response: call(getRealms),
            cancel: take(cancelLoadUserSource),
        });

        if (cancel) {
            return;
        }
        yield put(loadUserSourceRealmsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourceRealmsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserSourceResources(action: ReturnType<typeof loadUserSourceResources>) {
    const userSourceId = action.payload.userSourceId;
    const domainsCall = { domains: call(getDomainMappings, { params: { available: true } }) };
    const userSourceResourcecalls = userSourceId
        ? domainsCall
        : { ...domainsCall, userSourcePreview: call(getUserSourcePreview) };

    try {
        const { response, cancel }: { response: NonNullableUserSourceResource; cancel: any } = yield race({
            response: all(userSourceResourcecalls),
            cancel: take(cancelLoadUserSourceResources),
        });

        if (cancel) {
            return;
        }

        let realms: Realm[] = [];
        try {
            realms = yield select((state: RootState) => state.userSourceResources.data.realms);
            if (!realms) {
                const { response }: { response: Realm[] } = yield race({ response: call(getRealms) });
                realms = response;
            }
        } catch (e) {}

        let userSources: UserSource[] = [];
        try {
            userSources = yield select((state: RootState) => state.userSourceResources.data.userSources);
            if (!userSources) {
                const { response }: { response: UserSource[] } = yield race({ response: call(getUserSources) });
                userSources = response;
            }
        } catch (e) {}
        yield put(
            loadUserSourceResourcesSuccess({
                data: {
                    ...response,
                    realms,
                    userSources,
                },
            })
        );
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourceResourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserSourceDomainMappings(action: ReturnType<typeof loadUserSourceDomainMappings>) {
    try {
        const { response, cancel }: { response: DomainMapping[]; cancel: any } = yield race({
            response: call(getDomainMappings, { params: { available: true } }),
            cancel: take(cancelLoadUserSourceDomainMappings),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserSourceDomainMappingsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourceDomainMappingsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* createUserSourceDomainMapping(action: ReturnType<typeof createUserSourceDomainMappingRequest>) {
    const { data } = action.payload.params;
    try {
        const { response, cancel }: { response: DomainMapping; cancel: any } = yield race({
            response: call(postDomainMapping, { data }),
            cancel: take(cancelCreateUserSourceDomainMapping),
        });

        if (cancel) {
            return;
        }

        yield put(createUserSourceDomainMappingSuccess({ data: response }));

        // load initial data
        yield put(loadUserSourceDomainMappings({ params: { available: true } }));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createUserSourceDomainMappingFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserSourcesSaga() {
    yield takeLatest(loadUserSources, fetchUserSources);
}

export function* fetchUserSourceSaga() {
    yield takeLatest(loadUserSource, fetchUserSource);
}

export function* fetchUserSourceRealmsSaga() {
    yield takeLatest(loadUserSourceRealms, fetchUserSourceRealms);
}

export function* upsertUserSourceSaga() {
    yield takeLatest(upsertUserSourceRequest, upsertUserSource);
}

export function* deleteUserSourcesaga() {
    yield takeLatest(deleteUserSourcesRequest, deleteUserSources);
}

export function* fetchUserSourceResourcesSaga() {
    yield takeLatest(loadUserSourceResources, fetchUserSourceResources);
}

export function* createUserSourceDomainMappingSaga() {
    yield takeLatest(createUserSourceDomainMappingRequest, createUserSourceDomainMapping);
}

export function* fetchUserSourceDomainMappingsSaga() {
    yield takeLatest(loadUserSourceDomainMappings, fetchUserSourceDomainMappings);
}
