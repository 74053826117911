import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createCreateGenericSlice } from '../createCreateSlice';

interface InitialState extends GenericState<string | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateUsersInGroupSlice = createCreateGenericSlice({
    name: 'updateUsersInGroup',
    initialState: initialState as InitialState,
    reducers: {
        createRequest: (
            state,
            action: PayloadAction<{
                id: string;
                data: UpdateUsersInGroup;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        createSuccess: (state, action: PayloadAction<{ data: string }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { createRequest, createSuccess, createFailure, cancelCreate } = updateUsersInGroupSlice.actions;

export default updateUsersInGroupSlice.reducer;
