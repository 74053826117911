import { useState } from 'react';

export function useModal() {
    const [isOpen, setIsModalOpen] = useState(false);

    return {
        isOpen,
        toggle: () => setIsModalOpen(!isOpen),
        close: () => setIsModalOpen(false),
    };
}
