import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { deleteClient, getClient, getClients, postClient, putClient } from '@services/api/clients';
import { getRealms } from '@services/api/realms';
import { ResourcesCountResponse, getResourceCount } from '@services/api/resources';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteClients,
    deleteSuccess as deleteClientsSuccess,
    deleteFailure as deleteClientsFailure,
    cancelDelete as cancelDeleteClients,
} from './deleteMgmtAppClientsSlice';
import {
    loadRequest as loadClient,
    loadSuccess as loadClientSuccess,
    loadFailure as loadClientFailure,
    cancelLoad as cancelLoadClient,
} from './mgmtAppClientSlice';
import {
    loadRequest as loadClients,
    loadSuccess as loadClientsSuccess,
    loadFailure as loadClientsFailure,
    cancelLoad as cancelLoadClients,
} from './mgmtAppClientsSlice';
import {
    updateRequest as updateSecretClient,
    updateSuccess as updateSecretClientSuccess,
    updateFailure as updateSecretClientFailure,
    cancelUpdate as cancelUpdateSecretClient,
} from './updateSecretMgmtAppClientSlice';
import {
    upsertRequest as upsertClient,
    upsertSuccess as upsertClientSuccess,
    upsertFailure as updateClientFailure,
    cancelUpsert as cancelUpsertClient,
} from './upsertMgmtAppClientSlice';

export type MgmtAppsClientsResponse = {
    clients: Client[];
    realms: Realm[];
    resourcesCount: ResourcesCountResponse;
};

const clientsParams = {
    params: { clients: { type: 'ManagementApp' }, resourcesCount: { resource: 'client' } },
};

export function* fetchMgmtAppClients(action: ReturnType<typeof loadClients>) {
    const {
        params: { clients, resourcesCount },
    } = action.payload;
    try {
        const { response, cancel }: { response: MgmtAppsClientsResponse; cancel: any } = yield race({
            response: all({
                clients: call(getClients, { params: clients }),
                realms: call(getRealms),
                resourcesCount: call(getResourceCount, { params: resourcesCount }),
            }),
            cancel: take(cancelLoadClients),
        });

        if (cancel) {
            return;
        }

        yield put(loadClientsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadClientsFailure(e.message));
    }
}

export function* fetchMgmtAppClient(action: ReturnType<typeof loadClient>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: call(getClient, { params }),
            cancel: take(cancelLoadClient),
        });

        if (cancel) {
            return;
        }

        yield put(loadClientSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadClientFailure(e.message));
    }
}

export function* upsertMgmtAppClient(action: ReturnType<typeof upsertClient>) {
    const { id, data } = action.payload;

    // Only edit mode has id
    const upsertCall = id ? call(putClient, { id, data }) : call(postClient, { data });

    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertClient),
        });

        if (cancel) {
            return;
        }

        // Only in create mode, api response will not provide reamls. Need to set realms data from frontend to response
        if (!id && data.realms && !response.realms) {
            response.realms = data.realms;
        }

        yield put(upsertClientSuccess({ data: response }));

        // load initial data
        yield put(loadClients(clientsParams));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = e?.response?.data;
        yield put(updateClientFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateSecretMgmtAppClient(action: ReturnType<typeof updateSecretClient>) {
    const { id, data, option } = action.payload;

    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: call(putClient, { id, data }),
            cancel: take(cancelUpdateSecretClient),
        });

        if (cancel) {
            return;
        }

        yield put(updateSecretClientSuccess({ data: { client: response, option } }));

        // load initial data
        yield put(loadClients(clientsParams));
        // option 1 is send email
        if (option === 1) {
            toast.success('Email Sent');
        }
    } catch (e) {
        const errMsg = e?.response?.data?.error_message;
        yield put(updateSecretClientFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteMgmtAppsClientsRequest(action: ReturnType<typeof deleteClients>) {
    const { ids } = action.payload;

    const deleteClientCalls = ids.map((id) => call(deleteClient, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteClientCalls),
            cancel: take(cancelDeleteClients),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteClientsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadClients(clientsParams));
        toast.success(`${ids.length} Management Token Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data?.error_message;
        yield put(deleteClientsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchMgmtAppClientsSaga() {
    yield takeLatest(loadClients, fetchMgmtAppClients);
}

export function* fetchMgmtAppClientSaga() {
    yield takeLatest(loadClient, fetchMgmtAppClient);
}

export function* upsertMgmtAppClientSaga() {
    yield takeLatest(upsertClient, upsertMgmtAppClient);
}

export function* updateSecretMgmtAppClientSaga() {
    yield takeLatest(updateSecretClient, updateSecretMgmtAppClient);
}

export function* deleteMgmtAppClientsSaga() {
    yield takeLatest(deleteClients, deleteMgmtAppsClientsRequest);
}
