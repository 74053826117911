import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { SUPER_ADMIN } from '@shared/utils/constants';
import { request, success, failure, FETCH_AUTH } from '../../actions';
import type { RootState } from '../../index';

interface AuthState {
    loading: boolean;
    user: AuthUser | null;
    error: Error | string | null;
}

const initialState: AuthState = {
    loading: false,
    user: null,
    error: null,
};

// action creators
export const loadAuth = createAction(request(FETCH_AUTH));
export const loadAuthSuccess = createAction<{
    user: NonNullable<AuthState['user']>;
}>(success(FETCH_AUTH));
export const loadAuthFailure = createAction<Error | string>(failure(FETCH_AUTH));
// export const cancelloadAuth = createAction(cancel(FETCH_AUTH));

// reducers
const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loadAuth, (state) => {
            state.loading = true;
        })
        .addCase(loadAuthSuccess, (state, action) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload.user;
        })
        .addCase(loadAuthFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        // .addCase(cancelloadAuth, () => initialState)
        .addDefaultCase((state) => state);
});

// we will not use this selector before user logined, let's assume the user object is always not null
export const selectAuthUser = (state: RootState) => state.auth.user as NonNullable<AuthState['user']>;

export const checkIsSuperAdmin = (state: RootState) => state.auth.user?.admin_role === SUPER_ADMIN || false;

// @TODO
export const selectAuthUserLogoutHkey = createSelector(
    (state: RootState) => state.auth.user as NonNullable<AuthState['user']>,
    (data): string => {
        const userData = data['access_from'] ?? data;
        return JSON.stringify({
            source_app: 'FTC',
            account_id: userData.account_id ?? '0',
            user_id: userData.user_id ?? '0',
            node_id: userData.node_id ?? null,
            user_fullaccess: userData.user_fullaccess ?? true,
            partner_id: userData.partner_id ?? '',
            context_data: userData.context_data ?? '',
            visited_sites: userData.visited_sites ?? [],
        });
    }
);

export const selectAccountSelected = createSelector(
    (state: RootState) => state.auth?.user?.account_id,
    (data) => data !== undefined && data !== null
);

export const selectGenericCookieSuffix = createSelector(selectAuthUser, (authUser) =>
    authUser ? `:${authUser.account_id}:${authUser.user_id}` : ''
);

export const selectMenuItemStatus = createSelector(selectAuthUser, (authUser) =>
    process.env.REACT_APP_WEBSITE !== 'admin' && authUser.account_id
        ? [...authUser.support_menu, ...authUser.portals, ...authUser.user_menu].reduce<
              Record<number, FortiCloudItemStatus>
          >((statusMap, item) => {
              const key = 'item_id' in item ? item.item_id : item.app_id;
              statusMap[key] = item.visibility ?? 'Hide';
              return statusMap;
          }, {})
        : {}
);

export default authReducer;
