import axiosClient from '../axiosClient';
import { API } from '../index';

export type TokensResponse = Token[];

export type TempTokenParams = {
    temp_token?: boolean;
};

export type HardTokenParams = {
    available?: boolean; // this is hardTokens
};

export type SoftTokenParams = {
    status?: 'disable' | 'enabled' | 'transfer' | 'others';
    soft_token?: boolean;
};

export type TokenParams = TempTokenParams & HardTokenParams & SoftTokenParams;

export type ImportTokenResponse = {
    error: string;
    tokens: TokenImportData['tokens'];
};

export function* getTokens({ params }: { params?: TokenParams }): Generator {
    return yield axiosClient.get<TokensResponse>(API.TOKEN, { params }).then((res) => res.data);
}

export function* postTokenTemp({ data }: { data: UserTempTokenEditSubmitData }): Generator {
    return yield axiosClient.post<TokensResponse>(API.TOKEN_TEMP, data).then((res) => res.data);
}

export function* deleteTokenTemp({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.TOKEN_TEMP}/${id}`).then((res) => res.data);
}

export function* importToken(data: TokenImportData): Generator {
    return yield axiosClient.post<ImportTokenResponse>(API.TOKEN_IMPORT, data).then((res) => res.data);
}

export function* deleteTonken({ sn }: { sn: string }): Generator {
    return yield axiosClient.delete(`${API.TOKEN}/${sn}`).then((res) => res.data);
}
