import type { TreeNode, TreeItem, ExpandedNode, Organization, OuNode, AccountNode } from './types';

export const flattenTree = (data: Organization[]): TreeNode[][] => {
    const flatOrganizations: TreeNode[][] = [];
    data.forEach((org: Organization) => {
        const flatOrganization: TreeNode[] = [];
        flatOrganization.push({
            id: org.org_id,
            description: org.org_description,
            name: org.org_name,
            type: 'org',
        });

        if (org.org_units) {
            org.org_units.forEach((unit: OuNode) => {
                flatOrganization.push({
                    id: unit.ou_node_id,
                    description: unit.ou_description,
                    name: unit.ou_name,
                    parentId: unit.parent_id,
                    type: 'ou',
                });
            });
        }

        if (org.member_accounts) {
            org.member_accounts.forEach((account: AccountNode) => {
                flatOrganization.push({
                    id: account.account_node_id,
                    description: account.account_description,
                    name: account.account_name,
                    parentId: account.parent_id,
                    type: 'account',
                });
            });
        }
        flatOrganizations.push(flatOrganization);
    });
    return flatOrganizations;
};

export const buildTree = (
    flatTree: TreeNode[][],
    elementKey: keyof TreeNode,
    parentKey: keyof TreeNode
): TreeItem<TreeNode> => {
    const tree: TreeItem<TreeNode> = { name: 'root', id: -1, children: [], description: '', type: 'root' };
    const _flatTree = JSON.parse(JSON.stringify(flatTree)) as TreeItem<TreeNode>[][];
    _flatTree.forEach((array: TreeItem<TreeNode>[]) => {
        array.forEach((node: TreeItem<TreeNode>) => {
            if (node[parentKey]) {
                const parent = array.filter((elem: TreeItem<TreeNode>) => elem[elementKey] === node[parentKey]).pop();
                if (parent) {
                    if (!parent['children']) {
                        parent.children = [];
                    }
                    parent.children.push(node);
                }
            } else {
                tree.children.push(node);
            }
        });
    });
    return tree;
};

export const findNode = (node: TreeItem<TreeNode>, filter: string, matcher: Function): boolean => {
    return (
        matcher(filter, node) ||
        (node.children &&
            !!node.children.length &&
            !!node.children.find((child: TreeItem<TreeNode>) => findNode(child, filter, matcher)))
    );
};

export const filterTree = (
    node: TreeItem<TreeNode>,
    filter: string,
    expanded: ExpandedNode,
    matcher: Function
): { node: TreeItem<TreeNode>; expanded: ExpandedNode } => {
    if (matcher(filter, node) || !node.children) {
        return { node, expanded };
    }
    const filtered = node.children
        .filter((child: TreeItem<TreeNode>) => findNode(child, filter, matcher))
        .map((child: TreeItem<TreeNode>) => {
            const result = filterTree(child, filter, expanded, matcher);
            return result.node;
        });
    expanded[node.id] = true;
    const _node = Object.assign({}, node, { children: filtered });
    return { node: _node, expanded };
};

export const phraseOrgAccount = (nodeId: number, accessFrom: Organization['access_from']): { h_key: string } => {
    const query = {
        source_app: 'FTC',
        account_id: accessFrom.account_id,
        user_id:
            accessFrom.user_id == null || accessFrom.user_id === 0 || accessFrom.user_id === -1
                ? '0'
                : accessFrom.user_id,
        node_id: nodeId,
        user_fullaccess: true,
        partner_id: '',
        context_data: '',
        visited_sites: [],
    };
    return { h_key: JSON.stringify(query) };
};

const OrgIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#BCDAFF"
                />
                <mask
                    id="mask0_2028_688422"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="64"
                    height="64"
                >
                    <path
                        d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                        fill="#A5A6F6"
                    />
                </mask>
                <g mask="url(#mask0_2028_688422)">
                    <path
                        d="M16.5293 30.3298V13.5869C16.5293 13.0346 16.977 12.5869 17.5293 12.5869H44.4436C44.9959 12.5869 45.4436 13.0346 45.4436 13.5869V32.9583"
                        fill="#88B3E9"
                    />
                    <path
                        d="M22.4443 32.9584V19.5012C22.4443 18.9489 22.8921 18.5012 23.4443 18.5012H50.3586C50.9109 18.5012 51.3586 18.9489 51.3586 19.5012V32.9584"
                        fill="#88B3E9"
                    />
                    <path
                        d="M22.4443 32.9584V19.5012C22.4443 18.9489 22.8921 18.5012 23.4443 18.5012H50.3586C50.9109 18.5012 51.3586 18.9489 51.3586 19.5012V32.9584"
                        stroke="#BCDAFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M34.2463 32.9584H51.3517L53.9803 35.5738C54.0065 41.2909 53.9671 44.9972 53.9474 50.7144L51.3188 53.3298H14.5583L11.9297 50.7012V28.3584L14.5583 25.7298H27.0243L34.2463 32.9584Z"
                        fill="#69ACFF"
                        stroke="#BCDAFF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

const OrgUnitIconBlue = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#BCDAFF"
                />
                <path
                    d="M17.1788 22.0571H30.0359C30.3645 22.0571 30.6859 22.1806 30.9216 22.4068L38.3564 29.5443C38.3937 29.58 38.4433 29.6 38.4949 29.6H47.7859C48.3382 29.6 48.7859 29.1523 48.7859 28.6V21.4411C48.7859 21.1688 48.6749 20.9082 48.4784 20.7197L46.9476 19.25C46.7613 19.0712 46.5132 18.9714 46.255 18.9714H32.893C32.5645 18.9714 32.243 18.848 32.0073 18.6217L24.8047 11.7072C24.6185 11.5284 24.3703 11.4286 24.1122 11.4286H13.0525C12.8245 11.4286 12.6033 11.5065 12.4257 11.6494L10.4972 13.2006C10.2613 13.3904 10.124 13.677 10.124 13.9798V38.0289C10.124 38.3277 10.2577 38.6109 10.4884 38.8008L12.4173 40.3892C12.6783 40.6041 13.0716 40.4185 13.0716 40.0805V26C13.0716 25.6846 13.2002 25.376 13.4359 25.1497L16.293 22.4068C16.5288 22.1806 16.843 22.0571 17.1788 22.0571Z"
                    fill="#69ACFF"
                />
                <path
                    d="M51.657 32H37.8927C37.5641 32 37.2427 31.8766 37.007 31.6503L29.8044 24.7357C29.6181 24.557 29.37 24.4571 29.1118 24.4571H18.1022C17.844 24.4571 17.5959 24.557 17.4096 24.7357L15.8788 26.2054C15.6823 26.394 15.5713 26.6545 15.5713 26.9268V50.8708L17.6999 52.9143H51.657L53.7856 50.8708V34.4628C53.7856 34.1905 53.6745 33.93 53.4781 33.7414L51.662 31.9979C51.6601 31.9961 51.657 31.9974 51.657 32Z"
                    fill="#69ACFF"
                />
            </g>
        </svg>
    );
};

const OrgUnitIconPurple = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#C2C3FD"
                />
                <path
                    d="M17.1788 22.0571H30.0359C30.3645 22.0571 30.6859 22.1806 30.9216 22.4068L38.3564 29.5443C38.3937 29.58 38.4433 29.6 38.4949 29.6H47.7859C48.3382 29.6 48.7859 29.1523 48.7859 28.6V21.4411C48.7859 21.1688 48.6749 20.9082 48.4784 20.7197L46.9476 19.25C46.7613 19.0712 46.5132 18.9714 46.255 18.9714H32.893C32.5645 18.9714 32.243 18.848 32.0073 18.6217L24.8047 11.7072C24.6185 11.5284 24.3703 11.4286 24.1122 11.4286H13.0525C12.8245 11.4286 12.6033 11.5065 12.4257 11.6494L10.4972 13.2006C10.2613 13.3904 10.124 13.677 10.124 13.9798V38.0289C10.124 38.3277 10.2577 38.6109 10.4884 38.8008L12.4173 40.3892C12.6783 40.6041 13.0716 40.4185 13.0716 40.0805V26C13.0716 25.6846 13.2002 25.376 13.4359 25.1497L16.293 22.4068C16.5288 22.1806 16.843 22.0571 17.1788 22.0571Z"
                    fill="#9D9EFA"
                />
                <path
                    d="M51.657 32H37.8927C37.5641 32 37.2427 31.8766 37.007 31.6503L29.8044 24.7357C29.6181 24.557 29.37 24.4571 29.1118 24.4571H18.1022C17.844 24.4571 17.5959 24.557 17.4096 24.7357L15.8788 26.2054C15.6823 26.394 15.5713 26.6545 15.5713 26.9268V50.8708L17.6999 52.9143H51.657L53.7856 50.8708V34.4628C53.7856 34.1905 53.6745 33.93 53.4781 33.7414L51.662 31.9979C51.6601 31.9961 51.657 31.9974 51.657 32Z"
                    fill="#9D9EFA"
                />
            </g>
        </svg>
    );
};

const OrgUnitIconRed = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#F2D8D7"
                />
                <path
                    d="M17.1788 22.0571H30.0359C30.3645 22.0571 30.6859 22.1806 30.9216 22.4069L38.3564 29.5443C38.3937 29.58 38.4433 29.6 38.4949 29.6H47.7859C48.3382 29.6 48.7859 29.1523 48.7859 28.6V21.4411C48.7859 21.1688 48.6749 20.9083 48.4784 20.7197L46.9476 19.25C46.7613 19.0713 46.5132 18.9714 46.255 18.9714H32.893C32.5645 18.9714 32.243 18.848 32.0073 18.6217L24.8047 11.7072C24.6185 11.5284 24.3703 11.4286 24.1122 11.4286H13.0525C12.8245 11.4286 12.6033 11.5065 12.4257 11.6494L10.4972 13.2006C10.2613 13.3904 10.124 13.677 10.124 13.9798V38.0289C10.124 38.3277 10.2577 38.6109 10.4884 38.8008L12.4173 40.3893C12.6783 40.6041 13.0716 40.4185 13.0716 40.0805V26C13.0716 25.6846 13.2002 25.376 13.4359 25.1497L16.293 22.4069C16.5288 22.1806 16.843 22.0571 17.1788 22.0571Z"
                    fill="#F59A95"
                />
                <path
                    d="M51.657 32H37.8927C37.5641 32 37.2427 31.8766 37.007 31.6503L29.8044 24.7358C29.6181 24.557 29.37 24.4572 29.1118 24.4572H18.1022C17.844 24.4572 17.5959 24.557 17.4096 24.7358L15.8788 26.2054C15.6823 26.394 15.5713 26.6545 15.5713 26.9268V50.8709L17.6999 52.9143H51.657L53.7856 50.8709V34.4628C53.7856 34.1905 53.6745 33.93 53.4781 33.7414L51.662 31.9979C51.6601 31.9961 51.657 31.9974 51.657 32Z"
                    fill="#F59A95"
                />
            </g>
        </svg>
    );
};

const AccountIconBlue = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#BCDAFF"
                />
                <path
                    d="M46.001 26.5001H36.001C35.6724 26.5001 35.2367 26.2264 35.001 26.0001V12.0001C35.001 11.4478 34.5533 11.0001 34.001 11.0001H15.5319C15.2737 11.0001 15.0256 11.0999 14.8393 11.2787L13.3084 12.7484C13.112 12.9369 13.001 13.1974 13.001 13.4697V51.4138L15.1295 53.4572H48.0867L50.2153 51.4138V29.0057C50.2153 28.7334 50.1042 28.4729 49.9078 28.2844L48.3697 26.8078C48.1878 26.6331 47.9466 26.5337 47.6944 26.5293L46.001 26.5001Z"
                    fill="#69ACFF"
                />
                <path
                    d="M39.3178 11.0003C39.1018 11 38.7303 10.9996 38.4001 10.9997C38.1795 10.9997 38.001 11.1786 38.001 11.3992V23.6C38.001 23.8209 38.1801 24 38.401 24L49.3353 23.9996C49.4414 23.9996 49.5431 23.9574 49.6181 23.8824L49.8838 23.6167C49.9588 23.5417 50.003 23.4379 49.9646 23.339C49.7307 22.736 48.4338 21.4684 48.001 20.9996L42.501 14.4996L39.6205 11.1399C39.5446 11.0514 39.4344 11.0004 39.3178 11.0003Z"
                    fill="#69ACFF"
                />
            </g>
        </svg>
    );
};

const AccountIconPurple = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#C2C3FD"
                />
                <path
                    d="M46.001 26.5001H36.001C35.6724 26.5001 35.2367 26.2264 35.001 26.0001V12.0001C35.001 11.4478 34.5533 11.0001 34.001 11.0001H15.5319C15.2737 11.0001 15.0256 11.0999 14.8393 11.2787L13.3084 12.7484C13.112 12.9369 13.001 13.1974 13.001 13.4697V51.4138L15.1295 53.4572H48.0867L50.2153 51.4138V29.0057C50.2153 28.7334 50.1042 28.4729 49.9078 28.2844L48.3697 26.8078C48.1878 26.6331 47.9466 26.5337 47.6944 26.5293L46.001 26.5001Z"
                    fill="#9D9EFA"
                />
                <path
                    d="M39.3178 11.0003C39.1018 11 38.7303 10.9996 38.4001 10.9997C38.1795 10.9997 38.001 11.1786 38.001 11.3992V23.6C38.001 23.8209 38.1801 24 38.401 24L49.3353 23.9996C49.4414 23.9996 49.5431 23.9574 49.6181 23.8824L49.8838 23.6167C49.9588 23.5417 50.003 23.4379 49.9646 23.339C49.7307 22.736 48.4338 21.4684 48.001 20.9996L42.501 14.4996L39.6205 11.1399C39.5446 11.0514 39.4344 11.0004 39.3178 11.0003Z"
                    fill="#9D9EFA"
                />
            </g>
        </svg>
    );
};

const AccountIconRed = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path
                    d="M63.9982 31.9964C63.9982 38.561 62.0155 44.6686 58.6283 49.7447C58.5533 49.8571 58.4784 49.9678 58.3981 50.0784C58.1679 50.4193 57.9287 50.7513 57.6914 51.0815C52.0627 58.6402 43.1789 63.6377 33.11 63.9839C32.7424 63.9911 32.373 64 31.9982 64C31.1148 64 30.2386 63.9625 29.3802 63.8911C19.6736 63.1094 11.2021 57.9941 5.88211 50.4907C5.79823 50.3729 5.71614 50.2551 5.63583 50.1373C5.48414 49.9196 5.33601 49.7 5.18967 49.4769C4.9666 49.1307 4.75065 48.7844 4.53828 48.431C1.65969 43.6317 0 38.0095 0 31.9964C0 14.325 14.3287 0 32 0C49.6713 0 64 14.325 64 31.9964H63.9982Z"
                    fill="#F2D8D7"
                />
                <path
                    d="M46.001 26.5001H36.001C35.6724 26.5001 35.2367 26.2264 35.001 26.0001V12.0001C35.001 11.4478 34.5533 11.0001 34.001 11.0001H15.5319C15.2737 11.0001 15.0256 11.0999 14.8393 11.2787L13.3084 12.7484C13.112 12.9369 13.001 13.1974 13.001 13.4697V51.4138L15.1295 53.4572H48.0867L50.2153 51.4138V29.0057C50.2153 28.7334 50.1042 28.4729 49.9078 28.2844L48.3697 26.8078C48.1878 26.6331 47.9466 26.5337 47.6944 26.5293L46.001 26.5001Z"
                    fill="#F59A95"
                />
                <path
                    d="M39.3178 11.0003C39.1018 11 38.7303 10.9996 38.4001 10.9997C38.1795 10.9997 38.001 11.1786 38.001 11.3992V23.6C38.001 23.8209 38.1801 24 38.401 24L49.3353 23.9996C49.4414 23.9996 49.5431 23.9574 49.6181 23.8824L49.8838 23.6167C49.9588 23.5417 50.003 23.4379 49.9646 23.339C49.7307 22.736 48.4338 21.4684 48.001 20.9996L42.501 14.4996L39.6205 11.1399C39.5446 11.0514 39.4344 11.0004 39.3178 11.0003Z"
                    fill="#F59A95"
                />
            </g>
        </svg>
    );
};

const ArrowDownIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow_drop_down" clipPath="url(#clip0_2028_687642)">
                <path
                    id="Vector"
                    d="M17.4201 23.4201L22.6001 28.6001C23.3801 29.3801 24.6401 29.3801 25.4201 28.6001L30.6001 23.4201C31.8601 22.1601 30.9601 20.0001 29.1801 20.0001H18.8201C17.0401 20.0001 16.1601 22.1601 17.4201 23.4201Z"
                    fill="#323232"
                />
            </g>
            <defs>
                <clipPath id="clip0_2028_687642">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ArrowUpIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow_drop_up" clipPath="url(#clip0_2028_687513)">
                <path
                    id="Vector"
                    d="M17.4201 24.58L22.6001 19.4C23.3801 18.62 24.6401 18.62 25.4201 19.4L30.6001 24.58C31.8601 25.84 30.9601 28 29.1801 28H18.8201C17.0401 28 16.1601 25.84 17.4201 24.58Z"
                    fill="#323232"
                />
            </g>
            <defs>
                <clipPath id="clip0_2028_687513">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const CustomIcon = {
    OrgIcon,
    OrgUnitIconBlue,
    OrgUnitIconPurple,
    OrgUnitIconRed,
    AccountIconBlue,
    AccountIconPurple,
    AccountIconRed,
    ArrowDownIcon,
    ArrowUpIcon,
};
