import axiosClient from '../axiosClient';
import { API } from '../index';

export type RealmPermissionsParams = { realm_id?: string; account_group_id?: string; not?: boolean };

export function* getPermissions({ params }: { params: RealmPermissionsParams }): Generator {
    return yield axiosClient.get<RealmPermission[] | Realm[]>(API.REALM_PERMISSION, { params }).then((res) => res.data);
}

export function* postPermission({ data }: { data: AddRealmsByAdminGroup }): Generator {
    return yield axiosClient.post<Realm[]>(API.REALM_PERMISSION, data).then((res) => res.data);
}

export function* deletePermission({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.REALM_PERMISSION}/${id}`).then((res) => res.data);
}
