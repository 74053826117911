export const filterAll = (filterValue: string) => {
    return (item: any) =>
        Object.values(item)?.find(
            (value: any) => value?.toString()?.toLocaleLowerCase()?.includes(filterValue.toLocaleLowerCase())
        ) !== undefined;
};

export function compare(a: any, b: any, order: 'asc' | 'desc') {
    const compareA = typeof a === 'number' ? a : a === 'NA' ? -1 : a?.toUpperCase(); // ignore upper and lowercase
    const compareB = typeof b === 'number' ? b : b === 'NA' ? -1 : b?.toUpperCase(); // ignore upper and lowercase

    if (compareA < compareB) {
        return order === 'asc' ? -1 : 1;
    }
    if (compareA > compareB) {
        return order === 'asc' ? 1 : -1;
    }

    return 0;
}

export function compareQuota(a: any, b: any) {
    const compareA = typeof a === 'number' ? a : -1;
    const compareB = typeof b === 'number' ? b : -1;

    if (compareA < compareB) {
        return -1;
    }
    if (compareA > compareB) {
        return 1;
    }

    return 0;
}

export const PAGE_SIZE = 10;

export const REALM_KEY_MAP: Record<string, string> = {
    Name: 'name',
    'User Quota': 'quota',
    Description: 'description',
    'Application Count': 'client_count',
};

export const REALM_DATA_KEY_MAP: Record<string, string> = {
    Name: 'name',
    'User Quota': 'quota',
    Description: 'description',
    'Application Count': 'clientCount',
};
