import { createSelector } from '@reduxjs/toolkit';
import { loadSuccess as refreshBalanceSuccess } from './refreshBalanceSlice';
import { GenericState, createGenericSlice } from '../genericSlice';
import { loadSuccess as validateTrialSuccess } from '../trial/validateTrialSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface BalanceState extends GenericState<Balance | null> {}

const initialState: BalanceState = {
    loading: false,
    data: null,
    error: null,
};

const balanceSlice = createGenericSlice({
    name: 'balance',
    initialState: initialState as BalanceState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Balance }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(validateTrialSuccess, (state, action: PayloadAction<{ data: Trial }>) => {
            const { data } = action.payload;
            if (state.data?.expiration && data.expiration_date) {
                state.data.expiration = data.expiration_date;
            }
        });
        builder.addCase(refreshBalanceSuccess, (state, action: PayloadAction<{ data: Balance }>) => {
            const { data } = action.payload;
            if (data) {
                state.data = data;
            }
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = balanceSlice.actions;

export const selectBalance = createSelector(
    (state: RootState) => state.balance.data,
    (data) => data
);

export default balanceSlice.reducer;
