import { FLabel } from '@shared/components/Form/Label';
import { FtcModal } from '@shared/components/FtcModal';
import { FORTIPRODUCT } from '@shared/utils/constants';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onBtnClosed: () => void;
    data: AuthClientData[];
    meta: { selectedId: string };
};

function DetailsModal({ isOpen, toggle, onClosed, onBtnClosed, data, meta }: Props) {
    const { selectedId } = meta;
    const selectedDetails = data.find(({ id: clientId }) => clientId === selectedId);

    return (
        <FtcModal
            header={`Detailed Information for ${FORTIPRODUCT}: ${selectedDetails?.alias}`}
            size="lg"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            onBtnClosed={onBtnClosed}
        >
            <div className="details">
                {selectedDetails ? (
                    <>
                        <FLabel label="ID">{selectedDetails.id}</FLabel>
                        <FLabel label="Cluster ID">{selectedDetails.clusterId}</FLabel>
                        <FLabel label="Serial Number">{selectedDetails.sn}</FLabel>
                        <FLabel label="VDOM">{selectedDetails.vdom}</FLabel>
                        <FLabel label="Start Date">{selectedDetails.activationTime}</FLabel>
                    </>
                ) : (
                    'No data available'
                )}
            </div>
        </FtcModal>
    );
}

export default DetailsModal;
