import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { putAppUserSourceMapping } from '@services/api/application';
import { getUserSources, postUserSource } from '@services/api/userSource';
import {
    loadRequest as loadUserSources,
    loadSuccess as loadUserSourcesSuccess,
    loadFailure as loadUserSourcesFailure,
    cancelLoad as cancelLoadUserSources,
} from './appUserSourcesSlice';
import {
    createRequest as createUserSourceRequest,
    createSuccess as createUserSourceSuccess,
    createFailure as createUserSourceFailure,
    cancelCreate as cancelCreateUserSource,
} from './createAppUserSourceSlice';
import {
    updateRequest as updateAppUserSourceMappingRequest,
    updateSuccess as updateAppUserSourceMappingSuccess,
    updateFailure as updateAppUserSourceMappingFailure,
    cancelUpdate as cancelUpdateAppUserSourceMapping,
} from './updateAppUserSourceMappingSlice';

export function* fetchUserSources(action: ReturnType<typeof loadUserSources>) {
    try {
        const { response, cancel }: { response: UserSource[]; cancel: any } = yield race({
            response: call(getUserSources),
            // response: authentications,
            cancel: take(cancelLoadUserSources),
        });
        if (cancel) {
            return;
        }
        yield put(loadUserSourcesSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserSourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* createUserSource(action: ReturnType<typeof createUserSourceRequest>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: UserSource; cancel: any } = yield race({
            response: call(postUserSource, { data }),
            cancel: take(cancelCreateUserSource),
        });

        if (cancel) {
            return;
        }

        yield put(createUserSourceSuccess({ data: response }));

        // load initial data
        yield put(call(getUserSources));

        try {
            const { response }: { response: UserSource[] } = yield race({ response: call(getUserSources) });
            yield put(loadUserSourcesSuccess({ data: response }));
        } catch (e) {
            yield put(loadUserSourcesFailure(e.message));
        }

        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createUserSourceFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* updateAppUserSourceMapping(action: ReturnType<typeof updateAppUserSourceMappingRequest>) {
    const { appId, data } = action.payload;

    try {
        const { response, cancel }: { response: ApplicationUserSourceMapping[]; cancel: any } = yield race({
            response: call(putAppUserSourceMapping, { appId, data }),
            cancel: take(cancelUpdateAppUserSourceMapping),
        });

        if (cancel) {
            return;
        }

        yield put(updateAppUserSourceMappingSuccess({ data: response }));

        // load initial data
        yield put(call(getUserSources));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(updateAppUserSourceMappingFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchAppAuthenticationsSaga() {
    yield takeLatest(loadUserSources, fetchUserSources);
}

export function* createAppAuthenticationSaga() {
    yield takeLatest(createUserSourceRequest, createUserSource);
}
