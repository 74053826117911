import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, creatUpdateGenericSlice } from '@store/modules/updateGenericSlice';

interface InitialState extends GenericState<AdminGroup | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateAdminGroupSlice = creatUpdateGenericSlice({
    name: 'adminGroup',
    initialState: initialState as InitialState,
    reducers: {
        updateRequest: (
            state,
            action: PayloadAction<{
                id: string;
                data: UpsertAdminGroupData;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state, action: PayloadAction<{ data: AdminGroup }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } = updateAdminGroupSlice.actions;

export default updateAdminGroupSlice.reducer;
