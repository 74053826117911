import { GenericState, creatUpdateGenericSlice } from '../../updateGenericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface moveDevicesState extends GenericState<Device | null> {}

const initialState: moveDevicesState = {
    loading: false,
    data: null,
    error: null,
};

const moveDevicesSlice = creatUpdateGenericSlice({
    name: 'moveDevices',
    initialState: initialState as moveDevicesState,
    reducers: {
        updateRequest: (
            state,
            action: PayloadAction<{
                data: moveDevicesData;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state, action: PayloadAction<{ data: Device | null }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } = moveDevicesSlice.actions;

export default moveDevicesSlice.reducer;
