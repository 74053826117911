import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { formatSMS } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface SmsState extends GenericState<{ sms: SMS[]; allSms: SMS[] } | null> {}

const initialState: SmsState = {
    loading: false,
    data: null,
    error: null,
};

const smsSlice = createGenericSlice({
    name: 'sms',
    initialState: initialState as SmsState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: { sms: SMS[]; allSms: SMS[] } }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = smsSlice.actions;

export const selectAllRateSms = createSelector(
    (state: RootState) => state.sms.data?.sms?.map(formatSMS),
    (data) => data ?? []
);

export const selectAllSms = createSelector(
    (state: RootState) => state.sms.data?.allSms?.map(formatSMS),
    (data) => data ?? []
);

export default smsSlice.reducer;
