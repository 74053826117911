import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, creatUpdateGenericSlice } from '@store/modules/updateGenericSlice';

interface InitialState extends GenericState<Group | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateAdminsByGroupSlice = creatUpdateGenericSlice({
    name: 'adminsByGroup',
    initialState: initialState as InitialState,
    reducers: {
        updateRequest: (state, action: PayloadAction<{ id: string; data: UpdateAdminsByGroupData }>) => {
            state.loading = true;
        },
        updateSuccess: (state, action: PayloadAction<{ data: Group }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } = updateAdminsByGroupSlice.actions;

export default updateAdminsByGroupSlice.reducer;
