import { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { selectAccountSelected } from '@store/modules/auth/authSlice';
import { filterTree, CustomIcon } from './helper';
import type { TreeItem, TreeNode, ExpandedNode, NodeContents } from './types';
import './TreeView.scss';

const iconMap = {
    root: '',
    org: [<CustomIcon.OrgIcon />],
    ou: [<CustomIcon.OrgUnitIconBlue />, <CustomIcon.OrgUnitIconPurple />, <CustomIcon.OrgUnitIconRed />],
    account: [<CustomIcon.AccountIconBlue />, <CustomIcon.AccountIconPurple />, <CustomIcon.AccountIconRed />],
};

const TreeItemElement = ({
    children,
    node,
    openNodes,
    toggleNode,
    selectedNode,
}: {
    children: JSX.Element | JSX.Element[] | null;
    node: TreeItem<TreeNode>;
    openNodes: ExpandedNode;
    toggleNode: Function;
    selectedNode: number | undefined;
}) => {
    const isOpened = openNodes[node.id];
    return (
        <ul
            className={['list', children ? 'has-child' : ''].filter((d) => d).join(' ')}
            onClick={(event) => {
                if (selectedNode !== node.id) {
                    toggleNode(node);
                }
                event.stopPropagation();
            }}
        >
            <li>
                <div
                    id={`leaf-${node.id}`}
                    className={`leaf
                    ${
                        node.type === 'account' && !node.children
                            ? selectedNode === node.id
                                ? 'disabled'
                                : 'clickable'
                            : ''
                    }`}
                >
                    <div className="tree-node-card-icon">{iconMap[node.type][node.id % iconMap[node.type].length]}</div>
                    <div className="tree-node-card-content">
                        <dl>
                            <dt>{node.name}</dt>
                            {node.description && <dd>{node.description}</dd>}
                        </dl>
                    </div>
                    {children && (
                        <div className="tree-node-caret">
                            {isOpened ? <CustomIcon.ArrowUpIcon /> : <CustomIcon.ArrowDownIcon />}
                        </div>
                    )}
                </div>
                <div className="custom-collapse" style={{ display: isOpened ? 'block' : 'none' }}>
                    {children}
                </div>

                {node.description && (
                    <UncontrolledTooltip target={`leaf-${node.id}`}>
                        <dd>{node.description}</dd>
                    </UncontrolledTooltip>
                )}
            </li>
        </ul>
    );
};

function TreeView(props: {
    filter: string;
    data: TreeItem<TreeNode>;
    onNodeToggle: Function;
    matcher: Function;
    nodeContent: NodeContents;
}) {
    const { filter, data: sourceTree, matcher } = props;
    const [tree, setTree] = useState<TreeItem<TreeNode> | null>(null);
    const [expanded, setexpanded] = useState<ExpandedNode>({});
    const dropdownMode = useAppSelector(selectAccountSelected);
    const authUser = useAppSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        if (sourceTree !== null) {
            if (filter === '') {
                setTree(sourceTree);
                setexpanded({});
            } else {
                const filteredTree = filterTree(sourceTree, filter, expanded, matcher);
                setTree(filteredTree.node);
                setexpanded(filteredTree.expanded);
            }
        }
    }, [filter, sourceTree]);

    const [openNodes, setOpenNodes] = useState<ExpandedNode>({});

    const toggleNode = (node: TreeItem<TreeNode>) => {
        props.onNodeToggle(node);
        if (node.children?.length) {
            setOpenNodes((prevState) => ({
                ...prevState,
                [node.id]: !prevState[node.id],
            }));
        }
    };

    const renderTree = (nodes: TreeItem<TreeNode>) => (
        <TreeItemElement
            key={nodes.id}
            node={nodes}
            openNodes={openNodes}
            toggleNode={toggleNode}
            selectedNode={authUser?.node_id}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItemElement>
    );

    return (
        <div id="tree-view-page" className={`${dropdownMode ? 'dropdown-mode' : ''}`}>
            <nav>{tree && tree.children.map((node) => renderTree(node))}</nav>
        </div>
    );
}

export default TreeView;
