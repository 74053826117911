import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    getTemplates,
    getTemplate,
    postTemplate,
    putTemplate,
    deleteTemplate,
    previewTemplate,
} from '@services/api/templates';

import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadDefaultTemplate,
    loadFailure as loadDefaultTemplateFailure,
    loadSuccess as loadDefaultTemplateSuccess,
    cancelLoad as cancelLoadDefaultTemplate,
} from './defaultTemplateSlice';
import {
    deleteRequest as deleteTemplates,
    deleteSuccess as deleteTemplatesSuccess,
    deleteFailure as deleteTemplatesFailure,
    cancelDelete as cancelDeleteTemplates,
} from './deleteTemplatesSlice';

import {
    loadRequest as loadPreviewTemplate,
    loadFailure as loadPreviewTemplateFailure,
    loadSuccess as loadPreviewTemplateSuccess,
    cancelLoad as cancelLoadPreviewTemplate,
} from './previewTemplateSlice';

import {
    loadRequest as loadTemplate,
    loadFailure as loadTemplateFailure,
    loadSuccess as loadTemplateSuccess,
    cancelLoad as cancelLoadTemplate,
} from './templateSlice';

import {
    loadRequest as loadTemplates,
    loadFailure as loadTemplatesFailure,
    loadSuccess as loadTemplatesSuccess,
    cancelLoad as cancelLoadTemplates,
} from './templatesSlice';

import {
    upsertRequest as upsertTemplate,
    upsertSuccess as upsertTemplateSuccess,
    upsertFailure as upsertTemplateFailure,
    cancelUpsert as cancelUpsertTemplate,
} from './upsertTemplateSlice';

import type { CreateTemplateResponse, DefaultTemplateResponse, UpdateTemplateResponse } from '@services/api/templates';

export function* fetchTemplates(action: ReturnType<typeof loadTemplates>) {
    try {
        const { response, cancel }: { response: Template[]; cancel: any } = yield race({
            response: call(getTemplates),
            cancel: take(cancelLoadTemplates),
        });

        if (cancel) {
            return;
        }

        yield put(loadTemplatesSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadTemplatesFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchDefaultTemplates(action: ReturnType<typeof loadDefaultTemplate>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: DefaultTemplateResponse; cancel: any } = yield race({
            response: call(getTemplates, params),
            cancel: take(cancelLoadDefaultTemplate),
        });
        if (cancel) {
            return;
        }

        yield put(loadDefaultTemplateSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadDefaultTemplateFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchTemplate(action: ReturnType<typeof loadTemplate>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: TemplateDetail; cancel: any } = yield race({
            response: call(getTemplate, params),
            cancel: take(cancelLoadTemplate),
        });

        if (cancel) {
            return;
        }

        yield put(loadTemplateSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadTemplateFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchPreviewTemplate(action: ReturnType<typeof loadPreviewTemplate>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: DefaultTemplateResponse; cancel: any } = yield race({
            response: call(previewTemplate, params),
            cancel: take(cancelLoadPreviewTemplate),
        });

        if (cancel) {
            return;
        }

        yield put(loadPreviewTemplateSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadPreviewTemplateFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* upsertTemplateRequest(action: ReturnType<typeof upsertTemplate>) {
    const { id, data } = action.payload;

    const upsertCall = id ? call(putTemplate, { id, data }) : call(postTemplate, { data });

    try {
        const { response, cancel }: { response: CreateTemplateResponse | UpdateTemplateResponse; cancel: any } =
            yield race({
                response: upsertCall,
                cancel: take(cancelUpsertTemplate),
            });

        if (cancel) {
            return;
        }

        yield put(upsertTemplateSuccess({ data: response }));
        //  load initial data
        yield put(loadTemplates());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(upsertTemplateFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteTemplatesRequest(action: ReturnType<typeof deleteTemplates>) {
    const { ids } = action.payload;

    /**
     * @todo: Will use array after error type add string[]
     */
    // Collect delete failure
    let errors = '';
    for (let id of ids) {
        try {
            const { cancel }: { cancel: any } = yield race({
                response: call(deleteTemplate, { id: id }),
                cancel: take(cancelDeleteTemplates),
            });

            if (cancel) {
                return;
            }
        } catch (e) {
            errors += e?.response?.data + ', ';
        }
    }

    /**
     * @todo: Will add one case such as 1 success and 1 failure after error type add string[]
     */
    if (errors.length === 0) {
        yield put(deleteTemplatesSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadTemplates());
        toast.success(`${ids.length} Template Deleted`);
    } else {
        yield put(deleteTemplatesFailure(errors));
        toast.error(errors);
    }
}

export function* fetchTemplatesSaga() {
    yield takeLatest(loadTemplates, fetchTemplates);
}

export function* fetchDefaultTemplateSaga() {
    yield takeLatest(loadDefaultTemplate, fetchDefaultTemplates);
}

export function* fetchTemplateSaga() {
    yield takeLatest(loadTemplate, fetchTemplate);
}

export function* fetchPreviewTemplateSaga() {
    yield takeLatest(loadPreviewTemplate, fetchPreviewTemplate);
}

export function* deleteTemplatesSaga() {
    yield takeLatest(deleteTemplates, deleteTemplatesRequest);
}

export function* upsertTemplateSaga() {
    yield takeLatest(upsertTemplate, upsertTemplateRequest);
}
