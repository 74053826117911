import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import { loadSuccess as validateTrialSuccess } from '../trial/validateTrialSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface ResourceState extends GenericState<Resource | null> {}

const initialState: ResourceState = {
    loading: false,
    data: null,
    error: null,
};

const resourcesSlice = createGenericSlice({
    name: 'resources',
    initialState: initialState as ResourceState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Resource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(validateTrialSuccess, (state, action: PayloadAction<{ data: Trial }>) => {
            const { data } = action.payload;
            if (state.data?.client.quota) {
                state.data.client.quota = data.user_quota;
            }
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = resourcesSlice.actions;

export const selectResourcesUser = createSelector(
    (state: RootState) => state.resources.data?.user || null,
    (data) => data
);

export const selectResourcesRealm = createSelector(
    (state: RootState) => state.resources.data?.realm || null,
    (data) => data
);

export const selectResourcesClient = createSelector(
    (state: RootState) => state.resources.data?.client || null,
    (data) => data
);

export const selectResourcesSms = createSelector(
    (state: RootState) => state.resources.data?.sms || null,
    (data) => data
);

export default resourcesSlice.reducer;
