import { toast } from 'react-toastify';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { deleteBranding, getBranding, getBrandings, postBranding, putBranding } from '@services/api/branding';
import { DEFAULT_BRANDING, DEFAULT_BRANDING_ID } from '@shared/utils/constants';
import { RootState } from '@store/index';
import {
    loadRequest as loadBrandingResources,
    loadSuccess as loadBrandingResourcesSuccess,
    loadFailure as loadBrandingResourcesFailure,
    cancelLoad as cancelLoadBrandingResources,
} from './brandingResourcesSlice';
import {
    loadRequest as loadBrandings,
    loadSuccess as loadBrandingsSuccess,
    loadFailure as loadBrandingsFailure,
    cancelLoad as cancelLoadBrandings,
} from './brandingsSlice';
import {
    deleteRequest as deleteBrandingsRequest,
    deleteSuccess as deleteBrandingsSuccess,
    deleteFailure as deleteBrandingsFailure,
    cancelDelete as cancelDeleteBrandings,
} from './deleteBrandingsSlice';
import {
    upsertRequest as upsertBrandingRequest,
    upsertSuccess as upsertBrandingSuccess,
    upsertFailure as upsertBrandingFailure,
    cancelUpsert as cancelUpsertBranding,
} from './upsertBrandingSlice';

export function* fetchBrandingResources(action: ReturnType<typeof loadBrandingResources>) {
    const { id } = action.payload;
    const loadTemplateBranding = id ? false : true;
    const getCall = id ? call(getBranding, { id }) : call(getBranding, { id: DEFAULT_BRANDING_ID });
    try {
        const { response, cancel }: { response: Branding; cancel: any } = yield race({
            response: getCall,
            cancel: take(cancelLoadBrandingResources),
        });

        if (cancel) {
            return;
        }
        let brandings: Branding[];
        brandings = yield select((state: RootState) => state.brandingResources.data?.brandings);
        if (!brandings) {
            const { response }: { response: Branding[] } = yield race({
                response: call(getBrandings),
                cancel: take(cancelLoadBrandingResources),
            });
            brandings = response;
        }
        const data = loadTemplateBranding
            ? { brandings, branding: null, templateBranding: (response ?? DEFAULT_BRANDING) as Branding }
            : { branding: response as Branding, brandings, templateBranding: null };
        yield put(loadBrandingResourcesSuccess({ data }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadBrandingResourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchBrandings(action: ReturnType<typeof loadBrandings>) {
    try {
        const { response, cancel }: { response: Branding[]; cancel: any } = yield race({
            response: call(getBrandings),
            cancel: take(cancelLoadBrandings),
        });

        if (cancel) {
            return;
        }

        yield put(loadBrandingsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadBrandingsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* upsertBranding(action: ReturnType<typeof upsertBrandingRequest>) {
    const { id, data } = action.payload;

    // Only edit mode has id
    const upsertCall = id ? call(putBranding, { id, data }) : call(postBranding, { data: data as UpsertBrandingData });

    try {
        const { response, cancel }: { response: Branding; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertBranding),
        });

        if (cancel) {
            return;
        }

        yield put(upsertBrandingSuccess({ data: response }));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(upsertBrandingFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteBrandings(action: ReturnType<typeof deleteBrandingsRequest>) {
    const { ids } = action.payload;

    const deleteBrandingCalls = ids.map((id) => call(deleteBranding, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteBrandingCalls),
            cancel: take(cancelDeleteBrandings),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteBrandingsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadBrandings());
        toast.success(`${ids.length} Branding Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteBrandingsFailure(errMsg));
        yield put(loadBrandings());
        toast.error(errMsg);
    }
}

export function* fetchBrandingResourcesSaga() {
    yield takeLatest(loadBrandingResources, fetchBrandingResources);
}

export function* fetchBrandingsSaga() {
    yield takeLatest(loadBrandings, fetchBrandings);
}

export function* upsertBrandingSaga() {
    yield takeLatest(upsertBrandingRequest, upsertBranding);
}

export function* deleteBrandingSaga() {
    yield takeLatest(deleteBrandingsRequest, deleteBrandings);
}
