import { createSelector } from '@reduxjs/toolkit';
import { formatProfile } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface ProfilesState extends GenericState<Profile[] | null> {}

const initialState: ProfilesState = {
    loading: false,
    data: null,
    error: null,
};

const profilesSlice = createGenericSlice({
    name: 'profiles',
    initialState: initialState as ProfilesState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Profile[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = profilesSlice.actions;

export const selectAllProfiles = createSelector(
    (state: RootState) => state.profiles.data?.map(formatProfile),
    (data) => data ?? []
);

export default profilesSlice.reducer;
