import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { LogSearchData, getLogs } from '@services/api/logs';
import { getRealmsBrief } from '@services/api/realms';
import { LOG_TOTAL_COUNT_GREATER, MAX_LOG_RECORD } from '@shared/utils/constants';
import { downloadCSVFile } from '@shared/utils/exportCSV';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    cancelLoad as cancelExportLogs,
    loadRequest as exportLogs,
    loadSuccess as loadExportLogsSuccess,
    loadFailure as loadExportLogsFailure,
} from './exportLog';
import { createAuthLog, createMgmtLog, createSMSLog } from './helper';
import {
    loadRequest as loadLogs,
    loadFailure as loadLogsFailure,
    loadSuccess as loadLogsSuccess,
    cancelLoad as cancelLoadLogs,
} from './logsSlice';

export interface LogsResponseData {
    logs: AuthLogs | MgmtLogs | SMSLogs;
    realmsBrief?: RealmBrief[];
}

function* getAllRealms(params: LogSearchData) {
    try {
        //@ts-ignore
        const result = yield call(getRealmsBrief, { deleted: true });
        return result;
    } catch (err) {
        console.log(err);
    }
}

export function* fetchLogs(action: ReturnType<typeof loadLogs>) {
    const { params, logType, withoutRealms } = action.payload;
    try {
        const { response, cancel }: { response: LogsResponseData; cancel: any } = yield race({
            response: all(
                withoutRealms
                    ? {
                          logs: call(getLogs, params),
                      }
                    : {
                          logs: call(getLogs, params),
                          realmsBrief: call(getAllRealms, params),
                      }
            ),
            cancel: take(cancelLoadLogs),
        });

        if (cancel) {
            return;
        }

        yield put(loadLogsSuccess({ data: response, logType }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadLogsFailure(e.message));
    }
}

export function* fetchExportLogs(action: ReturnType<typeof exportLogs>): Generator {
    try {
        const { queryparams, columnsSetting, logType } = action.payload;
        //@ts-ignore
        const { response, cancel }: { response: Logs; cancel: any } = yield race({
            response: call(getLogs, queryparams),
            cancel: take(cancelExportLogs),
        });
        if (cancel) {
            return;
        }
        let logsData: Record<string, any>[] = [];
        let fileName = 'logs';
        if (logType === 'auth') {
            fileName = 'auth_logs';
            logsData = response?.hits?.hits
                ? response.hits.hits.map((element) => createAuthLog(element as AuthLogsHits))
                : [];
        } else if (logType === 'mgmt') {
            fileName = 'mgmt_logs';
            logsData = response?.hits?.hits
                ? response.hits.hits.map((element) => createMgmtLog(element as MgmtLogsHits))
                : [];
        } else if (logType === 'sms') {
            fileName = 'sms_logs';
            logsData = response?.hits?.hits
                ? response.hits.hits.map((element) => createSMSLog(element as SMSLogsHits))
                : [];
        }
        const logsTotalCount =
            typeof response?.hits?.total === 'number' ? response.hits.total : response?.hits?.total.value ?? 0;
        const logsTotalCountRelation =
            typeof response?.hits?.total === 'number' ? '' : response?.hits?.total?.relation ?? '';
        const showContinueWarning =
            logsTotalCount > MAX_LOG_RECORD || logsTotalCountRelation === LOG_TOTAL_COUNT_GREATER;
        downloadCSVFile({
            dataArray: logsData,
            fileName,
            columnsSetting,
            showContinueWarning,
        });
        yield put(loadExportLogsSuccess({ data: 'success' }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadExportLogsFailure(e.message));
    }
}

export function* fetchLogsSaga() {
    yield takeLatest(loadLogs, fetchLogs);
}

export function* fetchExportLogsSaga() {
    yield takeLatest(exportLogs, fetchExportLogs);
}
