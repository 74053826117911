import { PropsWithChildren } from 'react';
import {
    Modal,
    ModalBody,
    ModalBodyProps,
    ModalFooter,
    ModalFooterProps,
    ModalHeader,
    ModalHeaderProps,
    ModalProps,
} from 'reactstrap';
import { classnames } from '@shared/utils/classnames';
import { Icon } from '../Icon';

type TFormModalCustom = { size?: '' | 'sm' | 'lg' | 'xl' | 'xxl' };
type TFormModal = TFormModalCustom & ModalProps;
type TFModalHeader = ModalHeaderProps & { alert?: boolean };
type TFModalBody = ModalBodyProps & { alert?: boolean };

/**
 * This modal is for form to use
 * The footer should be inside of the ModalBody for form to trigger submit type button
 */
function FFormModal({ children, size = '', ...props }: React.PropsWithChildren<TFormModal>) {
    return (
        <Modal {...props} centered={true} size={size} className="ftc-modal">
            {children}
        </Modal>
    );
}

function FModalHeader({ children, toggle, alert = false, ...props }: PropsWithChildren<TFModalHeader>) {
    return (
        <ModalHeader
            className={classnames([alert && 'ftc-alert'])}
            {...props}
            close={
                <button className="close" type="button" onClick={toggle}>
                    <Icon classNames={['material-symbols-outlined']} content="close" />
                </button>
            }
        >
            {children}
        </ModalHeader>
    );
}

function FModalBody({ children, alert = false, ...props }: PropsWithChildren<TFModalBody>) {
    return (
        <ModalBody {...props} className={classnames([alert && 'ftc-alert', 'ftc-modal-body is-border-bottom-radius'])}>
            {children}
        </ModalBody>
    );
}

// The footer should be inside of the ModalBody for form to trigger submit type button
function FModalFooter({ children, ...props }: React.PropsWithChildren<ModalFooterProps>) {
    return (
        <ModalFooter {...props} className="modal-footer">
            {children}
        </ModalFooter>
    );
}

FFormModal.ModalHeader = FModalHeader;
FFormModal.ModalBody = FModalBody;
FFormModal.ModalFooter = FModalFooter;

export { FFormModal };
