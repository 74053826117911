import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getRealm, getRealms, putSettingRealm } from '@services/api/realms';
import { getTemplate, getTemplates, postPreviewTemplate } from '@services/api/templates';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadRealmSetting,
    loadFailure as loadRealmSettingFailure,
    loadSuccess as loadRealmSettingSuccess,
    cancelLoad as cancelLoadRealmSetting,
} from './realmSettingSlice';
import {
    loadRequest as loadRealmSettings,
    loadFailure as loadRealmSettingsFailure,
    loadSuccess as loadRealmSettingsSuccess,
    cancelLoad as cancelLoadRealmSettings,
} from './realmSettingsSlice';
import {
    loadRequest as loadTemplate,
    loadSuccess as loadTemplateSuccess,
    loadFailure as loadlTemplateFailure,
    cancelLoad as cancelLoadTemplate,
} from './realmTemplateSlice';
import {
    updateRequest as updateRealmSetting,
    updateSuccess as updateRealmSettingSuccess,
    updateFailure as updateRealmSettingFailure,
    cancelUpdate as cancelUpdateRealmSetting,
} from './updateRealmSettingSlice';
import {
    updateRequest as updatePreview,
    updateSuccess as updatePreviewSuccess,
    updateFailure as updatePreviewFailure,
    cancelUpdate as cancelUpdatePreview,
} from './updateRealmTemplatePreviewSlice';

export type RealmSettingsResponse = {
    realms: Realm[];
} & Record<string, Template[]>;

function* fetchRealmSettings(action: ReturnType<typeof loadRealmSettings>) {
    const { realmId, templateParamsList } = action.payload;

    const calls: any = { realms: call(getRealms) };
    templateParamsList.forEach(({ method, type }) => {
        const key = method + type;
        const params = { method, type };
        calls[key] = call(getTemplates, params);
    });

    try {
        const { response, cancel }: { response: RealmSettingsResponse; cancel: any } = yield race({
            response: all(calls),
            cancel: take(cancelLoadRealmSettings),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmSettingsSuccess({ data: response }));

        // if not get id from url, set the first one from array
        const defaultRealmId = realmId ? realmId : response['realms'][0]?.id;
        // fetch realmSetting
        yield put(loadRealmSetting({ id: defaultRealmId }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmSettingsFailure(e?.response?.data?.error_message));
    }
}

export function* fetchRealmSettingsSaga() {
    yield takeLatest(loadRealmSettings, fetchRealmSettings);
}

export type RealmSettingResponse = {
    selectedId: string;
    realmSetting: RealmSetting;
};

function* fetchRealmSetting(action: ReturnType<typeof loadRealmSetting>) {
    const { id } = action.payload;

    try {
        const { response, cancel }: { response: RealmSetting; cancel: any } = yield race({
            response: call(getRealm, { id }),
            cancel: take(cancelLoadRealmSetting),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmSettingSuccess({ data: { selectedId: id, realmSetting: response } }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmSettingFailure(e.message));
    }
}

export function* fetchRealmSettingSaga() {
    yield takeLatest(loadRealmSetting, fetchRealmSetting);
}

function* updateRealmSettingRequest(action: ReturnType<typeof updateRealmSetting>) {
    const { id, data } = action.payload;
    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(putSettingRealm, { id, data }),
            cancel: take(cancelUpdateRealmSetting),
        });

        if (cancel) {
            return;
        }

        yield put(updateRealmSettingSuccess({ data: response }));
        // load initial data after successfully
        yield put(loadRealmSetting({ id: id }));
        toast.success('Settings Saved');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateRealmSettingFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateRealmSettingSaga() {
    yield takeLatest(updateRealmSetting, updateRealmSettingRequest);
}

function* fetchRealmTemplate(action: ReturnType<typeof loadTemplate>) {
    const { id, data } = action.payload;
    try {
        const { response, cancel }: { response: TemplateDetail; cancel: any } = yield race({
            response: id ? call(getTemplate, { id }) : call(getTemplates, data),
            cancel: take(cancelLoadTemplate),
        });

        if (cancel) {
            return;
        }

        yield put(loadTemplateSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadlTemplateFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchRealmTemplateSaga() {
    yield takeLatest(loadTemplate, fetchRealmTemplate);
}

function* updateRealmTemplatePreviewRequest(action: ReturnType<typeof updatePreview>) {
    const { data } = action.payload;
    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(postPreviewTemplate, { data }),
            cancel: take(cancelUpdatePreview),
        });

        if (cancel) {
            return;
        }

        yield put(updatePreviewSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updatePreviewFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateRealmTemplatePreviewSaga() {
    yield takeLatest(updatePreview, updateRealmTemplatePreviewRequest);
}
