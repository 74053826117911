import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getRealms } from '@services/api/realms';
import {
    getApplications,
    deleteUserFromGroup,
    deleteUserGroup,
    deleteUserGroupPermission,
    getUserGroupById,
    getUserGroupPermissions,
    getUserGroups,
    getUsersWithGroup,
    postUserGroup,
    postUserGroupPermission,
    postUsersToGroup,
    putUserGroup,
    putUserGroupPermission,
    putUsersInGroup,
} from '@services/api/userGroups';
import { getUsers } from '@services/api/users';
import { ACS_ALLOW, ACS_DENY } from '@shared/utils/constants';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    createRequest as addUsersToGroup,
    createSuccess as addUsersToGroupSuccess,
    createFailure as addUsersToGroupFailure,
    cancelCreate as cancelAddUsersToGroup,
} from '@store/modules/userGroups/addUsersToGroupSlice';
import {
    createRequest as createUserGroup,
    createSuccess as createUserGroupSuccess,
    createFailure as createUserGroupFailure,
    cancelCreate as cancelCreateUserGroup,
} from '@store/modules/userGroups/createUserGroupSlice';
import {
    deleteRequest as deleteUserGroups,
    deleteSuccess as deleteUserGroupsSuccess,
    deleteFailure as deleteUserGroupsFailure,
    cancelDelete as cancelDeleteUserGroups,
} from '@store/modules/userGroups/deleteUserGroupsSlice';

import {
    deleteRequest as deleteUsersFromGroup,
    deleteSuccess as deleteUsersFromGroupSuccess,
    deleteFailure as deleteUsersFromGroupFailure,
    cancelDelete as cancelDeleteUsersFromGroup,
} from '@store/modules/userGroups/deleteUsersFromGroupSlice';
import {
    createRequest as updateUserGroupAppPermissions,
    createSuccess as updateUserGroupAppPermissionsSuccess,
    createFailure as updateUserGroupAppPermissionsFailure,
    cancelCreate as cancelUpdateUserGroupAppPermissions,
} from '@store/modules/userGroups/updateUserGroupAppPermissionSlice';
import {
    updateRequest as updateUserGroup,
    updateSuccess as updateUserGroupSuccess,
    updateFailure as updateUserGroupFailure,
    cancelUpdate as cancelUpdateUserGroup,
} from '@store/modules/userGroups/updateUserGroupSlice';
import {
    createRequest as updateUsersInGroup,
    createSuccess as updateUsersInGroupSuccess,
    createFailure as updateUsersInGroupFailure,
    cancelCreate as cancelUpdateUsersInGroup,
} from '@store/modules/userGroups/updateUsersInGroupSlice';
import {
    loadRequest as loadApplications,
    loadSuccess as loadApplicationsSuccess,
    loadFailure as loadApplicationsFailure,
    cancelLoad as cancelLoadApplications,
} from '@store/modules/userGroups/userGroupApplicationsSlice';
import {
    loadRequest as loadUserGroupPermission,
    loadSuccess as loadUserGroupPermissionSuccess,
    loadFailure as loadUserGroupPermissionFailure,
    cancelLoad as cancelLoadUserGroupPermission,
} from '@store/modules/userGroups/userGroupPermissionSlice';
import {
    loadRequest as loadUserGroup,
    loadSuccess as loadUserGroupSuccess,
    loadFailure as loadUserGroupFailure,
    cancelLoad as cancelLoadUserGroup,
} from '@store/modules/userGroups/userGroupSlice';
import {
    loadRequest as loadUserGroups,
    loadSuccess as loadUserGroupsSuccess,
    loadFailure as loadUserGroupsFailure,
    cancelLoad as cancelLoadUserGroups,
} from '@store/modules/userGroups/userGroupsSlice';
import {
    loadRequest as loadUsersByGroup,
    loadSuccess as loadUsersByGroupSuccess,
    loadFailure as loadUsersByGroupFailure,
    cancelLoad as cancelLoadUsersByGroup,
} from '@store/modules/userGroups/usersByGroupSlice';
import {
    loadRequest as loadUsersByRealm,
    loadSuccess as loadUsersByRealmSuccess,
    loadFailure as loadUsersByRealmFailure,
    cancelLoad as cancelLoadUsersByRealm,
} from '@store/modules/userGroups/usersByRealmSlice';

export type UserGroupsResponse = {
    userGroups: UserGroup[];
    realms: Realm[];
};

export type UserGroupResponse = {
    userGroup: UserGroup;
    realms: Realm[];
};

export type UserGroupPermissionResponse = {
    groupPermission: UserGroupPermission;
    applications: Application[];
};

export function* fetchUserGroup(action: ReturnType<typeof loadUserGroup>) {
    try {
        const { id } = action.payload;
        const { response, cancel }: { response: UserGroupResponse; cancel: any } = yield race({
            response: all({ userGroup: call(getUserGroupById, { id }), realms: call(getRealms) }),
            cancel: take(cancelLoadUserGroup),
        });

        if (cancel) {
            return;
        }
        yield put(loadUserGroupSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUserGroupFailure(e.message));
    }
}

export function* fetchUserGroupSaga() {
    yield takeLatest(loadUserGroup, fetchUserGroup);
}

export function* fetchUserGroups() {
    try {
        const { response, cancel }: { response: UserGroupsResponse; cancel: any } = yield race({
            response: all({ userGroups: call(getUserGroups), realms: call(getRealms) }),
            cancel: take(cancelLoadUserGroups),
        });

        if (cancel) {
            return;
        }
        yield put(loadUserGroupsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUserGroupsFailure(e.message));
    }
}

export function* fetchUserGroupsSaga() {
    yield takeLatest(loadUserGroups, fetchUserGroups);
}

export function* fetchUsersByGroup(action: ReturnType<typeof loadUsersByGroup>) {
    const { id, params } = action.payload;
    try {
        const { response, cancel }: { response: UserWithGroup[]; cancel: any } = yield race({
            response: call(getUsersWithGroup, { id, params }),
            cancel: take(cancelLoadUsersByGroup),
        });

        if (cancel) {
            return;
        }
        yield put(loadUsersByGroupSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUsersByGroupFailure(e.message));
    }
}

export function* fetchUsersByGroupSaga() {
    yield takeLatest(loadUsersByGroup, fetchUsersByGroup);
}

export function* createUserGroupRequest(action: ReturnType<typeof createUserGroup>) {
    const { data, users, allowedApps, deniedApps } = action.payload;

    try {
        const { response, cancel }: { response: UserGroup; cancel: any } = yield race({
            response: call(postUserGroup, { data }),
            cancel: take(cancelCreateUserGroup),
        });

        if (cancel) {
            return;
        }

        yield put(createUserGroupSuccess({ data: response }));
        yield put(addUsersToGroup({ id: response.id, data: { users } }));
        // add permission
        yield put(
            updateUserGroupAppPermissions({
                data: {
                    groupId: response.id,
                    addPermissions: [
                        ...allowedApps.map((applicationId) => {
                            return { applicationId, permission: ACS_ALLOW };
                        }),
                        ...deniedApps.map((applicationId) => {
                            return { applicationId, permission: ACS_DENY };
                        }),
                    ],
                },
            })
        );
        //  load initial data
        yield put(loadUserGroups());
        toast.success('User Group Created');
    } catch (e) {
        const msg = e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(createUserGroupFailure(msg));
    }
}

export function* createUserGroupSaga() {
    yield takeLatest(createUserGroup, createUserGroupRequest);
}

export function* deleteUserGroupsRequest(action: ReturnType<typeof deleteUserGroups>) {
    const { ids } = action.payload;

    const deleteCalls = ids.map((id: string) => call(deleteUserGroup, { id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteUserGroups),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteUserGroupsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadUserGroups());
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteUserGroupsFailure(e?.response?.data));
    }
}

export function* deleteUserGroupsSaga() {
    yield takeLatest(deleteUserGroups, deleteUserGroupsRequest);
}

export function* updateUserGroupRequest(action: ReturnType<typeof updateUserGroup>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: UserGroup; cancel: any } = yield race({
            response: call(putUserGroup, { id, data }),
            cancel: take(cancelUpdateUserGroup),
        });

        if (cancel) {
            return;
        }

        yield put(updateUserGroupSuccess({ data: response }));
        //  load initial data
        toast.success('Updates Saved');
    } catch (e) {
        yield put(updateUserGroupFailure(e?.response?.data?.error_message));
        showErrorAlert(e);
    }
}

export function* updateUserGroupSaga() {
    yield takeLatest(updateUserGroup, updateUserGroupRequest);
}

export function* addUsersToGroupRequest(action: ReturnType<typeof addUsersToGroup>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: UserGroupMapping[]; cancel: any } = yield race({
            response: call(postUsersToGroup, { id, data }),
            cancel: take(cancelAddUsersToGroup),
        });

        if (cancel) {
            return;
        }

        yield put(addUsersToGroupSuccess({ data: response }));
        //  load initial data
        yield put(loadUsersByGroup({ id }));
        yield put(loadUserGroups());
        toast.success('Users Added to Group');
    } catch (e) {
        const msg = e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(addUsersToGroupFailure(msg));
    }
}

export function* addUsersToGroupSaga() {
    yield takeLatest(addUsersToGroup, addUsersToGroupRequest);
}

export function* deleteUsersFromGroupRequest(action: ReturnType<typeof deleteUsersFromGroup>) {
    const { userIds, groupId } = action.payload;

    const deleteCalls = userIds.map((userId: string) => call(deleteUserFromGroup, { userId, groupId }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteUsersFromGroup),
        });

        if (cancel) {
            return;
        }

        yield put(deleteUsersFromGroupSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadUsersByGroup({ id: groupId }));
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteUsersFromGroupFailure(e?.response?.data));
    }
}

export function* deleteUsersFromGroupsSaga() {
    yield takeLatest(deleteUsersFromGroup, deleteUsersFromGroupRequest);
}

export function* updateUsersInGroupRequest(action: ReturnType<typeof updateUsersInGroup>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(putUsersInGroup, { id, data }),
            cancel: take(cancelUpdateUsersInGroup),
        });

        if (cancel) {
            return;
        }

        yield put(updateUsersInGroupSuccess({ data: response }));
        //  load initial data
        yield put(loadUsersByGroup({ id }));
        toast.success('Users Updated in Group');
    } catch (e) {
        const msg = e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(updateUsersInGroupFailure(msg));
    }
}

export function* updateUsersInGroupSaga() {
    yield takeLatest(updateUsersInGroup, updateUsersInGroupRequest);
}

export function* fetchUsersByRealm(action: ReturnType<typeof loadUsersByRealm>) {
    const params = action.payload;
    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: call(getUsers, params),
            cancel: take(cancelLoadUsersByRealm),
        });

        if (cancel) {
            return;
        }
        yield put(loadUsersByRealmSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUsersByRealmFailure(e.message));
    }
}

export function* fetchUsersByRealmSaga() {
    yield takeLatest(loadUsersByRealm, fetchUsersByRealm);
}

export function* fetchApplications() {
    try {
        const { response, cancel }: { response: Application[]; cancel: any } = yield race({
            response: call(getApplications),
            cancel: take(cancelLoadApplications),
        });

        if (cancel) {
            return;
        }
        yield put(loadApplicationsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadApplicationsFailure(e.message));
    }
}

export function* fetchUserGroupApplicationsSaga() {
    yield takeLatest(loadApplications, fetchApplications);
}

export function* fetchUserGroupPermission(action: ReturnType<typeof loadUserGroupPermission>) {
    const { groupId } = action.payload;
    try {
        const { response, cancel }: { response: any; cancel: UserGroupPermissionResponse } = yield race({
            response: all({
                groupPermission: call(getUserGroupPermissions, { id: groupId }),
                applications: call(getApplications),
            }),
            cancel: take(cancelLoadUserGroupPermission),
        });

        if (cancel) {
            return;
        }
        yield put(loadUserGroupPermissionSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUserGroupPermissionFailure(e.message));
    }
}

export function* fetchUserGroupPermissionSaga() {
    yield takeLatest(loadUserGroupPermission, fetchUserGroupPermission);
}

export function* updateUserGroupAppPermissionsRequest(action: ReturnType<typeof updateUserGroupAppPermissions>) {
    const { groupId, addPermissions, updatePermissions, deletePermissions } = action.payload.data;

    const deleteCalls =
        deletePermissions?.map(({ applicationId }) => call(deleteUserGroupPermission, { groupId, applicationId })) ??
        [];

    const postCalls =
        addPermissions?.map(({ applicationId, permission }) =>
            call(postUserGroupPermission, { id: groupId, data: { application_id: applicationId, access: permission } })
        ) ?? [];

    const putCalls =
        updatePermissions?.map(({ applicationId, permission }) =>
            call(putUserGroupPermission, { id: groupId, application_id: applicationId, data: { access: permission } })
        ) ?? [];

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all([...deleteCalls, ...postCalls, ...putCalls]),
            cancel: take(cancelUpdateUserGroupAppPermissions),
        });

        if (cancel) {
            return;
        }

        yield put(updateUserGroupAppPermissionsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadUserGroupPermission({ groupId }));
    } catch (e) {
        showErrorAlert(e);
        yield put(updateUserGroupAppPermissionsFailure(e?.response?.data));
    }
}

export function* updateUserGroupAppPermissionsSaga() {
    yield takeLatest(updateUserGroupAppPermissions, updateUserGroupAppPermissionsRequest);
}
