import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getRate } from '@services/api/rate';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadRateSms,
    loadFailure as loadRateSmsFailure,
    loadSuccess as loadRateSmsSuccess,
    cancelLoad as cancelLoadRateSms,
} from './smsSlice';

export function* fetchRateSms() {
    try {
        // @TBD
        // for internal admin GUI, we need the second API result
        const { response, cancel }: { response: { sms: SMS[]; allSms: SMS[] }; cancel: any } = yield race({
            response: all({
                sms: call(getRate, { resource: 'SMS' }),
                allSms: call(getRate, { resource: 'SMS', all: true }),
            }),
            cancel: take(cancelLoadRateSms),
        });

        if (!response) return;

        const { sms, allSms } = response;

        if (cancel) {
            return;
        }

        yield put(loadRateSmsSuccess({ data: { sms, allSms } }));
    } catch (e) {
        yield put(loadRateSmsFailure(e.message));
        showErrorAlert(e);
    }
}

export function* fetchRateSmsSaga() {
    yield takeLatest(loadRateSms, fetchRateSms);
}
