import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getDevices(): Generator {
    return yield axiosClient.get<Device[]>(API.DEVICE).then((res) => res.data);
}

export function* moveDevice({ sn, cluster_id }: { sn: string; cluster_id?: string }): Generator {
    return yield axiosClient.put(`${API.DEVICE}/${sn}`, cluster_id ? { cluster_id } : {}).then((res) => res.data);
}
