import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<UserSourceDetail | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userSourceSlice = createGenericSlice({
    name: 'userSource',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserSourceDetail }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSourceSlice.actions;

const defaultSamlUserSource = {
    entity_id: '',
    login_url: '',
    logout_url: null,
    signing_cert: null,
    post_binding: false,
    include_subject: false,
};

const defaultOidcUserSource = {
    auth_uri: null,
    issuer: null,
    token_uri: null,
    userinfo_uri: null,
    logout_uri: null,
    client_id: null,
    client_secret: null,
};

const defaultUserSource = {
    created_at: '',
    updated_at: null,
    id: '',
    customer_id: '',
    realm_id: '',
    name: '',
    prefix: '',
    type: 1 as number,
    attr_mapping: {},
    username_assertion: null,
    favicon_url: null,
    login_hint: null,
    proxy_sp: {
        prefix: '',
        slo_url: '',
        acs_url: '',
        sso_url: '',
        entity_id: '',
        callback_url: '',
        post_logout_redirect_uri: '',
        oidc_login_url: '',
    },
    domains: [] as { id: string; name: string }[],
    ...defaultSamlUserSource,
    ...defaultOidcUserSource,
};
export const selectUserSource = createSelector(
    (state: RootState) => state.userSource.data,
    (data) => data ?? null
);
export const selectNonnullableUserSource = createSelector(selectUserSource, (data) => data ?? defaultUserSource);

export default userSourceSlice.reducer;
