import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '../upsertGenericSlice';

interface UsersAliasState extends GenericState<User[] | null> {}

const initialState: UsersAliasState = {
    loading: false,
    data: null,
    error: null,
};

const updateUsersAliasSlice = createUpsertGenericSlice({
    name: 'usersAlias',
    initialState: initialState as UsersAliasState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                ids: string[];
                data: UsersAliasData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: User[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = updateUsersAliasSlice.actions;

export default updateUsersAliasSlice.reducer;
