import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './FtcModal.scss';
import { classnames } from '@shared/utils/classnames';
import { Icon } from '../Icon';

type TFtcModal = {
    header?: JSX.Element | string | null;
    isOpen: boolean;
    onOpened?: () => void;
    onSaved?: () => void;
    onYes?: () => void;
    onCanceled?: () => void;
    onClosed?: () => void;
    onBtnClosed?: () => void;
    toggle: () => void;
    size?: '' | 'sm' | 'lg' | 'xl' | 'xxl';
    hasFooter?: boolean;
    // control if the alert ui modal
    alert?: boolean;
    backdrop?: true | 'static';
};

/**
 * @TBD
 * 1. Will gradually keep this function as simple confirm modal such as dialog, only yes/no for footer
 * 2. Other complicated case, please use FModal and FFormModal
 */
function FtcModal({
    children,
    size = '',
    header = null,
    hasFooter = true,
    backdrop = true,
    alert = false,
    ...props
}: React.PropsWithChildren<TFtcModal>) {
    const { isOpen, onOpened, toggle, onSaved, onYes, onClosed, onCanceled, onBtnClosed } = props;
    return (
        <Modal
            isOpen={isOpen}
            onOpened={onOpened}
            toggle={toggle}
            centered={true}
            onClosed={onClosed}
            size={size}
            className="ftc-modal"
            backdrop={backdrop}
        >
            <ModalHeader
                className={classnames([alert && 'ftc-alert'])}
                close={
                    <button className="close" onClick={toggle} type="button">
                        <Icon classNames={['material-symbols-outlined']} content="close" />
                    </button>
                }
            >
                {header}
            </ModalHeader>
            <ModalBody
                className={classnames([
                    'ftc-modal-body',
                    !hasFooter && 'is-border-bottom-radius',
                    alert && 'ftc-alert',
                ])}
            >
                {children}
            </ModalBody>
            {hasFooter && (
                <ModalFooter className={classnames([hasFooter && 'is-border-bottom-radius'])}>
                    {onCanceled && (
                        <Button color="primary" outline onClick={onCanceled}>
                            Cancel
                        </Button>
                    )}
                    {onBtnClosed && (
                        <Button color="primary" outline onClick={onBtnClosed}>
                            Close
                        </Button>
                    )}
                    {onSaved && (
                        <Button color="primary" onClick={onSaved}>
                            Save
                        </Button>
                    )}
                    {onYes && (
                        <Button color="primary" onClick={onYes}>
                            Yes
                        </Button>
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
}

export { FtcModal };
