import { Button } from 'reactstrap';
import { FModal } from '@shared/components/FtcModal';
import { FixedLoading } from '@shared/components/Loading';
import { useAppDispatch } from '@store/hooks';
import { deleteRequest as deleteSigningCertRequest } from '@store/modules/settings/signingCert/deleteSigningCertsSlice';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onCanceled: () => void;
    data: SigningCert[];
    meta: {
        selectedIds: string[];
        submitLoading: boolean;
    };
};
function DeleteModal({ isOpen, toggle, onClosed, onCanceled, data, meta }: Props) {
    const dispatch = useAppDispatch();
    const { selectedIds, submitLoading } = meta;
    const selectedClients = data.filter(({ id }: { id: string }) => selectedIds.includes(id));
    return (
        <FModal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <FModal.ModalHeader toggle={toggle}>Delete Certificate(s)</FModal.ModalHeader>
            <FModal.ModalBody>
                <>
                    {/* loading */}
                    {submitLoading && <FixedLoading />}
                    <div className="plain-text">
                        <>
                            Are you sure you want to delete the following Certificate
                            <>{selectedClients.length > 1 && 's'}</>?
                        </>
                        <ul>
                            {selectedClients.map(({ name, subject }) => {
                                return <li key={name}>{`${name ?? subject}`}</li>;
                            })}
                        </ul>
                    </div>
                </>
            </FModal.ModalBody>
            <FModal.ModalFooter>
                <Button color="primary" outline onClick={onCanceled}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        dispatch(deleteSigningCertRequest({ ids: selectedIds }));
                    }}
                    disabled={submitLoading}
                >
                    Yes
                </Button>
            </FModal.ModalFooter>
        </FModal>
    );
}

export default DeleteModal;
