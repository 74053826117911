import { createSelector } from '@reduxjs/toolkit';
import { UsageParams } from '@services/api/usage';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UsageState extends GenericState<Usage[] | null> {}

const initialState: UsageState = {
    loading: false,
    data: null,
    error: null,
};

const usageSlice = createGenericSlice({
    name: 'usage',
    initialState: initialState as UsageState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsageParams }>) => {
            state.loading = true;
            state.data = null;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Usage[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usageSlice.actions;

export const selectUsages = createSelector(
    (state: RootState) => state.usage.data,
    (data) => {
        if (!data) {
            return [];
        }

        return data;
    }
);

export const selectUsageDetails = createSelector(
    (state: RootState) => state.usage.data,
    (data) => {
        if (!data) {
            return [];
        }

        const flattedUsageData: UsageDetail[] = data
            .map((d) => {
                return Object.entries(d.usage).map(([date, { users, sms }]) => {
                    return {
                        realm: d.realm,
                        realm_id: d.realm_id,
                        date: date,
                        report_cycle: d.report_cycle,
                        usage_users: users,
                        usage_sms: sms,
                        details_sms: d.details?.[date]?.sms,
                        details_users: d.details?.[date]?.users,
                        details_type: d.details?.[date]?.type,
                    };
                });
            })
            .flat()
            .sort((a, b) => a.realm.localeCompare(b.realm));

        return flattedUsageData;
    }
);

export default usageSlice.reducer;
