import isPropValid from '@emotion/is-prop-valid';
import { forwardRef } from 'react';
import DataTable, { SortOrder, TableColumn, TableProps } from 'react-data-table-component';
import { StyleSheetManager } from 'styled-components';
import { classnames } from '@shared/utils/classnames';

import './FtcTable.scss';

type FtcTableCustom = { id?: string; classNames?: string[]; setSortedRows?: Function };
type TFtcTable = FtcTableCustom & TableProps<Record<string, unknown> | any>;

const Bootstrap5Checkbox = forwardRef(
    ({ onClick, ...rest }: { onClick: React.MouseEventHandler<HTMLInputElement> }, ref) => (
        <div className="form-check">
            <input
                // @ts-ignore
                htmlFor="booty-check"
                type="checkbox"
                className="form-check-input"
                ref={ref as React.ForwardedRef<HTMLInputElement>}
                onClick={onClick}
                {...rest}
            />
            <label className="form-check-label" id="booty-check" />
        </div>
    )
);

function FtcTable({ id, classNames = [], ...props }: TFtcTable) {
    if (!props.onSort && props.setSortedRows) {
        // customized on sort function to send sorted row data
        props.onSort = (_selectedColumn: TableColumn<SMSLogData>, _sortDirection: SortOrder, sortedRows: any) => {
            if (props.setSortedRows) {
                // init sortedRows is an empty array, we set it null here to let us fall back orignal users list
                props.setSortedRows(sortedRows?.length === 0 ? null : sortedRows);
            }
        };
    }

    return (
        <>
            <div id={id} className={classnames(['ftc-table', ...classNames])}>
                {/**
                 *  Add StyleSheetManager to filter out unknown props in DataTable warning
                 *  @todo: isPropValid only used here, could try to find better solution if we can without using it in safety
                 */}
                <StyleSheetManager
                    shouldForwardProp={(propName: string) => isPropValid(propName) && !propName.startsWith('$')}
                >
                    <DataTable
                        // Pass the Checkbox component only
                        // @ts-ignore
                        selectableRowsComponent={Bootstrap5Checkbox}
                        {...props}
                    />
                </StyleSheetManager>
            </div>
        </>
    );
}

export { FtcTable };
