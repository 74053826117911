import { Icon } from '../Icon';

type Props = {
    msg: string;
};

function FtcErrorMessage({ msg }: Props) {
    return (
        <div className="form-error-message error-group">
            <Icon
                classNames={['material-symbols-outlined']}
                styles={{ fontSize: '16px', marginTop: '1.5px' }}
                content="error"
            />
            <span className="invalid-feedback">{msg}</span>
        </div>
    );
}

export { FtcErrorMessage };
