import axiosClient from '../axiosClient';
import { API } from '../index';

export type LogSearchData = {
    from: number;
    size: number;
    range?: { gte: number; lt: number };
    type: 'api-auth' | 'api-mgmt' | 'log-sms';
    request_id?: string;
    user?: string;
    namespace?: string;
    status?: number | string;
    // @TBD sort types
    sort: Record<string, string>[];
};

export function* getLogs(params: LogSearchData): Generator {
    return yield axiosClient.post<Logs>(API.LOG, params).then((res) => res.data);
}
