import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { createReceiverData } from '../helper';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<Receiver[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const receiversSlice = createGenericSlice({
    name: 'receivers',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Receiver[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = receiversSlice.actions;

export const selectReceivers = createSelector(
    (state: RootState) => state.receivers.data?.map(createReceiverData),
    (data) => data ?? []
);

export default receiversSlice.reducer;
