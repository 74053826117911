import { useField } from 'formik';
import { classnames } from '@shared/utils/classnames';

type FormikFieldInputProps = { [x: string]: any; name: string };
type FTextInputCustom = { append?: string | JSX.Element | null; serverErrors?: string | string[] | null };
type TFSelect = FormikFieldInputProps & FTextInputCustom;

const FSelect = ({ append = null, serverErrors = null, ...props }: TFSelect) => {
    const [field, meta] = useField(props);
    const errorExists = meta.error || serverErrors;

    return (
        <div className="d-flex gap-3 align-items-center">
            <select
                className={classnames(['form-select', `${errorExists ? 'is-invalid' : ''}`])}
                {...field}
                {...props}
            />
            {append && <span id={`${props.name}-addon`}>{append}</span>}
        </div>
    );
};

export { FSelect };
export type { TFSelect };
