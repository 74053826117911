import axiosClient from '../axiosClient';
import { API } from '../index';

export type DeviceTaskResponse = DeviceTask[];

export function* getDeviceTasks(): Generator {
    return yield axiosClient.get<DeviceTaskResponse>(API.DEVICE_TRANSFER).then((res) => res.data);
}

// create DeviceTask
export function* postDeviceTask({ data }: { data: UpsertDeviceTaskData }): Generator {
    return yield axiosClient
        .post<TaskApproveResponse[] | DeviceTask>(API.DEVICE_TRANSFER, data)
        .then((res) => res.data);
}

// // update DeviceTask
export function* putDeviceTask({ id }: { id?: string }): Generator {
    return yield axiosClient.put<TaskApproveResponse[]>(`${API.DEVICE_TRANSFER}/${id}`).then((res) => res.data);
}

export function* deleteDeviceTask({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.DEVICE_TRANSFER}/${id}`).then((res) => res.data);
}
