import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_BRANDING, NIL_UUID } from '@shared/utils/constants';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { brandingFormatter } from './helper';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<Branding[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const brandingsSlice = createGenericSlice({
    name: 'brandings',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Branding[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = brandingsSlice.actions;

export const selectBrandings = createSelector(
    (state: RootState) => {
        const formattedData = state.brandings.data?.map(brandingFormatter) ?? [];
        // set default
        const defaultTemplate = formattedData.find(({ id }) => id === NIL_UUID);
        if (!defaultTemplate) {
            // should not happen
            formattedData.push({ ...brandingFormatter({ ...DEFAULT_BRANDING, is_default: false, apps: '' }) });
        }
        const noDefaultSet = formattedData.every(({ isDefault }) => !isDefault);
        return formattedData.map((data) => {
            if (data.id === NIL_UUID && noDefaultSet) {
                return { ...data, isDefault: true };
            } else {
                return data;
            }
        });
    },
    (data) => {
        return data ?? [];
    }
);

export default brandingsSlice.reducer;
