import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    deleteDevice,
    getDevicesRemote,
    validateDeviceBySn,
    validateDevicesByClusterId,
} from '@services/api/devicesRemote';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteDevicesRequest,
    deleteSuccess as deleteDevicesSuccess,
    deleteFailure as deleteDevicesFailure,
    cancelDelete as canceldeleteDevices,
} from './deleteDevicesSlice';
import {
    loadRequest as validateDevicesInClusterRequest,
    loadSuccess as validateDevicesInClusterSuccess,
} from './devicesInClusterStatusSlice';
import {
    loadRequest as loadDevicesRemote,
    loadFailure as loadDevicesRemoteFailure,
    loadSuccess as loadDevicesRemoteSuccess,
    cancelLoad as cancelLoadDevicesRemote,
} from './devicesRemoteSlice';
import {
    loadRequest as validateDevicesRequest,
    loadFailure as validateDevicesFailure,
    loadSuccess as validateDevicesSuccess,
    cancelLoad as validateDevicesCancel,
} from './deviceStatusSlice';

export function* fetchDevicesRemote() {
    try {
        const { response, cancel }: { response: DeviceRemote[]; cancel: any } = yield race({
            response: call(getDevicesRemote),
            cancel: take(cancelLoadDevicesRemote),
        });

        if (cancel) {
            return;
        }

        yield put(loadDevicesRemoteSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(loadDevicesRemoteFailure(errMsg));
    }
}

export function* fetchDevicesRemoteSaga() {
    yield takeLatest(loadDevicesRemote, fetchDevicesRemote);
}

export function* validateDevices(action: ReturnType<typeof validateDevicesRequest>) {
    const { data } = action.payload;
    const sn = data.sn;
    try {
        const { response, cancel }: { response: DeviceValidateStatus; cancel: any } = yield race({
            response: call(validateDeviceBySn, { sn }),
            cancel: take(validateDevicesCancel),
        });
        if (cancel) {
            return;
        }
        yield put(validateDevicesSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(validateDevicesFailure(errMsg));
    }
}

export function* validateDevicesSaga() {
    yield takeLatest(validateDevicesRequest, validateDevices);
}

export function* validateDevicesInCluster(action: ReturnType<typeof validateDevicesInClusterRequest>) {
    const { data } = action.payload;
    try {
        const clusterIds = data.filter((device) => device.clusterId).map((device) => device.clusterId);
        if (clusterIds) {
            const { response, cancel }: { response: DeviceValidateStatus[][]; cancel: any } = yield race({
                //@ts-ignore
                response: all(clusterIds.map((id) => call(validateDevicesByClusterId, { id }))),
                cancel: take(validateDevicesCancel),
            });
            if (cancel) {
                return;
            }
            yield put(validateDevicesInClusterSuccess({ data: response }));
        }
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(validateDevicesFailure(errMsg));
    }
}

export function* validateDevicesInCLusterSaga() {
    yield takeLatest(validateDevicesInClusterRequest, validateDevicesInCluster);
}

export function* deleteDevices(action: ReturnType<typeof deleteDevicesRequest>) {
    try {
        const { sns } = action.payload;
        const { cancel }: { cancel: any } = yield race({
            response: all(sns.map((sn) => call(deleteDevice, { sn }))),
            cancel: take(canceldeleteDevices),
        });

        if (cancel) {
            return;
        }

        yield put(deleteDevicesSuccess({ data: 'success' }));
        yield put(loadDevicesRemote());
        toast.success('device(s) are being deleted!');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(deleteDevicesFailure(errMsg));
    }
}

export function* deleteDevicesSaga() {
    yield takeLatest(deleteDevicesRequest, deleteDevices);
}
