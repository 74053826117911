import { Form, Formik } from 'formik';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { FLabelInput } from '@shared/components/Form/Label';
import { FFormModal } from '@shared/components/FtcModal';
import { FixedLoading } from '@shared/components/Loading';
import { useAppDispatch } from '@store/hooks';
import { updateRequest as updateSigningCert } from '@store/modules/settings/signingCert/updateSigningCertSlice';

type Props = {
    onCanceled: () => void;
    data: SigningCert[];
    meta: {
        submitLoading: boolean;
        selectedItem?: SigningCert;
    };
};

function EditForm({ onCanceled, meta, data }: Props) {
    const dispatch = useAppDispatch();

    const { submitLoading, selectedItem } = meta;
    const signingCerts = data;

    if (!selectedItem) {
        return <>No data available</>;
    }

    const initialValues = {
        id: selectedItem.id,
        name: selectedItem.name,
    };

    const schema = Yup.object().shape({
        name: Yup.string()
            .matches(
                /^[a-zA-Z0-9#_-]{1,80}$/,
                'pattern: alphanumeric characters, number sign, hyphen and underscore; length: 1-80'
            )
            .test('check name duplicated', '', function (_, context) {
                const { originalValue } = context;

                // Should remove itself if check the duplicate
                // but still check if the name duplicate with other existing data
                const restSigningCerts = signingCerts.filter(({ name }) => name !== selectedItem?.name);
                const duplicate = ({ name }: { name: string }) => name.toLowerCase() === originalValue.toLowerCase();
                const isDuplicated = restSigningCerts.find(duplicate);

                if (isDuplicated) {
                    return context.createError({
                        message: `Certificate with name ${originalValue} already exists.`,
                    });
                }

                return true;
            })
            .required(),
    });

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
                const castedValues = schema.cast({ ...values });

                dispatch(updateSigningCert({ id: selectedItem?.id, data: castedValues }));
            }}
        >
            <Form>
                {/* loading */}
                {submitLoading && <FixedLoading />}

                <FLabelInput label="Name" name="name" type="text" />

                <FFormModal.ModalFooter>
                    <Button color="primary" outline type="reset" onClick={onCanceled}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={submitLoading}>
                        Save
                    </Button>
                </FFormModal.ModalFooter>
            </Form>
        </Formik>
    );
}

export default EditForm;
