import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getSigningCerts(): Generator {
    return yield axiosClient.get<SigningCert[]>(API.SIGNING_CERT).then((res) => res.data);
}

export function* postSigningCert({ data }: { data: CreateSigningCertData }): Generator {
    return yield axiosClient.post(API.SIGNING_CERT, data).then((res) => res.data);
}

export function* putSigningCert({ id, data }: { id: string; data: UpdateSigningCertData }): Generator {
    return yield axiosClient.put(`${API.SIGNING_CERT}/${id}`, data).then((res) => res.data);
}

export function* deleteSigningCert({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.SIGNING_CERT}/${id}`).then((res) => res.data);
}
