import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getForwarders(): Generator {
    return yield axiosClient.get<Forwarder[]>(API.SETTING_LOG).then((res) => res.data);
}

// create receiver
export function* postForwarder({ data }: { data: UpsertForwarderData }): Generator {
    return yield axiosClient.post<Forwarder>(API.SETTING_LOG, data).then((res) => res.data);
}

// update receiver
export function* putForwarder({ id, data }: { id?: string; data: UpsertForwarderData }): Generator {
    return yield axiosClient.put<Forwarder>(`${API.SETTING_LOG}/${id}`, data).then((res) => res.data);
}

export function* deleteForwarder({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.SETTING_LOG}/${id}`).then((res) => res.data);
}
