import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getAlerts, AlertResponse } from '@services/api/alerts';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAlerts,
    loadFailure as loadAlertsFailure,
    loadSuccess as loadAlertsSuccess,
    cancelLoad as cancelLoadAlerts,
} from './alertsSlice';

export function* fetchAlerts() {
    try {
        const { response, cancel }: { response: AlertResponse; cancel: any } = yield race({
            response: call(getAlerts),
            cancel: take(cancelLoadAlerts),
        });

        if (cancel) {
            return;
        }

        yield put(loadAlertsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAlertsFailure(e?.message));
    }
}

export function* fetchAlertsSaga() {
    yield takeLatest(loadAlerts, fetchAlerts);
}
