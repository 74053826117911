import { ChangeEvent } from 'react';

type TSearchInput = {
    filterValue: string;
    onFilter: (e: ChangeEvent) => void;
};

function SearchSelect({ filterValue, onFilter, children }: React.PropsWithChildren<TSearchInput>) {
    return (
        <>
            <select onChange={onFilter} value={filterValue}>
                {children}
            </select>
            <i className="fa fa-search"></i>
        </>
    );
}

export { SearchSelect };
