import axiosClient from '../axiosClient';
import { API } from '../index';

export type ReceiversParams = {
    group_id?: string;
};

export function* getReceivers({ params }: { params?: ReceiversParams }): Generator {
    return yield axiosClient.get<Receiver[]>(API.NOTIFICATION_RECEIVER, { params }).then((res) => res.data);
}

export function* getReceiver({ id }: { id: string }): Generator {
    return yield axiosClient.get<ReceiverWithGroup>(`${API.NOTIFICATION_RECEIVER}/${id}`).then((res) => res.data);
}

// create receiver
export function* postReceiver({ data }: { data: UpsertReceiverData }): Generator {
    return yield axiosClient.post<Receiver>(API.NOTIFICATION_RECEIVER, data).then((res) => res.data);
}

// update receiver
export function* putReceiver({ id, data }: { id?: string; data: UpsertReceiverData }): Generator {
    return yield axiosClient.put<Receiver>(`${API.NOTIFICATION_RECEIVER}/${id}`, data).then((res) => res.data);
}

export function* deleteReceiver({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.NOTIFICATION_RECEIVER}/${id}`).then((res) => res.data);
}
