import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

function RootBoundary() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>This page doesn't exist!</div>;
        }

        if (error.status === 403) {
            return <div>You aren't authorized to see this</div>;
        }

        if (error.status === 401) {
            return <div>not enough privileges to perform an action on a resource.</div>;
        }

        if (error.status === 503) {
            return <div>Looks like our API is down</div>;
        }

        if (error.status === 418) {
            return <div>error status 418</div>;
        }
    }

    console.warn('RootBoundary', error);
    return <div>Something went wrong, please refresh the page and try again.</div>;
}

export default RootBoundary;
