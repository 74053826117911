import { Button } from 'reactstrap';
import { FModal } from '@shared/components/FtcModal';
import { FixedLoading } from '@shared/components/Loading';
import { FORTIPRODUCT } from '@shared/utils/constants';
import { safeTagsReplace } from '@shared/utils/safeTagsReplace';
import { useAppDispatch } from '@store/hooks';
import { deleteRequest as deleteClients } from '@store/modules/authClients/fortiProducts/deleteFortiProductClientsSlice';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onCanceled: () => void;
    onClosed: () => void;
    data: AuthClientData[];
    meta: {
        selectedIds: string[];
        sortedRows: AuthClientData[] | null;
        submitLoading: boolean;
    };
};

function DeleteModal({ isOpen, toggle, onClosed, onCanceled, data: authClients, meta }: Props) {
    const { selectedIds, sortedRows, submitLoading } = meta;

    const dispatch = useAppDispatch();

    const handleDelete = ({ ids }: { ids: string[] }) => {
        dispatch(deleteClients({ ids }));
    };

    const selectedAuthClients = (sortedRows ?? authClients).filter(({ id }: { id: string }) =>
        selectedIds.includes(id)
    );

    return (
        <FModal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <FModal.ModalHeader toggle={toggle}>{`Delete ${FORTIPRODUCT}s`}</FModal.ModalHeader>
            <FModal.ModalBody>
                <>
                    {submitLoading && <FixedLoading />}
                    <div className="plain-text">
                        <p>
                            Please note that if users are still in use under the {FORTIPRODUCT}, they will fail to
                            authenticate.
                        </p>
                        <p>
                            Are you sure you want to delete the following {FORTIPRODUCT}(s)? You won't be able to undo
                            this action once it is executed!
                        </p>
                        <ul>
                            {selectedAuthClients?.map(({ name }) => {
                                return <li key={name}>{safeTagsReplace(name)}</li>;
                            })}
                        </ul>
                    </div>
                </>
            </FModal.ModalBody>
            <FModal.ModalFooter>
                <Button color="primary" outline onClick={onCanceled}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        handleDelete({ ids: selectedIds });
                    }}
                    disabled={submitLoading}
                >
                    Yes
                </Button>
            </FModal.ModalFooter>
        </FModal>
    );
}

export default DeleteModal;
