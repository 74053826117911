import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FortiCloudHeader } from '@shared/components/FortiCloudHeader';
import { FtcFooter } from '@shared/components/FtcFooter';
import { Navigation } from '@shared/components/Navigation';
import useAutoLogout from '@shared/hooks/useAutoLogout';
import { useModal } from '@shared/hooks/useModal';
import { AUTO_LOGOUT_TIME } from '@shared/utils/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectAuthUser, selectMenuItemStatus } from '@store/modules/auth/authSlice';
import { loadLogout } from '@store/modules/auth/logoutSlice';
import { selectNavbar } from '@store/modules/layout/navbarSlice';
import { loadRequest as loadBalance } from '@store/modules/resources/balanceSlice';
import {
    loadRequest as loadResources,
    selectResourcesUser,
    selectResourcesClient,
    selectResourcesRealm,
} from '@store/modules/resources/resourcesSlice';
import { loadRequest as loadGlobalSettings } from '@store/modules/settings/global/globalSettingsSlice';
import { getMenu } from './getMenu';

import './AuthenticatedLayout.scss';
import LogoutModal from '../../../shared/components/LogoutModal';

const AuthenticatedLayout = () => {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(selectAuthUser);
    const navbar = useAppSelector(selectNavbar);
    const fortiCloudItemStatusMap = useAppSelector(selectMenuItemStatus);
    const resourcesRealm = useAppSelector(selectResourcesRealm);
    const { data: globalSettingsData } = useAppSelector((state) => state.globalSettings);
    const [internalMenu, externalMenu] = getMenu({ authUser, globalSettingsData, resourcesRealm });

    const resourcesUser = useAppSelector(selectResourcesUser);
    const resourcesClient = useAppSelector(selectResourcesClient);

    const isLogin = !!authUser.account_id;
    const viewedCustomerId = authUser.ftc_account_id;
    const timer = useAutoLogout(AUTO_LOGOUT_TIME, isLogin);
    const { isOpen: isLogoutModalOpen, toggle: toggleLogoutModal, close: closeLogoutModal } = useModal();

    useEffect(() => {
        if (timer <= 0) {
            toggleLogoutModal();
        }
    }, [timer]);

    useEffect(() => {
        // keep in redux, load shared values ​​across all pages
        if (
            process.env.REACT_APP_WEBSITE !== 'admin' ||
            (process.env.REACT_APP_WEBSITE === 'admin' && viewedCustomerId)
        ) {
            dispatch(loadResources());
            dispatch(loadBalance());
            if (authUser.is_admin) {
                dispatch(loadGlobalSettings());
            }
        }
    }, [viewedCustomerId, authUser]);

    return (
        <div id="layout">
            <div className="layout-header">
                {navbar && (
                    <FortiCloudHeader
                        isAdmin={process.env.REACT_APP_WEBSITE === 'admin' && authUser.admin}
                        meta={navbar as FortiCareMenu}
                        user={{
                            accountId: authUser.account_id,
                            accountCompany: authUser.account_company,
                            userName: authUser.iam_user_name || authUser.fortinetid || authUser.user_name,
                            hasOrg: authUser.has_org,
                            userDisplayInfo: authUser.user_display_info,
                            selectedNode: authUser.selected_node,
                            isIAM: authUser.fortinetid != null && authUser.iam_user_name != null,
                            fortiCloudItemStatusMap,
                        }}
                        logout={() => {
                            dispatch(loadLogout());
                        }}
                        logoPath={
                            process.env.PUBLIC_URL +
                            `/media/${
                                authUser.premium || authUser.logo_info?.result.hasPremiumSubscription
                                    ? 'logo_premium.svg'
                                    : 'logo.svg'
                            }`
                        }
                    />
                )}
            </div>
            <main className="layout-main">
                <div className="layout-main-cnt">
                    <div className="layout-main-cnt-left">
                        <Navigation
                            ftcAccountId={authUser.ftc_account_id}
                            menu={[internalMenu, externalMenu]}
                            footer={{
                                users: parseInt(resourcesUser?.user ?? '0'),
                                authClients: parseInt(resourcesClient?.['client'] ?? '0'),
                                webApps: parseInt(resourcesClient?.['webapps'] ?? '0'),
                                applications: parseInt(resourcesClient?.['applications'] ?? '0'),
                                fortiProducts: parseInt(resourcesClient?.['forti-products'] ?? '0'),
                            }}
                        ></Navigation>
                    </div>
                    <div className="layout-main-cnt-right">
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </div>
                </div>
                {isLogoutModalOpen && (
                    <LogoutModal
                        isOpen={isLogoutModalOpen}
                        toggle={toggleLogoutModal}
                        onClosed={closeLogoutModal}
                    ></LogoutModal>
                )}
            </main>
            <div className="layout-footer">
                <FtcFooter />
            </div>
        </div>
    );
};

export { AuthenticatedLayout };
