import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { UsersRefParams } from '@services/api/users';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';

interface InitialState extends GenericState<User[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const webAppUsersSlice = createGenericSlice({
    name: 'webAppUsers',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsersRefParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: User[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = webAppUsersSlice.actions;

export const selectWebAppUsers = createSelector(
    (state: RootState) => state.webAppUsers.data,
    (data) => data ?? []
);

export default webAppUsersSlice.reducer;
