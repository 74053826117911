import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { request, success, failure, FETCH_NAVBAR } from '../../actions';
import type { RootState } from '../../index';

interface NavbarState {
    loading: boolean;
    data: FortiCareMenu | AdminMenu | null;
    error: string | null;
}

const initialState: NavbarState = {
    loading: false,
    data: null,
    error: null,
};

// action creators
export const loadNavbar = createAction(request(FETCH_NAVBAR));
export const loadNavbarSuccess = createAction<{
    data: NonNullable<NavbarState['data']>;
}>(success(FETCH_NAVBAR));
export const loadNavbarFailure = createAction<string>(failure(FETCH_NAVBAR));
// export const cancelloadNavbar = createAction(cancel(FETCH_NAVBAR));

// reducers
const navbarReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loadNavbar, (state) => {
            state.loading = true;
        })
        .addCase(loadNavbarSuccess, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        })
        .addCase(loadNavbarFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        // .addCase(cancelloadNavbar, () => initialState)
        .addDefaultCase((state) => state);
});

// we will not use this selector before user logined, let's assume the user object is always not null
export const selectNavbar = (state: RootState) => state.navbar.data as NonNullable<NavbarState['data']>;

export const selectRegisterUrl = createSelector(
    (state: RootState) => state.navbar.data,
    (navbar) => {
        if (!navbar || !('configurations' in navbar)) {
            return '';
        }
        return navbar.configurations.find((item) => item.name === 'AccountCreation_URL')?.value || '';
    }
);

export const selectLoginUrl = createSelector(
    (state: RootState) => state.navbar.data,
    (navbar) => {
        if (!navbar || !('portal_menu_items' in navbar)) {
            return '';
        }
        return (
            navbar.portal_menu_items.find((item) => item.app_name === 'FTC')?.url ?? 'https://ftc.fortinet.com/login/'
        );
    }
);

export const selectFortiCareApp = createSelector(
    (state: RootState) => state.navbar.data,
    (data) => {
        if (data && 'portal_menu_items' in data) {
            const portal_menu_items = data.portal_menu_items || null;
            if (portal_menu_items) {
                const forticareHeaderItem = data.portal_menu_items
                    .filter((item) => item.section_header === 'Assets & Accounts')
                    .find((item) => item.app_name === 'AMPortal');
                if (forticareHeaderItem) {
                    /* TODO: should consider permission from forticareHeaderItem.status */
                    return { url: forticareHeaderItem.url, name: forticareHeaderItem.display_name };
                }
            }
        }
        return null;
    }
);

export const selectLogoutUrl = createSelector(
    (state: RootState) => state.navbar.data,
    (navbar) => {
        if (!navbar) {
            return '';
        }

        if (process.env.REACT_APP_WEBSITE === 'admin') {
            if (Array.isArray((navbar as AdminMenu).admin_apps)) {
                // @ts-ignore
                return (navbar as AdminMenu).admin_apps.find((item) => item.app_name === 'Logout').url;
            }
            return '';
        }

        const logoutUrl =
            (navbar as FortiCareMenu).user_menu_items?.find((item) => item.display_name === 'Logout')?.url || '';
        return logoutUrl ? `${logoutUrl}?ReturnApp=FTC` : '';
    }
);

export default navbarReducer;
