import { NavLink, useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { Collapse } from './Collapse';

import './Navigation.scss';
import { Icon } from '../Icon';

export type MENU_UNIT = {
    name: string;
    to: string;
    sub?: MENU_UNIT[];
    meta?: {
        disabled?: boolean;
        tooltip?: { id: string; content: string };
    };
};

export type NAVIGATION_UNIT = MENU_UNIT & {
    meta: {
        icon: JSX.Element;
        disabled?: boolean;
        tooltip?: { id: string; content: string };
    };
};

const Navigation = ({
    ftcAccountId = null,
    menu = [[], []],
    footer = null,
}: {
    ftcAccountId: string | null;
    menu: NAVIGATION_UNIT[][];
    footer: {
        users: number;
        authClients: number;
        webApps: number;
        applications: number;
        fortiProducts: number;
    } | null;
}) => {
    const { pathname } = useLocation();

    const [internalMenu, externalMenu] = menu;
    const isMatch = (targetPath: string) => {
        return pathname.startsWith(targetPath);
    };

    return (
        <div className="navigation">
            <div className="navigation-top">
                {internalMenu.length > 0 && (
                    <>
                        <ul>
                            {internalMenu.map((l1, index) => {
                                return (
                                    <li key={index} className="level-1-li">
                                        {/* level 2 */}
                                        {Array.isArray(l1.sub) ? (
                                            <Collapse data={l1} isDefaultOpen={isMatch(l1.to)} levelStart={2} />
                                        ) : (
                                            <NavLink to={l1.to}>
                                                {l1.meta.icon}
                                                <span>{l1.name}</span>
                                            </NavLink>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                        <hr className="site-divider" />
                    </>
                )}

                {ftcAccountId && (
                    <>
                        <ul>
                            {externalMenu.map((l1, index) => {
                                return (
                                    <>
                                        <li key={index} className={`level-1-li ${l1.meta.disabled ? 'disabled' : ''}`}>
                                            {/* level 2 */}
                                            {Array.isArray(l1.sub) ? (
                                                <Collapse data={l1} isDefaultOpen={isMatch(l1.to)} levelStart={2} />
                                            ) : (
                                                <div id={l1.meta.tooltip?.id}>
                                                    <NavLink to={l1.to} className={l1.meta.disabled ? 'disabled' : ''}>
                                                        {l1.meta.icon}
                                                        <span>{l1.name}</span>
                                                    </NavLink>
                                                </div>
                                            )}
                                        </li>
                                        {l1.meta.tooltip && (
                                            <UncontrolledTooltip placement="right" target={l1.meta.tooltip?.id}>
                                                {l1.meta.tooltip.content}
                                            </UncontrolledTooltip>
                                        )}
                                    </>
                                );
                            })}
                        </ul>
                        <hr />
                    </>
                )}

                {footer && (
                    <ul className="info-list">
                        <li>
                            <dl>
                                <dt>
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="group" />
                                    Users
                                </dt>
                                <dd>{footer.users}</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt>
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="grid_view" />
                                    Applications
                                </dt>
                                <dd>{footer.webApps + footer.applications}</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt>
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="dns" />
                                    FortiProducts
                                </dt>
                                <dd>{footer.fortiProducts}</dd>
                            </dl>
                        </li>
                    </ul>
                )}
            </div>

            <div className="power-by">
                <img src={process.env.PUBLIC_URL + '/media/Logo_Fortinet.svg'} alt="Powered by Fortinet" />
                <span>
                    Powered by Fortinet<sup>&copy;</sup>
                </span>
            </div>
        </div>
    );
};

export { Navigation };
