import { createSelector } from '@reduxjs/toolkit';
import { LogSearchData } from '@services/api/logs';
import { createAuthLog, createMgmtLog, createSMSLog } from './helper';
import { LogsResponseData } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import { buildRealmMap } from '../realms/helper';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface LogsStateData {
    logs: {
        mgmt: MgmtLogs | null;
        auth: AuthLogs | null;
        sms: SMSLogs | null;
    };
    realmsBrief?: RealmBrief[];
}

interface LogsState extends GenericState<LogsStateData> {}

const initialState: LogsState = {
    loading: false,
    data: {
        logs: {
            mgmt: null,
            auth: null,
            sms: null,
        },
        realmsBrief: [],
    },
    error: null,
};

const logsSlice = createGenericSlice({
    name: 'logs',
    initialState: initialState as LogsState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                params: LogSearchData;
                logType: keyof LogsStateData['logs'];
                withoutRealms?: boolean;
            }>
        ) => {
            state.loading = true;
            state.data = initialState.data;
        },
        loadSuccess: (
            state,
            action: PayloadAction<{
                data: LogsResponseData;
                logType: keyof LogsStateData['logs'];
            }>
        ) => {
            state.loading = false;
            state.error = null;
            state.data = {
                realmsBrief: action.payload.data.realmsBrief,
                logs: { ...state.data.logs, [action.payload.logType]: action.payload.data.logs },
            };
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = logsSlice.actions;

export const selectAllAuthLogs = createSelector(
    (state: RootState) => state.logs.data?.logs?.auth,
    (data) => (data?.hits?.hits ? data.hits.hits.map((element) => createAuthLog(element)) : [])
);

export const selectAllMgmtLogs = createSelector(
    (state: RootState) => state.logs.data?.logs?.mgmt,
    (data) => (data?.hits?.hits ? data.hits.hits.map((element) => createMgmtLog(element)) : [])
);

export const selectAllSMSLogs = createSelector(
    (state: RootState) => state.logs.data?.logs?.sms,
    (data) => (data?.hits?.hits ? data.hits.hits.map((element) => createSMSLog(element)) : [])
);

export const selectLogsTotalCount = (logType: keyof LogsStateData['logs']) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.[logType],
        (data) => (typeof data?.hits?.total === 'number' ? data.hits.total : data?.hits?.total.value ?? 0)
    );

export const selectLogsTotalCountRelation = (logType: keyof LogsStateData['logs']) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.[logType],
        (data) => (typeof data?.hits?.total === 'number' ? '' : data?.hits?.total?.relation ?? '')
    );

export const selectAuthLogsFilterField = (field: keyof AuthLogsAggregations) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.auth,
        (data) => [
            ...(data?.aggregations?.[field]?.buckets
                ?.map((item) => item.key)
                .filter((item) => item !== '')
                .map((value: string) => ({ value: value, label: value })) ?? []),
            { value: 'All', label: 'All' },
        ]
    );

export const selectMgmtLogsFilterField = (field: keyof MgmtLogsAggregations) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.mgmt,
        (data) => [
            ...(data?.aggregations?.[field]?.buckets
                ?.map((item) => item.key)
                .filter((item) => item !== '')
                .map((value: string) => ({ value: value, label: value })) ?? []),
            { value: 'All', label: 'All' },
        ]
    );

export const selectSMSLogsFilterField = (field: keyof SMSLogsAggregations) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.sms,
        (data) => [
            ...(data?.aggregations?.[field]?.buckets
                ?.map((item) => item.key)
                .filter((item) => item !== '')
                .map((value: string) => ({ value: value, label: value })) ?? []),
            { value: 'All', label: 'All' },
        ]
    );

export const selectLogsFilterRealms = (logType: keyof LogsStateData['logs']) =>
    createSelector(
        (state: RootState) => state.logs.data?.logs?.[logType],
        (state: RootState) => state.logs.data?.realmsBrief,
        (logsData, realmsBriefData) => {
            const realmsMap = realmsBriefData ? buildRealmMap(realmsBriefData) : {};
            return [
                ...(logsData?.aggregations.realm.buckets.map((item) => ({
                    value: item.key,
                    label: realmsMap[item.key]?.name ?? item.key,
                })) ?? []),
                { value: 'All', label: 'All' },
            ];
        }
    );

export const selectRealmsBreifMap = createSelector(
    (state: RootState) => state.logs.data?.realmsBrief,
    (data) => (data ? buildRealmMap(data) : {})
);

export default logsSlice.reducer;
