import dayjs from 'dayjs';
import { DATE_FORMAT } from '@shared/utils/constants';

export function formatSMS(element: SMS): SMSRateData {
    return {
        id: element.id,
        resource: element.resource,
        location: element.location,
        details: element.details,
        ratio: Number(element.ratio),
        sender_id: element.sender_id,
        lastUpdated: dayjs(element.updated_at || element.created_at).format(DATE_FORMAT),
        unsupported: element.unsupported,
        description: element.description,
    };
}
