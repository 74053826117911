import axiosClient from '../axiosClient';
import { API } from '../index';

export type UserResponse = User[];

export type UsersRefParams = { client_id?: string };
export type UsersParams = { realm_id?: string };

export function* getUsers({ params }: { params?: UsersParams }): Generator {
    return yield axiosClient.get<UserResponse>(API.USER_BASE, { params }).then((res) => res.data);
}

export function* getUser({ id }: { id?: string }): Generator {
    const userBaseUrl = id ? `${API.USER_BASE}/${id}` : API.USER_BASE;
    return yield axiosClient.get<User>(userBaseUrl).then((res) => res.data);
}

export function* getUserExtension({ id }: { id: string }): Generator {
    return yield axiosClient.get<UserExtension>(`${API.USER_BASE}/${id}/extension`).then((res) => res.data);
}

export function* putUser({
    id,
    data,
}: {
    id: string;
    data: UserEditSubmitData | UserFtmTokenData | UsersAliasData;
}): Generator {
    return yield axiosClient.put(`${API.USER_BASE}/${id}`, data).then((res) => res.data);
}

export function* getUsersRef({ params }: { params?: UsersRefParams }): Generator {
    return yield axiosClient.get<UserResponse>(API.USER_REF, { params }).then((res) => res.data);
}

export function* putUserRef({ id, data }: { id: string; data: UsersAliasData }): Generator {
    return yield axiosClient.put(`${API.USER_REF}/${id}`, data).then((res) => res.data);
}

export function* deleteUser({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.USER_BASE}/${id}`).then((res) => res.data);
}

export function* deleteBatchUser({ data }: { data: UserBatchDeleteData }): Generator {
    return yield axiosClient.post(API.USER_BASE_BATCH, data).then((res) => res.data);
}

export function* addBatchUser({ data }: { data: UserBatchCreateData }): Generator {
    return yield axiosClient.post(API.USER_BASE_BATCH, data).then((res) => res.data);
}
