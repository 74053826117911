import { useEffect, useState, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import { SearchInput } from '@shared/components/FtcSearchBar';
import { FtcTable } from '@shared/components/FtcTable';
import { HelpLink } from '@shared/components/HelpLink';
import { Icon } from '@shared/components/Icon';
import { FixedLoading } from '@shared/components/Loading';
import Permissions from '@shared/components/Permissions';
import { useFilter } from '@shared/hooks/useFilter';
import { useModal } from '@shared/hooks/useModal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { cancelDelete as cancelDeleteClients } from '@store/modules/authClients/fortiProducts/deleteFortiProductClientsSlice';
import {
    loadRequest as loadClients,
    cancelLoad as cancelLoadClients,
    selectAuthClients,
} from '@store/modules/authClients/fortiProducts/fortiProductClientsSlice';
import {
    loadRequest as loadClientUsers,
    cancelLoad as cancelLoadClientUsers,
    selectClientUsers,
} from '@store/modules/authClients/fortiProducts/fortiProductClientUsersSlice';
import { cancelUpsert as cancelUpdateClient } from '@store/modules/authClients/fortiProducts/updateFortiProductClientSlice';
import { selectClientsSearch, cleanSearch } from '@store/modules/authClients/searchFortiProductClientsSlice';
import ClientUsersModal from './ClientUsersModal';
import DeleteModal from './DeleteModal';
import DetailsModal from './DetailsModal';
import EditModal from './EditModal';
import { fortiProductsColumns } from './tableConfig';

const FortiProducts = () => {
    const dispatch = useAppDispatch();
    const clientsState = useAppSelector((state) => state.fortiProductClients);
    const clients = useAppSelector(selectAuthClients);
    const clientUsersState = useAppSelector((state) => state.fortiProductClientUsers);
    const clientUsers = useAppSelector(selectClientUsers);
    const clientsSearch = useAppSelector(selectClientsSearch);
    const deleteClientsState = useAppSelector((state) => state.deleteFortiProductClients);
    const updateClientState = useAppSelector((state) => state.updateFortiProductClient);

    // state
    const [selectedRow, setSelectedRow] = useState('');
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [sortedRows, setSortedRows] = useState<AuthClientData[] | null>(null);

    // custom hooks
    const { isOpen: isEditModalOpen, toggle: toggleEditModal, close: closeEditModal } = useModal();
    const { isOpen: isClientUsersModalOpen, toggle: toggleClientUsersModal, close: closeClientUsersModal } = useModal();
    const { isOpen: isDetialsModalOpen, toggle: toggleDetialsModal, close: closeDetialsModal } = useModal();
    const { isOpen: isDeleteModalOpen, toggle: toggleDeleteModal, close: closeDeleteModal } = useModal();
    const { filterValue, setFilterValue, setSearchValue, filtered: filteredClients } = useFilter(clients);

    const isDeleteBtnDisabled = selectedRows.length === 0;
    const showPendingStatus = clients.find(({ deleteStatus }) => !!deleteStatus);
    const rowDisabledCriteria = (row: AuthClientData) => !!row.deleteStatusText;

    useEffect(() => {
        dispatch(loadClients({ params: { type: 'Fortinet' } }));
        return () => {
            dispatch(cancelLoadClients());
            dispatch(cleanSearch());
        };
    }, []);

    // When click "Name" in table from user modal at users page, will navigate to this page,
    // update search result
    useEffect(() => {
        if (clientsSearch) {
            setSearchValue({ value: clientsSearch });
        }
    }, [clientsSearch]);

    // handle table check box if all checkbox should be cleared when update
    useEffect(() => {
        if (
            updateClientState.loading === false &&
            updateClientState.data !== null &&
            updateClientState.error === null
        ) {
            console.log('submit success, return to list page');
            closeEditModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelUpdateClient());
        }
    }, [updateClientState]);

    // handle table check box if all checkbox should be cleared when delete
    useEffect(() => {
        if (
            deleteClientsState.loading === false &&
            deleteClientsState.data !== null &&
            deleteClientsState.error === null
        ) {
            console.log('submit success, return to list page');
            closeDeleteModal();
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setSelectedRow('');
            dispatch(cancelDeleteClients());
        }
    }, [deleteClientsState]);

    const handleRowSelected = useCallback((state: any) => {
        const rows = state.selectedRows as AuthClientData[];
        const items = rows.map(({ id }) => id);
        setSelectedRows(items);
    }, []);

    const handleCountClick = ({ id }: { id: string }) => {
        toggleClientUsersModal();
        setSelectedRow(id);
        dispatch(loadClientUsers({ params: { client_id: id } }));
    };

    const handleDetails = ({ id }: { id: string }) => {
        toggleDetialsModal();
        setSelectedRow(id);
    };

    const handleEdit = ({ id }: { id: string }) => {
        toggleEditModal();
        setSelectedRow(id);
    };

    const handleDelete = ({ id }: { id: string }) => {
        toggleDeleteModal();
        setSelectedRow(id);
    };

    return (
        <div className="content-wall">
            {clientsState.loading && <FixedLoading />}
            <h1 className="page-h1">FortiProducts</h1>
            <div>
                {/* table header */}
                <div className="d-flex justify-content-between flex-wrap gap-5 mb-5">
                    <div>
                        <Permissions permission="super_admin">
                            <button
                                onClick={toggleDeleteModal}
                                disabled={isDeleteBtnDisabled}
                                className="btn btn-outline-secondary"
                            >
                                <Icon classNames={['material-symbols-outlined', 'fill']} content="delete" />
                                Delete
                            </button>
                        </Permissions>
                    </div>
                    <div className="d-inline-flex gap-3 align-items-center">
                        <SearchInput
                            type="search"
                            placeholder="Search"
                            onFilter={(e) => {
                                // 1. Clean data if change value in the searchInput
                                // 2. Start using local state to search
                                if (clientsSearch) {
                                    dispatch(cleanSearch());
                                }
                                setFilterValue(e);
                            }}
                            filterValue={filterValue}
                        />
                        <HelpLink mapKey="applications/fortiprod"></HelpLink>
                    </div>
                </div>
                <FtcTable
                    id="authClientFortiProductsTable"
                    data={filteredClients}
                    columns={
                        fortiProductsColumns(
                            handleCountClick,
                            handleDetails,
                            handleDelete,
                            handleEdit,
                            showPendingStatus
                        ) as TableColumn<AuthClientData>[]
                    }
                    clearSelectedRows={toggleCleared}
                    onSelectedRowsChange={handleRowSelected}
                    selectableRowDisabled={rowDisabledCriteria}
                    selectableRows
                    pagination
                    setSortedRows={setSortedRows}
                />
            </div>
            <EditModal
                isOpen={isEditModalOpen}
                toggle={toggleEditModal}
                onClosed={() => {
                    closeEditModal();
                    setSelectedRow('');
                }}
                onCanceled={closeEditModal}
                data={clients}
                meta={{
                    selectedId: selectedRow,
                    submitLoading: updateClientState.loading,
                }}
            />
            <ClientUsersModal
                isOpen={isClientUsersModalOpen}
                toggle={toggleClientUsersModal}
                onClosed={() => {
                    closeClientUsersModal();
                    dispatch(cancelLoadClientUsers());
                }}
                onBtnClosed={closeClientUsersModal}
                data={{ clients: clients, clientUsers: clientUsers }}
                meta={{ selectedId: selectedRow, loading: clientUsersState.loading, error: clientUsersState.error }}
            />
            <DetailsModal
                isOpen={isDetialsModalOpen}
                toggle={toggleDetialsModal}
                onClosed={() => {
                    closeDetialsModal();
                    setSelectedRow('');
                }}
                onBtnClosed={closeDetialsModal}
                data={clients}
                meta={{ selectedId: selectedRow }}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                toggle={toggleDeleteModal}
                data={clients}
                onClosed={() => {
                    closeDeleteModal();
                    setSelectedRow('');
                }}
                onCanceled={toggleDeleteModal}
                meta={{
                    // for delete, the priorty of an item delete is higher than batch delete
                    selectedIds: selectedRow.length > 0 ? [selectedRow] : selectedRows,
                    sortedRows: sortedRows,
                    submitLoading: deleteClientsState.loading,
                }}
            />
        </div>
    );
};

export default FortiProducts;
