import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as loadUserSourceDomainMappingsSuccess } from './userSourceDomainMappingsSlice';
import { loadSuccess as loadUserSourceRealmsSuccess } from './userSourceRealmsSlice';
import { loadSuccess as loadUserSourcesSuccess } from './userSourcesSlice';

type UserSourceResource = {
    realms: Realm[] | null;
    userSources: UserSource[] | null;
    domains: DomainMapping[] | null;
    userSourcePreview: UserSourcePreview | null;
};

export type NonNullableUserSourceResource = {
    realms: NonNullable<UserSourceResource['realms']>;
    userSources: NonNullable<UserSourceResource['userSources']>;
    domains: NonNullable<UserSourceResource['domains']>;
    userSourcePreview: NonNullable<UserSourceResource['userSourcePreview']>;
};

interface InitialState extends GenericState<UserSourceResource> {}

const initialState: InitialState = {
    loading: false,
    data: {
        realms: null,
        userSources: null,
        domains: null,
        userSourcePreview: null,
    },
    error: null,
};

const userSourceResourcesSlice = createGenericSlice({
    name: 'userSourceResources',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ userSourceId?: string; realmId?: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserSourceResource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUserSourceRealmsSuccess, (state, action: PayloadAction<{ data: Realm[] }>) => {
            const { data: realms } = action.payload;
            state.data.realms = realms;
        });
        builder.addCase(loadUserSourcesSuccess, (state, action: PayloadAction<{ data: UserSource[] }>) => {
            const { data: userSources } = action.payload;
            state.data.userSources = userSources;
        });
        builder.addCase(
            loadUserSourceDomainMappingsSuccess,
            (state, action: PayloadAction<{ data: DomainMapping[] }>) => {
                const { data: domainMappings } = action.payload;
                state.data.domains = domainMappings;
            }
        );
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSourceResourcesSlice.actions;

export const selectAllUserSourceResources = createSelector(
    (state: RootState) => state.userSourceResources.data,
    (data) => {
        let nonNullData: NonNullableUserSourceResource = {
            realms: [],
            userSources: [],
            domains: [],
            userSourcePreview: {
                prefix: '',
                entity_id: '',
                acs_url: '',
                slo_url: '',
                callback_url: '',
                logout_uri: '',
                post_logout_redirect_uri: '',
            },
        };
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                nonNullData[key as keyof UserSourceResource] = value as any;
            }
        });
        return nonNullData;
    }
);

export const selectUserSourceRealmOptions = createSelector(selectAllUserSourceResources, (data) =>
    data.realms.map(({ id, name }) => ({ value: id, label: name }))
);

export default userSourceResourcesSlice.reducer;
