import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DATE_FORMAT } from '@shared/utils/constants';

extend(utc);

const licenseStatus = ['active', 'expired', 'pending'];

const userAgreements: IObjectKeys = {
    0: 'Trial',
    1: 'FTC Subscription',
    2: 'Free',
    3: 'FTM Migration',
    4: 'FortiTrustID',
};

const smsAgreements: IObjectKeys = {
    1: 'FTC SMS',
    2: 'Free SMS',
};

const licenseTypes = [
    // user licenses
    userAgreements,
    // SMS licenses
    smsAgreements,
];

export function formatLicense(element: License): LicenseData {
    const { sn, serial_number, type, agreement, quantity, free_sms, sms_balance, start_date, end_date, status } =
        element;
    return {
        id: sn,
        sn: sn,
        serialNumber: serial_number,
        type: licenseTypes[type][agreement],
        users: type === 0 ? quantity : 'N/A',
        sms: type === 0 ? free_sms : quantity,
        smsBalance: sms_balance,
        startDate: dayjs(start_date).format(DATE_FORMAT),
        endDate: dayjs(end_date).format(DATE_FORMAT),
        status: dayjs().utc().isAfter(end_date, 'day') ? 'expired' : licenseStatus[status],
    };
}
