import { createSelector } from '@reduxjs/toolkit';
import { ClientsParams } from '@services/api/clients';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { formatProfile } from '@store/modules/profiles/helper';
import { ClientsResponse } from './watcher';
import { formatAuthClient } from '../helper';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<ClientsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const fortiProductClientsSlice = createGenericSlice({
    name: 'fortiProductClients',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: ClientsParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: ClientsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = fortiProductClientsSlice.actions;

export const selectAllClients = createSelector(
    (state: RootState) => state.fortiProductClients.data?.clients,
    (data) => data ?? []
);

export const selectAuthClients = createSelector(
    (state: RootState) => state.fortiProductClients.data?.clients.map(formatAuthClient),
    (state: RootState) => state.fortiProductClients.data?.realms || [],
    (data, realms) => {
        if (!data) {
            return [];
        }
        return data.map((d) => ({ ...d, realm: realms.find((r) => r.id === d?.realmId || '')?.name || '' }));
    }
);

export const selectProfileOptions = createSelector(
    (state: RootState) => state.fortiProductClients.data?.profiles.map(formatProfile),
    (data) => data?.map(({ id, name }) => ({ id, name })) ?? []
);

export const selectRealmOptions = createSelector(
    (state: RootState) => state.fortiProductClients.data?.realms,
    (data) => data?.map(({ id, name }) => ({ id, name })) ?? []
);

export default fortiProductClientsSlice.reducer;
