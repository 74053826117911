/**
 * @param rule: if the suffix is not s, please pass custom word. e.g. fix -> fixes, please pass fixes
 */

export function pluralize(word: string, count: number, rule?: string) {
    if (count === 1) {
        return `${word}`;
    }

    if (!rule) {
        return `${word}s`;
    }

    return `${rule}`;
}
