import axiosClient from '../axiosClient';
import { API } from '../index';

export type AdminsParams = { group_id?: string; accountlist?: boolean };

export type AdminParams = { group_id?: string };

export function* getAdminGroups(): Generator {
    return yield axiosClient.get<AdminGroup[]>(API.ACCOUNT_GROUP).then((res) => res.data);
}

export function* postAdminGroup({ data }: { data: UpsertAdminGroupData }): Generator {
    return yield axiosClient.post<AdminGroup>(API.ACCOUNT_GROUP, data).then((res) => res.data);
}

export function* putAdminGroup({ id, data }: { id: string; data: UpsertAdminGroupData }): Generator {
    return yield axiosClient.put<AdminGroup>(`${API.ACCOUNT_GROUP}/${id}`, data).then((res) => res.data);
}

export function* deleteAdminGroup({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.ACCOUNT_GROUP}/${id}`).then((res) => res.data);
}

export function* getAdmins({ params }: { params?: AdminsParams }): Generator {
    return yield axiosClient.get<Admin[]>(API.ACCOUNT, { params }).then((res) => res.data);
}

// add admin to group
export function* postAdmin({ data }: { data: AddAdminsByGroup }): Generator {
    return yield axiosClient.post(API.ACCOUNT, data).then((res) => res.data);
}

export function* deleteAdmin({ id, params }: { id: string; params?: AdminParams }): Generator {
    return yield axiosClient.delete(`${API.ACCOUNT}/${id}`, { params }).then((res) => res.data);
}
