import { FFormModal } from '@shared/components/FtcModal';
import EditForm from './EditForm';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onCanceled: () => void;
    data?: AuthClientData[];
    meta: {
        selectedId: string;
        submitLoading: boolean;
    };
};

function EditModal({ isOpen, toggle, onClosed, onCanceled, data, meta }: Props) {
    const { selectedId } = meta;
    const selectedItem = data?.find(({ id }) => id === selectedId);

    return (
        <FFormModal
            header="Edit User"
            backdrop="static"
            size="lg"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={() => {
                onClosed();
            }}
        >
            <FFormModal.ModalHeader toggle={toggle}>{`Edit Client ${selectedItem?.alias}`}</FFormModal.ModalHeader>
            <FFormModal.ModalBody>
                <EditForm
                    data={{
                        clients: data,
                        client: selectedItem,
                    }}
                    onCanceled={onCanceled}
                    meta={meta}
                />
            </FFormModal.ModalBody>
        </FFormModal>
    );
}

export default EditModal;
