import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createCreateGenericSlice } from '../createCreateSlice';

interface InitialState extends GenericState<UserGroup | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const createUserGroupSlice = createCreateGenericSlice({
    name: 'userGroup',
    initialState: initialState as InitialState,
    reducers: {
        createRequest: (
            state,
            action: PayloadAction<{
                data: CreateUserGroupData;
                users: string[];
                deniedApps: string[];
                allowedApps: string[];
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        createSuccess: (state, action: PayloadAction<{ data: UserGroup }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { createRequest, createSuccess, createFailure, cancelCreate } = createUserGroupSlice.actions;

export default createUserGroupSlice.reducer;
