import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Account } from '@services/api/accounts';
import type { RootState } from '@store/index';

interface AccountsState extends GenericState<Account | null> {}

const initialState: AccountsState = {
    loading: false,
    data: null,
    error: null,
};

const accountsSlice = createGenericSlice({
    name: 'accounts',
    initialState: initialState as AccountsState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Account }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = accountsSlice.actions;

export const selectAccounts = createSelector(
    (state: RootState) => state.accounts.data,
    (data) => data ?? { accounts: [] }
);

export const selectAccountInfo = createSelector(
    (state: RootState) => state.accounts.data,
    (data) => (data ? data.account_info : null)
);

export const selectAccountList = createSelector(
    (state: RootState) => state.accounts.data,
    (data) => (data ? data.accounts ?? [] : [])
    // below is mock data for testing
    /* (data) => {
        const accountList = [];
        for (let i = 0; i <= 1000; i++) {
            accountList.push({
                account_company: Math.random().toString(36).slice(2, 7),
                account_email: 'ftcmanualtest2@hotmail.com',
                account_id: i,
                all_assets_access: true,
                iam_account_name: null,
                iam_user_name: null,
                user_type: 'Master',
                master_user_name: 'Solutions QA',
                portals: [],
                role_name: null,
                support_menu: [],
                user_auth_passed: true,
                user_auth_url: null,
                user_display_info: [
                    {
                        name: 'Account',
                        value: '1257532/Fortinet_飞度',
                    },
                    {
                        name: 'Username',
                        value: 'ftcmanualtest2@hotmail.com',
                    },
                ],
                user_email: null,
                user_group: null,
                user_id: -1,
                user_menu: [
                    {
                        item_id: 10205,
                        visibility: 'ShowEnabled',
                    },
                ],
                user_name: null,
                user_type: 'Master',
            });
        }

        return accountList;
    }
    */
);

export const selectFilteredAccountList = createSelector(selectAccountList, (accountList) => {
    const filteredAccounts = accountList.filter((account) => account.account_id !== 0 && !account.is_super_account);
    const masterAccountIds = filteredAccounts
        .filter((account) => account.user_id === -1)
        .map((account) => account.account_id);
    return filteredAccounts.filter(
        (account) => account.user_id === -1 || !masterAccountIds.includes(account.account_id)
    );
});

export const selectOrgList = createSelector(
    (state: RootState) => state.accounts.data,
    (data) => data?.orgs || []
);

// access_from in organization data store the account information
export const selectAccessFrom = createSelector(selectOrgList, (data) => data?.[0]?.access_from || null);

export default accountsSlice.reducer;
