import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    getAdmins,
    getAdminGroups,
    postAdminGroup,
    deleteAdmin,
    deleteAdminGroup,
    postAdmin,
    putAdminGroup,
} from '@services/api/admins';
import { deletePermission, getPermissions, postPermission } from '@services/api/realmPermissions';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAdminGroupsPermissions,
    loadSuccess as loadAdminGroupsPermissionsSuccess,
    loadFailure as loadAdminGroupsPermissionsFailure,
    cancelLoad as cancelLoadAdminGroupsPermissions,
} from '@store/modules/admins/adminGroupsPermissionsSlice';
import {
    loadRequest as loadAdminGroups,
    loadSuccess as loadAdminGroupsSuccess,
    loadFailure as loadAdminGroupsFailure,
    cancelLoad as cancelLoadAdminGroups,
} from '@store/modules/admins/adminGroupsSlice';
import {
    loadRequest as loadAdminsByGroup,
    loadSuccess as loadAdminsByGroupSuccess,
    loadFailure as loadAdminsByGroupFailure,
    cancelLoad as cancelLoadAdminsByGroup,
} from '@store/modules/admins/adminsByGroupSlice';
import {
    loadRequest as loadAdmins,
    loadSuccess as loadAdminsSuccess,
    loadFailure as loadAdminsFailure,
    cancelLoad as cancelLoadAdmins,
} from '@store/modules/admins/adminsSlice';
import {
    createRequest as createAdminGroup,
    createSuccess as createAdminGroupSuccess,
    createFailure as createAdminGroupFailure,
    cancelCreate as cancelCreateAdminGroup,
} from '@store/modules/admins/createAdminGroupSlice';
import {
    deleteRequest as deleteAdminByGroup,
    deleteSuccess as deleteAdminByGroupSuccess,
    deleteFailure as deleteAdminByGroupFailure,
    cancelDelete as cancelDeleteAdminByGroup,
} from '@store/modules/admins/deleteAdminByGroupSlice';
import {
    deleteRequest as deleteAdminGroups,
    deleteSuccess as deleteAdminGroupsSuccess,
    deleteFailure as deleteAdminGroupsFailure,
    cancelDelete as cancelDeleteAdminGroups,
} from '@store/modules/admins/deleteAdminGroupsSlice';
import {
    deleteRequest as deleteRealmByAdminGroup,
    deleteSuccess as deleteRealmByAdminGroupSuccess,
    deleteFailure as deleteRealmByAdminGroupFailure,
    cancelDelete as cancelDeleteRealmByAdminGroup,
} from '@store/modules/admins/deleteRelamByAdminGroupSlice';
import {
    loadRequest as loadRealmsByGroup,
    loadSuccess as loadRealmsByGroupSuccess,
    loadFailure as loadRealmsByGroupFailure,
    cancelLoad as cancelLoadRealmsByGroup,
} from '@store/modules/admins/realmsByAdminGroupSlice';
import {
    updateRequest as updateAdminGroup,
    updateSuccess as updateAdminGroupSuccess,
    updateFailure as updateAdminGroupFailure,
    cancelUpdate as cancelUpdateAdminGroup,
} from '@store/modules/admins/updateAdminGroupSlice';
import {
    updateRequest as updateAdminsByGroup,
    updateFailure as updateAdminsByGroupFailure,
    updateSuccess as updateAdminsByGroupSuccess,
    cancelUpdate as cancelUpdateAdminsByGroup,
} from './updateAdminsByGroupSlice';
import {
    updateRequest as updateRealmsByAdminGroup,
    updateFailure as updateRealmsByAdminGroupFailure,
    updateSuccess as updateRealmsByAdminGroupSuccess,
    cancelUpdate as cancelUpdateRealmsByAdminGroup,
} from './updateRealmsByAdminGroupSlice';

export function* fetchAdminGroups() {
    try {
        const { response, cancel }: { response: AdminGroup[]; cancel: any } = yield race({
            response: call(getAdminGroups),
            cancel: take(cancelLoadAdminGroups),
        });

        if (cancel) {
            return;
        }
        yield put(loadAdminGroupsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAdminGroupsFailure(e.message));
    }
}

export function* createAdminGroupRequest(action: ReturnType<typeof createAdminGroup>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: AdminGroup[]; cancel: any } = yield race({
            response: call(postAdminGroup, { data }),
            cancel: take(cancelCreateAdminGroup),
        });

        if (cancel) {
            return;
        }

        yield put(createAdminGroupSuccess({ data: response }));
        //  load initial data
        yield put(loadAdminGroups());
        toast.success('Admin Group Created');
    } catch (e) {
        const msg = e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(createAdminGroupFailure(msg));
    }
}

export function* updateAdminGroupRequest(action: ReturnType<typeof updateAdminGroup>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: AdminGroup; cancel: any } = yield race({
            response: call(putAdminGroup, { id, data }),
            cancel: take(cancelUpdateAdminGroup),
        });

        if (cancel) {
            return;
        }

        yield put(updateAdminGroupSuccess({ data: response }));
        //  load initial data
        toast.success('Updates Saved');
    } catch (e) {
        yield put(updateAdminGroupFailure(e?.response?.data?.error_message));
        showErrorAlert(e);
    }
}

export function* deleteAdminGroupsRequest(action: ReturnType<typeof deleteAdminGroups>) {
    const { ids } = action.payload;

    const deleteCalls = ids.map((id) => call(deleteAdminGroup, { id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteAdminGroups),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteAdminGroupsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadAdminGroups());
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteAdminGroupsFailure(e?.response?.data));
    }
}

export function* fetchAdmins(action: ReturnType<typeof loadAdmins>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Admin[]; cancel: any } = yield race({
            response: call(getAdmins, { params }),
            cancel: take(cancelLoadAdmins),
        });

        if (cancel) {
            return;
        }
        yield put(loadAdminsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAdminsFailure(e.message));
    }
}

export type AdminGroupsPermissionsResponse = {
    admins: Admin[];
    realms: Realm[];
};

export function* fetchAdminGroupsPermissions(action: ReturnType<typeof loadAdminGroupsPermissions>) {
    const { groupParams, permissionParams } = action.payload;
    try {
        const { response, cancel }: { response: AdminGroupsPermissionsResponse; cancel: any } = yield race({
            response: all({
                admins: call(getAdmins, { params: groupParams }),
                realms: call(getPermissions, { params: permissionParams }),
            }),
            cancel: take(cancelLoadAdminGroupsPermissions),
        });

        if (cancel) {
            return;
        }
        yield put(loadAdminGroupsPermissionsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAdminGroupsPermissionsFailure(e.message));
    }
}

export function* deleteAdminByGroupRequest(action: ReturnType<typeof deleteAdminByGroup>) {
    const { id, params } = action.payload;
    const { group_id } = params;

    try {
        const { cancel }: { cancel: any } = yield race({
            response: call(deleteAdmin, { id, params }),
            cancel: take(cancelDeleteAdminByGroup),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteAdminByGroupSuccess({ data: 'success' }));

        //  load initial data
        yield put(
            loadAdminGroupsPermissions({
                groupParams: { group_id: group_id },
                permissionParams: { account_group_id: group_id },
            })
        );
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteAdminByGroupFailure(e?.response?.data));
    }
}

// for api design
export type AdminsByGroupResponse = {
    adminsInGroup: Admin[];
    adminsNotInGroup: AdminNotInGroup[];
};

export function* fetchAdminsByGroup(action: ReturnType<typeof loadAdminsByGroup>) {
    const { inGroupParams, notInGroupParams } = action.payload;
    try {
        const { response, cancel }: { response: AdminsByGroupResponse; cancel: any } = yield race({
            response: all({
                adminsInGroup: call(getAdmins, { params: inGroupParams }),
                adminsNotInGroup: call(getAdmins, { params: notInGroupParams }),
            }),
            cancel: take(cancelLoadAdminsByGroup),
        });

        if (cancel) {
            return;
        }
        yield put(loadAdminsByGroupSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAdminsByGroupFailure(e.message));
    }
}

export function* updateAdminsByGroupRequest(action: ReturnType<typeof updateAdminsByGroup>) {
    const {
        id,
        data: { addAdmins, removedIds },
    } = action.payload;

    // Two types of call for api design
    // 2. post: add admins
    // 3. delete: remove admins

    const groupAdminCalls: any[] = [];
    if (addAdmins.length > 0) {
        addAdmins.forEach((admin) => groupAdminCalls.push(call(postAdmin, { data: admin })));
    }

    if (removedIds.length > 0) {
        removedIds.forEach((removeId) =>
            groupAdminCalls.push(call(deleteAdmin, { id: removeId, params: { group_id: id } }))
        );
    }

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: all(groupAdminCalls),
            cancel: take(cancelUpdateAdminsByGroup),
        });

        if (cancel) {
            return;
        }

        yield put(updateAdminsByGroupSuccess({ data: response }));
        yield put(
            loadAdminGroupsPermissions({ groupParams: { group_id: id }, permissionParams: { account_group_id: id } })
        );
        const count = groupAdminCalls.length;
        toast.success(`${count} Success`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateAdminsByGroupFailure(errMsg));
        showErrorAlert(e);
    }
}

// for api design
export type RealmsByGroupResponse = {
    realmsInGroup: Realm[];
    realmsNotInGroup: Realm[];
};

export function* fetchRealmsByGroup(action: ReturnType<typeof loadRealmsByGroup>) {
    const { inGroupParams, notInGroupParams } = action.payload;
    try {
        const { response, cancel }: { response: RealmsByGroupResponse; cancel: any } = yield race({
            response: all({
                realmsInGroup: call(getPermissions, { params: inGroupParams }),
                realmsNotInGroup: call(getPermissions, { params: notInGroupParams }),
            }),
            cancel: take(cancelLoadRealmsByGroup),
        });

        if (cancel) {
            return;
        }
        yield put(loadRealmsByGroupSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmsByGroupFailure(e.message));
    }
}

export function* updateRealmsByAdminGroupRequest(action: ReturnType<typeof updateRealmsByAdminGroup>) {
    const {
        id,
        data: { addRealms, removedIds },
    } = action.payload;

    // Two types of call for api design
    // 2. post: add realms
    // 3. delete: remove realms

    const groupRealmsCalls: any[] = [];
    if (addRealms.length > 0) {
        addRealms.forEach((realm) => groupRealmsCalls.push(call(postPermission, { data: realm })));
    }

    if (removedIds.length > 0) {
        removedIds.forEach((removeId) => groupRealmsCalls.push(call(deletePermission, { id: removeId })));
    }

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: all(groupRealmsCalls),
            cancel: take(cancelUpdateRealmsByAdminGroup),
        });

        if (cancel) {
            return;
        }

        yield put(updateRealmsByAdminGroupSuccess({ data: response }));
        yield put(
            loadAdminGroupsPermissions({ groupParams: { group_id: id }, permissionParams: { account_group_id: id } })
        );
        const count = groupRealmsCalls.length;
        toast.success(`${count} Success`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateRealmsByAdminGroupFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteRealmByAdminGroupRequest(action: ReturnType<typeof deleteRealmByAdminGroup>) {
    const { id, params } = action.payload;
    const { group_id } = params;
    //ftc-sso.fortinet.com/api/v1/permission/312ce737-825f-4bfb-b2c6-964b20fa6098
    try {
        const { cancel }: { cancel: any } = yield race({
            response: call(deletePermission, { id }),
            cancel: take(cancelDeleteRealmByAdminGroup),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteRealmByAdminGroupSuccess({ data: 'success' }));

        //  load initial data
        yield put(
            loadAdminGroupsPermissions({
                groupParams: { group_id: group_id },
                permissionParams: { account_group_id: group_id },
            })
        );
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteRealmByAdminGroupFailure(e?.response?.data));
    }
}

export function* fetchAdminGroupsSaga() {
    yield takeLatest(loadAdminGroups, fetchAdminGroups);
}

export function* createAdminGroupSaga() {
    yield takeLatest(createAdminGroup, createAdminGroupRequest);
}

export function* updateAdminGroupSaga() {
    yield takeLatest(updateAdminGroup, updateAdminGroupRequest);
}

export function* deleteAdminGroupsSaga() {
    yield takeLatest(deleteAdminGroups, deleteAdminGroupsRequest);
}

export function* fetchAdminsSaga() {
    yield takeLatest(loadAdmins, fetchAdmins);
}

export function* fetchAdminGroupsPermissionsSaga() {
    yield takeLatest(loadAdminGroupsPermissions, fetchAdminGroupsPermissions);
}

export function* deleteAdminByGroupSaga() {
    yield takeLatest(deleteAdminByGroup, deleteAdminByGroupRequest);
}

export function* fetchAdminsByGroupSaga() {
    yield takeLatest(loadAdminsByGroup, fetchAdminsByGroup);
}

export function* updateAdminsByGroupSaga() {
    yield takeLatest(updateAdminsByGroup, updateAdminsByGroupRequest);
}

export function* fetchRealmsByGroupSaga() {
    yield takeLatest(loadRealmsByGroup, fetchRealmsByGroup);
}

export function* updateRealmsByAdminGroupSaga() {
    yield takeLatest(updateRealmsByAdminGroup, updateRealmsByAdminGroupRequest);
}

export function* deleteRealmByAdminGroupSaga() {
    yield takeLatest(deleteRealmByAdminGroup, deleteRealmByAdminGroupRequest);
}
