import { PropsWithChildren } from 'react';
import { useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/modules/auth/authSlice';
import { ADMIN_ROLE_MAP } from './helper';

/*
    permission: used for admin portal only, to check admin portal account admin role permission
    customerAdmin:  used for regular portal only to limit sub_admin access permission
*/
function Permissions({
    permission,
    customerAdminRequried,
    children,
}: PropsWithChildren<{ permission: keyof typeof ADMIN_ROLE_MAP; customerAdminRequried?: boolean }>) {
    const adminRole = useAppSelector((state) => state.auth.user?.admin_role);
    const authUser = useAppSelector(selectAuthUser);
    const isAdmin = authUser.is_admin;
    const isAdminSite = process.env.REACT_APP_WEBSITE === 'admin';

    // not admin site
    if (!isAdminSite) {
        // check is global admin or not
        if (customerAdminRequried && !isAdmin) {
            return <></>;
        }
        return <>{children}</>;
    }

    // not one of admin role or permission not enough
    if (!adminRole || ADMIN_ROLE_MAP[permission] < adminRole) {
        return <></>;
    }

    return <>{children}</>;
}

export default Permissions;
