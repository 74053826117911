import { useField } from 'formik';
import { classnames } from '@shared/utils/classnames';

type FormikFieldTextareaProps = { [x: string]: any; name: string };
type FTextareaCustom = {
    append?: string | JSX.Element | null;
    serverErrors?: string | string[] | null;
    appendType?: 'text' | 'button' | null;
};
type TFTextarea = FTextareaCustom & FormikFieldTextareaProps;

const FTextarea = ({
    append = null,
    appendType = 'text',
    serverErrors = null,
    validate = undefined,
    ...props
}: TFTextarea) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField({ ...props, validate });
    const errorExists = meta.error || serverErrors;

    return (
        <>
            <div className="input-group">
                <textarea
                    className={classnames(['form-control', `${errorExists ? 'is-invalid' : ''}`])}
                    {...field}
                    {...props}
                    aria-describedby={`${props.name}-addon`}
                />
                {append && (
                    <div className="input-group-append">
                        <span
                            className={`${appendType === 'text' ? 'input-group-text' : ''}`}
                            id={`${props.name}-addon`}
                        >
                            {append}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};

export { FTextarea };
export type { TFTextarea };
