export function formatUserGroup(element: UserGroup, realmMap: Record<string, Realm>): UserGroupData {
    const { id, name, description, realm_id, user_count } = element;

    return {
        id: id,
        name: name,
        description: description || '',
        realmId: realm_id,
        realmName: realmMap[realm_id]?.name ?? '',
        userCount: user_count,
    };
}

export function formatUserInGroup(element: UserWithGroup): UserWithGroupData {
    const { id, group_id, user_id, realm_id, group_name, user_email, username } = element;

    return {
        id: id,
        mappingId: id,
        groupId: group_id,
        userId: user_id,
        realmId: realm_id,
        groupName: group_name,
        username: username,
        userEmail: user_email,
    };
}

export function formatApplication(element: Application): ApplicationData {
    const { id, name, access, realm_id, user_sources } = element;

    return {
        id: id,
        name: name,
        permission: access,
        realmId: realm_id,
        userSources: user_sources,
    };
}

export function formatApplicationPermission(
    app: Application,
    userGroupPermission?: UserGroupPermission
): ApplicationPermissionData {
    return {
        id: app.id,
        appId: app.id,
        appName: app.name,
        defaultPermission: app.access,
        groupPermission: userGroupPermission?.find((item) => item.application_id === app.id)?.access ?? null,
        userSources: app.user_sources,
        realmId: app.realm_id,
    };
}
