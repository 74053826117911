import validator from 'validator';

/** sep, radix, bitPerField, fields */
const IP_INFO: Record<number, [string, number, number, number]> = {
    4: ['.', 10, 8, 4],
    6: [':', 16, 16, 8],
};

/** convert IP(v4 or v6) to number(BigInt) */
export function convertIPToBigInt(value: string, version: validator.IPVersion = 4) {
    const [sep, radix, bitPerField, fields] = IP_INFO[Number(version)];
    const ip =
        Number(version) === 6 && value.includes('::')
            ? value
                  // 1. replace `::` with `:${synbol}:`
                  .replace('::', ':_:')
                  // 2. separate other fields
                  .split(sep)
                  // 3. add the shortened all-zero fields back
                  .map((d, idx, arr) =>
                      d === '_'
                          ? Array(fields - arr.length + 1)
                                .fill('0')
                                .join(sep)
                          : d
                  )
                  // 4. replace empty field by 0
                  .map((d) => (d === '' ? '0' : d))
                  .join(sep)
            : value;
    const result = ip
        .split(sep)
        .map((d) => BigInt(parseInt(isNaN(parseInt(d, radix)) ? '0' : d, radix)))
        .reduce((cur, next) => (cur << BigInt(bitPerField)) + next, BigInt(0));
    // // for debug
    // console.log('result in radix 2:', result.toString(2));

    return result;
}

/** convert number(BigInt) to IP(v4 or v6) */
export function convertBigIntToIP(value: bigint, version: validator.IPVersion = 4) {
    const [sep, radix, bitPerField, fields] = IP_INFO[Number(version)];
    const result = Array(fields)
        .fill(1)
        // get value of each segment
        .map((d, index) => (value >> BigInt(bitPerField * index)) & BigInt(2 ** bitPerField - 1))
        // convert to decimal/hex string based on ip version
        .map((d) => d.toString(radix))
        .reverse()
        .join(sep);
    // // for debug
    // console.log('result in radix 2:', result.toString(2));

    return result;
}

/** convert CIDR notation to ip-range (a.b.c.d/m -> [Start-IP, End-IP]) */
export function convertCIDRToIPRange(value: string, version: validator.IPVersion = 4) {
    // console.log('CIDR notaion:', value);
    const [, , bitPerField, fields] = IP_INFO[Number(version)];
    const maxMaskNum = bitPerField * fields;
    const [address, mask] = value.split('/');
    const maskNum = parseInt(mask, 10);

    if (!mask || isNaN(maskNum) || maskNum < 0 || maskNum > maxMaskNum) {
        throw new Error('IP range CIDR invalid');
    }

    // 0. convert ip address from string to bits in number(BigInt)
    const addressNum = convertIPToBigInt(address, version);
    // 1. get netmask
    const netmask = BigInt(BigInt('0b0' + '1'.repeat(maskNum) + '0'.repeat(bitPerField * fields - maskNum)));
    // 2. get start ip
    const startIp = convertBigIntToIP(addressNum & netmask, version);
    // 3. get end ip
    const endIp = convertBigIntToIP(addressNum | BigInt('0b0' + '1'.repeat(bitPerField * fields - maskNum)), version);
    // // for debug
    // console.log('[Start IP]-[End IP]:', `${startIp}-${endIp}`);

    return [startIp, endIp];
}

/** IPv4 only, convert CIDR notation to ip-range (a.b.c.d/m -> [IP, Netmask]) */
export function convertCIDRToIPSubnet(value: string) {
    console.log('CIDR notaion:', value);
    const [, , bitPerField, fields] = IP_INFO[4];
    const [address, mask] = value.split('/');
    const maskNum = parseInt(mask, 10);

    if (!mask || isNaN(maskNum)) {
        throw new Error('Invalid CIDR string');
    }

    // 0. convert ip address from string to bits in number(BigInt)
    const addressNum = convertIPToBigInt(address);
    // 1. get netmask
    const netmask = BigInt(BigInt('0b0' + '1'.repeat(maskNum) + '0'.repeat(bitPerField * fields - maskNum)));
    // 2. get start ip
    const startIp = convertBigIntToIP(addressNum & netmask);
    // 3. netmask(bigint) to metmask string
    const netmaskStr = convertBigIntToIP(netmask, 4);
    // // for debug
    // console.log('[IP]/[Netmask]:', `${address}/${netmaskStr}`);

    return [startIp, netmaskStr];
}
