import { createSelector } from '@reduxjs/toolkit';
import { UsersRefParams } from '@services/api/users';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<User[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const fortiProductClientUsersSlice = createGenericSlice({
    name: 'fortiProductClientUsers',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsersRefParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: User[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = fortiProductClientUsersSlice.actions;

export const selectClientUsers = createSelector(
    (state: RootState) => state.fortiProductClientUsers.data,
    (data) => data ?? []
);

export default fortiProductClientUsersSlice.reducer;
