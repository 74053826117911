import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { PreviewTemplateResponse } from '@services/api/templates';
import { RootState } from '@store/index';
import { GenericState, creatUpdateGenericSlice } from '@store/modules/updateGenericSlice';

interface InitialState extends GenericState<PreviewTemplateResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateRealmTemplatePreviewSlice = creatUpdateGenericSlice({
    name: 'realmTemplatePreview',
    initialState: initialState as InitialState,
    reducers: {
        updateRequest: (state, action: PayloadAction<{ data: UpdateRealmTemplatePreviewData }>) => {
            state.loading = true;
        },
        updateSuccess: (state, action: PayloadAction<{ data: PreviewTemplateResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const selectTemplatePreview = createSelector(
    (state: RootState) => state.updateRealmTemplatePreview.data,
    (data) => data
);

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } = updateRealmTemplatePreviewSlice.actions;

export default updateRealmTemplatePreviewSlice.reducer;
