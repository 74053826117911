import { TableColumn } from 'react-data-table-component';
import { createPortal } from 'react-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import { Icon } from '@shared/components/Icon';
import Permissions from '@shared/components/Permissions';

export const signingCertColumns = (handleEdit: Function, handleDetails: Function, handleDelete: Function) =>
    [
        {
            name: 'Name',
            selector: (row, rowIndex) => (
                <div className="d-flex justify-content-between gap-1">
                    {row.name}
                    {new Date() > new Date(row.expire_at + 'Z') && (
                        <>
                            <div id={`uncontrolledTooltipMessage-name-${rowIndex}`} className="text-warning">
                                <Icon classNames={['material-symbols-outlined']} content="info" />
                            </div>
                            <UncontrolledTooltip placement="top" target={`uncontrolledTooltipMessage-name-${rowIndex}`}>
                                Certificate has expired
                            </UncontrolledTooltip>
                        </>
                    )}
                </div>
            ),
            sortable: true,
            width: '100px',
        },
        {
            name: 'Subject Name',
            selector: (row, rowIndex) => (
                <>
                    <span id={`uncontrolledTooltipMessage-${rowIndex}`} className="text-truncate">
                        {row.subject}
                    </span>
                    <UncontrolledTooltip placement="top" target={`uncontrolledTooltipMessage-${rowIndex}`}>
                        {row.subject}
                    </UncontrolledTooltip>
                </>
            ),
        },
        {
            name: 'Validity',
            selector: (row) => row.expire_at,
            sortable: true,
        },
        {
            cell: (row) => (
                <UncontrolledDropdown>
                    <DropdownToggle color="link" className="btn-icon-only" caret={false}>
                        <Icon classNames={['material-symbols-outlined', 'bold']} content="more_vert" />
                    </DropdownToggle>
                    <>
                        {createPortal(
                            <DropdownMenu className="action-dropdown-menu" end={true}>
                                <DropdownItem
                                    className="btn btn-link text-start"
                                    onClick={() => handleEdit({ id: row.id })}
                                >
                                    Edit
                                </DropdownItem>
                                <DropdownItem divider className="transparent" />
                                <DropdownItem
                                    className="btn btn-link text-start"
                                    onClick={() => handleDetails({ id: row.id })}
                                >
                                    Details
                                </DropdownItem>
                                <Permissions permission="super_admin" customerAdminRequried>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        className="btn btn-link btn-link-danger text-start"
                                        onClick={() => handleDelete({ id: row.id })}
                                    >
                                        Delete
                                    </DropdownItem>
                                </Permissions>
                            </DropdownMenu>,
                            document.body
                        )}
                    </>
                </UncontrolledDropdown>
            ),
            width: '50px',
        },
    ] as TableColumn<SigningCert>[];
