import { PayloadAction } from '@reduxjs/toolkit';
import { UpdateRealmsResponse } from './watcher';
import { GenericState, creatUpdateGenericSlice } from '../updateGenericSlice';

interface InitialState extends GenericState<UpdateRealmsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateRealmSlice = creatUpdateGenericSlice({
    name: 'realm',
    initialState: initialState as InitialState,
    reducers: {
        updateRequest: (
            state,
            action: PayloadAction<{
                data: UpdateRealmData;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state, action: PayloadAction<{ data: UpdateRealmsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } = updateRealmSlice.actions;

export default updateRealmSlice.reducer;
