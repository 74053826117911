import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UserState extends GenericState<UserExtension | null> {}

const initialState: UserState = {
    loading: false,
    data: null,
    error: null,
};

const userExtensionSlice = createGenericSlice({
    name: 'userExtension',
    initialState: initialState as UserState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserExtension | null }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userExtensionSlice.actions;

export const selectUserExtension = createSelector(
    (state: RootState) => state.userExtension.data,
    (data) => data
);

export default userExtensionSlice.reducer;
