import { toast } from 'react-toastify';
import { SUPPORT_LINK } from './constants';
import { safeTagsReplace } from './safeTagsReplace';

export function showErrorAlert(error: any, contact = true) {
    let errMsg = error?.response?.data?.error_message ?? error?.response?.data;
    if (error?.error instanceof ProgressEvent) {
        errMsg = 'Network Error.';
    }
    if (!errMsg || typeof errMsg !== 'string') {
        errMsg = 'Unknown Error';
    }
    errMsg = safeTagsReplace(errMsg);
    const content = (
        <>
            <div>
                {!!error?.response?.status && (
                    <h5>
                        {error.response.statusText
                            ? error.response.status + ':' + error.response.statusText
                            : 'Unknown'}
                    </h5>
                )}
                <p>{errMsg}</p>
                {contact && (
                    <p>
                        Refresh the page and try again. If the problem persists, please contact our {''}
                        <a href={SUPPORT_LINK} target="_blank" rel="noopener noreferrer">
                            customer support
                        </a>{' '}
                        for help.
                    </p>
                )}
            </div>
        </>
    );
    toast.error(content);
}
