import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { RealmSettingResponse } from './watcher';
import { GenericState, createGenericSlice } from '../../genericSlice';

interface InitialState extends GenericState<RealmSettingResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const realmSettingSlice = createGenericSlice({
    name: 'realmSetting',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmSettingResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const selectRealmSetting = createSelector(
    (state: RootState) => state.realmSetting.data?.realmSetting,
    (data) => data
);

export const selectRealmId = createSelector(
    (state: RootState) => state.realmSetting.data?.selectedId,
    (data) => data
);

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmSettingSlice.actions;

export default realmSettingSlice.reducer;
