import { toast } from 'react-toastify';
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { deleteClient, getClients, postClient, putClient } from '@services/api/clients';
import { ProfileResponse, getProfiles } from '@services/api/profiles';
import { getRealms } from '@services/api/realms';
import { ResourcesCountResponse, getResourceCount } from '@services/api/resources';
import { getUsersRef } from '@services/api/users';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteClients,
    deleteSuccess as deleteClientsSuccess,
    deleteFailure as deleteClientsFailure,
    cancelDelete as cancelDeleteClients,
} from './deleteWebAppClientsSlice';
import {
    updateRequest as updateSecretClient,
    updateSuccess as updateSecretClientSuccess,
    updateFailure as updateSecretClientFailure,
    cancelUpdate as cancelUpdateSecretClient,
} from './updateSecretWebAppClientSlice';
import {
    upsertRequest as upsertClient,
    upsertSuccess as upsertClientSuccess,
    upsertFailure as updateClientFailure,
    cancelUpsert as cancelUpsertClient,
} from './upsertWebAppClientSlice';
import {
    loadRequest as loadClients,
    loadSuccess as loadClientsSuccess,
    loadFailure as loadClientsFailure,
    cancelLoad as cancelLoadClients,
} from './webAppClientsSlice';
import {
    loadRequest as loadUsers,
    loadSuccess as loadUsersSuccess,
    loadFailure as loadUsersFailure,
    cancelLoad as cancelLoadUsers,
} from './webAppUsersSlice';

export type WebAppsClientsResponse = {
    clients: Client[];
    realms: Realm[];
    resourcesCount: ResourcesCountResponse;
    profiles: ProfileResponse;
};

const clientsParams = {
    params: { clients: { type: 'GenericApp' }, resourcesCount: { resource: 'client' } },
};

const RETRY_TIME = 15 * 1000;

export function* fetchWebAppClients(action: ReturnType<typeof loadClients>) {
    const {
        params: { clients, resourcesCount },
    } = action.payload;
    try {
        const { response, cancel }: { response: WebAppsClientsResponse; cancel: any } = yield race({
            response: all({
                clients: call(getClients, { params: clients }),
                realms: call(getRealms),
                resourcesCount: call(getResourceCount, { params: resourcesCount }),
                profiles: call(getProfiles),
            }),
            cancel: take(cancelLoadClients),
        });

        if (cancel) {
            return;
        }

        yield put(loadClientsSuccess({ data: response }));

        const { clients: clientsResponse } = response;
        const shouldRetry = clientsResponse.find(({ task_id }) => !!task_id);

        while (shouldRetry) {
            yield delay(RETRY_TIME);
            yield put(loadClients(clientsParams));
        }

        yield put(loadClientsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadClientsFailure(e.message));
    }
}

export function* fetchWebAppUsers(action: ReturnType<typeof loadUsers>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: call(getUsersRef, { params }),
            cancel: take(cancelLoadUsers),
        });

        if (cancel) {
            return;
        }

        yield put(loadUsersSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadUsersFailure(e.message));
    }
}

export function* upsertWebAppClient(action: ReturnType<typeof upsertClient>) {
    const { id, data } = action.payload;

    // Only edit mode has id
    const upsertCall = id ? call(putClient, { id, data }) : call(postClient, { data });

    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertClient),
        });

        if (cancel) {
            return;
        }

        yield put(upsertClientSuccess({ data: response }));

        // load initial data
        yield put(loadClients(clientsParams));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = e?.response?.data;
        yield put(updateClientFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteWebAppClients(action: ReturnType<typeof deleteClients>) {
    const { ids } = action.payload;

    const deleteClientCalls = ids.map((id) => call(deleteClient, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteClientCalls),
            cancel: take(cancelDeleteClients),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteClientsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadClients(clientsParams));
        toast.success('Request Success');
    } catch (e) {
        const errMsg = e?.response?.data?.error_message;
        yield put(deleteClientsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateSecretWebAppClient(action: ReturnType<typeof updateSecretClient>) {
    const { id, data, option } = action.payload;

    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: call(putClient, { id, data }),
            cancel: take(cancelUpdateSecretClient),
        });

        if (cancel) {
            return;
        }

        yield put(updateSecretClientSuccess({ data: { client: response, option } }));

        // load initial data
        yield put(loadClients(clientsParams));
        // option 1 is send email
        if (option === 1) {
            toast.success('Email Sent');
        }
    } catch (e) {
        const errMsg = e?.response?.data?.error_message;
        yield put(updateSecretClientFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchWebAppClientsSaga() {
    yield takeLatest(loadClients, fetchWebAppClients);
}

export function* fetchWebAppUsersSaga() {
    yield takeLatest(loadUsers, fetchWebAppUsers);
}

export function* upsertWebAppClientSaga() {
    yield takeLatest(upsertClient, upsertWebAppClient);
}

export function* deleteWebAppClientsSaga() {
    yield takeLatest(deleteClients, deleteWebAppClients);
}

export function* updateSecretWebAppClientSaga() {
    yield takeLatest(updateSecretClient, updateSecretWebAppClient);
}
