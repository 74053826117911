import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { RootState } from '@store/index';

type DomainMappingResource = {
    realms: Realm[] | null;
    userSources: UserSource[] | null;
    domainMappings: DomainMapping[] | null;
};

type NonNullableDomainMappingResource = {
    realms: NonNullable<DomainMappingResource['realms']>;
    userSources: NonNullable<DomainMappingResource['userSources']>;
    domainMappings: NonNullable<DomainMappingResource['domainMappings']>;
};

interface InitialState extends GenericState<DomainMapping[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const domainMappingsSlice = createGenericSlice({
    name: 'domainMappings',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DomainMapping[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = domainMappingsSlice.actions;

export const selectDomainMappingResources = createSelector(
    (state: RootState) => state.domainMappingResources.data,
    (data) => {
        let nonNullData: NonNullableDomainMappingResource = {
            realms: [],
            userSources: [],
            domainMappings: [],
        };
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                nonNullData[key as keyof DomainMappingResource] = value as any;
            }
        });
        return nonNullData;
    }
);

export default domainMappingsSlice.reducer;
