import { delay, select, takeLatest, call } from 'redux-saga/effects';
import { getHeartbeat } from '@services/api/heartbeat';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import { loadAuthSuccess } from '../auth/authSlice';

function* fetchHeartbeat() {
    try {
        // @ts-ignore
        while (yield select((state) => state.auth?.user)) {
            // @ts-ignore
            const userInfo = yield select((state) => state.auth?.user);
            const isLogined = !!userInfo.account_id;

            // call heartbeat when there is a request then wait 60 secs
            yield delay(60000);
            try {
                if (isLogined) {
                    yield call(getHeartbeat);
                }
            } catch (error) {
                showErrorAlert(error);
            }
        }
    } catch (error) {
        showErrorAlert(error);
    }
}

export function* pollingHeartbeatSaga() {
    yield takeLatest(loadAuthSuccess, fetchHeartbeat);
}
