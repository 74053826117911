import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { createAlertData } from './helper';
import { EventResponse } from './watcher';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<EventResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const eventsSlice = createGenericSlice({
    name: 'events',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: { brief: boolean } }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: EventResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = eventsSlice.actions;

export const selectEventAlerts = createSelector(
    (state: RootState) => {
        const realmMap = state.events.data?.realms.reduce(
            (acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            },
            {} as Record<string, Realm>
        );
        return state.events.data?.alerts
            .map((alert) => createAlertData(alert, realmMap))
            .sort((a: AlertData, b: AlertData) => (new Date(a.sentTime) > new Date(b.sentTime) ? -1 : 1));
    },
    (data) => data ?? []
);

export const selectEventRealms = createSelector(
    (state: RootState) => state.events.data?.realms,
    (data) => data ?? []
);

export const selectEventRealmOptions = createSelector(
    (state: RootState) => {
        const realms = state.events.data?.realms;
        const realmsQuota = state.events.data?.realmQuota?.['realms'];
        // @ts-ignore, this type could be improved
        const realmsQuotaIds: string[] = realmsQuota?.map((item) => item?.realm_id);
        return realms?.filter(({ id }) => realmsQuotaIds.includes(id)).sort((a, b) => a.name.localeCompare(b.name));
    },
    (data) => data ?? []
);

export const selectEventGroups = createSelector(
    (state: RootState) => state.events.data?.groups,
    (data) => data ?? []
);

export default eventsSlice.reducer;
