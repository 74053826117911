import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getSigningCerts, postSigningCert } from '@services/api/cert';
import {
    loadRequest as loadSigningCerts,
    loadSuccess as loadSigningCertsSuccess,
    loadFailure as loadSigningCertsFailure,
    cancelLoad as cancelLoadSigningCerts,
} from './appSigningCertsSlice';
import {
    createRequest as createSigningCert,
    createSuccess as createSigningCertSuccess,
    createFailure as createSigningCertFailure,
    cancelCreate as cancelCreateSigningCert,
} from './createAppSigningCertSlice';

export function* fetchSigningCerts(action: ReturnType<typeof loadSigningCerts>) {
    try {
        const { response, cancel }: { response: SigningCert[]; cancel: any } = yield race({
            response: call(getSigningCerts),
            cancel: take(cancelLoadSigningCerts),
        });

        if (cancel) {
            return;
        }
        yield put(loadSigningCertsSuccess({ data: response }));
    } catch (e) {
        yield put(loadSigningCertsFailure(e.message));
    }
}

export function* createSigningCertRequest(action: ReturnType<typeof createSigningCert>) {
    const { data } = action.payload;
    try {
        const { response, cancel }: { response: SigningCert; cancel: any } = yield race({
            response: call(postSigningCert, { data }),
            cancel: take(cancelCreateSigningCert),
        });

        if (cancel) {
            return;
        }

        yield put(createSigningCertSuccess({ data: response }));

        // load initial data
        yield put(loadSigningCerts());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createSigningCertFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchAppSigningCertsSaga() {
    yield takeLatest(loadSigningCerts, fetchSigningCerts);
}

export function* createAppSigningCertSaga() {
    yield takeLatest(createSigningCert, createSigningCertRequest);
}
