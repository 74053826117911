import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    deleteProfile,
    getProfiles,
    ProfileResponse,
    postProfile,
    putProfile,
    getProfile,
} from '@services/api/profiles';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteProfiles,
    deleteSuccess as deleteProfilesSuccess,
    deleteFailure as deleteProfilesFailure,
    cancelDelete as cancelDeleteProfiles,
} from './deleteProfilesSlice';
import {
    loadRequest as loadProfile,
    loadFailure as loadProfileFailure,
    loadSuccess as loadProfileSuccess,
    cancelLoad as cancelLoadProfile,
} from './profileSlice';
import {
    loadRequest as loadProfiles,
    loadFailure as loadProfilesFailure,
    loadSuccess as loadProfilesSuccess,
    cancelLoad as cancelLoadProfiles,
} from './profilesSlice';
import {
    upsertRequest as upsertProfile,
    upsertFailure as upsertProfileFailure,
    upsertSuccess as upsertProfileSuccess,
    cancelUpsert as cancelUpsertProfile,
} from './upsertProfileSlice';

export function* fetchProfiles() {
    try {
        const { response, cancel }: { response: ProfileResponse; cancel: any } = yield race({
            response: call(getProfiles),
            cancel: take(cancelLoadProfiles),
        });

        if (cancel) {
            return;
        }

        yield put(loadProfilesSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadProfilesFailure(e.message));
    }
}

export function* fetchProfilesSaga() {
    yield takeLatest(loadProfiles, fetchProfiles);
}

export function* upsertProfileRequest(action: ReturnType<typeof upsertProfile>) {
    const { id, data } = action.payload;

    // id only exists for edit mode
    const upsertCall = id ? call(putProfile, { id, data }) : call(postProfile, { data });

    try {
        const { response, cancel }: { response: Profile; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertProfile),
        });

        if (cancel) {
            return;
        }

        yield put(upsertProfileSuccess({ data: response }));
        yield put(loadProfiles());
    } catch (e) {
        showErrorAlert(e);
        yield put(upsertProfileFailure(e?.response?.data));
    }
}

export function* upsertProfileSaga() {
    yield takeLatest(upsertProfile, upsertProfileRequest);
}

export function* deleteProfilesRequest(action: ReturnType<typeof deleteProfiles>) {
    const { ids } = action.payload;

    const deleteProfileCalls = ids.map((id) => call(deleteProfile, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteProfileCalls),
            cancel: take(cancelDeleteProfiles),
        });

        if (cancel) {
            return;
        }

        yield put(deleteProfilesSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadProfiles());
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteProfilesFailure(e?.response?.data?.error_message));
    }
}

export function* deleteProfilesSaga() {
    yield takeLatest(deleteProfiles, deleteProfilesRequest);
}

export function* fetchProfile(action: ReturnType<typeof loadProfile>) {
    const { id } = action.payload;
    try {
        const { response, cancel }: { response: ProfileDetailData; cancel: any } = yield race({
            response: call(getProfile, { id }),
            cancel: take(cancelLoadProfile),
        });

        if (cancel) {
            return;
        }

        yield put(loadProfileSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadProfileFailure(e.message));
    }
}

export function* fetchProfileSaga() {
    yield takeLatest(loadProfile, fetchProfile);
}
