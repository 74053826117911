import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { UserWebauthnResponse } from './watcher';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UserWebauthnCredentialsState extends GenericState<UserWebauthnResponse | null> {}

const initialState: UserWebauthnCredentialsState = {
    loading: false,
    data: null,
    error: null,
};

const userWebauthnCredentialsSlice = createGenericSlice({
    name: 'userWebauthnCredentials',
    initialState: initialState as UserWebauthnCredentialsState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ user_id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserWebauthnResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userWebauthnCredentialsSlice.actions;

export const selectUserWebauthnCredentials = createSelector(
    (state: RootState) => state.userWebauthnCredentials.data,
    (data) => data ?? []
);

export default userWebauthnCredentialsSlice.reducer;
