import { FFormModal } from '@shared/components/FtcModal';
import CreateForm from './CreateForm';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onCanceled: () => void;
    data: SigningCert[];
    meta: {
        submitLoading: boolean;
    };
};
function CreateModal({ isOpen, toggle, onClosed, onCanceled, data, meta }: Props) {
    return (
        <FFormModal keyboard={false} size="lg" backdrop="static" isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <FFormModal.ModalHeader toggle={toggle}>Add Certificate</FFormModal.ModalHeader>
            <FFormModal.ModalBody>
                <CreateForm onCanceled={onCanceled} data={data} meta={{ ...meta }} />
            </FFormModal.ModalBody>
        </FFormModal>
    );
}

export default CreateModal;
