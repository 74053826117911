import axiosClient from '../axiosClient';
import { API } from '../index';

export type ResourcesCountParams = { resource: string; realm_id?: string };

export type ResourcesCountResponse = UserResource | ClientResource | RealmResource | SMSResource;

export function* getResourceCount({ params }: { params: ResourcesCountParams }): Generator {
    return yield axiosClient.get<ResourcesCountResponse>(API.RESOURCE_COUNT, { params }).then((res) => res.data);
}

export function* getBalance(): Generator {
    return yield axiosClient.get<Balance>(API.BALANCE).then((res) => res.data);
}
