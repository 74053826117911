import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface InitialState {
    value: string | null;
}

const initialState: InitialState = {
    value: null,
};

const searchFortiProductClientsSlice = createSlice({
    name: 'fortiProductClients',
    initialState,
    reducers: {
        search: (state, action: PayloadAction<{ value: string }>) => {
            state.value = action.payload.value;
        },
        cleanSearch: (state) => {
            state.value = null;
        },
    },
});

export const { search, cleanSearch } = searchFortiProductClientsSlice.actions;

export const selectClientsSearch = (state: RootState) => state.searchFortiProductClients.value;

export default searchFortiProductClientsSlice.reducer;
