import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface GlobalSettingsState extends GenericState<GlobalSettings | null> {}

const initialState: GlobalSettingsState = {
    loading: false,
    data: null,
    error: null,
};

const globalSettingsSlice = createGenericSlice({
    name: 'globalSettings',
    initialState: initialState as GlobalSettingsState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: GlobalSettings }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;
