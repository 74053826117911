import { DEFAULT_COL } from '@shared/utils/constants';
import { FLabelWrapper } from './FLabelWrapper';
// import { FInput, TFInput } from '../FInput';
import { FTextarea, TFTextarea } from '../FTextarea';

const FLabelTextarea = ({
    label,
    cols = DEFAULT_COL,
    labelStyle = {},
    styles = {},
    cssClasses = [],
    align = 'center',
    alignText = 'end',
    tooltip,
    ...props
}: TFLabelWrapper & TFTextarea) => {
    return (
        <FLabelWrapper
            name={props.name}
            label={label}
            cols={cols}
            align={align}
            cssClasses={cssClasses}
            styles={styles}
            labelStyle={labelStyle}
            alignText={alignText}
            tooltip={tooltip}
        >
            <FTextarea {...props} />
        </FLabelWrapper>
    );
};

export { FLabelTextarea };
