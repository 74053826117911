import { createSelector } from '@reduxjs/toolkit';
import { createTemplate } from './helper';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface TemlatesState extends GenericState<Template[] | null> {}

const initialState: TemlatesState = {
    loading: false,
    data: null,
    error: null,
};

const templatesSlice = createGenericSlice({
    name: 'templates',
    initialState: initialState as TemlatesState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Template[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = templatesSlice.actions;

export const selectAllTemplates = createSelector(
    (state: RootState) => state.templates.data?.map(createTemplate),
    (data) => data ?? []
);

export default templatesSlice.reducer;
