import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getAnnouncement } from '@services/api/announcement';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAnnouncement,
    loadFailure as loadAnnouncementFailure,
    loadSuccess as loadAnnouncementSuccess,
    cancelLoad as cancelLoadAnnouncement,
} from './announcementSlice';

export function* fetchAnnouncement(action: ReturnType<typeof loadAnnouncement>) {
    const { alert } = action.payload;
    try {
        const { response, cancel }: { response: Announcement; cancel: any } = yield race({
            response: call(getAnnouncement, { params: { alert } }),
            cancel: take(cancelLoadAnnouncement),
        });

        if (cancel) {
            return;
        }

        yield put(loadAnnouncementSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAnnouncementFailure(e.message));
    }
}

export function* fetchAnnouncementSaga() {
    yield takeLatest(loadAnnouncement, fetchAnnouncement);
}
