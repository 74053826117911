import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/modules/auth/authSlice';
import { loadLogin } from '@store/modules/auth/loginSlice';
import { AccountSelectionView } from './AccountSelection';

const AccountSelectionContainer = () => {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(selectAuthUser);

    useEffect(() => {
        if (authUser?.admin === true && authUser?.fortinetid) {
            // Admin login process do not have account selection process
            dispatch(loadLogin({ fortinetid: authUser.fortinetid }));
        }
    }, [authUser]);

    return (
        <ErrorBoundary fallback={<p>⚠️Something went wrong, please refresh the page and try again.</p>}>
            {authUser && !authUser?.admin && <AccountSelectionView />}
        </ErrorBoundary>
    );
};

export default AccountSelectionContainer;
