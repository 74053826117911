import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface InitialState extends GenericState<Receiver | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const upsertReceiverSlice = createUpsertGenericSlice({
    name: 'receiver',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id?: string;
                data: UpsertReceiverData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: Receiver }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertReceiverSlice.actions;

export default upsertReceiverSlice.reducer;
