import { createSelector } from '@reduxjs/toolkit';
import { AdminsParams } from '@services/api/admins';
import { formatAdmin } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<Admin[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const adminsSlice = createGenericSlice({
    name: 'admins',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: AdminsParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Admin[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = adminsSlice.actions;

export const selectAdmins = createSelector(
    (state: RootState) => state.admins.data?.map(formatAdmin),
    (data) => data ?? []
);
export default adminsSlice.reducer;
