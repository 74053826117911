import axiosClient from '../axiosClient';
import { API } from '../index';

export type RealmsBriefResponse = RealmBrief[];
export type RealmsBriefParams = { deleted: boolean };

export function* getRealms(): Generator {
    return yield axiosClient.get<Realm[]>(API.SETTING_REALM).then((res) => res.data);
}

export function* getRealm({ id }: { id: string }): Generator {
    return yield axiosClient.get(`${API.SETTING_REALM}/${id}`).then((res) => res.data);
}

export function* putSettingRealm({ id, data }: { id: string; data: UpdateRealmSetting }): Generator {
    return yield axiosClient.put(`${API.SETTING_REALM}/${id}`, data).then((res) => res.data);
}

export function* postRealm({ data }: { data: CreateRealmData }): Generator {
    return yield axiosClient.post(API.REALM, data).then((res) => res.data);
}

export function* putRealm({ id, data }: { id: UpdateRealmData['id']; data: UpdateRealmData['realm'] }): Generator {
    return yield axiosClient.put(`${API.REALM}/${id}`, data).then((res) => res.data);
}

export function* deleteRealm({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.REALM}/${id}`).then((res) => res.data);
}

export function* getRealmsBrief(params: RealmsBriefParams): Generator {
    return yield axiosClient.get<RealmBrief[]>(API.REALM, { params }).then((res) => res.data);
}
