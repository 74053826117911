import { LOG_INF_FAZ } from '@shared/utils/constants';

export function createForwarderData(element: Forwarder): ForwarderData {
    return {
        id: element.id,
        name: element.name,
        address: element.address,
        peercn: element.peercn,
        status: element.status,
        receiverType: element.interface ?? LOG_INF_FAZ,
    };
}
