import { createSelector } from '@reduxjs/toolkit';
import { UsageParams } from '@services/api/usage';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UsageState extends GenericState<Usage[] | null> {}

const initialState: UsageState = {
    loading: false,
    data: null,
    error: null,
};

const usageSummarySlice = createGenericSlice({
    name: 'usageSummary',
    initialState: initialState as UsageState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsageParams }>) => {
            state.loading = true;
            state.data = null;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Usage[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
        cancelLoad: (state) => {
            state.loading = false;
            state.error = null;
            state.data = null;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usageSummarySlice.actions;

export const selectUsageSummary = createSelector(
    (state: RootState) => state.usage.data,
    (data) => {
        if (!data) {
            return 0;
        }
        const usageSum = data.reduce((acc, usage) => acc + usage.summary, 0);
        return Math.round(usageSum * 10) / 10;
    }
);

export default usageSummarySlice.reducer;
