export function formatAuthClient(element: Client): AuthClientData {
    let members = '';
    if (element.members != null) {
        const membersArr = JSON.parse(element.members);
        members = '[' + membersArr.join('-') + ']';
    }

    //0 means no delete status
    let del_status = 0;
    let del_status_text = '';
    if (element.task_id != null) {
        del_status = 1;
        del_status_text = element.task_name + ' pending';
    }

    const update_time = element.updated_at || element.created_at;
    return {
        alias: element.name,
        sn: element.sn == null ? members : [element.sn],
        type: element.type || '',
        vdom: element.vdom == null ? '' : element.vdom,
        realmId: element.realm_id == null ? 'none' : element.realm_id,
        createdAt: element.created_at,
        activationTime:
            element.created_at.indexOf('Z') === -1
                ? new Date(element.created_at + 'Z').toLocaleString()
                : new Date(element.created_at).toLocaleString(),
        id: element.id == null ? '' : element.id,
        name: (element.sn == null ? members : [element.sn]) + '-' + element.vdom,
        select: false,
        deleteStatus: del_status,
        deleteStatusText: del_status_text,
        refs: element.refs,
        clusterId: element.cluster_id == null ? '' : element.cluster_id,
        updatedAt: update_time,
        lastUpdate:
            update_time.indexOf('Z') === -1
                ? new Date(update_time + 'Z').toLocaleString()
                : new Date(update_time).toLocaleString(),
        secret: element.secret,
        profileId: element.profile_id || 'none',
        permission: element.permission || 'none',
        scope: element.scope,
    };
}
