import { AxiosResponse } from 'axios';
import axiosClient from '../axiosClient';
import { API } from '../index';

export type AuthUserResponse = AxiosResponse<AuthUser>;

export function* getAuth(): Generator {
    return yield axiosClient.get<AuthUserResponse>(API.AUTH).then((res) => res);
}

export function* postLogin(data: { h_key: string } | { fortinetid: string }): Generator {
    if ('fortinetid' in data) {
        return yield axiosClient
            .post(API.LOGIN, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                if (res.status === 201 || res.status === 200) {
                    // admin login
                    return res;
                } else {
                    console.log(res);
                }
            });
    }
    if ('h_key' in data) {
        const loginForm = document.createElement('form');
        document.body.appendChild(loginForm);
        const loginFormInput = document.createElement('input');
        Object.entries(data).forEach(([key, value]) => {
            loginFormInput.setAttribute('name', key);
            loginFormInput.setAttribute('value', value);
        });
        loginFormInput.setAttribute('type', 'hidden');
        loginForm.appendChild(loginFormInput);
        loginForm.setAttribute('action', '/login');
        loginForm.setAttribute('method', 'POST');
        loginForm.submit();
        loginForm.remove();
    }

    // @TBD
    /* .catch((error) => {
            if (process.env.REACT_APP_WEBSITE === 'admin') {
                if (error.response.status === 401) {
                    alert(`
                        This information system is the property of Fortinet.
                        Unauthorized or improper use of this system may result in administrative
                        disciplinary action, and/or civil charges/criminal penalties.'
                        By continuing to use the system you indicate your awareness of and consent to these terms and conditions of use.
                        STOP IMMEDIATELY
                        if you do not agree to the conditions stated in this warning.
                `);
                }
            }
            return error;
        }) */
}

export function* getLogout(hkey: string, logoutUrl: string): Generator {
    return yield axiosClient
        .get(API.LOGOUT, {
            validateStatus: (status) => status === 200 || status === 302,
        })
        .then(
            (res) => {
                console.log(res);
            },
            (error) => {
                console.log(error);
            }
        )
        .finally(() => {
            const lououtForm = document.createElement('form');
            document.body.appendChild(lououtForm);
            const lououtFormInput = document.createElement('input');
            lououtFormInput.setAttribute('name', 'h_key');
            lououtFormInput.setAttribute('value', hkey);
            lououtFormInput.setAttribute('type', 'hidden');
            lououtForm.appendChild(lououtFormInput);
            lououtForm.setAttribute('action', logoutUrl);
            lououtForm.setAttribute('method', 'POST');
            lououtForm.submit();
            lououtForm.remove();
        });
}
