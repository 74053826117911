import { TrialParams } from '@services/api/trial';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<Trial | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const trialSlice = createGenericSlice({
    name: 'validateTrial',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: TrialParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Trial }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = trialSlice.actions;

export default trialSlice.reducer;
