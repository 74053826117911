import { ChangeEvent, useState } from 'react';

/**
 * This custom hook filter is used for global search in a table
 */
export function useFilter<T>(data: T[]) {
    const [filterValue, setFilterValue] = useState('');

    const filterAll = (item: any) =>
        Object.values(item)?.find(
            (value: any) => value?.toString()?.toLocaleLowerCase()?.includes(filterValue.toLocaleLowerCase())
        ) !== undefined;

    // for data is from outside of event handler
    const onSearch = ({ value }: { value: string }) => {
        setFilterValue(value);
    };

    const onChange = (e: ChangeEvent) => {
        setFilterValue((e.target as HTMLInputElement).value);
    };

    return { filterValue, setFilterValue: onChange, setSearchValue: onSearch, filtered: data.filter(filterAll) };
}
