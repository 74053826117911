import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as loadDomainMappingsSuccess } from '../domainMapping/domainMappingsSlice';
import { loadSuccess as loadUserSourceRealmsSuccess } from '../userSource/userSourceRealmsSlice';
import { loadSuccess as loadUserSourcesSuccess } from '../userSource/userSourcesSlice';
import type { RootState } from '@store/index';

type DomainMappingResource = {
    realms: Realm[] | null;
    userSources: UserSource[] | null;
    domainMappings: DomainMapping[] | null;
};

type NonNullableDomainMappingResource = {
    realms: NonNullable<DomainMappingResource['realms']>;
    userSources: NonNullable<DomainMappingResource['userSources']>;
    domainMappings: NonNullable<DomainMappingResource['domainMappings']>;
};

interface InitialState extends GenericState<DomainMappingResource> {}

const initialState: InitialState = {
    loading: false,
    data: { realms: null, userSources: null, domainMappings: null },
    error: null,
};

const domainMappingResourcesSlice = createGenericSlice({
    name: 'domainMappingResources',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DomainMappingResource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadDomainMappingsSuccess, (state, action: PayloadAction<{ data: DomainMapping[] }>) => {
            const { data: domainMappings } = action.payload;
            state.data.domainMappings = domainMappings;
        });

        builder.addCase(loadUserSourceRealmsSuccess, (state, action: PayloadAction<{ data: Realm[] }>) => {
            const { data: realms } = action.payload;
            state.data.realms = realms;
        });

        builder.addCase(loadUserSourcesSuccess, (state, action: PayloadAction<{ data: UserSource[] }>) => {
            const { data: authentications } = action.payload;
            state.data.userSources = authentications;
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = domainMappingResourcesSlice.actions;

export const selectDomainMappingResources = createSelector(
    (state: RootState) => state.domainMappingResources.data,
    (data) => {
        let nonNullData: NonNullableDomainMappingResource = {
            realms: [],
            userSources: [],
            domainMappings: [],
        };
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                nonNullData[key as keyof DomainMappingResource] = value as any;
            }
        });
        return nonNullData;
    }
);

export const selectRealmOptions = createSelector(selectDomainMappingResources, (data) =>
    data.realms.map(({ id, name }) => ({ value: id, label: name }))
);

export const selectAuthenticationOptions = createSelector(selectDomainMappingResources, (data) =>
    data.userSources.map(({ id, name }) => ({ value: id, label: name }))
);

export default domainMappingResourcesSlice.reducer;
