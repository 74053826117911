import { createSelector } from '@reduxjs/toolkit';
import { formatPolicyData, formatProfileBrief } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface PolicyState extends GenericState<Policy | null> {}

const initialState: PolicyState = {
    loading: false,
    data: null,
    error: null,
};

const policySlice = createGenericSlice({
    name: 'policy',
    initialState: initialState as PolicyState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Policy }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = policySlice.actions;

export const selectPolicy = createSelector(
    (state: RootState) => state.policy.data,
    (data) => (data ? formatPolicyData(data) : null)
);

export const selectPolicyProfiles = createSelector(
    (state: RootState) => state.policy.data?.profiles?.map(formatProfileBrief),
    (data) => data ?? []
);

export default policySlice.reducer;
