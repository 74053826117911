import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '../upsertGenericSlice';

interface InitialState extends GenericState<'success' | 'failure' | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const batchDeleteUserSlice = createUpsertGenericSlice({
    name: 'batchDeleteUser',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                data: UserBatchDeleteData;
            }>
        ) => {
            state.loading = true;
            state.error = null;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: 'success' | 'failure' }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = batchDeleteUserSlice.actions;

export default batchDeleteUserSlice.reducer;
