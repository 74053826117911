import { PropsWithChildren, useEffect } from 'react';
import { FortiCloudHeader } from '@shared/components/FortiCloudHeader';
import LogoutModal from '@shared/components/LogoutModal';
import useAutoLogout from '@shared/hooks/useAutoLogout';
import { useModal } from '@shared/hooks/useModal';
import { AUTO_LOGOUT_TIME } from '@shared/utils/constants';

const CommonSection = ({
    children,
    user,
    navbar,
    fortiCloudItemStatusMap,
    logout,
}: PropsWithChildren<{
    user: any;
    navbar: any;
    fortiCloudItemStatusMap: Record<number, FortiCloudItemStatus>;
    logout: Function;
}>) => {
    const isLogin = !!user;
    const timer = useAutoLogout(AUTO_LOGOUT_TIME, isLogin);
    const { isOpen: isLogoutModalOpen, toggle: toggleLogoutModal, close: closeLogoutModal } = useModal();

    useEffect(() => {
        if (timer <= 0) {
            toggleLogoutModal();
        }
    }, [timer]);
    return (
        <div id="layout">
            <div className="layout-header">
                <FortiCloudHeader
                    isAdmin={process.env.REACT_APP_WEBSITE === 'admin' && user.admin}
                    meta={navbar as FortiCareMenu}
                    user={{
                        accountId: user.account_id,
                        accountCompany: user.account_company,
                        userName: user.iam_user_name || user.fortinetid || user.user_name,
                        hasOrg: user.has_org,
                        userDisplayInfo: user.user_display_info,
                        selectedNode: user.selected_node,
                        isIAM: user.fortinetid != null && user.iam_user_name != null,
                        fortiCloudItemStatusMap,
                    }}
                    logout={logout}
                    logoPath={process.env.PUBLIC_URL + '/media/logo.svg'}
                />
            </div>
            <div id="account-selection-page" className="account-selection-fullpage">
                <main>
                    {isLogoutModalOpen && (
                        <LogoutModal
                            isOpen={isLogoutModalOpen}
                            toggle={toggleLogoutModal}
                            onClosed={closeLogoutModal}
                        ></LogoutModal>
                    )}
                    {children}
                </main>
            </div>
        </div>
    );
};

export default CommonSection;
