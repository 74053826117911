import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface DeviceStatusState extends GenericState<DeviceValidateStatus | null> {}

const initialState: DeviceStatusState = {
    loading: false,
    data: null,
    error: null,
};

const devicesStatusSlice = createGenericSlice({
    name: 'devicesStatus',
    initialState: initialState as DeviceStatusState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ data: DeviceRemoteData }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: DeviceValidateStatus }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = devicesStatusSlice.actions;

export const selectAllDeviceStatuss = createSelector(
    (state: RootState) => state.devicesStatus.data,
    (data) => data
);

export default devicesStatusSlice.reducer;
