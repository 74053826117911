import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface AccountsStatusState extends GenericState<Record<number, number> | null> {}

const initialState: AccountsStatusState = {
    loading: false,
    data: null,
    error: null,
};

const accountsStatusSlice = createGenericSlice({
    name: 'accountsStatus',
    initialState: initialState as AccountsStatusState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ accounts: AuthUser[] }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: AuthUser[] }>) => {
            state.loading = false;
            state.error = null;
            const accountStatusMap = { ...state.data };
            action.payload.data.forEach((account) => {
                accountStatusMap[account.account_id] = account.ftc_status ?? -1;
            });
            state.data = accountStatusMap;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = accountsStatusSlice.actions;

export const selectAccountsStatusMap = createSelector(
    (state: RootState) => state.accountsStatus.data,
    (data) => data ?? {}
);

export default accountsStatusSlice.reducer;
