import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface InitialState extends GenericState<TaskApproveResponse[] | DeviceTask | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const createTaskSlice = createUpsertGenericSlice({
    name: 'taskCreate',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (state, action: PayloadAction<{ data: UpsertDeviceTaskData }>) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: TaskApproveResponse[] | DeviceTask }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = createTaskSlice.actions;

export default createTaskSlice.reducer;
