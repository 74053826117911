import { localTime } from '@shared/utils/formateTime';

export const buildRealmMap = (realms: RealmBrief[]): Record<string, RealmBriefData> => {
    const realmMap: Record<string, RealmBriefData> = realms.reduce((acc, realm) => {
        return {
            ...acc,
            [realm.id]: createRealmBrief(realm),
        };
    }, {});
    // for the realm have duplicated realm names,
    // append deleted_at to the name
    const realmNameToIDMap: Record<string, string[]> = realms.reduce((acc: Record<string, string[]>, realm) => {
        return {
            ...acc,
            [realm.name]: acc.hasOwnProperty(realm.name) ? acc[realm.name].concat([realm.id]) : [realm.id],
        };
    }, {});
    Object.entries(realmNameToIDMap).forEach(([, ids]) => {
        if (ids.length > 1) {
            ids.forEach((id) => {
                realmMap[id] = {
                    ...realmMap[id],
                    name: realmMap[id]?.deletedAt
                        ? `${realmMap[id].name}(${localTime(realmMap[id].deletedAt)})`
                        : `${realmMap[id].name}`,
                };
            });
        }
    });
    return realmMap;
};

export const createRealmBrief = (realm: RealmBrief): RealmBriefData => {
    return {
        id: realm.id,
        name: realm.name,
        description: realm.description,
        isDefault: realm.is_default,
        deletedAt: realm.deleted_at,
    };
};

export function createRealm(element: Realm, realmQuotaMap?: Record<string, RealmQuota> | null): RealmData {
    return {
        name: element.name,
        id: element.id,
        description: element.description,
        isDefault: element.is_default,
        select: false,
        userCount: element.user_count == null ? realmQuotaMap?.[element.id]?.users ?? null : element.user_count,
        clientCount: element.client_count,
        samlApplicationCount: element.application_count,
        totalApplicationCount: element.client_count + element.application_count,
        permissionId: element.permission_id ? element.permission_id : '0',
        quota: element.quota ? element.quota : 'NA',
    };
}

export function createRealmClient(element: Client): RealmClientData {
    const members = element.members ? JSON.parse(element.members) : [];
    let name;
    if (element.sn || element.cluster_id) {
        name = (element.sn == null ? '[' + members.join('-') + ']' : element.sn) + '-' + element.vdom;
    } else {
        name = element.name;
    }
    return {
        name: name,
        clusterId: element.cluster_id,
        sns: element.sn == null ? members : [element.sn],
        vdom: element.vdom,
    };
}

export function createPermission(element: RealmPermission): RealmPermissionData {
    return {
        groupName: element.group_name,
        description: element.group_description,
        realm: element.realm_id,
        id: element.id,
    };
}

export function createRealmQuota(quota: QuotaBreif, realmQuota: RealmQuota) {
    const realmsQuota = quota['realms'];
    const currentQuota = realmQuota.realm_quota == null ? 0 : realmQuota.realm_quota;
    const leftQuota = quota.total_quota - quota.usage;
    const userCount = realmQuota.users;
    return {
        leftQuota,
        realmQuotaDic: realmsQuota?.reduce((acc: any, cur: any) => {
            const { realm_id } = cur;
            acc[realm_id] = cur;
            return acc;
        }, {}),
        quota: currentQuota,
        userCount,
        maxQuota: (leftQuota < 0 ? 0 : leftQuota) + Math.max(currentQuota, userCount),
    };
}
