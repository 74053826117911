import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { GroupResponse } from './watcher';
import { createGroupData, createReceiverData } from '../helper';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<GroupResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const groupsSlice = createGenericSlice({
    name: 'groups',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: GroupResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = groupsSlice.actions;

export const selectGroups = createSelector(
    (state: RootState) => state.groups.data?.groups.map(createGroupData) || [],
    (data) => data ?? []
);

export const selectReceivers = createSelector(
    (state: RootState) => state.groups.data?.receivers.map(createReceiverData) || [],
    (data) => data ?? []
);

export default groupsSlice.reducer;
