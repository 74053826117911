import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { WebAppsClientsResponse } from './watcher';
import { formatAuthClient } from '../helper';
import type { ClientsParams } from '@services/api/clients';
import type { ResourcesCountParams } from '@services/api/resources';

interface InitialState extends GenericState<WebAppsClientsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const webAppClientsSlice = createGenericSlice({
    name: 'webAppClients',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{ params: { clients: ClientsParams; resourcesCount: ResourcesCountParams } }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: WebAppsClientsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = webAppClientsSlice.actions;

export const selectWebAppsClients = createSelector(
    (state: RootState) => state.webAppClients.data?.clients.map(formatAuthClient),
    (state: RootState) => state.webAppClients.data?.realms || [],
    (data, realms) => {
        if (!data) {
            return [];
        }
        return data.map((d) => ({ ...d, realm: realms.find((r) => r.id === d?.realmId || '')?.name || '' }));
    }
);

export const selectWebAppsRealmOptions = createSelector(
    (state: RootState) => state.webAppClients.data?.realms.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectWebAppsProfileOptions = createSelector(
    (state: RootState) => state.webAppClients.data?.profiles.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export default webAppClientsSlice.reducer;
