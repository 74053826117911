import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FFormModal } from '@shared/components/FtcModal';
import { COUNT_DOWN_TIME } from '@shared/utils/constants';
import { useAppDispatch } from '@store/hooks';
import { loadLogout } from '@store/modules/auth/logoutSlice';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
};

function LogoutModal({ isOpen, toggle, onClosed }: Props) {
    const dispatch = useAppDispatch();
    const [timer, setTimer] = useState(COUNT_DOWN_TIME);

    useEffect(() => {
        const countDownInterval = setInterval(() => {
            setTimer((timer) => (timer - 1 >= 0 ? timer - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(countDownInterval);
        };
    }, []);

    useEffect(() => {
        if (timer <= 0) {
            dispatch(loadLogout());
        }
    }, [timer]);

    return (
        <FFormModal size="sm" backdrop="static" isOpen={isOpen} toggle={toggle}>
            <FFormModal.ModalHeader toggle={toggle} alert></FFormModal.ModalHeader>
            <FFormModal.ModalBody alert>
                <>
                    <div className="alert-title">Automatic Logout Warning</div>
                    <div className="alert-content">
                        You are about to be logged out of the system in <strong>{timer}</strong> seconds. Press
                        <strong> Cancel </strong>to stop this process.
                    </div>
                </>
                <div className={'alert-btn'}>
                    <Button color="primary" type="submit" onClick={onClosed}>
                        Cancel
                    </Button>
                </div>
            </FFormModal.ModalBody>
        </FFormModal>
    );
}

export default LogoutModal;
