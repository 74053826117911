import axiosClient from '../axiosClient';
import { API } from '../index';

export function* getAccounts(): Generator {
    return yield axiosClient.get<Account>(API.ACCOUNT_LIST).then((res) => res.data);
}

export function* getAccountsStatus({ accounts }: { accounts: AuthUser[] }): Generator {
    return yield axiosClient.post<AuthUser[]>(API.ACCOUNT_LIST_V1, { accounts }).then((res) => res.data);
}

export type Account = {
    account_info: AccountInfo;
    accounts: AuthUser[];
    orgs: Organization[];
};
