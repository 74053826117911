import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getQuota } from '@services/api/quota';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadQuota,
    loadFailure as loadQuotaFailure,
    loadSuccess as loadQuotaSuccess,
    cancelLoad as cancelLoadQuota,
} from './quotaSlice';

export function* fetchQuota() {
    try {
        const { response, cancel }: { response: QuotaDetail; cancel: any } = yield race({
            response: call(getQuota, {}),
            cancel: take(cancelLoadQuota),
        });

        if (cancel) {
            return;
        }

        yield put(loadQuotaSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadQuotaFailure(e.message));
    }
}

export function* fetchQuotaSaga() {
    yield takeLatest(loadQuota, fetchQuota);
}
