import axiosClient from '../axiosClient';
import { API } from '../index';

export type PolicyResponse = PolicyBrief[];

export function* getPolicies(): Generator {
    return yield axiosClient.get<PolicyResponse>(API.ADAPTIVEAUTH_POLICY).then((res) => res.data);
}

export function* getPolicy({ id }: { id: string }): Generator {
    return yield axiosClient.get<Policy>(`${API.ADAPTIVEAUTH_POLICY}/${id}`).then((res) => res.data);
}

// create policy
export function* postPolicy({ data }: { data: UpsertPolicyData }): Generator {
    return yield axiosClient.post<PolicyBrief>(API.ADAPTIVEAUTH_POLICY, data).then((res) => res.data);
}

// update policy
export function* putPolicy({ id, data }: { id: string; data: UpsertPolicyData }): Generator {
    return yield axiosClient.put<PolicyBrief>(`${API.ADAPTIVEAUTH_POLICY}/${id}`, data).then((res) => res.data);
}

export function* deletePolicy({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.ADAPTIVEAUTH_POLICY}/${id}`).then((res) => res.data);
}
