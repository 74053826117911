import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getSigningCerts, postSigningCert, putSigningCert, deleteSigningCert } from '@services/api/cert';
import {
    createRequest as createSigningCert,
    createSuccess as createSigningCertSuccess,
    createFailure as createSigningCertFailure,
    cancelCreate as cancelCreateSigningCert,
} from './createSigningCertSlice';
import {
    deleteRequest as deleteSigningCerts,
    deleteSuccess as deleteSigningCertsSuccess,
    deleteFailure as deleteSigningCertsFailure,
    cancelDelete as cancelDeleteSigningCerts,
} from './deleteSigningCertsSlice';
import {
    loadRequest as loadSigningCerts,
    loadSuccess as loadSigningCertsSuccess,
    loadFailure as loadSigningCertsFailure,
    cancelLoad as cancelLoadSigningCerts,
} from './signingCertsSlice';
import {
    updateRequest as updateSigningCert,
    updateSuccess as updateSigningCertSuccess,
    updateFailure as updateSigningCertFailure,
    cancelUpdate as cancelUpdateSigningCert,
} from './updateSigningCertSlice';

export function* fetchSigningCerts(action: ReturnType<typeof loadSigningCerts>) {
    try {
        const { response, cancel }: { response: SigningCert[]; cancel: any } = yield race({
            response: call(getSigningCerts),
            cancel: take(cancelLoadSigningCerts),
        });

        if (cancel) {
            return;
        }
        yield put(loadSigningCertsSuccess({ data: response }));
    } catch (e) {
        yield put(loadSigningCertsFailure(e.message));
    }
}

export function* createSigningCertRequest(action: ReturnType<typeof createSigningCert>) {
    const { data } = action.payload;
    try {
        const { response, cancel }: { response: SigningCert; cancel: any } = yield race({
            response: call(postSigningCert, { data }),
            cancel: take(cancelCreateSigningCert),
        });

        if (cancel) {
            return;
        }

        yield put(createSigningCertSuccess({ data: response }));

        // load initial data
        yield put(loadSigningCerts());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createSigningCertFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* updateSigningCertRequest(action: ReturnType<typeof updateSigningCert>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: SigningCert; cancel: any } = yield race({
            response: call(putSigningCert, { id, data }),
            cancel: take(cancelUpdateSigningCert),
        });

        if (cancel) {
            return;
        }

        yield put(updateSigningCertSuccess({ data: response }));

        // load initial data
        yield put(loadSigningCerts());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(updateSigningCertFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteSigningCertsRequest(action: ReturnType<typeof deleteSigningCerts>) {
    const { ids } = action.payload;

    const deleteSigningCertCalls = ids.map((id) => call(deleteSigningCert, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteSigningCertCalls),
            cancel: take(cancelDeleteSigningCerts),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteSigningCertsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadSigningCerts());
        toast.success(`${ids.length} Management Token Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteSigningCertsFailure(errMsg));
        yield put(loadSigningCerts());
        toast.error(errMsg);
    }
}

export function* fetchSigningCertsSaga() {
    yield takeLatest(loadSigningCerts, fetchSigningCerts);
}

export function* createSigningCertSaga() {
    yield takeLatest(createSigningCert, createSigningCertRequest);
}

export function* updateSigningCertSaga() {
    yield takeLatest(updateSigningCert, updateSigningCertRequest);
}

export function* deleteSigningCertsSaga() {
    yield takeLatest(deleteSigningCerts, deleteSigningCertsRequest);
}
