import { type PayloadAction } from '@reduxjs/toolkit';
import { UserGroupResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';

interface InitialState extends GenericState<UserGroupResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userGroupSlice = createGenericSlice({
    name: 'userGroup',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserGroupResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userGroupSlice.actions;

export default userGroupSlice.reducer;
