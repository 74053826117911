import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { ClientsParams } from '@services/api/clients';
import { RootState } from '@store/index';
import { createRealmClient } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';

interface InitialState extends GenericState<Client[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const realmClientsSlice = createGenericSlice({
    name: 'realmClients',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: ClientsParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Client[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmClientsSlice.actions;

export const selectRealmClients = createSelector(
    (state: RootState) => state.realmClients.data?.map(createRealmClient),
    (data) => data ?? []
);

export default realmClientsSlice.reducer;
