import { toast } from 'react-toastify';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    getDeviceTasks,
    DeviceTaskResponse,
    putDeviceTask,
    deleteDeviceTask,
    postDeviceTask,
} from '@services/api/deviceTasks';
import { DEVICE_STATUS, DEVICE_TRANSFER_TASK_RETRY_TIME } from '@shared/utils/constants';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import { loadRequest as loadDevicesRemote } from './../devicesRemote/devicesRemoteSlice';
import {
    upsertRequest as approveDeviceTaskRequest,
    upsertFailure as approveDeviceTaskFailure,
    upsertSuccess as approveDeviceTaskSuccess,
    cancelUpsert as cancelApproveDeviceTask,
} from './approveTaskSlice';
import {
    upsertRequest as createDeviceTaskRequest,
    upsertSuccess as createDeviceTaskSuccess,
    upsertFailure as createDeviceTaskFailure,
} from './createTaskSlice';
import {
    deleteRequest as deleteTask,
    deleteSuccess as deleteTaskSuccess,
    deleteFailure as deleteTaskFailure,
    cancelDelete as cancelDeleteTask,
} from './deleteTaskSlice';
import {
    loadRequest as loadDeviceTasks,
    loadFailure as loadDeviceTasksFailure,
    loadSuccess as loadDeviceTasksSuccess,
    cancelLoad as cancelLoadDeviceTasks,
} from './tasksSlice';

export function* fetchDeviceTasks() {
    try {
        const { response, cancel }: { response: DeviceTaskResponse; cancel: any } = yield race({
            response: call(getDeviceTasks),
            cancel: take(cancelLoadDeviceTasks),
        });

        if (cancel) {
            return;
        }

        yield put(loadDeviceTasksSuccess({ data: response }));

        const shouldRetry = response.find(({ status }) => status === DEVICE_STATUS.DEV_TFR_INPR);

        while (shouldRetry) {
            yield delay(DEVICE_TRANSFER_TASK_RETRY_TIME);
            yield put(loadDeviceTasks());
        }
        // finished task no pending task reload devices list
        if (!shouldRetry) {
            yield put(loadDevicesRemote());
        }
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(loadDeviceTasksFailure(errMsg));
    }
}

export function* fetchDeviceTasksSaga() {
    yield takeLatest(loadDeviceTasks, fetchDeviceTasks);
}

export function* createDeviceTask(action: ReturnType<typeof createDeviceTaskRequest>) {
    try {
        const { data } = action.payload;
        const { response, cancel }: { response: TaskApproveResponse[] | DeviceTask; cancel: any } = yield race({
            response: call(postDeviceTask, { data }),
            cancel: take(cancelDeleteTask),
        });

        if (cancel) {
            return;
        }

        yield put(loadDeviceTasks());

        yield put(createDeviceTaskSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(createDeviceTaskFailure(errMsg));
    }
}

export function* createDeviceTaskSaga() {
    yield takeLatest(createDeviceTaskRequest, createDeviceTask);
}

export function* approveDeviceTask(action: ReturnType<typeof approveDeviceTaskRequest>) {
    try {
        const { id } = action.payload;
        const { response, cancel }: { response: TaskApproveResponse[]; cancel: any } = yield race({
            response: call(putDeviceTask, { id }),
            cancel: take(cancelDeleteTask),
        });

        if (cancel) {
            return;
        }

        yield put(loadDeviceTasks());

        yield put(approveDeviceTaskSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(approveDeviceTaskFailure(errMsg));
    }
}

export function* approveDeviceTaskSaga() {
    yield takeLatest(approveDeviceTaskRequest, approveDeviceTask);
}

export function* deleteDeviceTaskRequest(action: ReturnType<typeof deleteTask>) {
    try {
        const { id } = action.payload;
        const { cancel }: { response: DeviceTaskResponse; cancel: any } = yield race({
            response: call(deleteDeviceTask, { id }),
            cancel: take(cancelApproveDeviceTask),
        });

        if (cancel) {
            return;
        }

        yield put(loadDeviceTasks());

        yield put(deleteTaskSuccess({ data: 'success' }));
        toast.success('Task is Deleted.');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(deleteTaskFailure(errMsg));
    }
}

export function* deleteDeviceTaskSaga() {
    yield takeLatest(deleteTask, deleteDeviceTaskRequest);
}
