import { call, put, takeLatest } from 'redux-saga/effects';
import { getNavbar, NavbarResponse } from '@services/api/layout';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import { loadNavbar, loadNavbarFailure, loadNavbarSuccess /* , cancelloadAuth */ } from './navbarSlice';

export function* fetchNavbar() {
    try {
        const res: NavbarResponse | AdminMenu = yield call(getNavbar);

        if (process.env.REACT_APP_WEBSITE === 'admin') {
            if (!(res as AdminMenu)?.admin_apps) {
                throw Error('Can not find admin_apps in get_common_data');
            }

            yield put(loadNavbarSuccess({ data: res as AdminMenu }));
        } else {
            if ((res as NavbarResponse).status.return_code !== 1) {
                throw Error((res as NavbarResponse).status.message);
            }

            yield put(loadNavbarSuccess({ data: (res as NavbarResponse).result }));
        }
    } catch (e) {
        showErrorAlert(e);
        yield put(loadNavbarFailure(e.message));
    }
}

export function* fetchNavbarSaga() {
    yield takeLatest(loadNavbar, fetchNavbar);
}
