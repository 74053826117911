import { createSelector } from '@reduxjs/toolkit';
import { ClientParams } from '@services/api/clients';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<Client | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const mgmtAppClientSlice = createGenericSlice({
    name: 'mgmtAppClient',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: ClientParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Client }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = mgmtAppClientSlice.actions;

export const selectMgmtAppClient = createSelector(
    (state: RootState) => state.mgmtAppClient.data,
    (data) => data ?? null
);

export default mgmtAppClientSlice.reducer;
