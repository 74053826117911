import { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers, createSlice } from '@reduxjs/toolkit';

export interface GenericState<T> {
    data: T;
    loading: boolean;
    error: Error | string | null;
}

/**
 * createUpdateGenericSlice is used for update
 * Mapping to the restful method: put/post
 */

export const creatUpdateGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
    name = '',
    initialState,
    reducers,
}: {
    name: string;
    initialState: GenericState<T>;
    reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            updateFailure: (state, action: PayloadAction<Error | string>) => {
                state.loading = false;
                state.error = action.payload;
            },
            cancelUpdate: () => initialState,
            ...reducers,
        },
    });
};
