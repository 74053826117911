import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface InitialState extends GenericState<TaskApproveResponse[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const approveTaskSlice = createUpsertGenericSlice({
    name: 'taskApprove',
    initialState: initialState as InitialState,
    reducers: {
        upsertRequest: (state, action: PayloadAction<{ id: string }>) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: TaskApproveResponse[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = approveTaskSlice.actions;

export default approveTaskSlice.reducer;
