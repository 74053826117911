import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as loadBrandingsSuccess } from './brandingsSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

type BrandingResponse = {
    brandings: Branding[] | null;
    branding: Branding | null;
};

interface InitialState extends GenericState<BrandingResponse> {}

type BrandingParams = { id: string };

const initialState: InitialState = {
    loading: false,
    data: {
        brandings: null,
        branding: null,
    },
    error: null,
};

const brandingSlice = createGenericSlice({
    name: 'branding',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: BrandingParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: BrandingResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadBrandingsSuccess, (state, action: PayloadAction<{ data: Branding[] }>) => {
            const { data: brandings } = action.payload;
            if (state.data) {
                state.data.brandings = brandings;
            }
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = brandingSlice.actions;

export const selectBranding = createSelector(
    (state: RootState) => state.branding.data,
    (data) => {
        return data;
    }
);

export default brandingSlice.reducer;
