const profileAction: IObjectKeys = {
    0: 'Multi-factor Authentication',
    1: 'Block',
    2: 'Bypass',
};

export function formatProfile(element: Profile): ProfileData {
    return {
        id: element.id,
        name: element.name,
        action: profileAction[element.action] ?? 'NA',
        clientCount: element.client_count,
        realmCount: element.realm_count,
        samlApplicationCount: element.application_count,
        totalApplicationCount: element.application_count + element.client_count,
    };
}

export function formatProfileData(element: ProfileDetailData): ProfileDetailData {
    return {
        id: element.id,
        name: element.name,
        action: element.action,
        policies: element.policies,
    };
}
