import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { AccountSelectionView } from '@pages/AccountSelection/AccountSelection';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getFTCLastUrl, getJsonAccount, getVisitedSites, setCurrentUrl } from '@store/modules/auth/ssoAccountSlice';
import { selectLoginUrl } from '@store/modules/layout/navbarSlice';
import { Icon } from '../Icon';
// @TODO
import type {
    FortiCareMenuResponse,
    ServicesMenuHeader,
    ServicesMenuItem,
    SupportMenuHeader,
    SupportMenuItem,
    UserMenuItem,
} from './types';
import './FortiCloudHeader.scss';

const itemStatusMap: Record<FortiCloudItemStatus, string> = {
    Hide: 'hide',
    ShowEnabled: 'enabled',
    ShowDisabled: 'disabled',
};

const getDecodedImgSrc = (base64EncodedStr: string) => `data:image/svg+xml;base64,${base64EncodedStr}`;

const generateFortiCareHeaderLeftMenu = (
    groupedHeaderItems: { [k: string]: any },
    fortiCloudItemStatusMap: Record<number, FortiCloudItemStatus>,
    jumpToOtherApp: Function
) => {
    return (
        <div className="col col-auto align-self-center">
            <ul className="fcloud-header-dropdown-ul">
                {/* Services */}
                <UncontrolledDropdown direction="down">
                    <DropdownToggle caret color="dark" tag="span">
                        <div className="fcloud-service-toggle">
                            <span>Services</span>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu end={false} className="fcloud-dropdown-menu fcloud-dropdown-services">
                        {groupedHeaderItems?.services && (
                            <div className="grid-services">
                                <div className="left-col">
                                    {/* Assets & Accounts */}
                                    {GenerateSectionComponent(
                                        groupedHeaderItems.services[0],
                                        fortiCloudItemStatusMap,
                                        jumpToOtherApp,
                                        2,
                                        true
                                    )}

                                    <hr className="dashed" />
                                    {/* Cloud Management */}
                                    {GenerateSectionComponent(
                                        groupedHeaderItems.services[1],
                                        fortiCloudItemStatusMap,
                                        jumpToOtherApp,
                                        2,
                                        true
                                    )}
                                </div>
                                <div className="right-col">
                                    {/* Cloud Services */}
                                    {GenerateSectionComponent(
                                        groupedHeaderItems.services[2],
                                        fortiCloudItemStatusMap,
                                        jumpToOtherApp,
                                        2,
                                        true
                                    )}
                                </div>
                            </div>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
                {/* Support */}
                <UncontrolledDropdown direction="down">
                    <DropdownToggle caret color="dark" tag="span">
                        <div className="fcloud-support-toggle">
                            <span>Support</span>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu end={false} className="fcloud-dropdown-menu fcloud-dropdown-support">
                        {groupedHeaderItems?.support && (
                            <div className="support-container d-flex gap-5">
                                <div className="col-auto">
                                    {/* Downloads */}
                                    {GenerateSectionComponent(
                                        groupedHeaderItems.support[1],
                                        fortiCloudItemStatusMap,
                                        jumpToOtherApp,
                                        1,
                                        false
                                    )}
                                </div>
                                <div className="col border-between">
                                    {/* Resources */}
                                    {GenerateSectionComponent(
                                        groupedHeaderItems.support[2],
                                        fortiCloudItemStatusMap,
                                        jumpToOtherApp,
                                        2,
                                        false
                                    )}
                                </div>
                                <div className="col-auto">
                                    <div>
                                        {/* FortiCare */}
                                        {GenerateSectionComponent(
                                            groupedHeaderItems.support[3],
                                            fortiCloudItemStatusMap,
                                            jumpToOtherApp,
                                            2,
                                            false
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        {/* FortiCare */}
                                        {GenerateSectionComponent(
                                            groupedHeaderItems.support[0],
                                            fortiCloudItemStatusMap,
                                            jumpToOtherApp,
                                            1,
                                            false
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ul>
        </div>
    );
};

const GenerateSectionComponent = (
    section: {
        header?: ServicesMenuHeader | SupportMenuHeader;
        items: ServicesMenuItem[] | SupportMenuItem[] | UserMenuItem[];
        count?: number | null;
    },
    fortiCloudItemStatusMap: Record<number, FortiCloudItemStatus>,
    jumpToOtherApp: Function,
    colNum: number,
    showIcon: boolean,
    beforeAccountSelected?: boolean
) => {
    const [expandedList, setExpandedList] = useState(false);
    const itemsFullList = section.items?.sort((first, second) => first['order'] - second['order']) ?? [];
    const itemList =
        section.count != null ? (expandedList ? itemsFullList : itemsFullList.slice(0, section.count)) : itemsFullList;
    return (
        <>
            {section.header && (
                <>
                    {section.header.url ? (
                        <button
                            className="fcloud-header-link"
                            key={section.header.display_name}
                            onClick={() => {
                                jumpToOtherApp(section.header);
                            }}
                        >
                            {section.header.display_name}
                        </button>
                    ) : (
                        <div className="fcloud-header-link">{section.header.display_name}</div>
                    )}
                </>
            )}
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${colNum}, 1fr)`, gap: '0 20px' }}>
                {itemList.map((item: ServicesMenuItem | SupportMenuItem | UserMenuItem, index) => (
                    <div
                        key={index}
                        className={
                            beforeAccountSelected
                                ? ''
                                : itemStatusMap[
                                      fortiCloudItemStatusMap['item_id' in item ? item.item_id : item.app_id] ?? 'Hide'
                                  ]
                        }
                    >
                        <button
                            key={item.display_name}
                            title={'app_name' in item ? item['app_name'] : item.display_name}
                            onClick={() => {
                                jumpToOtherApp(item);
                                console.log(item.url);
                            }}
                        >
                            {showIcon && item.image_content !== '' && (
                                <>
                                    <img src={getDecodedImgSrc(item.image_content)} alt={item.display_name as string} />{' '}
                                </>
                            )}

                            {item.display_name}
                        </button>
                    </div>
                ))}
            </div>
            {section.count != null && (
                <div
                    className="show-more-less"
                    onClick={() => {
                        setExpandedList(!expandedList);
                    }}
                >
                    <span>{expandedList ? 'Show Less' : 'Show More'}</span>
                    <Icon
                        classNames={['material-symbols-outlined', 'fill', 'fs-xs']}
                        content={expandedList ? 'expand_less' : 'expand_more'}
                    />
                </div>
            )}
        </>
    );
};

export const FortiCloudHeader = (props: {
    isAdmin?: boolean;
    meta: FortiCareMenuResponse | AdminMenu;
    user: {
        accountId?: string | number;
        accountCompany: string;
        userName: string | null;
        hasOrg?: boolean;
        userDisplayInfo: AuthUser['user_display_info'];
        selectedNode?: AuthUser['selected_node'];
        isIAM?: boolean;
        fortiCloudItemStatusMap: Record<number, FortiCloudItemStatus>;
    };
    logoPath?: string;
    logout: Function;
    enableTrialBtn?: JSX.Element;
}) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const introductionPageRegex = /^\/accountselection\/[0-9_-]+\/introduction$/;
    const isInIntroductionPage = introductionPageRegex.test(location.pathname);
    const chosenHKey = useAppSelector(getJsonAccount);
    const visitedUrlArray = useAppSelector(getVisitedSites);
    const loginUrl = useAppSelector(selectLoginUrl);
    const lastUrl = useAppSelector(getFTCLastUrl) ?? loginUrl;
    const [isAccountSelectionFlodOpen, setAccountSelectionFlodOpen] = useState(false);
    const beforeLoginUrl = loginUrl.replace('login', 'app');
    const currentUrl = window.location.href.includes('error')
        ? beforeLoginUrl
        : window.location.href.replace('app', 'login');
    if (currentUrl !== lastUrl) {
        dispatch(setCurrentUrl({ url: currentUrl }));
    }

    const jumpToOtherApp = (item: ServicesMenuItem | SupportMenuItem | UserMenuItem) => {
        if (!props.user) {
            return;
        }
        if (item.display_name === 'Switch Accounts') {
            navigate('/accountselection');
            return;
        }
        if (item.display_name === 'Logout') {
            props.logout();
            return;
        }
        if (fortiCloudItemStatusMap['item_id' in item ? item.item_id : item.app_id] === 'ShowDisabled') {
            return;
        }
        if ('app_name' in item && item.app_name === 'FortinetHome') {
            window.location.href = item.url;
            return;
        }

        const visitedUrl = visitedUrlArray.find((app) => 'app_name' in item && app.app_name === item.app_name)
            ?.last_url;
        const appform = document.createElement('form');
        document.body.appendChild(appform);
        const appFormInput = document.createElement('input');
        appFormInput.setAttribute('name', 'h_key');
        appFormInput.setAttribute('value', chosenHKey);
        appFormInput.setAttribute('type', 'hidden');
        appform.appendChild(appFormInput);
        appform.setAttribute('action', visitedUrl || item.url || '');
        appform.setAttribute('name', 'app_name' in item ? item.app_name : '');
        appform.setAttribute('method', 'POST');
        appform.submit();
        appform.remove();
    };
    const data = props.meta;
    const { fortiCloudItemStatusMap } = props.user;

    const cloudManagementDisplayCount = Number(
        (data as FortiCareMenuResponse)?.configurations?.find(
            (item) => item.name === 'DefaultNumOfCloudManagementSrvMenu'
        )?.value
    );

    const cloudServiceDisplayCount = Number(
        (data as FortiCareMenuResponse)?.configurations?.find((item) => item.name === 'DefaultNumOfCloudServiceSrvMenu')
            ?.value
    );

    const getItemsBySectionHeader = (menuItems: ServicesMenuItem[] | SupportMenuItem[], sectionHeaderName: string) =>
        menuItems.filter((item) => item.section_header === sectionHeaderName);

    const groupedHeaderItems = !props.isAdmin
        ? {
              services:
                  (data as FortiCareMenuResponse)?.portal_menu_headers?.map((serviceHeader) => ({
                      header: serviceHeader,
                      items: getItemsBySectionHeader(
                          (data as FortiCareMenuResponse)?.portal_menu_items,
                          serviceHeader.display_name
                      ),
                      count:
                          serviceHeader.display_name === 'Cloud Services'
                              ? cloudServiceDisplayCount
                              : serviceHeader.display_name === 'Cloud Management'
                              ? cloudManagementDisplayCount
                              : null,
                  })) ?? [],
              support:
                  (data as FortiCareMenuResponse)?.support_menu_headers?.map((supportHeader) => ({
                      header: supportHeader,
                      items: getItemsBySectionHeader(
                          (data as FortiCareMenuResponse)?.support_menu_items,
                          supportHeader.display_name
                      ),
                  })) ?? [],
              user: {
                  items:
                      (data as FortiCareMenuResponse)?.user_menu_items?.filter((item) =>
                          (!props.user.accountId
                              ? isInIntroductionPage
                                  ? ['Security Credentials', 'Logout', 'Switch Accounts']
                                  : ['Security Credentials', 'Logout']
                              : props.user.isIAM
                              ? ['My Account', 'Security Credentials', 'Logout']
                              : ['My Account', 'Security Credentials', 'Logout', 'Switch Accounts']
                          ).includes(item.display_name)
                      ) ?? [],
              },
          }
        : null;

    const [{ image_content: fortiCloudLogoContent = '' /* , image_content: fortiCloudPremiumLogoContent = '' */ }] = (
        data as FortiCareMenuResponse
    )?.logos || [{}, {}];

    const logoPath = props.logoPath || getDecodedImgSrc(fortiCloudLogoContent);

    return (
        <>
            <header className="fcloud-header">
                <nav className="row w-100 align-items-center g-0">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col col-auto">
                                <img
                                    className="brand-logo me-2"
                                    src={logoPath}
                                    width="215px"
                                    height="32px"
                                    alt="FortiTokenCloud"
                                />
                            </div>
                            {props.user.accountId &&
                                groupedHeaderItems &&
                                generateFortiCareHeaderLeftMenu(
                                    groupedHeaderItems,
                                    fortiCloudItemStatusMap,
                                    jumpToOtherApp
                                )}
                        </div>
                    </div>
                    <div className="col col-auto align-self-center d-flex flex-row">
                        <ul className="fcloud-header-dropdown-ul">
                            {props.isAdmin ? (
                                <UncontrolledDropdown direction="down">
                                    <DropdownToggle caret color="dark" tag="span">
                                        {props.user.userName}
                                    </DropdownToggle>
                                    <DropdownMenu end className="fcloud-dropdown-menu fcloud-dropdown-user">
                                        <div className="user-resources-links">
                                            <button
                                                className="d-flex align-items-center text-decoration-none"
                                                onClick={(e: React.SyntheticEvent) => {
                                                    e.preventDefault();
                                                    props.logout();
                                                }}
                                            >
                                                <Icon classNames={['material-symbols-outlined']} content="logout" />
                                                Logout
                                            </button>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            ) : (
                                <>
                                    {props.user.hasOrg && (
                                        <Dropdown
                                            direction="down"
                                            isOpen={isAccountSelectionFlodOpen}
                                            toggle={() => setAccountSelectionFlodOpen(!isAccountSelectionFlodOpen)}
                                        >
                                            <DropdownToggle caret tag="span">
                                                {props.user.selectedNode?.path}
                                            </DropdownToggle>
                                            <DropdownMenu end className="fcloud-dropdown-menu">
                                                <div className="row g-0">
                                                    {/* account selection dropdown for switch organization */}
                                                    {isAccountSelectionFlodOpen && (
                                                        <AccountSelectionView
                                                            onClose={() => setAccountSelectionFlodOpen(false)}
                                                        ></AccountSelectionView>
                                                    )}
                                                </div>
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                    <UncontrolledDropdown direction="down">
                                        <DropdownToggle caret color="dark" tag="span">
                                            {props.user.userName}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            end
                                            className={[
                                                'fcloud-dropdown-menu',
                                                'fcloud-dropdown-user',
                                                props.user.accountId ? 'wide' : '',
                                            ].join(' ')}
                                        >
                                            <div className="row g-0">
                                                {props.user.accountId && (
                                                    <div className="col-6 user-infos border-right">
                                                        {props.user.userDisplayInfo.map((info, index) => (
                                                            <div key={info.value} className={index ? 'spacing' : ''}>
                                                                {/* Don't show the spacing on the first row */}
                                                                <div className="user-menu-title">{info.name}</div>
                                                                <div className="user-info">{info.value}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                <div className="col-6">
                                                    {/* {JSON.stringify(groupedHeaderItems?.user)} */}
                                                    <div className="user-resources-links">
                                                        {groupedHeaderItems?.user &&
                                                            GenerateSectionComponent(
                                                                groupedHeaderItems.user,
                                                                fortiCloudItemStatusMap,
                                                                jumpToOtherApp,
                                                                1,
                                                                true,
                                                                props.user.accountId ? false : true
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </>
                            )}
                        </ul>
                        {!!props.enableTrialBtn && (
                            <div className="align-content-around p-2 px-5">{props.enableTrialBtn}</div>
                        )}
                    </div>
                </nav>
            </header>
        </>
    );
};
