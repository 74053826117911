import axiosClient from '../axiosClient';
import { API } from '../index';

export type AlertResponse = Alert[];

export function* getAlerts(): Generator {
    return yield axiosClient.get<AlertResponse>(API.ALERT).then((res) => res.data);
}

export function* postAlert({ data }: { data: CreateEventData }): Generator {
    return yield axiosClient.post<Alert>(API.ALERT, data).then((res) => res.data);
}

export function* putAlert({ id, data }: { id: string; data: UpdateEventData['alarm'] }): Generator {
    return yield axiosClient.put<Alert>(`${API.ALERT}/${id}`, data).then((res) => res.data);
}

export function* deleteAlert({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.ALERT}/${id}`).then((res) => res.data);
}

export function* postAlertGroup({ id, data }: { id: string; data: { group_id: string } }): Generator {
    return yield axiosClient.post<Group>(`${API.ALERT}/${id}/group`, data).then((res) => res.data);
}

export function* deleteAlertGroup({ alertId, groupId }: { alertId: string; groupId: string }): Generator {
    return yield axiosClient.delete(`${API.ALERT}/${alertId}/group/${groupId}`).then((res) => res.data);
}
