import { createSelector } from '@reduxjs/toolkit';
import { formatLicense } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface LicensesState extends GenericState<License[] | null> {}

const initialState: LicensesState = {
    loading: false,
    data: null,
    error: null,
};

const licensesSlice = createGenericSlice({
    name: 'licenses',
    initialState: initialState as LicensesState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: License[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = licensesSlice.actions;

export const selectAllLicenses = createSelector(
    (state: RootState) => state.licenses.data?.map(formatLicense),
    (data) => data ?? []
);

export default licensesSlice.reducer;
