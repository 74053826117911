import { PayloadAction } from '@reduxjs/toolkit';
import { LogSearchData } from '@services/api/logs';
import { GenericState, createGenericSlice } from '../genericSlice';

interface ExportLogsState extends GenericState<string | null> {}

const initialState: ExportLogsState = {
    loading: false,
    data: null,
    error: null,
};

const exportLogsSlice = createGenericSlice({
    name: 'exportLogs',
    initialState: initialState as ExportLogsState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                queryparams: LogSearchData;
                columnsSetting: Record<string, any>[];
                logType: 'mgmt' | 'auth' | 'sms';
            }>
        ) => {
            state.loading = true;
            state.data = null;
        },
        loadSuccess: (state, action: PayloadAction<{ data: string }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = exportLogsSlice.actions;
