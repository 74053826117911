import { all } from 'redux-saga/effects';
import { fetchAccountsSaga, fetchAccountsStatusSaga } from './modules/accounts/watcher';
import {
    createAdminGroupSaga,
    deleteAdminByGroupSaga,
    deleteAdminGroupsSaga,
    deleteRealmByAdminGroupSaga,
    fetchAdminGroupsPermissionsSaga,
    fetchAdminGroupsSaga,
    fetchAdminsByGroupSaga,
    fetchAdminsSaga,
    fetchRealmsByGroupSaga,
    updateAdminGroupSaga,
    updateAdminsByGroupSaga,
    updateRealmsByAdminGroupSaga,
} from './modules/admins/watcher';
import {
    createEventSaga,
    updateEventSaga,
    deleteEventsSaga,
    fetchEventReceiversSaga,
    fetchEventsSaga,
    fetchGroupsByEventSaga,
} from './modules/alarm/events/watcher';
import {
    createGroupSaga,
    deleteGroupsSaga,
    fetchGroupsSaga,
    fetchReceiversGroupSaga,
    updateGroupSaga,
} from './modules/alarm/notification/groups/watcher';
import {
    upsertReceiverSaga,
    deleteReceiversSaga,
    fetchReceiversSaga,
    fetchReceiverSaga,
} from './modules/alarm/notification/receivers/watcher';
import { fetchAlertsSaga } from './modules/alerts/watcher';
import { fetchAnnouncementSaga } from './modules/announcement/watcher';
import { fetchAppBrandingsSaga, createAppBrandingaga } from './modules/applications/branding/watcher';
import { fetchAppSigningCertsSaga, createAppSigningCertSaga } from './modules/applications/signingCert/watcher';
import { fetchAppAuthenticationsSaga, createAppAuthenticationSaga } from './modules/applications/userSource/watcher';
import {
    fetchApplicationSaga,
    fetchApplicationsSaga,
    upsertApplicationSaga,
    fetchApplicationRealmsSaga,
    fetchApplicationResourcesSaga,
    deleteApplicationsSaga,
} from './modules/applications/watcher';
import { fetchAuthUserSaga, fetchLoginSaga, fetchLogoutSaga } from './modules/auth/watcher';
import {
    deleteClientsSaga as fortiProductsDeleteClientsSaga,
    fetchClientUsersSaga as fortiProductsFetchClientUsersSaga,
    fetchClientsSaga as fortiProductsFetchClientsSaga,
    updateClientSaga as fortiProductsUpdateClientSaga,
} from './modules/authClients/fortiProducts/watcher';
import {
    deleteMgmtAppClientsSaga,
    fetchMgmtAppClientSaga,
    fetchMgmtAppClientsSaga,
    updateSecretMgmtAppClientSaga,
    upsertMgmtAppClientSaga,
} from './modules/authClients/mgmtApps/watcher';
import {
    deleteWebAppClientsSaga,
    fetchWebAppClientsSaga,
    fetchWebAppUsersSaga,
    updateSecretWebAppClientSaga,
    upsertWebAppClientSaga,
} from './modules/authClients/webApps/watcher';
import { fetchAuthDeviceSaga, importTokensSaga, deleteTokensSaga } from './modules/authDevices/watcher';
import { fetchWebauthnCredentialsSaga, deleteWebauthnCredentialsSaga } from './modules/authDevices/webauthn/watcher';
import {
    fetchDomainMappingResourcesSaga,
    upsertDomainMappingSaga,
    deleteDomainMappingSaga,
    fetchDomainMappingsSaga,
} from './modules/authentication/domainMapping/watcher';
import {
    fetchUserSourceSaga,
    fetchUserSourcesSaga,
    fetchUserSourceRealmsSaga,
    fetchUserSourceDomainMappingsSaga,
    upsertUserSourceSaga,
    deleteUserSourcesaga,
    fetchUserSourceResourcesSaga,
    createUserSourceDomainMappingSaga,
} from './modules/authentication/userSource/watcher';
import { fetchDevicesSaga, moveDevicesSaga } from './modules/devices/clusters/watcher';
import {
    deleteDevicesSaga,
    fetchDevicesRemoteSaga,
    validateDevicesInCLusterSaga,
    validateDevicesSaga,
} from './modules/devices/ownership/devicesRemote/watcher';
import {
    approveDeviceTaskSaga,
    createDeviceTaskSaga,
    deleteDeviceTaskSaga,
    fetchDeviceTasksSaga,
} from './modules/devices/ownership/tasks/watcher';
import { pollingHeartbeatSaga } from './modules/heartbeat.ts/watcher';
import { fetchNavbarSaga } from './modules/layout/watcher';
import { fetchLicensesSaga } from './modules/licenses/watcher';
import { fetchExportLogsSaga, fetchLogsSaga } from './modules/logs/watcher';
import { fetchMetadataSaga } from './modules/metadata/watcher';
import { deletePoliciesSaga, fetchPoliciesSaga, fetchPolicySaga, upsertPolicySaga } from './modules/policies/watcher';
import { deleteProfilesSaga, fetchProfileSaga, fetchProfilesSaga, upsertProfileSaga } from './modules/profiles/watcher';
import { fetchQuotaSaga } from './modules/quota/watcher';
import {
    createRealmSaga,
    deleteRealmsSaga,
    fetchRealmClientsSaga,
    fetchRealmPermissionsSaga,
    fetchRealmsBriefSaga,
    fetchRealmsSaga,
    fetchRealmQuotaSaga,
    updateRealmSaga,
    deleteRealmPermissionSaga,
} from './modules/realms/watcher';
import { fetchBalanceSaga, fetchResourcesSaga, refreshBalanceSaga } from './modules/resources/watcher';
import {
    fetchBrandingResourcesSaga,
    fetchBrandingsSaga,
    upsertBrandingSaga,
    deleteBrandingSaga,
} from './modules/settings/branding/watcher';
import { fetchGlobalSettingsSaga, updateGlobalSettingsSaga } from './modules/settings/global/watcher';
import { fetchForwardersSaga, upsertForwarderSaga, deleteForwardersSaga } from './modules/settings/log/watcher';
import {
    fetchRealmSettingSaga,
    fetchRealmSettingsSaga,
    fetchRealmTemplateSaga,
    updateRealmSettingSaga,
    updateRealmTemplatePreviewSaga,
} from './modules/settings/realm/watcher';
import {
    fetchSigningCertsSaga,
    createSigningCertSaga,
    updateSigningCertSaga,
    deleteSigningCertsSaga,
} from './modules/settings/signingCert/watcher';
import {
    fetchTemplateSaga,
    fetchTemplatesSaga,
    deleteTemplatesSaga,
    fetchDefaultTemplateSaga,
    fetchPreviewTemplateSaga,
    upsertTemplateSaga,
} from './modules/settings/templates/watcher';
import { fetchRateSmsSaga } from './modules/sms/watcher';
import { fetchTrialSaga, validateTrialSaga } from './modules/trial/watcher';
import { fetchUsageSaga, fetchUsageReamlSaga, fetchSummaryUsageSaga } from './modules/usage/watcher';
import {
    addUsersToGroupSaga,
    createUserGroupSaga,
    deleteUserGroupsSaga,
    deleteUsersFromGroupsSaga,
    fetchUserGroupApplicationsSaga,
    fetchUserGroupPermissionSaga,
    fetchUserGroupSaga,
    fetchUserGroupsSaga,
    fetchUsersByGroupSaga,
    fetchUsersByRealmSaga,
    updateUserGroupAppPermissionsSaga,
    updateUserGroupSaga,
    updateUsersInGroupSaga,
} from './modules/userGroups/watcher';
import {
    fetchUserSaga,
    fetchUsersSaga,
    editUserSaga,
    deleteUsersSaga,
    upsertUsersTokenSaga,
    upsertUsersAliasSaga,
    removeUsersAliasSaga,
    fetchUserExtensionSaga,
    batchAddUserSaga,
    fetchUserWebauthnCredentialsSaga,
    deleteUserWebauthnCredentialsSaga,
    fetchWebauthnRegisterationOptionsSaga,
    createWebauthnRegisterationVerificationSaga,
    batchDeleteUserSaga,
} from './modules/users/watcher';

export default function* rootSaga(): Generator {
    yield all([
        fetchLoginSaga(),
        fetchLogoutSaga(),
        fetchAuthUserSaga(),
        fetchMetadataSaga(),
        fetchAdminsSaga(),
        fetchAdminGroupsSaga(),
        fetchAdminGroupsPermissionsSaga(),
        createAdminGroupSaga(),
        updateAdminGroupSaga(),
        deleteAdminGroupsSaga(),
        fetchAdminsByGroupSaga(),
        updateAdminsByGroupSaga(),
        deleteAdminByGroupSaga(),
        fetchRealmsByGroupSaga(),
        updateRealmsByAdminGroupSaga(),
        deleteRealmByAdminGroupSaga(),
        fetchUsersSaga(),
        fetchUserSaga(),
        editUserSaga(),
        deleteUsersSaga(),
        upsertUsersTokenSaga(),
        upsertUsersAliasSaga(),
        removeUsersAliasSaga(),
        fetchNavbarSaga(),
        fetchRealmsSaga(),
        deleteRealmsSaga(),
        createRealmSaga(),
        updateRealmSaga(),
        fetchRealmPermissionsSaga(),
        fetchRealmsBriefSaga(),
        fetchRealmQuotaSaga(),
        fetchRealmClientsSaga(),
        deleteRealmPermissionSaga(),
        fortiProductsFetchClientsSaga(),
        fortiProductsFetchClientUsersSaga(),
        fortiProductsDeleteClientsSaga(),
        fortiProductsUpdateClientSaga(),
        fetchMgmtAppClientsSaga(),
        fetchMgmtAppClientSaga(),
        deleteMgmtAppClientsSaga(),
        upsertMgmtAppClientSaga(),
        updateSecretMgmtAppClientSaga(),
        fetchWebAppClientsSaga(),
        fetchWebAppUsersSaga(),
        deleteWebAppClientsSaga(),
        updateSecretWebAppClientSaga(),
        upsertWebAppClientSaga(),
        fetchAccountsSaga(),
        fetchLicensesSaga(),
        fetchLogsSaga(),
        fetchResourcesSaga(),
        fetchBalanceSaga(),
        fetchAlertsSaga(),
        fetchUsageReamlSaga(),
        fetchUsageSaga(),
        fetchSummaryUsageSaga(),
        fetchQuotaSaga(),
        fetchRateSmsSaga(),
        fetchExportLogsSaga(),
        fetchAuthDeviceSaga(),
        importTokensSaga(),
        deleteTokensSaga(),
        fetchEventsSaga(),
        fetchGroupsByEventSaga(),
        createEventSaga(),
        updateEventSaga(),
        deleteEventsSaga(),
        fetchEventReceiversSaga(),
        fetchReceiversSaga(),
        fetchReceiverSaga(),
        upsertReceiverSaga(),
        deleteReceiversSaga(),
        fetchGroupsSaga(),
        fetchReceiversGroupSaga(),
        createGroupSaga(),
        updateGroupSaga(),
        deleteGroupsSaga(),
        fetchPoliciesSaga(),
        fetchPolicySaga(),
        fetchProfilesSaga(),
        upsertPolicySaga(),
        deletePoliciesSaga(),
        upsertProfileSaga(),
        deleteProfilesSaga(),
        fetchProfileSaga(),
        fetchAnnouncementSaga(),
        fetchTemplateSaga(),
        fetchTemplatesSaga(),
        fetchDefaultTemplateSaga(),
        deleteTemplatesSaga(),
        fetchPreviewTemplateSaga(),
        upsertTemplateSaga(),
        fetchGlobalSettingsSaga(),
        updateGlobalSettingsSaga(),
        fetchRealmSettingsSaga(),
        fetchRealmSettingSaga(),
        updateRealmSettingSaga(),
        fetchRealmTemplateSaga(),
        updateRealmTemplatePreviewSaga(),
        fetchDeviceTasksSaga(),
        approveDeviceTaskSaga(),
        deleteDeviceTaskSaga(),
        fetchDevicesRemoteSaga(),
        validateDevicesSaga(),
        createDeviceTaskSaga(),
        deleteDevicesSaga(),
        validateDevicesInCLusterSaga(),
        fetchDevicesSaga(),
        moveDevicesSaga(),
        fetchForwardersSaga(),
        upsertForwarderSaga(),
        deleteForwardersSaga(),
        pollingHeartbeatSaga(),
        fetchAccountsStatusSaga(),
        fetchTrialSaga(),
        validateTrialSaga(),
        refreshBalanceSaga(),
        fetchUserExtensionSaga(),
        batchAddUserSaga(),
        batchDeleteUserSaga(),
        fetchWebauthnCredentialsSaga(),
        fetchUserWebauthnCredentialsSaga(),
        deleteUserWebauthnCredentialsSaga(),
        deleteWebauthnCredentialsSaga(),
        fetchWebauthnRegisterationOptionsSaga(),
        createWebauthnRegisterationVerificationSaga(),
        fetchSigningCertsSaga(),
        createSigningCertSaga(),
        updateSigningCertSaga(),
        deleteSigningCertsSaga(),
        fetchDomainMappingsSaga(),
        fetchDomainMappingResourcesSaga(),
        upsertDomainMappingSaga(),
        deleteDomainMappingSaga(),
        fetchBrandingsSaga(),
        upsertBrandingSaga(),
        deleteBrandingSaga(),
        fetchApplicationSaga(),
        fetchApplicationsSaga(),
        upsertApplicationSaga(),
        fetchApplicationRealmsSaga(),
        fetchApplicationResourcesSaga(),
        deleteApplicationsSaga(),
        fetchAppBrandingsSaga(),
        createAppBrandingaga(),
        fetchAppSigningCertsSaga(),
        createAppSigningCertSaga(),
        fetchAppAuthenticationsSaga(),
        createAppAuthenticationSaga(),
        fetchUserSourceSaga(),
        fetchUserSourcesSaga(),
        fetchUserSourceRealmsSaga(),
        upsertUserSourceSaga(),
        deleteUserSourcesaga(),
        fetchUserSourceResourcesSaga(),
        fetchBrandingResourcesSaga(),
        createUserSourceDomainMappingSaga(),
        fetchUserSourceDomainMappingsSaga(),
        fetchUserGroupsSaga(),
        fetchUserGroupSaga(),
        fetchUsersByGroupSaga(),
        createUserGroupSaga(),
        deleteUserGroupsSaga(),
        updateUserGroupSaga(),
        addUsersToGroupSaga(),
        deleteUsersFromGroupsSaga(),
        updateUsersInGroupSaga(),
        fetchUsersByRealmSaga(),
        fetchUserGroupApplicationsSaga(),
        fetchUserGroupPermissionSaga(),
        updateUserGroupAppPermissionsSaga(),
    ]);
}
