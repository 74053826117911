import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface BalanceState extends GenericState<Balance | null> {}

const initialState: BalanceState = {
    loading: false,
    data: null,
    error: null,
};

const refreshBalanceSlice = createGenericSlice({
    name: 'refreshBalance',
    initialState: initialState as BalanceState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Balance }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = refreshBalanceSlice.actions;

export const selectBalance = createSelector(
    (state: RootState) => state.balance.data,
    (data) => data
);

export default refreshBalanceSlice.reducer;
