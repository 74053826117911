import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { FLabelInput, FLabelTextarea } from '@shared/components/Form/Label';
import { FFormModal } from '@shared/components/FtcModal';
import { Icon } from '@shared/components/Icon';
import { FixedLoading } from '@shared/components/Loading';
import { parseCertificate } from '@shared/utils/idpProxy';
import { useAppDispatch } from '@store/hooks';
import { createRequest as createAppSigningCert } from '@store/modules/applications/signingCert/createAppSigningCertSlice';
import { createRequest as createSigningCert } from '@store/modules/settings/signingCert/createSigningCertSlice';

type Source = 'application' | 'default';

type Props = {
    onCanceled: () => void;
    data: SigningCert[];
    meta: {
        source?: Source;
        submitLoading: boolean;
    };
};

const sourceRequestMappping: { [key in Source]: typeof createSigningCert | typeof createAppSigningCert } = {
    default: createSigningCert,
    application: createAppSigningCert,
};

function CreateForm({ onCanceled, meta, data }: Props) {
    const dispatch = useAppDispatch();
    const { submitLoading, source = 'default' } = meta;
    const signingCerts = data;
    const certificateUploader = useRef<HTMLInputElement>(null);
    const privateKeyUploader = useRef<HTMLInputElement>(null);

    const triggerUpload = (e: React.SyntheticEvent, fieldRef: React.RefObject<HTMLInputElement>) => {
        e.preventDefault();

        if (fieldRef?.current) {
            fieldRef.current?.click();
        }
    };

    const initialValues = {
        name: '',
        cert_content: '',
        key_content: '',
    };

    const schema = Yup.object().shape({
        name: Yup.string()
            .matches(
                /^[a-zA-Z0-9#_-]{1,80}$/,
                'pattern: alphanumeric characters, number sign, hyphen and underscore; length: 1-80'
            )
            .test('check name duplicated', '', function (_, context) {
                const { originalValue } = context;

                const duplicate = ({ name }: { name: string }) => name.toLowerCase() === originalValue.toLowerCase();
                const isDuplicated = signingCerts.find(duplicate);

                if (isDuplicated) {
                    return context.createError({
                        message: `Certificate with name ${originalValue} already exists.`,
                    });
                }

                return true;
            })
            .required(),
        cert_content: Yup.string()
            .required()
            .min(256, 'Cert content must be at least 256 characters')
            .max(8192, 'Cert content must be at most 8192 characters')
            .test('check PEM format', '', function (_, context) {
                const { originalValue } = context;
                if (
                    originalValue &&
                    (!originalValue.includes('-----BEGIN CERTIFICATE-----') ||
                        !originalValue.includes('-----END CERTIFICATE-----'))
                )
                    return context.createError({
                        message: `Please make sure the content includes
                        -----BEGIN CERTIFICATE----- <Certificate Content>  -----END CERTIFICATE-----`,
                    });

                return true;
            }),
        key_content: Yup.string()
            .required()
            .min(256, 'Key content must be at least 256 characters')
            .max(8192, 'Key content must be at most 8192 characters')
            .test('check PEM format', '', function (_, context) {
                const { originalValue } = context;
                if (
                    originalValue &&
                    ((!originalValue.includes('-----BEGIN PRIVATE KEY-----') &&
                        !originalValue.includes('-----BEGIN RSA PRIVATE KEY-----')) ||
                        (!originalValue.includes('-----END PRIVATE KEY-----') &&
                            !originalValue.includes('-----END RSA PRIVATE KEY-----')))
                )
                    return context.createError({
                        message: `Please make sure the private key includes
                        -----BEGIN PRIVATE KEY----- <Private Key>  -----END PRIVATE KEY----- or -----BEGIN RSA PRIVATE KEY----- <Private Key>  -----END RSA PRIVATE KEY-----`,
                    });

                return true;
            }),
    });

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
                const castedValues = schema.cast({ ...values }) as CreateSigningCertData;
                dispatch(
                    sourceRequestMappping[source]({
                        data: {
                            ...castedValues,
                            cert_content: btoa(castedValues.cert_content),
                            key_content: btoa(castedValues.key_content),
                        },
                    })
                );
            }}
        >
            {({ setFieldValue, setFieldError, dirty }) => (
                <Form>
                    {/* loading */}
                    {submitLoading && <FixedLoading />}

                    <FLabelInput label="Name" name="name" type="text" />
                    <FLabelTextarea
                        label="Certificate"
                        name="cert_content"
                        append={
                            <>
                                <button
                                    className="btn btn-link btn-icon-only"
                                    disabled={submitLoading}
                                    onClick={(event) => triggerUpload(event, certificateUploader)}
                                >
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="upload" />
                                </button>
                                <input
                                    ref={certificateUploader}
                                    type="file"
                                    accept=".pem, .crt, .cer"
                                    className="d-none"
                                    onChange={(event) =>
                                        parseCertificate(
                                            event,
                                            'cert_content',
                                            setFieldValue,
                                            setFieldError,
                                            certificateUploader
                                        )
                                    }
                                />
                            </>
                        }
                        appendType="button"
                    />
                    <FLabelTextarea
                        label="Private Key"
                        name="key_content"
                        append={
                            <>
                                <button
                                    className="btn btn-link btn-icon-only"
                                    disabled={submitLoading}
                                    onClick={(event) => triggerUpload(event, privateKeyUploader)}
                                >
                                    <Icon classNames={['material-symbols-outlined', 'fill']} content="upload" />
                                </button>
                                <input
                                    ref={privateKeyUploader}
                                    type="file"
                                    accept=".pem, .key"
                                    className="d-none"
                                    onChange={(event) =>
                                        parseCertificate(
                                            event,
                                            'key_content',
                                            setFieldValue,
                                            setFieldError,
                                            privateKeyUploader
                                        )
                                    }
                                />
                            </>
                        }
                        appendType="button"
                    />

                    <FFormModal.ModalFooter>
                        <Button color="primary" outline type="reset" onClick={onCanceled}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" disabled={submitLoading || !dirty}>
                            Save
                        </Button>
                    </FFormModal.ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default CreateForm;
