import axios from 'axios';
import { FETCH_LOGOUT, request } from '@store/actions';
import { store } from '@store/index';

const axiosClient = axios.create({
    baseURL: window.location.origin,
});

axiosClient.interceptors.request.use((config) => {
    if (config.params) {
        let encodeParams = config.params;
        Object.keys(encodeParams).forEach((key) => {
            if (encodeParams[key] === null || typeof encodeParams[key] === 'undefined') {
                delete encodeParams[key];
            }
        });
        config.params = new URLSearchParams(encodeParams);
    }
    return config;
});

axiosClient.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }
        if (error.response) {
            if (error.response.status === 401) {
                console.warn('Invalid Session');
                store.dispatch({ type: request(FETCH_LOGOUT) });
            } else if (error.response.status === 403) {
                return Promise.reject(error);
            } else {
                console.error('Looks like there was a problem. Status Code: ' + error.response.status);
                return Promise.reject(error);
            }
        }
    }
);

/*

let customHeaders = {};
function updateRequestHeaders(key: string, val: any) {
    customHeaders[key] = val;
}

const getCsrfCookies = (): string | null => {
    const csrfCookies = document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((c) => c.startsWith('csrftoken='));
    if (csrfCookies.length) {
        return csrfCookies[0].split('=')[1];
    } else {
        return undefined;
    }
};

axiosClient.interceptors.request.use(
    (config) => {
        config.headers = {
            ...config.headers,
            ...(getCsrfCookies() ? { 'X-CSRFToken': getCsrfCookies() } : undefined),
            // ...customHeaders,
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);*/

export default axiosClient;
