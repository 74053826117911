import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '../upsertGenericSlice';

interface UsersFtmTokenState extends GenericState<User[] | null> {}

const initialState: UsersFtmTokenState = {
    loading: false,
    data: null,
    error: null,
};

// Used for following updated at users page
// New FTM Token
// Auto-assign FTK
const updateUsersFtmTokenSlice = createUpsertGenericSlice({
    name: 'usersToken',
    initialState: initialState as UsersFtmTokenState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                ids: string[];
                data: UserFtmTokenData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: User[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = updateUsersFtmTokenSlice.actions;

export default updateUsersFtmTokenSlice.reducer;
