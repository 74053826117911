import { TableColumn } from 'react-data-table-component';

export const downloadCSVFile = <T = Record<string, any>>({
    dataArray = [],
    fileName = 'data',
    columnsSetting = [],
    showContinueWarning = false,
}: {
    dataArray: T[];
    fileName: string;
    columnsSetting: TableColumn<T>[];
    showContinueWarning: boolean;
}) => {
    if (!columnsSetting || columnsSetting.length === 0 || !dataArray || dataArray.length === 0) {
        return;
    }
    const csvcolumnsSetting: TableColumn<T>[] = columnsSetting.filter((col) => col.name !== 'Details');
    const csvData = convertToCSV({ dataArray, columnsSetting: csvcolumnsSetting, showContinueWarning });
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    // @TBD to confirm if neccessary
    const isSafariBrowser =
        navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
        //if Safari open in new window to save file with random fileName.
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', fileName + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
};

export const convertToCSV = <T = Record<string, any>>({
    dataArray = [],
    columnsSetting = [],
    showContinueWarning = false,
}: {
    dataArray: T[];
    columnsSetting: TableColumn<T>[];
    showContinueWarning: boolean;
}): string => {
    if (!columnsSetting || columnsSetting.length === 0 || !dataArray || dataArray.length === 0) {
        return '';
    }
    const csvHeader = columnsSetting?.map((column) => `"${column.name}",`).join('') + '\r\n';
    const csvBody = dataArray
        .map((row) =>
            columnsSetting
                .map((column) => {
                    const content: string =
                        typeof column?.selector === 'function'
                            ? column.selector(row).toString().replace(/"/g, '""')
                            : '';
                    return `"${content}"`;
                })
                .join(',')
        )

        .join('\r\n');
    const continueWarning =
        '\r\nTo be continued... The number of results exceeds 10k. Please adjust the time range to export the rest.';
    return showContinueWarning ? `${csvHeader}${csvBody}${continueWarning}` : `${csvHeader}${csvBody}`;
};
