import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getDevices, moveDevice } from '@services/api/devices';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadDevices,
    loadFailure as loadDevicesFailure,
    loadSuccess as loadDevicesSuccess,
    cancelLoad as cancelLoadDevices,
} from './devicesSlice';
import {
    updateRequest as moveDevicesRequest,
    updateSuccess as moveDevicesSuccess,
    updateFailure as moveDevicesFailure,
    cancelUpdate as cancelMoveDevices,
} from './moveDevicesSlice';

export function* fetchDevices() {
    try {
        const { response, cancel }: { response: Device[]; cancel: any } = yield race({
            response: call(getDevices),
            cancel: take(cancelLoadDevices),
        });

        if (cancel) {
            return;
        }

        yield put(loadDevicesSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(loadDevicesFailure(errMsg));
    }
}

export function* fetchDevicesSaga() {
    yield takeLatest(loadDevices, fetchDevices);
}

export function* moveDevices(action: ReturnType<typeof moveDevicesRequest>) {
    try {
        const { snList, cluster_id } = action.payload.data;
        // only collect last response
        let finalResponse = null;
        for (const sn of snList) {
            const { response, cancel }: { response: Device; cancel: any } = yield race({
                response: call(moveDevice, { sn, cluster_id }),
                cancel: take(cancelMoveDevices),
            });
            finalResponse = response;
            if (cancel) {
                return;
            }
        }

        yield put(moveDevicesSuccess({ data: finalResponse }));
        toast.success('device(s) are being moved!');
        yield put(loadDevices());
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(moveDevicesFailure(errMsg));
    }
}

export function* moveDevicesSaga() {
    yield takeLatest(moveDevicesRequest, moveDevices);
}
