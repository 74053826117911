import './FtcFooter.scss';

const FtcFooter = () => (
    <footer>
        <div>Copyright &copy; {new Date().getFullYear()} Fortinet, Inc. All Rights Reserved.</div>
        <ul>
            <li>
                <a
                    href="https://docs.fortinet.com/document/fortitoken-cloud/latest/service-descriptions/197879/introduction"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms of Service
                </a>
            </li>
            <li>
                <a href="https://www.fortinet.com/corporate/about-us/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
            </li>
            <li>
                <a href="https://www.fortinet.com/corporate/about-us/gdpr" target="_blank" rel="noopener noreferrer">
                    GDPR
                </a>
            </li>
            {process.env.REACT_APP_WEBSITE === 'admin' && (
                <li>
                    <a href="/dashboard/root">Legacy UI</a>
                </li>
            )}
        </ul>
    </footer>
);

export { FtcFooter };
