import { PreviewTemplateResponse, PreviewTemplateParams } from '@services/api/templates';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TemlateState extends GenericState<PreviewTemplateResponse | null> {}

const initialState: TemlateState = {
    loading: false,
    data: null,
    error: null,
};

const previewTemplateSlice = createGenericSlice({
    name: 'previewTemplate',
    initialState: initialState as TemlateState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: PreviewTemplateParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: PreviewTemplateResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = previewTemplateSlice.actions;

export default previewTemplateSlice.reducer;
