import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { ResourcesCountResponse, getBalance, getResourceCount } from '@services/api/resources';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadBalance,
    loadFailure as loadBalanceFailure,
    loadSuccess as loadBalanceSuccess,
    cancelLoad as cancelLoadBalance,
} from './balanceSlice';
import {
    loadRequest as refreshBalance,
    loadFailure as refreshBalanceFailure,
    loadSuccess as refreshBalanceSuccess,
    cancelLoad as cancelRefreshBalance,
} from './refreshBalanceSlice';
import {
    loadRequest as loadResources,
    loadFailure as loadResourcesFailure,
    loadSuccess as loadResourcesSuccess,
    cancelLoad as cancelLoadResources,
} from './resourcesSlice';

export function* fetchResources() {
    try {
        const resourceList = ['user', 'realm', 'client', 'sms'];
        const { response }: { response: ResourcesCountResponse[]; cancel: any } = yield race({
            response: all(resourceList.map((resource) => call(getResourceCount, { params: { resource: resource } }))),
            cancel: take(cancelLoadResources),
        });
        const resourceData: Resource = {
            user: response[0] as UserResource,
            realm: response[1] as RealmResource,
            client: response[2] as ClientResource,
            sms: response[3] as SMSResource,
        };
        yield put(loadResourcesSuccess({ data: resourceData }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadResourcesFailure(e.message));
    }
}

export function* fetchBalance() {
    try {
        const { response, cancel }: { response: Balance; cancel: any } = yield race({
            response: call(getBalance),
            cancel: take(cancelLoadBalance),
        });

        if (cancel) {
            return;
        }

        yield put(loadBalanceSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadBalanceFailure(e.message));
    }
}

export function* refreshBalanceCall() {
    try {
        const { response, cancel }: { response: Balance; cancel: any } = yield race({
            response: call(getBalance),
            cancel: take(cancelRefreshBalance),
        });

        if (cancel) {
            return;
        }

        yield put(refreshBalanceSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(refreshBalanceFailure(e.message));
    }
}

export function* fetchResourcesSaga() {
    yield takeLatest(loadResources, fetchResources);
}

export function* fetchBalanceSaga() {
    yield takeLatest(loadBalance, fetchBalance);
}

export function* refreshBalanceSaga() {
    yield takeLatest(refreshBalance, refreshBalanceCall);
}
