import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { deleteReceiver, getReceiver, getReceivers, postReceiver, putReceiver } from '@services/api/receivers';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    deleteRequest as deleteReceivers,
    deleteSuccess as deleteReceiversSuccess,
    deleteFailure as deleteReceiversFailure,
    cancelDelete as cancelDeleteReceivers,
} from './deleteReceiversSlice';
import {
    loadRequest as loadReceiver,
    loadFailure as loadReceiverFailure,
    loadSuccess as loadReceiverSuccess,
    cancelLoad as cancelLoadReceiver,
} from './receiverSlice';
import {
    loadRequest as loadReceivers,
    loadFailure as loadReceiversFailure,
    loadSuccess as loadReceiversSuccess,
    cancelLoad as cancelLoadReceivers,
} from './receiversSlice';
import {
    upsertRequest as upsertReceiver,
    upsertFailure as upsertReceiverFailure,
    upsertSuccess as upsertReceiverSuccess,
    cancelUpsert as cancelUpsertReceiver,
} from './upsertReceiverSlice';

export function* fetchReceivers() {
    try {
        const { response, cancel }: { response: Receiver[]; cancel: any } = yield race({
            response: call(getReceivers, {}),
            cancel: take(cancelLoadReceivers),
        });

        if (cancel) {
            return;
        }

        yield put(loadReceiversSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadReceiversFailure(e?.response?.data?.error_message));
    }
}

export function* fetchReceiversSaga() {
    yield takeLatest(loadReceivers, fetchReceivers);
}

export function* fetchReceiver(action: ReturnType<typeof loadReceiver>) {
    const { id } = action.payload;
    try {
        const { response, cancel }: { response: ReceiverWithGroup; cancel: any } = yield race({
            response: call(getReceiver, { id }),
            cancel: take(cancelLoadReceiver),
        });

        if (cancel) {
            return;
        }

        yield put(loadReceiverSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadReceiverFailure(e?.response?.data?.error_message));
    }
}

export function* fetchReceiverSaga() {
    yield takeLatest(loadReceiver, fetchReceiver);
}

export function* upsertReceiverRequest(action: ReturnType<typeof upsertReceiver>) {
    const { id, data } = action.payload;

    // id only exists for edit mode
    const upsertCall = id ? call(putReceiver, { id, data }) : call(postReceiver, { data });

    try {
        const { response, cancel }: { response: Receiver; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertReceiver),
        });

        if (cancel) {
            return;
        }

        yield put(upsertReceiverSuccess({ data: response }));
        // load initial data
        yield put(loadReceivers());
        toast.success('Receiver Created');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(upsertReceiverFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* upsertReceiverSaga() {
    yield takeLatest(upsertReceiver, upsertReceiverRequest);
}

export function* deleteReceiversRequest(action: ReturnType<typeof deleteReceivers>) {
    const { ids } = action.payload;

    const deleteReceiverCalls = ids.map((id) => call(deleteReceiver, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteReceiverCalls),
            cancel: take(cancelDeleteReceivers),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteReceiversSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadReceivers());

        const count = ids.length;
        toast.success(`${count} ${pluralize('Receiver', count)} Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteReceiversFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteReceiversSaga() {
    yield takeLatest(deleteReceivers, deleteReceiversRequest);
}
