import { createSelector } from '@reduxjs/toolkit';
import { formatDeviceData } from './helper';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface DevicesState extends GenericState<Device[] | null> {}

const initialState: DevicesState = {
    loading: false,
    data: null,
    error: null,
};

const devicesSlice = createGenericSlice({
    name: 'devices',
    initialState: initialState as DevicesState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Device[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = devicesSlice.actions;

export const selectAllDevices = createSelector(
    (state: RootState) => state.devices.data?.map(formatDeviceData),
    (data) => data ?? []
);

export const selectAllStandaloneDevices = createSelector(
    (state: RootState) => state.devices.data?.map(formatDeviceData).filter((device) => !device.clusterId),
    (data) => data ?? []
);

export const selectAllInClusterDevices = createSelector(
    (state: RootState) => state.devices.data?.map(formatDeviceData).filter((device) => !!device.clusterId),
    (data) => data ?? []
);

export const selectClustersMap = createSelector(
    (state: RootState) => state.devices.data,
    (data) =>
        data?.reduce<Record<string, ClusterData>>((clusters, device) => {
            if (device.cluster_id) {
                if (!clusters[device.cluster_id]) {
                    clusters[device.cluster_id] = {
                        id: device['cluster_id'],
                        name: device.name,
                        type: device.type,
                        devices: [],
                        devicesSns: [],
                    };
                }
                clusters[device.cluster_id]?.devices.push(formatDeviceData(device));
                clusters[device.cluster_id]?.devicesSns.push(device.sn);
            }
            return clusters;
        }, {}) ?? {}
);

export default devicesSlice.reducer;
