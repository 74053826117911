const FixedLoading = () => {
    return (
        <div
            style={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                background: 'rgb(51 51 51 / 50%)',
                color: '#fff',
                zIndex: 1000,
            }}
        >
            <div className="spinner-border ftc-loader" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export { FixedLoading };
