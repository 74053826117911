import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState, createUpsertGenericSlice } from '@store/modules/upsertGenericSlice';

interface UpsertPolicyState extends GenericState<PolicyBrief | null> {}

const initialState: UpsertPolicyState = {
    loading: false,
    data: null,
    error: null,
};

const upsertPolicySlice = createUpsertGenericSlice({
    name: 'upsertPolicy',
    initialState: initialState as UpsertPolicyState,
    reducers: {
        upsertRequest: (
            state,
            action: PayloadAction<{
                id: string | null;
                data: UpsertPolicyData;
            }>
        ) => {
            state.loading = true;
        },
        upsertSuccess: (state, action: PayloadAction<{ data: PolicyBrief }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { upsertRequest, upsertSuccess, upsertFailure, cancelUpsert } = upsertPolicySlice.actions;

export default upsertPolicySlice.reducer;
