import { createSelector } from '@reduxjs/toolkit';
import { RealmPermissionsParams } from '@services/api/realmPermissions';
import { createPermission } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<RealmPermission[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const realmPermissionsSlice = createGenericSlice({
    name: 'realmPermissions',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: RealmPermissionsParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmPermission[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmPermissionsSlice.actions;

export const selectRealmPermissions = createSelector(
    (state: RootState) => state.realmPermissions.data?.map(createPermission),
    (data) => data ?? []
);

export default realmPermissionsSlice.reducer;
