import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<UserSource[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userSourcesSlice = createGenericSlice({
    name: 'userSources',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserSource[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSourcesSlice.actions;

export const selectAllUserSources = createSelector(
    (state: RootState) => state.userSources.data,
    (data) => data ?? []
);

export default userSourcesSlice.reducer;
