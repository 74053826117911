import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getGlobalSettings, putGlobalSettings } from '@services/api/globalSettings';

import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadGlobalSettings,
    loadFailure as loadGlobalSettingsFailure,
    loadSuccess as loadGlobalSettingsSuccess,
    cancelLoad as cancelGlobalSettings,
} from './globalSettingsSlice';

import {
    updateRequest as updateGlobalSettings,
    updateFailure as updateGlobalSettingsFailure,
    updateSuccess as updateGlobalSettingsSuccess,
    cancelUpdate as cancelupdateGlobalSettings,
} from './updateGlobalSettingsSlice';

export function* fetchGlobalSettings(action: ReturnType<typeof loadGlobalSettings>) {
    try {
        const { response, cancel }: { response: GlobalSettings; cancel: any } = yield race({
            response: call(getGlobalSettings),
            cancel: take(cancelGlobalSettings),
        });

        if (cancel) {
            return;
        }

        yield put(loadGlobalSettingsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadGlobalSettingsFailure(e.message));
    }
}

export function* updateGlobalSettingsRequest(action: ReturnType<typeof updateGlobalSettings>) {
    try {
        const { response, cancel }: { response: GlobalSettings; cancel: any } = yield race({
            response: call(putGlobalSettings, action.payload),
            cancel: take(cancelupdateGlobalSettings),
        });

        if (cancel) {
            return;
        }

        yield put(updateGlobalSettingsSuccess({ data: response }));
        toast.success('Global Settings Updated');
        yield put(loadGlobalSettings());
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        showErrorAlert(e);
        yield put(updateGlobalSettingsFailure(errMsg));
    }
}

export function* fetchGlobalSettingsSaga() {
    yield takeLatest(loadGlobalSettings, fetchGlobalSettings);
}

export function* updateGlobalSettingsSaga() {
    yield takeLatest(updateGlobalSettings, updateGlobalSettingsRequest);
}
