import { classnames } from '@shared/utils/classnames';
import type { materialIconAvailableKey } from './map';

type IconProps = { classNames?: string[]; styles?: any; content: materialIconAvailableKey };

function Icon({ classNames = [], styles = {}, content }: IconProps) {
    return (
        <span className={classnames([...classNames])} style={styles}>
            {content}
        </span>
    );
}

export { Icon };
