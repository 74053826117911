import { createSelector } from '@reduxjs/toolkit';
import { disabledAuthMethod, disabledNotifyMethod } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UserState extends GenericState<User | null> {}

const initialState: UserState = {
    loading: false,
    data: null,
    error: null,
};

const userSlice = createGenericSlice({
    name: 'user',
    initialState: initialState as UserState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id?: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: User }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    /**
     * commnet out extraReducers because we can reload users to get all api after update data to api
     */
    // extraReducers: (builder) => {
    //     builder.addCase(editUserSuccess, (state, action: PayloadAction<UserFormSubmitResponse>) => {
    //         const { user } = action.payload.data;
    //         state.data = user;
    //     });
    // },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSlice.actions;

export const selectUser = createSelector(
    (state: RootState) => state.user.data || null,
    (data) => data
);

export const selectOptions = createSelector(
    (state: RootState) => {
        const { time_base, trial_status } = state.auth.user || {};
        const { balance, SMSCount, hardTokens } = state.users.data || {};
        const { auth_method: currentAuthMethod } = state.user.data || { auth_method: '' };

        const authOptions = ['FTM', 'Email', 'SMS', 'FTK'];
        const notificationOptions = ['Email', 'SMS'];

        const timeBase = time_base;
        let zeroBalance = false;
        if (!timeBase) {
            const parsedBalance: any = balance?.balance.toFixed(1);
            zeroBalance = parsedBalance <= 0;
        }

        const conditionObj = {
            timeBase: timeBase,
            trial: trial_status === 1,
            zeroBalance: zeroBalance,
            SMSCount: SMSCount?.sms,
            hardTokens: hardTokens?.map((token) => token.sn) ?? [],
        };

        const mappedAuthOptions = authOptions.map((method) => ({
            name: method,
            disabled: disabledAuthMethod(method, conditionObj, currentAuthMethod),
        }));
        const mappedNotifyOptions = notificationOptions.map((method) => ({
            name: method,
            disabled: disabledNotifyMethod(method, conditionObj),
        }));

        return {
            auths: mappedAuthOptions,
            notifications: mappedNotifyOptions,
            conditionObj: conditionObj,
        };
    },
    (data) => data
);

export default userSlice.reducer;
