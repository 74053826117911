/* eslint-disable import/no-named-as-default-member */
import validator from 'validator';
import * as Yup from 'yup';
import { convertIPToBigInt } from './utils/ip';

const setYup = () => {
    Yup.addMethod(Yup.string, 'ip', function ip(message = 'Invalid IP', version?: validator.IPVersion) {
        return this.trim().test('is-ip', message, (value) =>
            value === null || value === undefined ? true : value ? validator.isIP(value, version) : false
        );
    });

    Yup.addMethod(Yup.string, 'fqdn', function fqdn(message = 'InValid FQDN', options = undefined) {
        return this.trim().test('is-fqdn', message, (value) =>
            value === null || value === undefined || value === ''
                ? true
                : value
                ? validator.isFQDN(value, options)
                : false
        );
    });

    Yup.addMethod(
        Yup.string,
        'iprange',
        function iprange(message = 'Invalid IP Range', version: validator.IPVersion = 4) {
            return this.trim().test('is-iprange', message, (value, testContext) => {
                if (value === null || value === undefined) {
                    return true;
                }
                let [start = '', end = ''] = value.split('-').map((d) => d?.trim());

                return !validator.isIP(start, version) || !validator.isIP(end, version)
                    ? testContext.createError({ message })
                    : convertIPToBigInt(start, version) <= convertIPToBigInt(end, version)
                    ? true
                    : testContext.createError({ message: 'IP1 is bigger than IP2' });
            });
        }
    );
};

declare module 'yup' {
    interface StringSchema {
        ip(message?: string, version?: validator.IPVersion): this;
        fqdn(message?: string, options?: Record<string, boolean>): this;
        iprange(message?: string, version?: validator.IPVersion): this;
    }
}

export { setYup };
