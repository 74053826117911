import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface WebauthnCredentialsState extends GenericState<WebauthnCredential[] | null> {}

const initialState: WebauthnCredentialsState = {
    loading: false,
    data: null,
    error: null,
};

const webauthnCredentialsSlice = createGenericSlice({
    name: 'webauthnCredentials',
    initialState: initialState as WebauthnCredentialsState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: WebauthnCredential[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = webauthnCredentialsSlice.actions;

export const selectAllWebauthnCredentials = createSelector(
    (state: RootState) => state.webauthnCredentials.data,
    (data) => data ?? []
);

export default webauthnCredentialsSlice.reducer;
