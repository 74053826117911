import memoize from 'memoize-one';
import { TableColumn } from 'react-data-table-component';
import { createPortal } from 'react-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '@shared/components/Icon';
import Permissions from '@shared/components/Permissions';

export const fortiProductsColumns = memoize(
    (handleCountClick, handleDetails, handleDelete, handleEdit, showPendingStatus) =>
        (
            [
                showPendingStatus && {
                    name: 'Action Status',
                    cell: (row) => (
                        <div style={{ width: '150px' }}>
                            {row.deleteStatusText && (
                                <Progress animated color="success" value="100" style={{ height: '20px' }}>
                                    <span style={{ color: 'white' }}>{row.deleteStatusText}</span>
                                </Progress>
                            )}
                        </div>
                    ),
                },
                {
                    name: 'Alias',
                    selector: (row) => row.alias,
                    sortable: true,
                    maxWidth: '300px',
                },
                {
                    name: 'Name',
                    selector: (row) => row.name,
                    sortable: true,
                    maxWidth: '300px',
                },
                {
                    name: 'Type',
                    selector: (row) => row.type,
                    sortable: true,
                    width: '200px',
                },
                {
                    name: 'Count',
                    selector: (row) => row.refs,
                    cell: (row) => (
                        <button className="btn btn-link" onClick={() => handleCountClick({ id: row.id })}>
                            {row.refs}
                        </button>
                    ),
                    sortable: true,
                    center: true,
                    width: '120px',
                },
                {
                    name: 'Realm Name',
                    selector: (row) => row.realm,
                    sortable: true,
                    maxWidth: '350px',
                },
                {
                    cell: (row) => (
                        <UncontrolledDropdown>
                            <DropdownToggle color="link" className="btn-icon-only" caret={false}>
                                <Icon classNames={['material-symbols-outlined', 'bold']} content="more_vert" />
                            </DropdownToggle>
                            <>
                                {createPortal(
                                    <DropdownMenu className="action-dropdown-menu" end={true}>
                                        <Permissions permission="super_admin">
                                            <DropdownItem
                                                className="btn btn-link text-start"
                                                onClick={() => handleEdit({ id: row.id })}
                                            >
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem divider className="transparent" />
                                        </Permissions>
                                        <DropdownItem
                                            className="btn btn-link text-start"
                                            onClick={() => handleDetails({ id: row.id })}
                                        >
                                            Details
                                        </DropdownItem>
                                        <Permissions permission="super_admin">
                                            <DropdownItem divider />
                                            <DropdownItem
                                                className="btn btn-link btn-link-danger text-start"
                                                disabled={!!row.deleteStatusText}
                                                onClick={() => handleDelete({ id: row.id })}
                                            >
                                                Delete
                                            </DropdownItem>
                                        </Permissions>
                                    </DropdownMenu>,
                                    document.body
                                )}
                            </>
                        </UncontrolledDropdown>
                    ),
                    width: '50px',
                },
            ] as TableColumn<AuthClientData>[]
        ).filter(Boolean)
);
