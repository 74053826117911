import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { Account, getAccounts, getAccountsStatus } from '@services/api/accounts';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAccounts,
    loadSuccess as loadAccountsSuccess,
    loadFailure as loadAccountsFailure,
    cancelLoad as cancelLoadAccounts,
} from '@store/modules/accounts/accountsSlice';
import {
    loadRequest as loadAccountsStatus,
    loadSuccess as loadAccountsStatusSuccess,
    loadFailure as loadAccountsStatusFailure,
    cancelLoad as cancelLoadAccountsStatus,
} from '@store/modules/accounts/accountsStatusSlice';

export function* fetchAccounts() {
    try {
        const { response, cancel }: { response: Account; cancel: any } = yield race({
            response: call(getAccounts),
            cancel: take(cancelLoadAccounts),
        });

        if (cancel) {
            return;
        }
        yield put(loadAccountsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAccountsFailure(e.message));
    }
}

export function* fetchAccountsSaga() {
    yield takeLatest(loadAccounts, fetchAccounts);
}

export function* fetchAccountsStatus(action: ReturnType<typeof loadAccountsStatus>) {
    const { accounts } = action.payload;
    try {
        const { response, cancel }: { response: AuthUser[]; cancel: any } = yield race({
            response: call(getAccountsStatus, { accounts }),
            cancel: take(cancelLoadAccountsStatus),
        });

        if (cancel) {
            return;
        }
        yield put(loadAccountsStatusSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAccountsStatusFailure(e.message));
    }
}

export function* fetchAccountsStatusSaga() {
    yield takeLatest(loadAccountsStatus, fetchAccountsStatus);
}
