function replaceTag(tag: string) {
    const tagsToReplace: IObjectKeys = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
    };
    return tagsToReplace[tag] || tag;
}

export function safeTagsReplace(str?: string | null): string {
    if (!str) return '';
    return str.replace(/[&<>]/g, replaceTag);
}
