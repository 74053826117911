import { FFormModal } from '@shared/components/FtcModal';
import EditForm from './EditForm';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onCanceled: () => void;
    data: SigningCert[];
    meta: {
        selectedId: string;
        submitLoading: boolean;
    };
};
function EditModal({ isOpen, toggle, onClosed, onCanceled, data, meta }: Props) {
    // const { signingCerts } = data;
    const { selectedId } = meta;
    const selectedItem = data.find(({ id }) => id === selectedId);

    return (
        <FFormModal size="lg" backdrop="static" isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <FFormModal.ModalHeader toggle={toggle}>Edit Certificate</FFormModal.ModalHeader>
            <FFormModal.ModalBody>
                <EditForm onCanceled={onCanceled} data={data} meta={{ ...meta, selectedItem }} />
            </FFormModal.ModalBody>
        </FFormModal>
    );
}

export default EditModal;
