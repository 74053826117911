import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { ResourcesCountParams } from '@services/api/resources';
import { HardTokenParams, TempTokenParams } from '@services/api/tokens';
import { formatUser } from './helper';
import { UsersResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { RootState } from '@store/index';

interface UserState extends GenericState<UsersResponse | null> {}

const initialState: UserState = {
    loading: false,
    data: null,
    error: null,
};

export type loadUserParams = {
    tempToken: TempTokenParams;
    hardToken: HardTokenParams;
    SMSCount: ResourcesCountParams;
};

const usersSlice = createGenericSlice({
    name: 'users',
    initialState: initialState as UserState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: loadUserParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UsersResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    /**
     * commnet out extraReducers because we can reload users to get all api after update data to api
     */
    // extraReducers: (builder) => {
    //     builder.addCase(editUserSuccess, (state, action: PayloadAction<UserFormSubmitResponse>) => {
    //         const {
    //             data: { user, tokenTemp },
    //             isTempTokenDeleted,
    //         } = action.payload;

    //         // update tempToken data in frontend store after fetch tempToken api because we will not call tempToken again
    //         // 1. update: if the tempToken existing
    //         // 2. add: if the tempToken not existing
    //         // 3. delete: if delete tempToken
    //         if (state.data) {
    //             const findTempToken = state.data.tempTokens.find((item) => item.user_id === tokenTemp?.user_id);

    //             // update existing tempToken
    //             if (findTempToken) {
    //                 state.data.tempTokens =
    //                     state.data.tempTokens.map((item) => {
    //                         if (item.user_id === tokenTemp?.user_id) {
    //                             return {
    //                                 ...item,
    //                                 ...tokenTemp,
    //                             };
    //                         }
    //                         return item;
    //                     }) ?? [];
    //             }

    //             // add tempToken
    //             if (!findTempToken && tokenTemp) {
    //                 state.data.tempTokens.push(tokenTemp);
    //             }

    //             // delete tempToken
    //             if (isTempTokenDeleted) {
    //                 state.data.tempTokens = state.data.tempTokens.filter(({ user_id }) => user_id !== user.id);
    //             }
    //         }
    //     });
    // },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usersSlice.actions;

export const selectRealms = createSelector(
    (state: RootState) => state.users.data?.realms || [],
    (data) => data ?? []
);

export const selectAllUsers = createSelector(
    (state: RootState) => {
        const { users, realms, tempTokens } = state.users.data || {};
        const settings: Record<string, Realm> = {};
        const userTempTokenMap: Record<string, Token> = {};
        const filterUsers: any[] = [];
        const { data: user } = state.user || {};

        realms?.forEach((element: Realm) => {
            settings[element.id] = element;
        });

        tempTokens?.forEach((token: Token) => {
            userTempTokenMap[token.user_id] = token;
        });

        users?.forEach((element) => {
            // Combined data in users and user if user fetch data from api
            // when edit user, need to combine data in users and user to display
            // because users lack of some attributes, which only can be retrieved from user
            // user also lack of some data, which only can be retrieved from users
            let item = element;
            if (element.id === user?.id) {
                item = { ...element, ...user };
            }
            filterUsers.push(formatUser(item, settings, userTempTokenMap));
        });

        return filterUsers;
    },
    (data) => data ?? []
);

export default usersSlice.reducer;
