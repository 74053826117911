import { createSelector } from '@reduxjs/toolkit';
import { TokenParams } from '@services/api/tokens';
import { formatAuthClientHardToken, formatAuthClientSoftToken } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface TokenStateData {
    hardToken: HardToken[] | null;
    softToken: SoftToken[] | null;
    tempToken: SoftToken[] | null;
}

interface TokenState extends GenericState<TokenStateData> {}

const initialState: TokenState = {
    loading: false,
    data: { hardToken: null, softToken: null, tempToken: null },
    error: null,
};

const authDeviceSlice = createGenericSlice({
    name: 'authDevice',
    initialState: initialState as TokenState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params?: TokenParams; type: keyof TokenStateData }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Token[]; type: keyof TokenStateData }>) => {
            state.loading = false;
            state.error = null;
            state.data = { ...state.data, [action.payload.type]: action.payload.data };
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = authDeviceSlice.actions;

export const selectAllAuthDevicesSoftToken = createSelector(
    (state: RootState) => state.authDevice.data.softToken?.map(formatAuthClientSoftToken),
    (data) => data ?? []
);

export const selectAllAuthDevicesHardTokens = createSelector(
    (state: RootState) => state.authDevice.data.hardToken?.map(formatAuthClientHardToken),
    (data) => data ?? []
);

export default authDeviceSlice.reducer;
