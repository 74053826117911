import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    deleteGroup,
    deleteGroupReceiver,
    getGroup,
    postGroup,
    postGroupReceiver,
    putGroup,
} from '@services/api/groups';
import { getReceivers } from '@services/api/receivers';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    createRequest as createGroup,
    createSuccess as createGroupSuccess,
    createFailure as createGroupFailure,
    cancelCreate as cancelCreateGroup,
} from './createGroupSlice';
import {
    deleteRequest as deleteGroups,
    deleteSuccess as deleteGroupsSuccess,
    deleteFailure as deleteGroupsFailure,
    cancelDelete as cancelDeleteGroups,
} from './deleteGroupsSlice';
import {
    loadRequest as loadGroups,
    loadFailure as loadGroupsFailure,
    loadSuccess as loadGroupsSuccess,
    cancelLoad as cancelLoadGroups,
} from './groupsSlice';
import {
    loadRequest as loadReceiversGroup,
    loadFailure as loadReceiversGroupFailure,
    loadSuccess as loadReceiversGroupSuccess,
    cancelLoad as cancelLoadReceiversGroup,
} from './receiversGroupSlice';
import {
    updateRequest as updateGroups,
    updateFailure as updateGroupsFailure,
    updateSuccess as updateGroupsSuccess,
    cancelUpdate as cancelUpdateGroups,
} from './updateGroupSlice';

export type GroupResponse = {
    groups: Group[];
    receivers: Receiver[];
};

export function* fetchGroups() {
    try {
        const { response, cancel }: { response: GroupResponse; cancel: any } = yield race({
            response: all({ groups: call(getGroup, {}), receivers: call(getReceivers, {}) }),
            cancel: take(cancelLoadGroups),
        });

        if (cancel) {
            return;
        }

        yield put(loadGroupsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadGroupsFailure(e?.response?.data?.error_message));
    }
}

export function* fetchGroupsSaga() {
    yield takeLatest(loadGroups, fetchGroups);
}

export function* fetchReceiversGroup(action: ReturnType<typeof loadReceiversGroup>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Receiver[]; cancel: any } = yield race({
            response: call(getReceivers, { params }),
            cancel: take(cancelLoadReceiversGroup),
        });

        if (cancel) {
            return;
        }

        yield put(loadReceiversGroupSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadReceiversGroupFailure(e?.response?.data?.error_message));
    }
}

export function* fetchReceiversGroupSaga() {
    yield takeLatest(loadReceiversGroup, fetchReceiversGroup);
}

export function* createGroupRequest(action: ReturnType<typeof createGroup>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(postGroup, { data }),
            cancel: take(cancelCreateGroup),
        });

        if (cancel) {
            return;
        }

        yield put(createGroupSuccess({ data: response }));
        // load initial data after successfully
        yield put(loadGroups());
        toast.success('Group Created');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(createGroupFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* createGroupSaga() {
    yield takeLatest(createGroup, createGroupRequest);
}

export function* updateGroupRequest(action: ReturnType<typeof updateGroups>) {
    const {
        id,
        data: {
            group,
            receiver: { addIds, removeIds },
        },
    } = action.payload;

    // Three types of call
    // 1. update group
    // 2. delete receivers
    // 3. add receivers

    const groupCalls = [call(putGroup, { id, data: group })];
    if (addIds.length > 0) {
        addIds.forEach((receiver) => groupCalls.push(call(postGroupReceiver, { id, data: receiver })));
    }

    if (removeIds.length > 0) {
        removeIds.forEach((receiverId) => groupCalls.push(call(deleteGroupReceiver, { groupId: id, receiverId })));
    }

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: all(groupCalls),
            cancel: take(cancelUpdateGroups),
        });

        if (cancel) {
            return;
        }

        yield put(updateGroupsSuccess({ data: response }));
        // load initial data after successfully
        yield put(loadGroups());
        toast.success('Group Created');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateGroupsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateGroupSaga() {
    yield takeLatest(updateGroups, updateGroupRequest);
}

export function* deleteGroupsRequest(action: ReturnType<typeof deleteGroups>) {
    const { ids } = action.payload;

    const deleteGroupCalls = ids.map((id) => call(deleteGroup, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteGroupCalls),
            cancel: take(cancelDeleteGroups),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give data to determin to close the modal after success
        yield put(deleteGroupsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadGroups());
        const count = ids.length;
        toast.success(`${count} ${pluralize('Group', count)} Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteGroupsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteGroupsSaga() {
    yield takeLatest(deleteGroups, deleteGroupsRequest);
}
