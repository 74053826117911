import axiosClient from '../axiosClient';
import { API } from '../index';

export type UserSourceParams = { id: string };
export type DomainMappingParams = { user_source_id?: string; realm_id?: string; available?: boolean };

export function* getUserSourcePreview(): Generator {
    return yield axiosClient.get<UserSource>(API.USERRESOURCE_PREVIEW).then((res) => res.data);
}

export function* getUserSource({ id }: { id: string }): Generator {
    return yield axiosClient.get<UserSource>(`${API.USERRESOURCE}/${id}`).then((res) => res.data);
}

export function* getUserSources(): Generator {
    return yield axiosClient.get<UserSource[]>(API.USERRESOURCE).then((res) => res.data);
}

export function* putUserSource({ id, data }: { id: string; data: UpsertUserSourceData }): Generator {
    return yield axiosClient.put<UserSource[]>(`${API.USERRESOURCE}/${id}`, data).then((res) => res.data);
}

export function* postUserSource({ data }: { data: UpsertUserSourceData }): Generator {
    return yield axiosClient.post<UserSource[]>(API.USERRESOURCE, data).then((res) => res.data);
}

export function* deleteUserSource({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.USERRESOURCE}/${id}`).then((res) => res.data);
}

export function* getDomainMappings({ params }: { params?: DomainMappingParams }): Generator {
    return yield axiosClient.get<DomainMapping[]>(`${API.DOMAIN_MAPPING}`, { params }).then((res) => res.data);
}

export function* postDomainMapping({ data }: { data: UpsertDomainMappingData }): Generator {
    return yield axiosClient.post(API.DOMAIN_MAPPING, data).then((res) => res.data);
}

export function* putDomainMapping({ id, data }: { id: string; data: UpsertDomainMappingData }): Generator {
    return yield axiosClient.put(`${API.DOMAIN_MAPPING}/${id}`, data).then((res) => res.data);
}

export function* deleteDomainMapping({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.DOMAIN_MAPPING}/${id}`).then((res) => res.data);
}

export function* postUserSourceDomainMapping({
    userSourceId,
    data,
}: {
    userSourceId: string;
    data: CreateUserSourceDomainMappingData;
}): Generator {
    return yield axiosClient.post(API.USERSOURCE_DOMAINMAPPING(userSourceId), data).then((res) => res.data);
}

export function* putUserSourceDomainMapping({
    userSourceId,
    data,
}: {
    userSourceId: string;
    data: { domain_ids: string[] };
}): Generator {
    return yield axiosClient.put(API.USERSOURCE_DOMAINMAPPING(userSourceId), data).then((res) => res.data);
}
