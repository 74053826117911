import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { formatApplication } from './helper';
import { GenericState, createGenericSlice } from '../genericSlice';

interface InitialState extends GenericState<Application[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userGroupApplicationsSlice = createGenericSlice({
    name: 'userGroupApplications',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Application[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userGroupApplicationsSlice.actions;

export const selectApplications = createSelector(
    (state: RootState) => state.userGroupApplications.data?.map(formatApplication),
    (data) => data ?? []
);

export default userGroupApplicationsSlice.reducer;
