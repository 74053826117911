import { AdminParams } from '@services/api/admins';
import { GenericState, createDeleteGenericSlice } from '@store/modules/deleteGenericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<string | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const deleteRealmByAdminGroupSlice = createDeleteGenericSlice({
    name: 'realmByAdminGroup',
    initialState: initialState as InitialState,
    reducers: {
        deleteRequest: (
            state,
            action: PayloadAction<{
                id: string;
                params: AdminParams;
            }>
        ) => {
            state.loading = true;
        },
        deleteSuccess: (state, action: PayloadAction<{ data: string }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { deleteRequest, deleteSuccess, deleteFailure, cancelDelete } = deleteRealmByAdminGroupSlice.actions;

export default deleteRealmByAdminGroupSlice.reducer;
