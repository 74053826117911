import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getLicenses, LicenseResponse } from '@services/api/licenses';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadLicenses,
    loadFailure as loadLicensesFailure,
    loadSuccess as loadLicensesSuccess,
    cancelLoad as cancelLoadLicenses,
} from './licensesSlice';

export function* fetchLicenses() {
    try {
        const { response, cancel }: { response: LicenseResponse; cancel: any } = yield race({
            response: call(getLicenses),
            cancel: take(cancelLoadLicenses),
        });

        if (cancel) {
            return;
        }

        yield put(loadLicensesSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadLicensesFailure(e.message));
    }
}

export function* fetchLicensesSaga() {
    yield takeLatest(loadLicenses, fetchLicenses);
}
