import { FtcModal } from '@shared/components/FtcModal';
import { FtcTable } from '@shared/components/FtcTable';
import { RelativeLoading } from '@shared/components/Loading';
import { FORTIPRODUCT } from '@shared/utils/constants';
import { clientUsersColumns } from './tableConfig';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onBtnClosed: () => void;
    data: { clients: AuthClientData[]; clientUsers: User[] };
    meta: {
        selectedId: string;
        loading: boolean;
        error: string | null | Error;
    };
};

function ClientUsersModal({ isOpen, toggle, onClosed, onBtnClosed, data, meta }: Props) {
    const { clients, clientUsers } = data;
    const { selectedId, loading, error } = meta;
    const modalTitle = clients.find(({ id: clientId }) => clientId === selectedId)?.alias;
    return (
        <FtcModal
            header={`User list for ${FORTIPRODUCT}: ${modalTitle}`}
            size="xl"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            onBtnClosed={onBtnClosed}
        >
            <>{error}</>
            <FtcTable
                id="authClientFortiProductUserListTable"
                data={clientUsers}
                columns={clientUsersColumns}
                progressPending={loading}
                progressComponent={<RelativeLoading />}
                pagination
            />
        </FtcModal>
    );
}

export default ClientUsersModal;
